import { gql } from '@apollo/client';

export const updateRegistrationQuestions_Name = 'UpdateRegistrationQuestion';
export const updateRegistrationQuestions_Gql = gql`
  mutation UpdateRegistrationQuestion($cohortId: String!, $registrationQuestion: RegistrationQuestionInput!) {
    updateRegistrationQuestion(cohortId: $cohortId, registrationQuestion: $registrationQuestion) {
      id
    }
  }
`;
