import { gql } from '@apollo/client';

export const getCohortsForProgramQuery_Name = 'GetCohortsForProgram';
export const getCohortsForProgramQuery_Gql = gql`
  query GetCohortsForProgram($programId: String!) {
    getCohortsForProgram(programId: $programId) {
      id
      startDate
      endDate
      archived
      internalLabel
      registrationCloseDate
      isOngoing
    }
  }
`;
