import DOMPurify from 'dompurify';
import { useForm, SubmitHandler, FieldValues, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Checkbox,
  Stack,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
} from '@mui/material';

import SectionHeader from 'components/learner/SectionHeader/SectionHeader';

import TextSection from '../TextSection/TextSection';

import LikertScaleQuestion from './Questions/LikertScale';

import {
  GetCohortById_getCohortById_registrationQuestions,
  GetCohortDashboard_getCohortDashboard,
} from '../../../models/GeneratedModels';

interface LearnerRegistrationQuestionsProps {
  onSubmit: SubmitHandler<FieldValues>;
  cohort: GetCohortDashboard_getCohortDashboard | null;
  saveButtonText: string;
}

interface QuestionHeaderProps {
  label: string;
  isRequired: boolean;
}

const QuestionHeader = ({ label, isRequired }: QuestionHeaderProps) => {
  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(label),
  });

  return (
    <Stack direction="row" sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>
      <Typography dangerouslySetInnerHTML={sanitizedData()} />
      {isRequired && (
        <Typography ml={1} color="red">
          *
        </Typography>
      )}
    </Stack>
  );
};

const LearnerRegistrationQuestions = ({
  onSubmit,
  cohort,
  saveButtonText,
}: LearnerRegistrationQuestionsProps) => {
  const questions = cohort?.registrationQuestions;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm();

  const watchedFields = watch();

  const getSelectValue = (label: string, value: string | null) => {
    if (!value || value.length === 0) return label;
    return value;
  };

  const renderFields = (
    questions: GetCohortById_getCohortById_registrationQuestions[]
  ) => {
    return questions.map((question) => (
      <div key={question.name} style={{ width: question.type === 'likert' ? '100%' : '600px' }}>
        {question.type === 'text' && (
          <>
            <QuestionHeader
              label={question.title}
              isRequired={question.required}
            />
            <TextField
              size='small'
              id={question.name}
              variant="outlined"
              color="warning"
              {...register(question.name, { required: question.required })}
              sx={{ width: '100%' }}
            />
            {errors[question.name] && (
              <FormHelperText error>Required</FormHelperText>
            )}
          </>
        )}
        {question.type === 'select' && (
          <>
            <QuestionHeader label={question.title} isRequired={question.required} />
            <FormControl fullWidth error={Boolean(errors[question.name])}>
              <Controller
                name={question.name}
                control={control}
                rules={{ required: question.required ? "Required" : false }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Select
                      size='small'
                      {...field}
                      multiple={question.isMultiSelect || false}
                      color="warning"
                      value={question.isMultiSelect ? (field.value || []) : field.value}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (Array.isArray(value) && value.includes('Other')) {
                          // Logic to handle showing "Other" input, if applicable
                        }
                        field.onChange(value);
                      }}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected.map(value =>
                              question.options?.find(option => option.value === value)?.label || value
                            ).join(', ')
                          : selected
                      }
                    >
                      {question.options?.map((option: any, index) => (
                        <MenuItem key={index} value={getSelectValue(option.label, option.value)}>
                          {question.isMultiSelect && (
                            <Checkbox checked={Array.isArray(field.value) ? field.value.includes(option.value) : field.value === option.value} />
                          )}
                          {option.label}
                        </MenuItem>
                      ))}
                      {question.hasOtherOption && (
                        <MenuItem value="Other">
                          {question.isMultiSelect && (
                            <Checkbox checked={Array.isArray(field.value) ? field.value.includes('Other') : field.value === 'Other'} />
                          )}
                          Other - Please Specify
                        </MenuItem>
                      )}
                    </Select>
                    {error && <FormHelperText error>Required</FormHelperText>}
                  </>
                )}
              />
            </FormControl>
            {question.hasOtherOption && watchedFields[question.name]?.includes('Other') && (
              <>
                <TextField
                  size='small'
                  label="Other - Please Specify"
                  {...register(`${question.name}Other`, { required: true })}
                  margin="normal"
                  fullWidth
                  error={errors ? !!errors[`${question.name}Other`] : false}
                />
                {errors[`${question.name}Other`] && (
                  <FormHelperText error>Required</FormHelperText>
                )}
              </>
            )}
          </>
        )}
        {question.type === 'yes/no' && (
          <>
            <FormControl size='small'>
              <QuestionHeader
                label={question.title}
                isRequired={question.required}
              />
              <Controller
                name={question.name}
                control={control}
                rules={{ required: question.required ? 'Selection is required' : false }}
                defaultValue={null}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value={question.yesNo?.yesText || 'Yes'}
                      control={<Radio />}
                      label={question.yesNo?.yesText || 'Yes'}
                    />
                    <FormControlLabel
                      value={question.yesNo?.noText || 'No'}
                      control={<Radio />}
                      label={question.yesNo?.noText || 'No'}
                    />
                  </RadioGroup>
                )}
              />
              {errors[question.name] && (
                <FormHelperText error>Required</FormHelperText>
              )}
            </FormControl>
          </>
        )}
        {question.type === 'likert' && (
          <LikertScaleQuestion question={question} register={register} errors={errors} />
        )}
      </div>
    ));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {questions && (
          <>
            <SectionHeader>Registration Questions</SectionHeader>
            <Box sx={{ textAlign: 'left', mt: 2, width: '100%' }}>
              <Stack spacing={2}>{renderFields(questions)}</Stack>
            </Box>
          </>
        )}
        {cohort?.agreement && cohort.agreement !== '<div><br></div>' && (
          <Box mt={4}>
            <TextSection
              title="Agreement For All Series Participants"
              text={cohort.agreement}
            />
            <FormControlLabel
              control={
                <Checkbox {...register('isAgreed', { required: true })} />
              }
              label="Yes, I Agree"
            />
            {errors['isAgreed'] && (
              <FormHelperText error>Required</FormHelperText>
            )}
          </Box>
        )}
        <Button
          sx={{ mt: 4 }}
          type="submit"
          color="primary"
          variant="contained"
        >
          {saveButtonText}
        </Button>
      </form>
    </>
  );
};

export default LearnerRegistrationQuestions;
