import React from 'react';
import { useQuery } from '@apollo/client';
import { Link as RouterLink, useParams } from 'react-router-dom';

import Container from '@mui/material/Container';
import { Box, Tabs, Tab, Typography, Grid, Button, Tooltip, Link } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

import IdParams from '../../models/params/IdParams';
import PageHeader from '../../components/global/PageHeader';
import ProgressIndicator from '../../components/global/ProgressIndicator';
import RegistrationHistory from '../../components/admin/Account/RegistrationHistory';
import EmailHistory from '../../components/admin/Account/EmailHistory';

import { getFullUserById_Gql } from '../../gql/user/getFullUserById';
import {
  GetFullUserById,
  GetFullUserByIdVariables,
  GetFullUserById_getFullUserById,
} from '../../models/GeneratedModels';
import PermissionDeniedSplash from 'pages/PermissionDeniedSplash';
import { useAuth } from 'hooks/useAuth';

const AccountDetail = () => {
  const { id } = useParams<IdParams>();
  const [value, setValue] = React.useState(0);

  const { loading, data } = useQuery<GetFullUserById, GetFullUserByIdVariables>(getFullUserById_Gql, {
    variables: { id },
  });

  const user: GetFullUserById_getFullUserById | null = data?.getFullUserById ?? null;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const auth = useAuth();
  const isAuthorized = () => {
    return auth.user?.isAlbatross || auth.user?.isAdmin || auth.user?.isEchoStaff || auth.user?.isDevelopmentStaff || auth.user?.isImplementationStaff || auth.user?.isEvaluationStaff;
  };
  
  if (!isAuthorized()) {
    return <PermissionDeniedSplash />;
  }
  
  if (loading || !user) {
    return <ProgressIndicator title="Loading..." isOpen={loading} />;
  }

  return (
    (!isAuthorized()) ? (
      <PermissionDeniedSplash />
    ) : (
      <>
      {isAuthorized() && (
      <Container maxWidth="xl" sx={{ p: 10, mb: 12 }}>
      <PageHeader title={user?.fullName ?? ''} />
      <Box sx={{ width: '100%', textAlign: 'left' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Account" {...a11yProps(0)} />
            <Tab label="Registration History" {...a11yProps(1)} />
            {/* <Tab label="Notes" {...a11yProps(2)} /> */}
            <Tab label="Email History" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Button
            sx={{ mb: 2 }}
            size="small"
            variant="outlined"
            component={RouterLink}
            to={`/admin/account/edit/${id}`}
          >
            Edit Account
          </Button>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6">About</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                      <TableCell>{user.fullName}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                      <TableCell>
                        {user.email}
                        <Tooltip title={`Email ${user.firstName}`}>
                          <Link href={`mailto:${user.email}`}>
                            <EmailIcon sx={{ fontSize: 16, verticalAlign: 'middle', ml: 1 }} />
                          </Link>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Credentials</TableCell>
                      <TableCell>{user.userCredentialsForDisplay}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Profession</TableCell>
                      <TableCell>{user.userProfessionsForDisplay}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Race/Ethnicity</TableCell>
                      <TableCell>{user.userEthnicitiesForDisplay}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Organization</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Organization/Employer</TableCell>
                      <TableCell>{user.organization}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Organization Address</TableCell>
                      <TableCell>
                        {user.organizationAddress1}
                        {user.organizationAddress2 && (
                          <>
                            <br />
                            {user.organizationAddress2}
                          </>
                        )}
                        <br />
                        {user.city}, {user.state} {user.zip}
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Provide Direct Care</TableCell>
                      <TableCell>{user.provideDirectCare ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Provide Medicaid</TableCell>
                      <TableCell>{user.provideMedicaid ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Primary Care Setting</TableCell>
                      <TableCell>{user.primaryCare ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Receive Emails</TableCell>
                      <TableCell>{user.receiveEmails ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RegistrationHistory registrations={user.cohortRegistrationsAsUsers} />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          Notes
        </TabPanel> */}
        <TabPanel value={value} index={2}>
          <EmailHistory userId={id} />
        </TabPanel>
      </Box>
      </Container>
      )}
    </>
  ));
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, textAlign: 'left' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default AccountDetail;
