import { makeStyles } from '@mui/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import DateRangeIcon from '@mui/icons-material/DateRange';
import PeopleIcon from '@mui/icons-material/People';
import GroupIcon from '@mui/icons-material/Group';
import EmailIcon from '@mui/icons-material/Email';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditIcon from '@mui/icons-material/Edit';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

type CohortBottomNavProps = {
  activeComponent: number;
  switchComponent: Function;
};

const CohortBottomNav = ({ activeComponent, switchComponent }: CohortBottomNavProps) => {
  const useStyles: any = makeStyles({
    stickyBottom: {
      position: 'fixed',
      bottom: 95,
      width: '95%',
    },
    container: {
      display: 'flex',
      'justify-content': 'space-around',
      zIndex: 100,
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <BottomNavigation
        value={activeComponent}
        onChange={(event, newValue) => {
          switchComponent(newValue);
        }}
        showLabels
        className={classes.stickyBottom}
      >
        <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} />
        <BottomNavigationAction label="Edit" icon={<EditIcon />} />
        <BottomNavigationAction label="Registration Questions" icon={<HelpCenterIcon />} />
        <BottomNavigationAction label="Sessions" icon={<DateRangeIcon />} />
        <BottomNavigationAction label="Registrants" icon={<PeopleIcon />} />
        <BottomNavigationAction label="Email" icon={<EmailIcon />} />
        <BottomNavigationAction label="Hub" icon={<GroupIcon />} />
      </BottomNavigation>
    </div>
  );
};

export default CohortBottomNav;
