import { useState, useContext, createContext, ReactNode, useEffect } from 'react';
import { ApolloError, useApolloClient, useMutation } from '@apollo/client';
import { clarity } from 'react-microsoft-clarity';
import Cookies from 'js-cookie';

import { encrypt } from 'components/account/Password/Password.helpers';
import { LoginUserMutation_Gql } from '../gql/user/loginUserMutation';
import { LogoutUserMutation_Gql } from '../gql/user/logoutUserMutation';
import { LoginUser, LoginUserVariables, LogoutUser } from '../models/GeneratedModels';
import Auth from '../models/Auth.model';
import { USER_ID, USER_EMAIL, USER_FULLNAME, USER_ROLES } from '../utils/constants';
import { currentEnvironment } from 'helpers/tenantHelpers';
import { ExtendSessionMutation_Gql } from '../gql/user/extendSession';

interface AuthContext {
  user: Auth | null;
  signIn: (username: string, password: string) => Promise<LoginResult>;
  signOut: () => void;
  signInErrors: ApolloError | undefined;
  isLoading: boolean;
  extendAdminSession: () => Promise<boolean>;
  adminSessionContinue: () => boolean;
}

const authContext = createContext<AuthContext>(undefined!);

interface ProvideAuthProps {
  children: ReactNode;
}

export const ProvideAuth = ({ children }: ProvideAuthProps) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

export interface LoginResult {
  isAdmin: boolean;
  isSuccessful: boolean;
  needsPasswordReset: boolean;
  isRegistrationComplete: boolean;
  errorMessage: string | null;
}

export function useProvideAuth() {
  const client = useApolloClient();

  const [user, setUser] = useState<Auth | null>(() => getAuthValuesFromSession());
  const [signInErrors, setSignInErrors] = useState<ApolloError | undefined>();
  const [isLoading, setIsLoading] = useState(true);  // Start with loading true
  const [extendSession, { error: extendSessionError, loading: extendSessionLoading }] = useMutation(ExtendSessionMutation_Gql);

  const [logUserIn, { error: loginError, loading: loginLoading }] = useMutation<LoginUser, LoginUserVariables>(LoginUserMutation_Gql);
  const [logUserOut, { error: logoutError, loading: logoutLoading }] = useMutation<LogoutUser>(LogoutUserMutation_Gql);

  useEffect(() => {
    setSignInErrors(loginError || logoutError || extendSessionError);
  }, [loginError, logoutError, extendSessionError]);

  useEffect(() => {
    setIsLoading(loginLoading || logoutLoading || extendSessionLoading);
  }, [loginLoading, logoutLoading, extendSessionLoading]);

  useEffect(() => {
    // Check authentication status on mount
    const checkAuth = async () => {
      const authUser = getAuthValuesFromSession();
      if (authUser.isAuthenticated) {
        setUser(authUser);
      } else {
        setUser(null);
      }
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  const signIn = async (emailAddress: string, password: string): Promise<LoginResult> => {
    try {
      const loginResult = (
        await logUserIn({
          variables: {
            credentials: {
              email: emailAddress,
              password: encrypt(password),
            },
          },
        })
      ).data?.loginUser;

      if (!loginResult) throw new Error('Login failed');

      if (!loginResult.isValid) {
        return {
          isAdmin: false,
          isSuccessful: loginResult.isValid,
          needsPasswordReset: loginResult.needsPasswordReset,
          isRegistrationComplete: loginResult && loginResult.isRegistrationComplete ? true : false,
          errorMessage: loginResult.errorMessage,
        };
      }

      const { userProfile } = loginResult;

      if (!userProfile) throw new Error('User profile was null');
      // console.log(userProfile);
      const { roles, id, email, firstName, lastName } = userProfile;

      const isAdmin = roles.indexOf('Administrator') > -1 ? true : false;
      const isAlbatross = roles.indexOf('Albatross') > -1 ? true : false;
      const isEchoStaff = roles && roles.indexOf('ECHOStaff') > -1 ? true : false;
      const isDevelopmentStaff = roles && roles.indexOf('development') > -1 ? true : false;
      const isImplementationStaff = roles && roles.indexOf('implementation') > -1 ? true : false;
      const isEvaluationStaff = roles && roles.indexOf('evaluation') > -1 ? true : false;
      const isPartner = roles && roles.indexOf('Partner') > -1 ? true : false;
      const isRegistrant = roles && roles.indexOf('Registrant') > -1 ? true : false;

      try {
        // Check if Clarity has been initialized before calling its methods
        if (clarity.hasStarted()) {
          // Identify the user
          clarity.identify('USER_ID', { userProperty: `${email}` });
          // Cookie consent
          // clarity.consent();
          // Setup a custom tag
          clarity.setTag('user_role_admin', `${isAdmin}`);
          clarity.setTag('user_role_echoStaff', `${isEchoStaff}`);
          clarity.setTag('user_role_partner', `${isPartner}`);
          clarity.setTag('user_full_name', `${firstName} ${lastName}`);
          clarity.setTag('environment', `${currentEnvironment}`);
          // Upgrade session
          // clarity.upgrade('upgradeReason');
        }
      } catch (err) {
        console.log('There was an error setting Clarity variables', err);
      }

      // Used throughout the context to identify the user
      setUser({
        id: id,
        email: email,
        fullName: `${firstName} ${lastName}`,
        userRoles: roles,
        isRegistrationComplete: loginResult.isRegistrationComplete,
        isAuthenticated: true,
        isAdmin,
        isAlbatross,
        isRegistrant,
        isEchoStaff,
        isDevelopmentStaff,
        isImplementationStaff,
        isEvaluationStaff,
        isPartner,
      });

      // Set cookies instead of localStorage
      Cookies.set(USER_ID, id || '', { secure: true, sameSite: 'strict' });
      Cookies.set(USER_EMAIL, email || '', { secure: true, sameSite: 'strict' });
      Cookies.set(USER_FULLNAME, `${firstName} ${lastName}`, { secure: true, sameSite: 'strict' });
      Cookies.set(USER_ROLES, roles || '', { secure: true, sameSite: 'strict' });

      return {
        isAdmin: isAdmin || isAlbatross || isEchoStaff || isDevelopmentStaff || isImplementationStaff || isEvaluationStaff || isPartner,
        isSuccessful: true,
        isRegistrationComplete: loginResult.isRegistrationComplete,
        needsPasswordReset: false,
        errorMessage: null,
      };
    } catch (error: any) {
      console.log('signIn Error', error);
      return {
        isAdmin: false,
        isSuccessful: false,
        isRegistrationComplete: false,
        needsPasswordReset: false,
        errorMessage: error,
      };
    }
  };

  const signOut = async () => {
    try {
      const { data } = await logUserOut();
      if (data?.logout?.success) {
        client.clearStore();

        // Remove cookies instead of localStorage items
        Cookies.remove(USER_ID);
        Cookies.remove(USER_EMAIL);
        Cookies.remove(USER_FULLNAME);
        Cookies.remove(USER_ROLES);

        setUser(getAuthValuesFromSession());
      } else {
        console.error('Logout failed:', data?.logout);
      }
    } catch (error) {
      console.error('Logout error', error);
    }
  };

  function getAuthValuesFromSession(): Auth {
    if (!Cookies.get(USER_ID)) {
      return {
        email: null,
        fullName: null,
        id: null,
        isAdmin: false,
        isAlbatross: false,
        isAuthenticated: false,
        isRegistrant: false,
        isEchoStaff: false,
        isDevelopmentStaff: false,
        isImplementationStaff: false,
        isEvaluationStaff: false,
        isPartner: false,
        userRoles: null,
        isRegistrationComplete: false,
      };
    }

    const roles = Cookies.get(USER_ROLES) || '';
    const isAdmin = roles.indexOf('Administrator') > -1;
    const isAlbatross = roles.indexOf('Albatross') > -1;
    const isEchoStaff = roles.indexOf('ECHOStaff') > -1;
    const isDevelopmentStaff = roles.indexOf('development') > -1;
    const isImplementationStaff = roles.indexOf('implementation') > -1;
    const isEvaluationStaff = roles.indexOf('evaluation') > -1;
    const isPartner = roles.indexOf('Partner') > -1;
    const isRegistrant = roles.indexOf('Registrant') > -1;

    return {
      id: Cookies.get(USER_ID) || null,
      email: Cookies.get(USER_EMAIL) || null,
      fullName: Cookies.get(USER_FULLNAME) || null,
      userRoles: roles,
      isRegistrationComplete: true,
      isAuthenticated: true,
      isAdmin,
      isAlbatross,
      isRegistrant,
      isEchoStaff,
      isDevelopmentStaff,
      isImplementationStaff,
      isEvaluationStaff,
      isPartner,
    };
  };

  const adminSessionContinue = (): boolean => {
    const authUser = getAuthValuesFromSession();

    if (authUser.isAuthenticated) {
      setUser(authUser);
    } else {
      setUser(null);
    }
    // console.log('Admin session continue successfully');

    return true;
  };

  const extendAdminSession = async (): Promise<boolean> => {
    try {
      const { data } = await extendSession();
      if (data && data.extendToken && data.extendToken.isValid) {
        const authUser = getAuthValuesFromSession();

        if (data && data.extendToken && data.extendToken.isValid) {
          const authUser = getAuthValuesFromSession();
   
          if (authUser.isAuthenticated) {
            setUser(authUser);
          } else {
            setUser(null);
          }
          console.log('Admin session extended successfully');
          return true;
        }
      }
      console.error('Failed to extend session:', data?.extendToken?.errorMessage);
      return false;
    } catch (error) {
      console.error('Error extending admin session:', error);
      return false;
    }
  };

  return {
    user,
    signIn,
    signOut,
    signInErrors,
    isLoading,
    extendAdminSession,
    adminSessionContinue,
  };
}
