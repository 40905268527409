import dayjs from 'dayjs';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { GetFullUserById_getFullUserById_cohortRegistrationsAsUsers } from '../../../models/GeneratedModels';

interface RegistrationHistoryProps {
  registrations: GetFullUserById_getFullUserById_cohortRegistrationsAsUsers[] | null;
}

const RegistrationHistory = ({ registrations }: RegistrationHistoryProps) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Program Name</TableCell>
              <TableCell align="center">Registration Date</TableCell>
              <TableCell align="center">Start Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registrations &&
              registrations.map((r) => (
                <TableRow key={r.cohort?.program?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {r.cohort?.program?.name}
                  </TableCell>
                  <TableCell align="center">{dayjs(r.createdAt).format('MM/DD/YYYY')}</TableCell>
                  <TableCell align="center">
                    {r.cohort?.startDate ? dayjs(r.cohort.startDate).format('MM/DD/YYYY') : ''}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RegistrationHistory;
