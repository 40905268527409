import { useState, useEffect, ChangeEvent } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { useAuth } from 'hooks/useAuth';
import { accountLinks } from 'utils/links';
import { isEmpty, validateEmail } from 'utils/validators';

import ProgressIndicator from 'components/global/ProgressIndicator';
import StyledRouterLink from 'components/global/StyledRouterLink';
import SuccessSnackbar from 'components/global/SuccessSnackbar';

import { getUserById_Gql } from 'gql/user/getUserById';
import { updateStaffUser_Gql } from 'gql/user/updateStaffUser';

import { AddOrUpdateStaffUserInput } from 'models/GeneratedModels';
import { GetUserById, GetUserByIdVariables } from 'models/GeneratedModels';
import { UpdateStaffUser, UpdateStaffUserVariables } from 'models/GeneratedModels';
import { Box } from '@mui/material';

const MyStaffAccount = () => {
  const auth = useAuth();

  const [errorText, setErrorText] = useState('');
  const [successNotificationOpen, setSuccessNotifcationOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<AddOrUpdateStaffUserInput>({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
  });

  const { loading, error, data } = useQuery<GetUserById, GetUserByIdVariables>(getUserById_Gql, {
    variables: {
      id: auth.user?.id ?? '',
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (loading || !data) return;

    const user = data.getUserById;

    setUserToEdit({
      id: user?.id,
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? '',
      password: '',
      partnerId: '',
    });
  }, [loading, data]);

  const [updateStaffAccount, { loading: saving, error: savingError }] = useMutation<
    UpdateStaffUser,
    UpdateStaffUserVariables
  >(updateStaffUser_Gql);

  const onFormElementChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setUserToEdit({
      ...userToEdit,
      [name]: value,
    });
  };

  const isValid = (): boolean => {
    setErrorText('');

    if (isEmpty(userToEdit.firstName) || isEmpty(userToEdit.lastName) || isEmpty(userToEdit.email)) {
      setErrorText('All fields are required');
      return false;
    }

    if (!validateEmail(userToEdit.email)) {
      setErrorText('Invalid email address');
      return false;
    }

    return true;
  };

  const saveUser = async () => {
    if (isValid()) {
      try {
        await updateStaffAccount({
          variables: {
            user: {
              id: userToEdit.id,
              email: userToEdit.email,
              firstName: userToEdit.firstName,
              lastName: userToEdit.lastName,
            },
          },
        });

        setSuccessNotifcationOpen(true);
      } catch {}
    }
  };

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Loading..." />
      <ProgressIndicator isOpen={saving} title="Saving..." />
      <Box sx={{ mt: 3, pb: 2, textAlign: 'center' }}>
        <StyledRouterLink text="Change Your Password" to={accountLinks.changePassword.href} />

        {error && <Alert severity="error">{error.graphQLErrors[0].message}</Alert>}
        {savingError && <Alert severity="error">{savingError.graphQLErrors[0].message}</Alert>}
        {errorText && <Alert severity="error">{errorText}</Alert>}

        <Grid container spacing={2} sx={{ pb: 2, mt: 4, textAlign: 'center' }}>
          <Grid item lg={12} xs={12} sx={{ mb: 2 }}>
            <TextField
              onChange={onFormElementChange}
              sx={{ width: '400px' }}
              value={userToEdit.firstName}
              name="firstName"
              color="warning"
              label="First Name"
            />
          </Grid>

          <Grid item xs={12} sx={{ mb: 2 }}>
            <TextField
              onChange={onFormElementChange}
              sx={{ width: '400px' }}
              value={userToEdit.lastName}
              name="lastName"
              color="warning"
              label="Last Name"
            />
          </Grid>

          <Grid item xs={12} sx={{ mb: 2 }}>
            <TextField
              onChange={onFormElementChange}
              sx={{ width: '400px' }}
              value={userToEdit.email}
              name="email"
              color="warning"
              label="Email"
            />
          </Grid>

          <Grid item xs={12} sx={{ mb: 2 }}>
            <Button color="primary" onClick={saveUser} variant="contained" sx={{ width: '200px' }}>
              Save
            </Button>
          </Grid>
        </Grid>

        <SuccessSnackbar isOpen={successNotificationOpen} text="Account has been saved" />
      </Box>
    </>
  );
};

export default MyStaffAccount;
