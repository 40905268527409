import { useState, ChangeEvent } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import ProgressIndicator from 'components/global/ProgressIndicator';
import PasswordRequirements from 'components/account/Password/PasswordRequirements';
import { validatePassword } from 'components/account/Password/Password.helpers';

import { UpdatePassword_Gql } from 'gql/user/updatePassword';
import { UpdatePassword as UpdatePasswordResult, UpdatePasswordVariables } from 'models/GeneratedModels';
import { loginLink } from 'utils/links';

interface PasswordFields {
  password: string;
  confirmPassword: string;
}

interface ChangePasswordProps {
  userId: string;
  redirectToLogin: boolean;
}

const UpdatePassword = ({ userId, redirectToLogin }: ChangePasswordProps) => {
  const history = useHistory();

  const [errorText, setErrorText] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [password, setPassword] = useState<PasswordFields>({
    password: '',
    confirmPassword: '',
  });

  const [savePassword, { loading }] = useMutation<UpdatePasswordResult, UpdatePasswordVariables>(UpdatePassword_Gql);

  const onFormElementChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setPassword({
      ...password,
      [name]: value,
    });
  };

  const savePasswordUpdate = async () => {
    setErrorText('');

    const passwordValidation = validatePassword(password.password, password.confirmPassword);
    if (passwordValidation.isValid) {
      try {
        await savePassword({
          variables: {
            userId,
            password: password.password,
          },
        });

        setPassword({ password: '', confirmPassword: '' });
        setShowConfirmation(true);

        if (redirectToLogin) {
          history.push(loginLink.href);
        }
      } catch (error: any) {
        setErrorText(error.graphQLErrors[0].message);
      }
    } else {
      setErrorText(passwordValidation.message);
    }
  };

  // Function to check if all required fields are filled
  const areAllFieldsFilled = () => {
    return password.password && password.confirmPassword;
  };

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Saving..." />

      <Grid container>
        <Grid xs={3}></Grid>
        <Grid xs={6}>
          <Stack spacing={2}>
            {showConfirmation && <Alert severity="success">Password has been updated</Alert>}

            <TextField
              autoFocus
              margin="dense"
              name="password"
              label="Password"
              type="password"
              variant="outlined"
              color="warning"
              value={password.password}
              onChange={onFormElementChange}
              required
            />
            <PasswordRequirements />
            <TextField
              sx={{ mt: 2 }}
              margin="dense"
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              variant="outlined"
              color="warning"
              value={password.confirmPassword}
              onChange={onFormElementChange}
              required
            />

            {errorText.length > 0 && <Alert severity="error">{errorText}</Alert>}

            <Box sx={{ textAlign: 'right' }}>
              <Button 
                onClick={savePasswordUpdate} 
                variant="contained" 
                color="primary"
                disabled={!areAllFieldsFilled()} // Disable button if fields are not filled
              >
                Update
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid xs={3}></Grid>
      </Grid>
    </>
  );
};

export default UpdatePassword;
