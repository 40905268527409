import { useEffect, useState } from 'react';
import { Paper, Typography, Button, Box } from '@mui/material';
import Cookies from 'js-cookie';

import { useAuth } from 'hooks/useAuth';

const CookieConsent = () => {
  const { user } = useAuth();

  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    if (user?.id) {
      // Reset consent state when user changes
      setShowConsent(false);
      
      // Check if current user has consented
      const hasConsented = Cookies.get(`cookie-consent-${user.id}`);
      if (!hasConsented) {
        setShowConsent(true);
      }
    }
  }, [user?.id]);

  const handleConsent = () => {
    if (user?.id) {
      Cookies.set(`cookie-consent-${user.id}`, 'true', { expires: 365 });
      setShowConsent(false);
    }
  };

  // Only show if we have a user ID and consent is needed
  if (!user?.id || !showConsent) return null;

  return (
    <Paper
      elevation={3}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        p: 4,
        backgroundColor: '#2B373B',
        borderTop: 1,
        borderColor: 'divider',
        zIndex: 1300
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          mx: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: 2
        }}
      >
        <Typography variant="body1" sx={{ flex: 1, color: "white" }}>
          By using this site, you agree with our use of cookies. 
          Please feel free to contact support for more information
        </Typography>
        <Button
          variant="contained"
          onClick={handleConsent}
          sx={{
            whiteSpace: 'nowrap',
            background: '#f5f05f',
            color: '#2B373B',
            '&:hover': {
              backgroundColor: '#f5f05f'
            }
          }}
        >
          I Understand
        </Button>
      </Box>
    </Paper>
  );
};

export default CookieConsent;
