import { InputLabel, Select, MenuItem, FormControl, Grid } from '@mui/material';
import {
  COLORADO_TENANT_ID,
  OREGON_TENANT_ID,
  VIRGINIA_TENANT_ID,
  DARTMOUTH_TENANT_ID,
  CS_TENANT_ID,
  DEMO_TENANT_ID,
} from 'utils/constants';
import { getTenantIdForDomain } from 'helpers/tenantHelpers';
// import LearnerAccountDetailOregonOrg from './org/LearnerAccountDetailOregonOrg';
// import LearnerAccountDetailColoradoOrg from './org/LearnerAccountDetailColoradoOrg';
// import LearnerAccountDetailVirginiaOrg from './org/LearnerAccountDetailVirginiaOrg';
// import LearnerAccountDetailDartmouthOrg from './org/LearnerAccountDetailDartmouthOrg';
// import LearnerAccountDetailCSOrg from './org/LearnerAccountDetailCSOrg';

const yesNo: string[] = ['yes', 'no'];

const SelectDirectCare = ({ classes, onChange, value }: any) => {
  return (
    <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
      {yesNo.length > 0 && (
        <FormControl>
          <InputLabel required id="direct-care-select-label" color="warning">
            Do you provide direct care to patients/clients?
          </InputLabel>
          <Select
            required
            className={classes.accountText}
            labelId="direct-care-select-label"
            id="direct-care-select"
            label="Do you provide direct care to patients/clients?"
            color="warning"
            onChange={onChange}
            value={value === null ? '' : value === true ? 'yes' : 'no'}
            sx={{ textAlign: 'left' }}
          >
            <MenuItem value={''} disabled>
              Select One
            </MenuItem>
            {yesNo.map((item: any, index: number) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
};

const SelectProvideMedicaid = ({ classes, onChange, value }: any) => {
  return (
    <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
      {yesNo.length > 0 && (
        <FormControl>
          <InputLabel
            required
            id="provide-medicaid-select-label"
            color="warning"
          >
            Are you a medicaid provider?
          </InputLabel>
          <Select
            required
            className={classes.accountText}
            labelId="provide-medicaid-select-label"
            id="provide-medicaid-select"
            label="Are you a medicaid provider?"
            color="warning"
            onChange={onChange}
            sx={{ textAlign: 'left' }}
            value={value === null ? '' : value === true ? 'yes' : 'no'}
          >
            <MenuItem value={''} disabled>
              Select One
            </MenuItem>
            {yesNo.map((item: any, index: number) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
};

const SelectPrimaryCare = ({ classes, onChange, value }: any) => {
  return (
    <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
      {yesNo.length > 0 && (
        <FormControl>
          <InputLabel required id="primary-care-select-label" color="warning">
            Do you work in a primary care setting?
          </InputLabel>
          <Select
            required
            className={classes.accountText}
            labelId="primary-care-select-label"
            id="primary-care-select"
            label="Do you work in a primary care setting?"
            color="warning"
            onChange={onChange}
            sx={{ textAlign: 'left' }}
            value={value === null ? '' : value === true ? 'yes' : 'no'}
          >
            <MenuItem value={''} disabled>
              Select One
            </MenuItem>
            {yesNo.map((item: any, index: number) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
};

const TentantCareFields = ({
  user,
  classes,
  onDirectCareChange,
  onProvideMedicaidChange,
  onPrimaryCareChange,
}: any) => {
  const tenantId = getTenantIdForDomain();

  if (tenantId === COLORADO_TENANT_ID || tenantId === DEMO_TENANT_ID) {
    return (
      <>
        <SelectDirectCare
          classes={classes}
          value={user.provideDirectCare}
          onChange={onDirectCareChange}
        />
        <SelectProvideMedicaid
          classes={classes}
          value={user.provideMedicaid}
          onChange={onProvideMedicaidChange}
        />
        <SelectPrimaryCare
          classes={classes}
          value={user.primaryCare}
          onChange={onPrimaryCareChange}
        />
      </>
    );
  }

  if (tenantId === OREGON_TENANT_ID) {
    return (
      <>
        <SelectDirectCare
          classes={classes}
          value={user.provideDirectCare}
          onChange={onDirectCareChange}
        />
        <SelectProvideMedicaid
          classes={classes}
          value={user.provideMedicaid}
          onChange={onProvideMedicaidChange}
        />
        <SelectPrimaryCare
          classes={classes}
          value={user.primaryCare}
          onChange={onPrimaryCareChange}
        />
      </>
    );
  }

  if (tenantId === VIRGINIA_TENANT_ID) {
    return (
      <SelectDirectCare
        classes={classes}
        value={user.provideDirectCare}
        onChange={onDirectCareChange}
      />
    );
  }

  if (tenantId === DARTMOUTH_TENANT_ID) {
    return (
      <>
        <SelectDirectCare
          classes={classes}
          value={user.provideDirectCare}
          onChange={onDirectCareChange}
        />
        <SelectProvideMedicaid
          classes={classes}
          value={user.provideMedicaid}
          onChange={onProvideMedicaidChange}
        />
        <SelectPrimaryCare
          classes={classes}
          value={user.primaryCare}
          onChange={onPrimaryCareChange}
        />
      </>
    );
  }

  if (tenantId === CS_TENANT_ID) {
    return (
      <>
        <SelectDirectCare
          classes={classes}
          value={user.provideDirectCare}
          onChange={onDirectCareChange}
        />
        <SelectProvideMedicaid
          classes={classes}
          value={user.provideMedicaid}
          onChange={onProvideMedicaidChange}
        />
        <SelectPrimaryCare
          classes={classes}
          value={user.primaryCare}
          onChange={onPrimaryCareChange}
        />
      </>
    );
  }

  return <></>;
};

export default TentantCareFields;
