import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import { Grid, TextField, Card, CardContent, Button, Typography, MenuItem, InputLabel, FormControl, FormHelperText } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import AddStaffAccount from '../../components/admin/Account/AddStaffAccount';
import PageHeader from '../../components/global/PageHeader';
import AccountList from '../../components/admin/Account/AccountList';
import ProgressIndicator from '../../components/global/ProgressIndicator';

import { SearchUsersByField, SearchUsersByFieldVariables } from '../../models/GeneratedModels';
import { searchUserByField_Gql } from '../../gql/user/searchUserByField';
import PermissionDeniedSplash from 'pages/PermissionDeniedSplash';

import { useAuth } from 'hooks/useAuth';

interface SearchProperties {
  search: string;
  field: string;
}

const charLimits = {
  email: 4,
  default: 2
};

const Accounts = () => {
  const [searchProperties, setSearchProperties] = useState<SearchProperties>({ search: '', field: 'lastName' });
  const [searchWasRun, setSearchWasRun] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [searchAccounts, { loading, data }] = useLazyQuery<SearchUsersByField, SearchUsersByFieldVariables>(
    searchUserByField_Gql,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const auth = useAuth();
  const isAuthorized = () => {
    return auth.user?.isAlbatross || auth.user?.isAdmin || auth.user?.isEchoStaff || auth.user?.isDevelopmentStaff || auth.user?.isImplementationStaff || auth.user?.isEvaluationStaff; // auth.user?.isPartner;
  };

  const validateInput = (): boolean => {
    const limit = searchProperties.field === 'email' ? charLimits.email : charLimits.default;
    if (searchProperties.search.length < limit) {
      setError(`Search term must be at least ${limit} characters.`);
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !error) {
      e.preventDefault();
      onSearch();
    }
  };  

  const onSearch = () => {
    if (!validateInput()) {
      return;
    }
    setSearchWasRun(true);
    searchAccounts({
      variables: {
        search: searchProperties.search,
        field: searchProperties.field,
      },
    });
  };

  const onFormElementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchProperties({
      ...searchProperties,
      search: e.target.value,
    });

    setError(null);
  };

  const onFieldChange = (event: SelectChangeEvent<string>) => {
    setSearchProperties({
      ...searchProperties,
      field: event.target.value,
    });

    setError(null);
  };

  const onReset = () => {
    setSearchWasRun(false);
    setSearchProperties({ search: '', field: 'firstName' });
    setError(null);
  };

  const users = data?.searchUsersByField ?? [];

  if (!isAuthorized()) {
    return <PermissionDeniedSplash />;
  }

  return (
    (!isAuthorized()) ? (
      <PermissionDeniedSplash />
    ) : (
      <>
      {isAuthorized() && (
      <>
      <ProgressIndicator isOpen={loading} title="Loading..." />
      <Container maxWidth="xl" sx={{ p: 10, mb: 6 }}>
        <PageHeader title="Accounts" />

        <AddStaffAccount />

        <Card sx={{ width: '100%', mt: 2, mb: 3, textAlign: 'left' }}>
          <CardContent>
            <Typography sx={{ marginBottom: '20px' }} gutterBottom variant="h5" component="div">
              User Search
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Search Field</InputLabel>
                  <Select
                    size='small'
                    value={searchProperties.field}
                    label="Search Field"
                    onChange={onFieldChange}
                  >
                    <MenuItem value="lastName">Last Name</MenuItem>
                    <MenuItem value="firstName">First Name</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="organization">Organization</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  sx={{ width: '100%' }}
                  size='small'
                  label="Search"
                  value={searchProperties.search}
                  onChange={onFormElementChange}
                  error={!!error}
                  helperText={error || ' '}
                  onKeyDown={onKeyDown}
                />
              </Grid>
              <Grid item xs={3}>
                <Button onClick={onSearch} sx={{ width: '40%' }} color="primary" variant="contained" disabled={!!error}>
                  Search
                </Button>
                <Button onClick={onReset} sx={{ width: '40%', ml: 2 }} color="primary" variant="outlined">
                  Reset
                </Button>
              </Grid>
            </Grid>
            <Typography variant="caption">Select a field and enter a search term</Typography>
          </CardContent>
        </Card>

        {!loading && <AccountList users={users} searchWasRun={searchWasRun} />}
      </Container>
    </>
    )}
    </>
  ));
};

export default Accounts;
