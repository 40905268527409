import { gql } from '@apollo/client';

export const waitlistCohortRegistrationMutation_Name = 'WaitlistCohortRegistration';
export const waitlistCohortRegistrationMutation_Gql = gql`
  mutation WaitlistCohortRegistration($cohortRegistration: WaitlistCohortRegistrationInput!) {
    waitlistCohortRegistration(cohortRegistration: $cohortRegistration) {
      id
      updatedAt
    }
  }
`;
