import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { getAllOpenCohorts_Gql } from '../../gql/cohort/getAllOpenCohorts';
import { GetAllOpenCohorts, GetAllOpenCohorts_getAllOpenCohorts } from '../../models/GeneratedModels';

import ProgressIndicator from 'components/global/ProgressIndicator';
import CurrentProjectList from '../../components/admin/CurrentProjects/CurrentProjectList';
import { Container } from '@mui/material';
import PermissionDeniedSplash from 'pages/PermissionDeniedSplash';
import { useAuth } from 'hooks/useAuth';

const CurrentProjects = () => {
  const [statusQuery, setStatusQuery] = useState<string>('Live');

  const { loading, data } = useQuery<GetAllOpenCohorts>(getAllOpenCohorts_Gql, {
    fetchPolicy: 'no-cache',
    variables: {
      status: statusQuery,
    }
  });

  const cohorts: GetAllOpenCohorts_getAllOpenCohorts[] = data?.getAllOpenCohorts ?? [];

  const auth = useAuth();
  const isAuthorized = () => {
    return auth.user?.isAlbatross || auth.user?.isAdmin || auth.user?.isEchoStaff || auth.user?.isDevelopmentStaff || auth.user?.isImplementationStaff || auth.user?.isEvaluationStaff || auth.user?.isPartner;
  };
  
  if (!isAuthorized()) {
    return <PermissionDeniedSplash />;
  }

  return (
    (!isAuthorized()) ? (
      <PermissionDeniedSplash />
    ) : (
      <>
      {isAuthorized() && (
      <>
      <ProgressIndicator isOpen={loading} title="Loading..." />
      <Container maxWidth="xl" sx={{ p: 10, mb: 6 }}>
        <CurrentProjectList title="All Current Projects" cohorts={cohorts} statusQuery={statusQuery} setStatusQuery={setStatusQuery} />
      </Container>
      </>
      )}
    </>
  ));
};

export default CurrentProjects;
