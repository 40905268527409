import { gql } from '@apollo/client';

export const searchUserByField_Name = 'SearchUsersByField';
export const searchUserByField_Gql = gql`
  query SearchUsersByField($search: String, $field: String) {
    searchUsersByField(search: $search, field: $field) {
      id
      firstName
      lastName
      email
      userCredentialsForDisplay
      organization
      userRolesForDisplay
      partnerId
    }
  }
`;
