import { gql } from '@apollo/client';

export const deleteRegistrationQuestion_Name = 'DeleteRegistrationQuestion';
export const deleteRegistrationQuestion_Gql = gql`
  mutation DeleteRegistrationQuestion($cohortId: String!, $registrationQuestionId: String!) {
    deleteRegistrationQuestion(cohortId: $cohortId, registrationQuestionId: $registrationQuestionId) {
      id
    }
  }
`;
