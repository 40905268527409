import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

export interface BreadcrumbItem {
  to?: string;
  text: string;
  isActive: boolean;
}

interface BreadcrumbProps {
  breadcrumbs: BreadcrumbItem[];
}

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbProps) => {
  const count = breadcrumbs.length - 1;

  return (
    <>
      <Box sx={{ textAlign: 'left', mb: 2 }}>
        {breadcrumbs &&
          breadcrumbs.map((b: BreadcrumbItem, index: number) => (
            <>
              <span>
                {b.isActive && b.to ? (
                  <Link component={RouterLink} to={b.to}>
                    {b.text}
                  </Link>
                ) : (
                  <>{b.text}</>
                )}

                {index < count ? <>&nbsp;&nbsp;/&nbsp;&nbsp;</> : <></>}
              </span>
            </>
          ))}
      </Box>
    </>
  );
};

export default Breadcrumbs;
