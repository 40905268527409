import React from 'react';
import {
  Box,
  Stack,
  Typography,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel,
  RadioGroup,
  useTheme
} from '@mui/material';
import DOMPurify from 'dompurify';
import { GetCohortById_getCohortById_registrationQuestions } from '../../../../models/GeneratedModels';

interface LikertScaleQuestionProps {
  question: GetCohortById_getCohortById_registrationQuestions;
  register: Function;
  errors: any;
}

const LikertScaleQuestion = ({ question, register, errors }: LikertScaleQuestionProps) => {
  const theme = useTheme();
  const sanitizedTitle = () => ({
    __html: DOMPurify.sanitize(question?.title),
  });

  const hasErrors = Object.keys(errors).some(errorKey =>
    errorKey.startsWith(question.name)
  );

  return (
    <Box>
      <Stack direction="row">
        <Typography component="div" dangerouslySetInnerHTML={sanitizedTitle()} />
        {question.required && (
          <Typography ml={1} color="primary.main">
            *
          </Typography>
        )}
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {question.scale?.map((scale, index) => (
              <TableCell key={index} align="center">{scale.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {question.statements?.map((statement, rowIndex) => {
            const hasStatementError = !!errors[`${question.name}-${statement.name}`];
            return (
              <TableRow key={rowIndex}>
                <TableCell component="th" scope="row" style={{ color: hasStatementError ? theme.palette.primary.main : 'inherit' }}>
                  {statement.statement}
                </TableCell>
                <RadioGroup
                  row
                  name={`${question.name}-${statement.name}`}
                  defaultValue=""
                  style={{ display: 'contents' }}
                >
                  {question.scale?.map((scale, colIndex) => (
                    <TableCell key={colIndex} align="center">
                      <FormControlLabel
                        value={scale.label}
                        control={<Radio {...register(`${question.name}-${statement.name}`, { required: question.required })} />}
                        label=""
                        style={{ margin: 0 }}
                      />
                    </TableCell>
                  ))}
                </RadioGroup>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {hasErrors && (
        <Typography color="primary.main" sx={{ mt: 2 }}>
          All questions in this section are required.
        </Typography>
      )}
    </Box>
  );
};

export default LikertScaleQuestion;
