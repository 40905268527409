import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Drawer, Button, Typography } from '@mui/material';
import { TextField, Stack } from '@mui/material';

import { FaPlus } from 'react-icons/fa';

import { AddCohortToProgramInput } from '../../../models/GeneratedModels';
import { addCohortToProgramMutation_Gql } from '../../../gql/cohort/addCohortToProgramMutation';
import { getCohortsForProgramQuery_Name } from '../../../gql/cohort/getCohortsForProgramQuery';
import { getProgramById_Name } from 'gql/program/getProgramByIdQuery';

import GqlErrorDisplay from 'components/global/GqlErrorDisplay';
import ProgressIndicator from '../../global/ProgressIndicator';
import SuccessSnackbar from '../../global/SuccessSnackbar';

interface AddCohortDrawerProps {
  programId: string;
}

const AddCohortDrawer = ({ programId }: AddCohortDrawerProps) => {
  const getDefaultCohort = (): AddCohortToProgramInput => {
    return {
      programId,
      internalLabel: '',
      startDate: null,
      endDate: null,
    };
  };

  const [cohort, setCohort] = useState<AddCohortToProgramInput>(getDefaultCohort());

  const [open, setOpen] = useState(false);
  const [hasNameError, setHasNameError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const startDateRef = useRef<HTMLInputElement | null>(null);
  const endDateRef = useRef<HTMLInputElement | null>(null);
  
  const [addNewCohort, { error, loading }] = useMutation(addCohortToProgramMutation_Gql, {
    refetchQueries: [getCohortsForProgramQuery_Name, getProgramById_Name]
  });
  
  const parsedStartDate = cohort.startDate ? cohort.startDate.split('T')[0] : '';
  const parsedEndDate = cohort.endDate ? cohort.endDate.split('T')[0]   : '';
  const [startDate, setStartDate] = useState(parsedStartDate);
  const [endDate, setEndDate] = useState(parsedEndDate);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    setCohort({ ...cohort, [name]: value });
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== "") {
      const updatedStartDate = event.target.value;
      setStartDate(updatedStartDate);
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== "") {
      const updatedEndDate = event.target.value;
      setEndDate(updatedEndDate);
    }
  };

  const isValid = (): boolean => {
    setHasNameError(false);
    let valid = true;

    if (cohort.internalLabel.length === 0) {
      setHasNameError(true);
      valid = false;
    }

    return valid;
  };

  const addCohort = async () => {
    try {
      if (!isValid()) return;

      const sDate = startDateRef?.current?.value ||'';
      const eDate = endDateRef?.current?.value ||'';

      cohort.startDate = sDate ? `${sDate}T00:00:00.000Z` : null;
      cohort.endDate = eDate ? `${eDate}T00:00:00.000Z` : null;

      await addNewCohort({
        variables: {
          cohort,
        },
      });

      if (!error) {
        setCohort(getDefaultCohort());
        setShowSuccess(true);
        setOpen(false);
      }
    } catch {}
  };

  const cancelEntry = async () => {
    setCohort(getDefaultCohort());
    setHasNameError(false);
    setOpen(false);
  };

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Saving" />

      <Button onClick={() => setOpen(true)} variant="outlined" color="primary" size="small">
        <FaPlus />
        &nbsp; Add New Cohort
      </Button>

      <GqlErrorDisplay error={error} />

      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Typography sx={{ marginTop: 3, marginBottom: 3, textAlign: 'center' }} variant="h5" color="primary">
          Add New Cohort
        </Typography>

        <Box sx={{ width: 400 }}>
          <Stack sx={{ ml: 2, width: 350 }} spacing={2}>
            <TextField
              value={cohort.internalLabel}
              name="internalLabel"
              label="Cohort Name"
              variant="outlined"
              color="warning"
              onChange={handleTextChange}
              helperText="Required"
              error={hasNameError}
            />

            <TextField
              sx={{ mb: 3 }}
              type="date"
              id="startDate"
              name="startDate"
              inputRef={startDateRef}
              onChange={handleStartDateChange}
              value={startDate}
              style={{ width: '100%', marginRight: '8px' }}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label="Start Date"
              color="warning"
            />

            <TextField
              sx={{ mb: 3 }}
              type="date"
              id="endDate"
              name="endDate"
              inputRef={endDateRef}
              onChange={handleEndDateChange}
              value={endDate}
              style={{ width: '100%', marginRight: '8px' }}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label="End Date"
              color="warning"
            />

            <Box sx={{ marginLeft: 2, textAlign: 'center' }}>
              <Button onClick={addCohort} color="primary" variant="contained">
                Add
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={cancelEntry} color="primary" variant="outlined">
                Cancel
              </Button>
            </Box>
          </Stack>
        </Box>
      </Drawer>

      <SuccessSnackbar isOpen={showSuccess} text="Cohort has been created" />
    </>
  );
};

export default AddCohortDrawer;
