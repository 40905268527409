import {
  COLORADO_TENANT_ID,
  OREGON_TENANT_ID,
  VIRGINIA_TENANT_ID,
  DARTMOUTH_TENANT_ID,
  DEMO_TENANT_ID,
  CS_TENANT_ID,
} from 'utils/constants';
import { getTenantIdForDomain } from 'helpers/tenantHelpers';
import ColoradoHome from './components/ColoradoHome';
import DemoHome from './components/DemoHome';
import OregonHome from './components/OregonHome';
import VirginiaHome from './components/VirginiaHome';
import DartmouthHome from './components/DartmouthHome';
import CSHome from './components/CSHome';

const Home = () => {
  const tenantId = getTenantIdForDomain();

  if (tenantId === COLORADO_TENANT_ID) {
    return <ColoradoHome />;
  }

  if (tenantId === OREGON_TENANT_ID) {
    return <OregonHome />;
  }

  if (tenantId === VIRGINIA_TENANT_ID) {
    return <VirginiaHome />;
  }

  if (tenantId === DARTMOUTH_TENANT_ID) {
    return <DartmouthHome />;
  }
  if (tenantId === DEMO_TENANT_ID) {
    return <DemoHome />;
  }

  if (tenantId === CS_TENANT_ID) {
    return <CSHome />;
  }

  return <></>;
};

export default Home;
