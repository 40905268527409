import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
  Checkbox,
  Typography,
  Link,
  Tooltip,
} from '@mui/material';
import { PersonOutline, HowToReg } from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';

import DuplicateCohortIconWithDialog from './DuplicateCohortIconWithDialog';
import AddCohortDrawer from './AddCohortDrawer';
import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';
import ViewAsLearnerUnregistered from './ViewAsLearnerUnRegistered';
import ViewAsLearnerRegistered from './ViewAsLearnerRegistered';

import SortableTableColumn, {
  SortProperty,
  sortBy,
}  from 'components/global/SortableTableColumn';

import {
  GetCohortsForProgram,
  GetCohortsForProgramVariables,
  ArchiveCohort,
  ArchiveCohortVariables,
  GetProgramById_getProgramById_cohorts,
} from '../../../models/GeneratedModels';
import { getCohortsForProgramQuery_Gql } from 'gql/cohort/getCohortsForProgramQuery';
import { archiveCohort_Gql } from 'gql/cohort/archiveCohort';

interface CohortListProps {
  programId: string;
  programName: string;
  cohorts: GetProgramById_getProgramById_cohorts[] | null | undefined;
}

const CohortList = ({
  cohorts: programCohorts,
  programId,
  programName,
}: CohortListProps) => {
  const [getCohorts, { called, data }] = useLazyQuery<
    GetCohortsForProgram,
    GetCohortsForProgramVariables
  >(getCohortsForProgramQuery_Gql, {
    fetchPolicy: 'no-cache',
  });
  const [showArchived, setShowArchived] = useState(false);
  const [registeredCohortId, setRegisteredCohortId] = useState('');
  const [unregisteredCohortId, setUnregisteredCohortId] = useState('');
  const [unregisteredDialogOpen, setUnregisteredDialogOpen] = useState(false);
  const [registeredDialogOpen, setRegisteredDialogOpen] = useState(false);
  
  const sortPropDefault: SortProperty = {
    prop: 'startDate',
    order: 'desc'
  };
  const [sortProp, setSortProp] = useState<SortProperty>(sortPropDefault);

  const [cohorts, setCohorts] = useState<
    GetProgramById_getProgramById_cohorts[]
  >(programCohorts || []);
  
  const [archiveCohort, { loading }] = useMutation<
    ArchiveCohort,
    ArchiveCohortVariables
  >(archiveCohort_Gql);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowArchived(event.target.checked);
  };

  const handleRegisteredDialogOnClose = () => {
    setRegisteredDialogOpen(false);
  };

  const handleUnregisteredDialogOnClose = () => {
    setUnregisteredDialogOpen(false);
  };

  const handleOpenRegisteredViewInNewTab = (cohortId: string) => {
    setRegisteredCohortId(cohortId);
    setRegisteredDialogOpen(true);
  };

  const handleOpenUnregisteredViewInNewTab = (cohortId: string) => {
    setUnregisteredCohortId(cohortId);
    setUnregisteredDialogOpen(true);
  };

  const reloadCohorts = () => {
    getCohorts({
      variables: {
        programId,
      },
    });
  };

  const changeArchiveStatus = async (cohortId: any, archiveStatus: boolean) => {
    try {
      await archiveCohort({
        variables: {
          cohort: {
            cohortIdToArchive: cohortId,
          },
        },
      });
    } catch (error: any) {
      console.log('There was an error with archiving', error)
    } finally {
      console.log('i am here');
      reloadCohorts();
    }
  }

  useEffect(() => {
    setCohorts(programCohorts || []);
  }, [programCohorts]);

  useEffect(() => {
    if (called) {
      setCohorts(data?.getCohortsForProgram || []);
    }
  }, [data]);

  return (
    <>
      <Typography variant="h6">Cohorts</Typography>

      <FormControlLabel
        control={
          <Checkbox
            checked={showArchived}
            onChange={handleChange}
            color="primary"
            name="checkedA"
          />
        }
        label="Show Archived"
      />

      <AddCohortDrawer programId={programId} />

      <TableContainer component={Paper}>
        <Table aria-label="cohort list" size='small'>
          <TableHead>
            <TableRow>
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="internalLabel"
                headerText="Cohort"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="startDate"
                headerText="Start"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="endDate"
                headerText="End"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="registrationCloseDate"
                headerText="Reg Close Date"
                order={sortProp?.order}
              />
              {showArchived && 
                <SortableTableColumn
                  currentSort={sortProp?.prop}
                  onSort={setSortProp}
                  sortProperty="archived"
                  headerText="Archived"
                  order={sortProp?.order}
              />}
              <TableCell align="left">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cohorts &&
              sortBy(cohorts, sortProp.order ? sortProp : sortPropDefault).map((cohort, i) => (
                // Check if showArchived is true or cohort is not archived, then render TableRow
                showArchived || !cohort.archived ? (
                <TableRow key={i}>
                  <TableCell component="th" scope="row" style={{ width: 100 }}>
                    <Link
                      sx={{ color: 'primary.main' }}
                      component={RouterLink}
                      to={`/admin/cohort/${cohort.id}`}
                    >
                      {cohort.internalLabel}
                    </Link>
                  </TableCell>
                  <TableCell style={{ width: 80 }}>
                    {convertDateToTimeZoneFromUtc(cohort.startDate)}
                  </TableCell>
                  <TableCell style={{ width: 80 }}>
                    {cohort.isOngoing
                      ? 'Ongoing'
                      : convertDateToTimeZoneFromUtc(cohort.endDate)}
                  </TableCell>
                  <TableCell style={{ width: 80 }}>
                    {convertDateToTimeZoneFromUtc(cohort.registrationCloseDate)}
                  </TableCell>
                  {showArchived && <TableCell style={{ width: 35 }}>{cohort.archived ? 'Yes' : 'No'}</TableCell>}
                  <TableCell align="center" style={{ width: 50 }}>
                    <div>
                      <Tooltip title="Registered View">
                        <HowToReg sx={{ cursor: 'pointer', color: 'primary.main' }} onClick={() => handleOpenRegisteredViewInNewTab(cohort.id)} />
                      </Tooltip>
                      <Tooltip title="Un-Registered View">
                        <PersonOutline sx={{ cursor: 'pointer', color: 'primary.main' }} onClick={() => handleOpenUnregisteredViewInNewTab(cohort.id)} />
                      </Tooltip>
                    </div>
                    <div>
                      <DuplicateCohortIconWithDialog
                        programName={programName}
                        cohort={cohort}
                        onDuplicateSuccess={reloadCohorts}
                      />
                      {cohort.archived ? (
                        <Tooltip title="Restore Cohort">
                          <UnarchiveOutlinedIcon
                            onClick={() => changeArchiveStatus(cohort.id, false)}
                            sx={{ color: 'primary.main', cursor: 'pointer' }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Archive Cohort">
                          <ArchiveIcon
                            onClick={() => changeArchiveStatus(cohort.id, true)}
                            sx={{ color: 'primary.main', cursor: 'pointer' }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ) : null))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <ViewAsLearnerRegistered
        cohortId={registeredCohortId}
        isOpen={registeredDialogOpen}
        onClose={handleRegisteredDialogOnClose}
      />
      <ViewAsLearnerUnregistered
        cohortId={unregisteredCohortId}
        isOpen={unregisteredDialogOpen}
        onClose={handleUnregisteredDialogOnClose}
      />
    </>
  );
};

export default CohortList;
