import { useState } from 'react';
import { useMutation } from '@apollo/client';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import ReplayIcon from '@mui/icons-material/Replay';

import ProgressIndicator from 'components/global/ProgressIndicator';
import YesNoDialog from 'components/global/YesNoDialog';

import { resendRegistrationConfirmationEmail_Gql } from 'gql/cohortRegistration/resendRegistrationConfirmationEmail';
import {
  ResendRegistrationConfirmationEmail,
  ResendRegistrationConfirmationEmailVariables,
} from 'models/GeneratedModels';

interface ResendConfirmationIconProps {
  id: string;
}

const ResendConfirmationIcon = ({ id }: ResendConfirmationIconProps) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [resendEmail, { loading, error }] = useMutation<
    ResendRegistrationConfirmationEmail,
    ResendRegistrationConfirmationEmailVariables
  >(resendRegistrationConfirmationEmail_Gql, {
    variables: {
      cohortRegistrationId: id,
    },
  });

  const handleConfirmationClose = (sendEmail: boolean) => {
    setConfirmationDialogOpen(false);

    if (sendEmail) {
      try {
        resendEmail();
        setSnackbarOpen(true);
      } catch {}
    }
  };

  const resendConfirmation = () => {
    setConfirmationDialogOpen(true);
  };

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Sending..." />

      {error && <Alert severity="error">{error.graphQLErrors[0].message}</Alert>}

      <Tooltip title="Resend Confirmation Email">
        <ReplayIcon onClick={resendConfirmation} sx={{ color: 'navy', cursor: 'pointer', mr: 1 }} />
      </Tooltip>

      <YesNoDialog
        dialogTitle="Resend Confirmation Email with Calendar Invites"
        confirmText="Send"
        dialogText="This will send the confirmation email again, along with the calendar invites"
        open={confirmationDialogOpen}
        onClose={handleConfirmationClose}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
          Confirmation Email was Sent
        </Alert>
      </Snackbar>
    </>
  );
};

export default ResendConfirmationIcon;
