import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Typography, Button, Grid, Alert } from '@mui/material';

import ProgressIndicator from '../../global/ProgressIndicator';
import SuccessSnackbar from '../../global/SuccessSnackbar';
import { GetCohortById_getCohortById } from '../../../models/GeneratedModels';
import { GetCohortByIdQuery_Name } from '../../../gql/cohort/getCohortByIdQuery';
import { getCohortDashboard_Name } from '../../../gql/cohort/getCohortDashboard';
import { UpdateCohort_Gql } from '../../../gql/cohort/updateCohort';
import { EditCohortInput } from '../../../models/GeneratedModels';

import 'react-quill/dist/quill.snow.css';
const ReactQuill = require('react-quill');

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    editor: {
      height: '100px',
    },
    label: {
      textAlign: 'left',
    },
  })
);

interface CohortNotesProps {
  cohort: GetCohortById_getCohortById;
}

const CohortNotes = ({ cohort }: CohortNotesProps) => {
  const classes = useStyles();
  const [cohortDetail, setCohortDetail] = useState<GetCohortById_getCohortById>(cohort);
  const [showProgress, setShowProgress] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setCohortDetail(cohort);
  }, [cohort]);

  const [updateCohort, { loading, error }] = useMutation(UpdateCohort_Gql, {
    refetchQueries: [GetCohortByIdQuery_Name, getCohortDashboard_Name],
  });

  useEffect(() => {
    setShowProgress(loading);
  }, [loading]);

  const saveNotes = async () => {
    try {
      const cohortToUpdate: EditCohortInput = {
        allowPreregistration: cohortDetail.allowPreregistration,
        archived: cohortDetail.archived,
        audience: cohortDetail.audience ?? '',
        agreement: cohortDetail.agreement ?? '',
        caseFormLink: cohortDetail.caseFormLink,
        developmentUserId: cohortDetail.developmentUserId,
        endDate: cohortDetail.endDate,
        evaluationGoal: cohortDetail.evaluationGoal ?? '',
        evaluationUserId: cohortDetail.evaluationUserId,
        externalLabel: cohortDetail.externalLabel,
        id: cohortDetail.id,
        implementationUserId: cohortDetail.implementationUserId,
        internalLabel: cohortDetail.internalLabel ?? '',
        isOngoing: cohortDetail.isOngoing,
        learningObjectives: cohortDetail.learningObjectives ?? '',
        meetingLink: cohortDetail.meetingLink,
        prelaunchDateTime: cohortDetail.prelaunchDateTime,
        projectPartner: cohortDetail.projectPartner ?? '',
        registrationCloseDate: cohortDetail.registrationCloseDate,
        registrationOpenDate: cohortDetail.registrationOpenDate,
        scheduleDescription: cohortDetail.scheduleDescription,
        sessionDurationInMinutes: cohortDetail.sessionDurationInMinutes,
        startDate: cohortDetail.startDate,
        statistics: cohortDetail.statistics,
        surveyLink: cohortDetail.surveyLink,
        timeZone: cohortDetail.timeZone,
      };

      await updateCohort({
        variables: {
          cohort: cohortToUpdate,
        },
      });

      if (!error) setShowSuccess(true);
    } catch {}
  };

  return (
    <>
      <ProgressIndicator isOpen={showProgress} title="Saving" />

      {error && (
        <Alert sx={{ mb: 2 }} severity="error">
          {error}
        </Alert>
      )}

      <Grid sx={{ height: '200px' }} container>
        <Grid item xs={12}>
          <Typography className={classes.label} variant="h6">
            Goals
          </Typography>
          <Typography variant="caption">The overarching goal of this ECHO is for WHO to DO WHAT</Typography>
          <ReactQuill
            id="evaluationGoal"
            className={classes.editor}
            value={cohortDetail.evaluationGoal}
            onChange={(value: string) => {
              if (value !== cohortDetail.evaluationGoal) {
                setCohortDetail({ ...cohortDetail, evaluationGoal: value });
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid sx={{ height: '200px', mt: 3 }} container>
        <Grid item xs={12}>
          <Typography className={classes.label} variant="h6">
            Learning Objectives
          </Typography>
          <Typography variant="caption"></Typography>
          <ReactQuill
            id="learningObjectives"
            className={classes.editor}
            value={cohortDetail.learningObjectives}
            onChange={(value: string) => {
              if (value !== cohortDetail.learningObjectives) {
                setCohortDetail({ ...cohortDetail, learningObjectives: value });
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid sx={{ height: '200px', mt: 3 }} container>
        <Grid item xs={12}>
          <Typography className={classes.label} variant="h6">
            Project Partners
          </Typography>
          <ReactQuill
            id="projectPartner"
            className={classes.editor}
            value={cohortDetail.projectPartner}
            onChange={(value: string) => {
              if (value !== cohortDetail.projectPartner) {
                setCohortDetail({ ...cohortDetail, projectPartner: value });
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid sx={{ height: '200px', mt: 3 }} container>
        <Grid item xs={12}>
          <Typography className={classes.label} variant="h6">
            Audience
          </Typography>
          <Typography className={classes.label} variant="caption">
            Describe the ideal participant for this ECHO
          </Typography>
          <ReactQuill
            id="audience"
            className={classes.editor}
            value={cohortDetail.audience}
            onChange={(value: string) => {
              if (value !== cohortDetail.audience) {
                setCohortDetail({ ...cohortDetail, audience: value });
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid sx={{ height: '200px', mt: 3 }} container>
        <Grid item xs={12}>
          <Typography className={classes.label} variant="h6">
            Build Schedule Description
          </Typography>
          <Typography className={classes.label} variant="caption">
            This text is used in all email communication with participants
          </Typography>
          <ReactQuill
            id="scheduleDescription"
            className={classes.editor}
            value={cohortDetail.scheduleDescription}
            onChange={(value: string) => {
              if (value !== cohortDetail.scheduleDescription) {
                setCohortDetail({ ...cohortDetail, scheduleDescription: value });
              }
            }}
          />
        </Grid>
      </Grid>

      <Grid sx={{ height: '200px', mt: 3 }} container>
        <Grid item xs={12}>
          <Typography className={classes.label} variant="h6">
            Agreement
          </Typography>
          <Typography className={classes.label} variant="caption">
            Agreement for all series participants
          </Typography>
          <ReactQuill
            id="agreement"
            className={classes.editor}
            value={cohortDetail.agreement}
            onChange={(value: string) => {
              if (value !== cohortDetail.agreement) {
                setCohortDetail({ ...cohortDetail, agreement: value });
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} sx={{ pb: 2, mt: 1, textAlign: 'center' }} container>
        <Grid item xs={12}>
          {error && (
            <Alert sx={{ mt: 2 }} severity="error">
              {error}
            </Alert>
          )}

          <Button onClick={saveNotes} variant="contained" color="primary">
            Save Notes
          </Button>

          <SuccessSnackbar isOpen={showSuccess} text="Cohort notes have been saved" />
        </Grid>
      </Grid>
    </>
  );
};

export default CohortNotes;
