import { gql } from '@apollo/client';

export const addUserMutation_Name = 'addUser';
export const addUserMutation_Gql = gql`
  mutation AddUser($user: AddOrUpdateUserInput) {
    addUser(user: $user) {
      id
      firstName
      lastName
      email
    }
  }
`;
