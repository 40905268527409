import React, { useState, useMemo } from 'react';
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { LoginResult, useAuth } from '../hooks/useAuth';
import { ValidateLoginInput } from '../models/GeneratedModels';
import ProgressIndicator from '../components/global/ProgressIndicator';
import { accountLinks, adminLinks, learnerLinks } from 'utils/links';
import StyledRouterLink from 'components/global/StyledRouterLink';
import LoginTitle from 'components/account/LoginTitle/LoginTitle';
import LoginHeader from 'components/account/LoginHeader/LoginHeader';
import { Box, Container, Typography } from '@mui/material';
import AdminSession from 'components/admin/Account/AdminSession';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const Login = () => {
  const auth = useAuth();
  const history = useHistory();
  const query = useQuery();
  const redirectParam = 'redirectTo';

  const [hasEmailError, setEmailError] = useState(false);
  const [hasPasswordError, setPasswordError] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const [hasLoginError, setHasLoginError] = useState(false);
  const [needPasswordReset, setNeedPasswordReset] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loginInput, setLoginInput] = useState<ValidateLoginInput>({
    email: '',
    password: '',
  });
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [pendingRedirect, setPendingRedirect] = useState<string | null>(null);

  const onFormKeyPress = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      await loginUser();
    }
  };

  const onFormElementChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setLoginInput({
      ...loginInput,
      [name]: value,
    });

    if (name === 'email') setEmailError(false);
    if (name === 'password') setPasswordError(false);
  };

  const validateForm = (): boolean => {
    let isValid = true;
    setEmailError(false);
    setPasswordError(false);
    setHasLoginError(false);
    setNeedPasswordReset(false);
    setErrorMessage(null);

    if (loginInput.email.length === 0) {
      setEmailError(true);
      isValid = false;
    }

    if (loginInput.password.length === 0) {
      setPasswordError(true);
      isValid = false;
    }

    return isValid;
  };

  const handleContinue = () => {    
    const success = auth.adminSessionContinue();
    if (pendingRedirect) {
      setTimeout(() => {
        setShowAdminModal(false);
      }, 50);

      setTimeout(() => {
        history.push(pendingRedirect);
      }, 200);
      
      // Then, clear the pending redirect after a longer delay
      setTimeout(() => {
        setPendingRedirect(null);
      }, 800);
    } else {      
      setTimeout(() => {
        setShowAdminModal(false);
      }, 50);

      setTimeout(() => {
        history.push(learnerLinks.progams.href);
      }, 200);
            
      // Then, clear the pending redirect after a longer delay
      setTimeout(() => {
        setPendingRedirect(null);
      }, 800);
    }
  };

  const handleExtendSession = async () => {
    try {
      const success = await auth.extendAdminSession();
      if (success) {
        console.info('admin session extended');  
      } else {
        console.error('Failed to extend session');
      }
    } catch (error) {
      console.error('Error extending admin session:', error);
    } finally {
      
      // Handle redirection first
      if (pendingRedirect) {
        setTimeout(() => {
          history.push(pendingRedirect);
        }, 50);

        setTimeout(() => {
          setShowAdminModal(false);
        }, 250);

        // Then, clear the pending redirect after a longer delay
        setTimeout(() => {
          setPendingRedirect(null);
        }, 800);
      }  else {
        setTimeout(() => {
          history.push(adminLinks.currentProjects.href);
        }, 50);

        setTimeout(() => {
          setShowAdminModal(false);
        }, 250);

        // Then, clear the pending redirect after a longer delay
        setTimeout(() => {
          setPendingRedirect(null);
        }, 800);
      }
    }
  };

  const loginUser = async () => {
    if (!validateForm()) return;

    setOpenProgress(true);

    try {
      const loginResult = await auth.signIn(loginInput.email, loginInput.password);

      if (loginResult.isSuccessful) {
        const redirectTo = query.get(redirectParam);

        if (!loginResult.isRegistrationComplete){
          history.push(accountLinks.editAccount.href);
        } else {
            // Immediate redirect for non-admin users
            if (redirectTo) {
              if (loginResult.isAdmin) {
                setShowAdminModal(true);
                setPendingRedirect(redirectTo);
              } else {
                history.push(redirectTo);
              }
            } else if (loginResult.isAdmin) {
              setShowAdminModal(true);
              setPendingRedirect(adminLinks.currentProjects.href);
            } else {
              history.push(learnerLinks.progams.href);
            }
        }
      } else {
        // Handle login failure...
        setErrorMessage(loginResult.errorMessage);
      }
    } catch (error: any) {
      console.log('loginUser', error);
    } finally {
      setOpenProgress(false);
    }
  };

  return (
    <>
      <ProgressIndicator isOpen={openProgress} title="Loading..." />
      <Container maxWidth="xl" sx={{ p: 14, mb: 2 }}>
      <LoginTitle />

        <Grid sx={{ mt: 3 }} container>
          <Grid item sx={{ width: '20%' }}></Grid>
          <Grid item sx={{ width: '60%' }}>
            <LoginHeader />

            {(hasLoginError || errorMessage?.trim().length) && (
              <Alert sx={{ mt: 2 }} severity="error">
                {errorMessage}
              </Alert>
            )}

            {needPasswordReset && (
              <Alert sx={{ mt: 2 }} severity="info">                 
                Due to site-wide security enhancements, all Connect users are required to{' '}
                <StyledRouterLink to={accountLinks.forgotPassword.href} text="reset your password" />.
              </Alert>
            )}

            <Stack spacing={2} sx={{ marginTop: 3, textAlign: 'center', justifyContent: 'center' }}>
              <FormControl error variant="standard">
                <TextField
                  onChange={onFormElementChange}
                  name="email"
                  label="Email"
                  color="warning"
                  onKeyPress={onFormKeyPress}
                />
                {hasEmailError && (
                  <FormHelperText id="email-error" color="error">
                    Email is required
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl error variant="standard">
                <TextField
                  onChange={onFormElementChange}
                  name="password"
                  type="password"
                  label="Password"
                  color="warning"
                  onKeyPress={onFormKeyPress}
                />
                {hasPasswordError && (
                  <FormHelperText id="password-error" color="error">
                    Password is required
                  </FormHelperText>
                )}
              </FormControl>

              <Button sx={{ mb: 6 }} variant="contained" color="primary" onClick={loginUser}>
                Login
              </Button>

              <Typography sx={{ pt: 6 }} variant="body1">If you do not have an account or need to reset your password, please click below</Typography>
              
              <Box>
                <Button
                  sx={{ mb: 6, width: '50%', margin: 'auto' }} variant="contained" color="primary" 
                  component={RouterLink}
                  to={accountLinks.createAccount.href}>
                  {accountLinks.createAccount.text}
                </Button>
                <br />
                <br />
                <Button
                  sx={{ mb: 6, width: '50%', margin: 'auto' }} variant="contained" color="primary" 
                  component={RouterLink}
                  to={accountLinks.forgotPassword.href}>
                  {accountLinks.forgotPassword.text}
                </Button>

                <Typography 
                  sx={{ 
                    pt: { xs: 2, sm: 3, md: 4 }, // Responsive padding-top
                    mt: { xs: 2, sm: 3, md: 4 }, // Responsive margin-top
                    fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }, // Responsive font size
                  }} 
                  variant="body2" 
                  color="textSecondary"
                >
                  This site uses cookies to ensure core functionality, improve user experience, and maintain service reliability. <br />By using this site, you accept the site's use of cookies.
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item sx={{ width: '20%' }}></Grid>
        </Grid>
      </Container>
      <AdminSession 
        isOpen={showAdminModal} 
        onClose={handleExtendSession}
        onContinue={handleContinue}
      />
    </>
  );
};

export default Login;
