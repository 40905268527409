import { gql } from '@apollo/client';

export const getRegistrationsForUser_Name = 'GetRegistrationsForUser';
export const getRegistrationsForUser_Gql = gql`
  query GetRegistrationsForUser($userId: ID!) {
    getRegistrationsForUser(userId: $userId) {
      id
      cohortId
      userId
      isActive
      isApproved
      isDenied
      isWaitlisted
      copiedFromWaitlist
      createdAt
    }
  }
`;
