import { useState } from 'react';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Button, Box } from '@mui/material';

import { FaPlus } from 'react-icons/fa';

import PartnerDrawer from './PartnerDrawer';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    plusIcon: {
      paddingRight: 5,
    },
  })
);

const AddPartnerButtonWithDrawer = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Box sx={{ textAlign: 'left', mt: 2, mb: 2 }}>
        <Button
          onClick={() => setDrawerOpen(true)}
          sx={{ textAlign: 'left' }}
          variant="outlined"
          color="primary"
          size="small"
        >
          <FaPlus className={classes.plusIcon} />
          Add Partner
        </Button>
      </Box>

      <PartnerDrawer open={drawerOpen} onDrawerClose={closeDrawer} />
    </>
  );
};

export default AddPartnerButtonWithDrawer;
