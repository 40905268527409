import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import EmailIcon from '@mui/icons-material/Email';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';

import ProgressIndicator from 'components/global/ProgressIndicator';

import {
  GetNextStepsEmailContent,
  GetNextStepsEmailContentVariables,
  SendNextStepsEmail,
  SendNextStepsEmailVariables,
} from 'models/GeneratedModels';
import { getNextStepsEmailContent_Gql } from 'gql/hubTeam/getNextStepsEmailContent';
import { sendNextStepsEmail_Gql } from 'gql/hubTeam/sendNextStepsEmail';
import { getMembersByCohort_Name } from 'gql/hubTeam/getMembersByCohort';

import 'react-quill/dist/quill.snow.css';

const ReactQuill = require('react-quill');

interface SendNextStepsEmailProps {
  hubMemberCohortId: string;
}

const SendHubTeamNextStepsEmail = ({ hubMemberCohortId }: SendNextStepsEmailProps) => {
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [nextStepsSubject, setNextStepsSubject] = useState('');
  const [nextStepsText, setNextStepsText] = useState('');

  const { loading, data } = useQuery<GetNextStepsEmailContent, GetNextStepsEmailContentVariables>(
    getNextStepsEmailContent_Gql,
    {
      variables: {
        hubMemberCohortId,
      },
      skip: !emailDialogOpen,
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (data) {
      const { subject, text } = data.getNextStepsEmailContent;
      setNextStepsSubject(subject);
      setNextStepsText(text);
    }
  }, [data]);

  const [sendNextStepsEmail, { loading: saving }] = useMutation<SendNextStepsEmail, SendNextStepsEmailVariables>(
    sendNextStepsEmail_Gql,
    {
      refetchQueries: [getMembersByCohort_Name],
    }
  );

  const onSendClick = async () => {
    await sendNextStepsEmail({
      variables: {
        emailData: {
          hubMemberCohortId,
          subject: nextStepsSubject,
          text: nextStepsText,
        },
      },
    });
  };

  const handleClose = () => {
    setEmailDialogOpen(false);
  };

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Loading..." />
      <ProgressIndicator isOpen={saving} title="Saving..." />

      <Tooltip title="Send Next Steps Email">
        <EmailIcon sx={{ color: 'primary.main', cursor: 'pointer', mr: 3 }} onClick={() => setEmailDialogOpen(true)} />
      </Tooltip>

      <Dialog open={emailDialogOpen} onClose={handleClose}>
        <DialogTitle>Deny Registration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b>Subject: </b> {nextStepsSubject}
          </DialogContentText>
          <br />

          <ReactQuill
            id="nextStepsEmailContent"
            value={nextStepsText}
            onChange={(value: string) => setNextStepsText(value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={onSendClick}>
            Send Next Steps
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendHubTeamNextStepsEmail;
