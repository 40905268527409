// supportedBrowsers.js

const supportedBrowsers = [
    {
        name: 'chrome',
        minVersion: 88,
    },
    {
        name: 'firefox',
        minVersion: 68,
    },
    {
        name: 'safari',
        minVersion: 13,
    },
    {
        name: 'edge',
        minVersion: 88,
    },
];
  
export default supportedBrowsers;
