import { gql } from '@apollo/client';

export const addOrUpdateCohortAttendance_Name = 'AddOrUpdateCohortAttendance';
export const addOrUpdateCohortAttendance_Gql = gql`
  mutation AddOrUpdateCohortAttendance($cohortAttendance: AddOrUpdateCohortAttendanceInput!) {
    addOrUpdateCohortAttendance(cohortAttendance: $cohortAttendance) {
      id
    }
  }
`;
