import { COLORADO_TENANT_ID, OREGON_TENANT_ID, VIRGINIA_TENANT_ID, DARTMOUTH_TENANT_ID } from 'utils/constants';
import { getTenantIdForDomain } from 'helpers/tenantHelpers';

const Footer = () => {
  const tenantId = getTenantIdForDomain();
  const logoProps = getFooterLogoProps(tenantId);
  const { logoUrl, height, width } = logoProps;

  return (
    <div className="footer">
      <p>
        <FooterLogo logoUrl={logoUrl} height={height} width={width} />
      </p>
    </div>
  );
};

export default Footer;

interface FooterLogoProps {
  logoUrl: string;
  height: string;
  width: string;
}

const FooterLogo = ({ logoUrl, height, width }: FooterLogoProps) => {
  return <img src={logoUrl} height={height} width={width} alt="ECHO Logo" />;
};

function getFooterLogoProps(tenantId: string): FooterLogoProps {
  const coloradoLogo: FooterLogoProps = {
    logoUrl: '/images/CO-logo.jpg',
    height: '60px',
    width: '79px',
  };

  const oregonLogo: FooterLogoProps = {
    logoUrl: '/images/OEN-logo.jpg',
    height: '60px',
    width: '79px',
  };

  const virginiaLogo: FooterLogoProps = {
    logoUrl: '/images/ECHO-logo.jpg',
    height: '60px',
    width: '122px',
  };

  const dartmouthLogo: FooterLogoProps = {
    logoUrl: '/images/ECHO-logo.jpg',
    height: '60px',
    width: '122px',
  };

  if (tenantId === COLORADO_TENANT_ID) return coloradoLogo;
  if (tenantId === OREGON_TENANT_ID) return oregonLogo;
  if (tenantId === VIRGINIA_TENANT_ID) return virginiaLogo;
  if (tenantId === DARTMOUTH_TENANT_ID) return dartmouthLogo;
  if (tenantId === DARTMOUTH_TENANT_ID) return dartmouthLogo;

  return coloradoLogo;
}
