import {
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  OutlinedInput,
  Grid,
  TextField,
} from '@mui/material';
import {
  COLORADO_TENANT_ID,
  OREGON_TENANT_ID,
  VIRGINIA_TENANT_ID,
  DARTMOUTH_TENANT_ID,
  CS_TENANT_ID,
  DEMO_TENANT_ID,
} from 'utils/constants';
import { getTenantIdForDomain } from 'helpers/tenantHelpers';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getNamesFromList = (items: any, selectedValues: string[]) => {
  const labels: string[] = [];
  for (let selected of selectedValues) {
    labels.push(
      ...items.filter((c: any) => c.id === selected).map((x: any) => x.name)
    );
  }

  return labels.join(', ');
};

const isSelected = (selectedIds: any[], item: any) =>
  selectedIds.indexOf(item.id) > -1;
const getNoneOfAbove = (items: any[]) =>
  items.find((item: any) => item.name === 'None of the Above');
const noneOfAboveDisabled = (selectedIds: any[], allItems: any[], item: any) =>
  isSelected(selectedIds, getNoneOfAbove(allItems)) &&
  item.name !== 'None of the Above';

const SelectOrganizationDescription = ({
  classes,
  onChange,
  organizationDescriptions,
  value,
}: any) => {
  return (
    <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
      {organizationDescriptions.length > 0 && (
        <FormControl sx={{ mb: 1 }}>
          <InputLabel required id="orgdesc-label" color="warning">
            Select option(s) that describe the organization
          </InputLabel>
          <Select
            required
            className={classes.accountSelect}
            labelId="orgdesc-label"
            multiple
            value={value.length ? value : []}
            onChange={onChange}
            input={<OutlinedInput label="Select option(s) that describe the organization where you work" />}
            renderValue={(selected) =>
              getNamesFromList(organizationDescriptions, selected)
            }
            MenuProps={MenuProps}
            color="warning"
          >
            {organizationDescriptions.map((desc: any, index: number) => (
              <MenuItem
                key={index}
                value={desc.id}
                disabled={noneOfAboveDisabled(
                  value,
                  organizationDescriptions,
                  desc
                )}
              >
                <Checkbox checked={value.indexOf(desc.id) > -1} />
                <ListItemText primary={desc.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
};

const SelectWorkSetting = ({
  user,
  classes,
  workSettings,
  showOtherWorkSetting,
  onChange,
  onFormElementChange,
  value,
}: any) => {
  return (
    <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
      {workSettings.length > 0 && (
        <>
          <FormControl sx={{ mb: 1 }}>
            <InputLabel required id="orgdesc-label" color="warning">
              Select option(s) that describe your work setting
            </InputLabel>
            <Select
              required
              className={classes.accountSelect}
              labelId="orgdesc-label"
              multiple
              value={value.length ? value : []}
              onChange={onChange}
              input={<OutlinedInput label="Select option(s) that describe your work setting" />}
              renderValue={(selected) =>
                getNamesFromList(workSettings, selected)
              }
              MenuProps={MenuProps}
              color="warning"
            >
              {workSettings.map((desc: any, index: number) => (
                <MenuItem key={index} value={desc.id}>
                  <Checkbox checked={value.indexOf(desc.id) > -1} />
                  <ListItemText primary={desc.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          {showOtherWorkSetting && (
            <TextField
              required
              className={classes.accountText}
              label="Other Work Setting"
              name="otherWorkSetting"
              value={user.otherWorkSetting}
              onChange={onFormElementChange}
              color="warning"
              sx={{ textAlign: 'center' }}
            />
          )}
        </>
      )}
    </Grid>
  );
};

const TentantFields = ({
  isHome,
  user,
  classes,
  organizationDescriptions,
  workSettings,
  showOtherWorkSetting,
  onOrgDescChange,
  onWorkSettingChange,
  onFormElementChange,
}: any) => {
  const tenantId = getTenantIdForDomain();

  if (!isHome) {
    return <></>;
  }

  if (tenantId === COLORADO_TENANT_ID || tenantId === DEMO_TENANT_ID) {
    return (
      <>
        <SelectOrganizationDescription
          classes={classes}
          organizationDescriptions={organizationDescriptions}
          onChange={onOrgDescChange}
          value={user.organizationDescriptionIds}
        />
        <SelectWorkSetting
          classes={classes}
          workSettings={workSettings}
          onChange={onWorkSettingChange}
          value={user.workSettingIds}
          showOtherWorkSetting={showOtherWorkSetting}
          user={user}
          onFormElementChange={onFormElementChange}
        />
      </>
    );
  }

  if (tenantId === OREGON_TENANT_ID) {
    return (
      <>
        <SelectOrganizationDescription
          classes={classes}
          organizationDescriptions={organizationDescriptions}
          onChange={onOrgDescChange}
          value={user.organizationDescriptionIds}
        />
        <SelectWorkSetting
          classes={classes}
          workSettings={workSettings}
          onChange={onWorkSettingChange}
          value={user.workSettingIds}
          showOtherWorkSetting={showOtherWorkSetting}
          user={user}
          onFormElementChange={onFormElementChange}
        />
      </>
    );
  }

  if (tenantId === VIRGINIA_TENANT_ID) {
    return <></>;
  }

  if (tenantId === DARTMOUTH_TENANT_ID) {
    return (
      <>
        <SelectOrganizationDescription
          classes={classes}
          organizationDescriptions={organizationDescriptions}
          onChange={onOrgDescChange}
          value={user.organizationDescriptionIds}
        />
        <SelectWorkSetting
          classes={classes}
          workSettings={workSettings}
          onChange={onWorkSettingChange}
          value={user.workSettingIds}
          showOtherWorkSetting={showOtherWorkSetting}
          user={user}
          onFormElementChange={onFormElementChange}
        />
      </>
    );
  }

  if (tenantId === CS_TENANT_ID) {
    return <></>;
  }

  return <></>;
};

export default TentantFields;
