import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import Label from './Label';
import { FileExportsProps } from '../../cohort/Cohort.interfaces';
import { exportCohortAttendance, exportCohortRegistrants, exportCohortEvaluationData } from './FileExports.helpers';

interface ExportDownloadIconProps {
  onClick: () => void;
}

const ExportDownloadIcon = ({ onClick }: ExportDownloadIconProps) => {
  return (
    <>
      <DownloadIcon onClick={onClick} sx={{ cursor: 'pointer', color: 'primary.main' }} />
    </>
  );
};

const FileExports = ({ cohortId, internalLabel, onExportStart }: FileExportsProps) => {
  
  return (
    <>
      <Label text="Data Exports" />

      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>Attendance</TableCell>
            <TableCell align="right">
              <ExportDownloadIcon onClick={async () => await exportCohortAttendance(cohortId, internalLabel, onExportStart)} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Registrants (Coordinators)</TableCell>
            <TableCell align="right">
              <ExportDownloadIcon onClick={async () => await exportCohortRegistrants(cohortId, internalLabel, onExportStart)} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Registrants with Labels (Evaluators)</TableCell>
            <TableCell align="right">
              <ExportDownloadIcon
                onClick={async () => await exportCohortEvaluationData(cohortId, internalLabel, 'label', onExportStart)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              Registrants with Values (Evaluators)
            </TableCell>
            <TableCell align="right">
              <ExportDownloadIcon
                onClick={async () => await exportCohortEvaluationData(cohortId, internalLabel, 'value', onExportStart)}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default FileExports;
