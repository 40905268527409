import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Box, Alert, Button, Container } from '@mui/material';

import PageHeader from '../../global/PageHeader';
import ProgressIndicator from '../../global/ProgressIndicator';
import EmailTemplateList from '../EmailTemplate/EmailTemplateList';
import ProgramBreadcrumb from './ProgramBreadcrumb';

import ProgramParams from '../../../models/ProgramParams.model';
import GenericEmailTemplate from '../../../models/GenericEmailTemplate.model';
import {
  GetEmailTemplatesForProgram,
  GetEmailTemplatesForProgramVariables,
  GetEmailTemplatesForProgram_getEmailTemplatesForProgram,
  DeleteEmailTemplate,
  DeleteEmailTemplateVariables,
} from '../../../models/GeneratedModels';
import {
  getEmailTemplatesForProgram_Gql,
  getEmailTemplatesForProgram_Name,
} from '../../../gql/programEmailTemplate/getEmailTemplatesForProgram';
import { deleteEmailTemplate_Gql } from '../../../gql/emailTemplate/deleteEmailTemplate';
import { copyEmailTemplatesFromTenant_Gql } from '../../../gql/programEmailTemplate/copyEmailTemplatesFromTenant';
import { updateProgramEmailTemplate_Gql } from '../../../gql/programEmailTemplate/updateProgramEmailTemplate';
import { getProgramEmailTemplatesByCategory } from '../EmailTemplate/EmailTemplate.helpers';

const ManageEmailTemplates = () => {
  let { id } = useParams<ProgramParams>();

  const { loading: templatesLoading, data } = useQuery<
    GetEmailTemplatesForProgram,
    GetEmailTemplatesForProgramVariables
  >(getEmailTemplatesForProgram_Gql, {
    variables: {
      programId: id,
    },
  });

  const programEmailTemplates: GetEmailTemplatesForProgram_getEmailTemplatesForProgram[] =
    data?.getEmailTemplatesForProgram ?? [];
  const templatesByCategory = getProgramEmailTemplatesByCategory(programEmailTemplates);

  const [copyEmailTemplates, { loading: copyLoading }] = useMutation(copyEmailTemplatesFromTenant_Gql, {
    variables: {
      programId: id,
    },
    refetchQueries: [getEmailTemplatesForProgram_Name],
  });

  const [updateProgramEmailTemplate, { loading: savingTemplate }] = useMutation(updateProgramEmailTemplate_Gql, {
    refetchQueries: [getEmailTemplatesForProgram_Name],
  });

  const [deleteEmailTemplate] = useMutation<DeleteEmailTemplate, DeleteEmailTemplateVariables>(
    deleteEmailTemplate_Gql,
    { refetchQueries: [getEmailTemplatesForProgram_Name] }
  );

  const copyTemplatesToProgram = async () => {
    await copyEmailTemplates();
  };

  const onSaveClick = async (template: GenericEmailTemplate) => {
    await updateProgramEmailTemplate({
      variables: {
        template: {
          id: template.id,
          subject: template.subject,
          template: template.template,
        },
      },
    });
  };

  const onDeleteClick = async (template: GenericEmailTemplate) => {
    await deleteEmailTemplate({
      variables: {
        emailTemplateId: template.id,
      },
    });
  };

  return (
    <>
      <Container maxWidth="xl" sx= {{ pt: 10, mb: 12 }}>
        <ProgramBreadcrumb programId={id} currentPageText="Program Email Templates" />

        <PageHeader title="Manage Email Templates for Program" />
        <ProgressIndicator isOpen={templatesLoading} title="Loading..." />
        <ProgressIndicator isOpen={copyLoading || savingTemplate} title="Saving..." />
        <br />

        {programEmailTemplates.length === 0 && (
          <Box sx={{ textAlign: 'center', width: '100%' }}>
            <Alert sx={{ marginBottom: 3 }} severity="info">
              There are currently no customized email templates for this program. It will use templates from the primary
              email template list. To customize templates for this program, duplicate the primary templates and then
              customize to fit the program.
            </Alert>

            <Button onClick={() => copyTemplatesToProgram()} variant="contained" color="primary">
              Duplicate Primary Templates
            </Button>
          </Box>
        )}

        {programEmailTemplates.length > 0 && (
          <EmailTemplateList
            accountTemplates={templatesByCategory.accountTemplates}
            cohortTemplates={templatesByCategory.cohortTemplates}
            hubTeamTemplates={templatesByCategory.hubTeamTemplates}
            registrationTemplates={templatesByCategory.registrationTemplates}
            templatesNoCategory={templatesByCategory.templatesNoCategory}
            onSaveClick={onSaveClick}
            onDeleteClick={onDeleteClick}
          />
        )}
      </Container>
    </>
  );
};

export default ManageEmailTemplates;
