import { Typography } from '@mui/material';

interface PageHeaderProps {
  title: string;
}

const PageHeader = ({ title }: PageHeaderProps) => {
  return (
    <Typography sx={{ mt: 4, mb: 4, textAlign: "center" }} variant="h4" color="primary">
      {title}
    </Typography>
  );
};

export default PageHeader;
