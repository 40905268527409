import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Chip from '@mui/material/Chip';

import ProgressIndicator from 'components/global/ProgressIndicator';

import {
  SendEmail,
  SendEmailVariables,
} from 'models/GeneratedModels';
import { SendEmail_Gql } from 'gql/emailTemplate/sendEmail';
import 'react-quill/dist/quill.snow.css';
import { TextField } from '@mui/material';

import { GetRegistrantsForCohort_getRegistrantsForCohort } from '../../../../../models/GeneratedModels';

const ReactQuill = require('react-quill');

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      paddingRight: 15,
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none'
    }
  })
);

interface MailerProps {
  renderTrigger: (onClick: () => void) => void;
  onBodyUpdate?: (value: string) => void;
  onSubjectUpdate?: (value: string) => void;
  popupTitle: string;
  subject?: string;
  body?: string;
  submitButtonText?: string;
  submitButtonTextAlt?: string;
  recipients: GetRegistrantsForCohort_getRegistrantsForCohort[];
  senderId: string;
  checkSendEmail?: boolean;
  progressText?: string;
  errorText?: string;
  onSubmit?: () => void;
}

const Mailer = ({
  renderTrigger,
  onBodyUpdate = () => {},
  onSubjectUpdate = () => {},
  popupTitle,
  subject = "",
  body = "",
  submitButtonText = 'Send',
  submitButtonTextAlt,
  checkSendEmail = false,
  recipients,
  onSubmit = () => {},
  progressText = '',
  errorText = '',
  senderId,
}: MailerProps) => {
  const classes = useStyles();

  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [emailEnabled, setEmailEnabled] = useState(!checkSendEmail);
  const [currentSubject, setCurrentSubject] = useState(subject);
  const [currentBody, setCurrentBody] = useState(body);

  const [sendEmail, { loading: sendingEmail, error: loadingError }] = useMutation<SendEmail, SendEmailVariables>(
    SendEmail_Gql
  );

  useEffect(() => {
    if (checkSendEmail) {
      setEmailEnabled(false);
    }
  }, [emailDialogOpen, checkSendEmail]);

  useEffect(() => {
    setCurrentSubject(subject);
  }, [subject]);

  useEffect(() => {
    setCurrentBody(body);
  }, [body]);

  const onClick = async () => {
    setEmailDialogOpen(true);
  };

  const send = async () => {
    try {
      if (emailEnabled) {
        await sendEmail({
          variables: {
            email: {
              recipientIds: recipients?.map((recipient: GetRegistrantsForCohort_getRegistrantsForCohort) => recipient.userId),
              senderId: senderId,
              nonTemplateSubject: currentSubject,
              nonTemplateText: currentBody,
            },
          },
        });
      }

      onSubmit();
      handleClose();
    } catch {}
  };

  const handleClose = () => {
    setEmailDialogOpen(false);
  };

  return (
    <>
      <ProgressIndicator isOpen={Boolean(progressText) || sendingEmail} title={progressText || 'Saving...'} /> 
      {(Boolean(errorText) || loadingError) && <Alert severity="error">{errorText || loadingError?.graphQLErrors[0].message}</Alert>}
      {renderTrigger(onClick)}

      <Dialog open={emailDialogOpen} onClose={handleClose}>
        <DialogTitle>{popupTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText className={emailEnabled ? '' : classes.disabled}>
            <p>To: {recipients.map(
              (recipient: GetRegistrantsForCohort_getRegistrantsForCohort) => (
                <Chip style={{ margin: 5 }} key={recipient?.user?.email} label={recipient?.user?.email} />
              ))
            }</p>
            <br />
            <TextField
              value={currentSubject}
              fullWidth
              placeholder="Subject"
              onChange={(e) => {
                onSubjectUpdate(e.target.value);
                setCurrentSubject(e.target.value);
              }}
            />
          </DialogContentText>
          <br />

          <ReactQuill
            id="emailDescription"
            className={emailEnabled ? '' : classes.disabled}
            value={currentBody}
            readOnly={!emailEnabled}
            onChange={(value: string) => {
              onBodyUpdate(value);
              setCurrentBody(value);
            }}
            placeholder="Body"
          />
          {checkSendEmail && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailEnabled}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailEnabled(e.target.checked)}
                  name="sendEmail"
                />
              }
              label="Send Email"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={send}>
            {checkSendEmail && !emailEnabled ? submitButtonTextAlt : submitButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Mailer;
