import { ComponentType } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import ProgressIndicator from './ProgressIndicator';

interface ProtectedRouteProps {
  component: ComponentType<any>;
  path: string;
  key: string;
}

const ProtectedRoute = ({ key, path, component }: ProtectedRouteProps) => {
  const auth = useAuth();
  const location = useLocation();

  if (auth.isLoading) {
    return <ProgressIndicator isOpen={true} title="Loading..." />;
  }

  if (auth.user?.isAuthenticated) {
    // @ts-ignore
    return <Route key={key} path={path} component={component} exact />;
  }

  return <Redirect to={`/login?redirectTo=${location.pathname}`} />;
};

export default ProtectedRoute;
