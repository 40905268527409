import { gql } from '@apollo/client';

export const addCohortToProgramMutation_Name = 'AddCohortToProgram';
export const addCohortToProgramMutation_Gql = gql`
  mutation AddCohortToProgram($cohort: AddCohortToProgramInput!) {
    addCohortToProgram(cohort: $cohort) {
      id
    }
  }
`;
