import { Quill } from 'react-quill';
import QuillHtmlSourceButton from 'quill-source-code-button';

const Block = Quill.import('blots/block');
class DivBlock extends Block {}

DivBlock.tagName = 'DIV';

Quill.register('blots/block', DivBlock, true);
Quill.register('modules/htmlSource', QuillHtmlSourceButton);

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida'];
Quill.register(Font, true);

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
];

interface QuillToolbarProps {
  name: string;
}

// Quill Toolbar component
export const QuillToolbar = (props: QuillToolbarProps) => {
  const { name } = props;
  return (
    <>
      <div id={name}>
        <span className="ql-formats">
          <select className="ql-font" defaultValue="arial">
            <option value="arial">Arial</option>
            <option value="comic-sans">Comic Sans</option>
            <option value="courier-new">Courier New</option>
            <option value="georgia">Georgia</option>
            <option value="helvetica">Helvetica</option>
            <option value="lucida">Lucida</option>
          </select>
          <select className="ql-header" defaultValue="3">
            <option value="1">Heading</option>
            <option value="2">Subheading</option>
            <option value="3">Normal</option>
          </select>
        </span>
        <span className="ql-formats">
          <button className="ql-bold" title="Bold" />
          <button className="ql-italic" title="Italic" />
          <button className="ql-underline" title="Underline" />
          <button className="ql-strike" title="Strikethrough" />
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" title="Numbered List" />
          <button className="ql-list" value="bullet" title="Bullet List" />
          <button className="ql-indent" value="-1" title="Decrease Indent" />
          <button className="ql-indent" value="+1" title="Increase Indent" />
        </span>
        <span className="ql-formats">
          <button className="ql-blockquote" title="Blockquote" />
          <select className="ql-color" title="Text Color" />
          <button className="ql-link" title="Insert Link" />
        </span>
      </div>
    </>
  );
};

export default QuillToolbar;
