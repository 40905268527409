import { gql } from '@apollo/client';

export const getCohortRoster_Name = 'GetCohortRoster';
export const getCohortRoster_Gql = gql`
  query GetCohortRoster($cohortId: String!) {
    getCohortRoster(cohortId: $cohortId) {
      cohortTitle
      cohortRosters {
        name
        email
        credentials
        organization
        location
      }
    }
  }
`;
