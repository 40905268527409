import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HomeTitleAndBody from './HomeTitleAndBody';
import { Container } from '@mui/material';

const DartmouthHome = () => {
  return (
    <>
      <Container maxWidth="xl" sx={{ p: 14, mb: 2 }}>
        <HomeTitleAndBody title="Join In and Help Make Health Knowledge ECHO">
          <Stack spacing={2}>
            <Typography variant="subtitle1">
              Project ECHO at Dartmouth Health (Extension for Community Health Outcomes) leverages learning, training and
              practice support to give voice to health and healthcare knowledge across New Hampshire and beyond.
            </Typography>

            <Typography variant="subtitle1">
              Working with health care specialists and generalists and as well as a wide range of professionals and
              community members, Project ECHO at Dartmouth Health helps build learning communities where participants
              share knowledge, skills and experiences to improve the health and well being of the people and communities
              that we serve.
            </Typography>

            <img src="/images/D-H-logo.jpg" alt="Dartmouth-Hitchcoch logo" />
          </Stack>
        </HomeTitleAndBody>
      </Container>
    </>
  );
};

export default DartmouthHome;
