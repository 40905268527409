import { Link as RouterLink } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import moment from 'moment';
import LabelAndValue from '../../CohortDetails/components/LabelAndValue';
import Label from '../../CohortDetails/components/Label';

interface ProjectListQuickViewProps {
  cohortId: string;
  meetingLink: string | null;
  developmentUser: string | null | undefined;
  evaluationUser: string | null | undefined;
  implementationUser: string | null | undefined;
  registrationOpenDate: string | null | undefined;
  registrationCloseDate: string | null | undefined;
  preRegistrantsCount: number | null
  newRegistrantsCount: number | null
  registeredCount: number | null
  waitlistCount: number | null
  deniedCount: number | null
  developmentSupportUser: string[]
  evaluationSupportUser: string[]
  implementationSupportUser: string[]
}

const ProjectListQuickView = (props: ProjectListQuickViewProps) => {
  const {
    cohortId,
    meetingLink,
    developmentUser,
    evaluationUser,
    implementationUser,
    registrationOpenDate,
    registrationCloseDate,
    preRegistrantsCount,
    newRegistrantsCount,
    registeredCount,
    waitlistCount,
    deniedCount,
    developmentSupportUser,
    evaluationSupportUser,
    implementationSupportUser
  } = props;
  const now = moment();

  return (
    <>
      {((registrationCloseDate && registrationCloseDate.length 
            && moment(registrationCloseDate).add(1, 'day').isBefore(now)) ||
        (registrationOpenDate && registrationOpenDate.length 
        && moment(registrationOpenDate).isAfter(now))) ? 
        <>
          <Grid container>
            <Grid xs={1}>&nbsp;</Grid>
            <Grid xs={10}>
              <LabelAndValue label="" value="Registration is currently closed" />
            </Grid>
            <Grid xs={1}>&nbsp;</Grid>
          </Grid>
          <Grid container>
            <Grid xs={1}>&nbsp;</Grid>
            <Grid xs={5}>
              <Link component={RouterLink} to={`/admin/cohort/${cohortId}`}>
                Dashboard Link
              </Link>
            </Grid>
            <Grid xs={5}>{meetingLink && <Link href={meetingLink}>Video Conference Link</Link>}</Grid>
            <Grid xs={1}>&nbsp;</Grid>
          </Grid>
        </>
      : 
        <>
          <Grid container>
            <Grid xs={2}>&nbsp;</Grid>
            <Grid xs={4}>
              <Link component={RouterLink} to={`/admin/cohort/${cohortId}`}>
                Dashboard Link
              </Link>
            </Grid>
            <Grid xs={4}>
              <Link href={`/registration/${cohortId}`}>Registration Link</Link>
            </Grid>
            <Grid xs={2}>&nbsp;</Grid>
          </Grid>
          <Grid container>
            <Grid xs={3}>&nbsp;</Grid>
            <Grid xs={5}>{meetingLink && <Link href={meetingLink}>Video Conference Link</Link>}</Grid>
            <Grid xs={3}>&nbsp;</Grid>
          </Grid>
        </>
      }

      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell align="center">ECHO Team Leads</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>
                {/* do not change - appears there's an issue in Prisma/GQL query */}
                <b>Development: </b> {developmentUser}
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>
                {/* do not change - appears there's an issue in Prisma/GQL query */}
                <b>Implementation: </b> {implementationUser}
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>
                {/* do not change - appears there's an issue in Prisma/GQL query */}
                <b>Evaluation: </b> {evaluationUser}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer sx={{ margin: '10px 0' }} component={Paper}>
        <Table sx={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell align="center">ECHO Support Team</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>
                <b>Development: </b> {developmentSupportUser.map((user, i) => {
                  if (i === 0) {
                    return user;
                  }

                  return `, ${user}`
                })}
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>
                <b>Implementation: </b> {implementationSupportUser.map((user, i) => {
                  if (i === 0) {
                    return user;
                  }

                  return `, ${user}`
                })}
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>
                <b>Evaluation: </b> {evaluationSupportUser.map((user, i) => {
                  if (i === 0) {
                    return user;
                  }

                  return `, ${user}`
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      
      <div>
        <Label text="Registrant Summary" />

        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Pre-Registrants</TableCell>
              <TableCell align="right">{preRegistrantsCount || 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>New Registrants</TableCell>
              <TableCell align="right">{newRegistrantsCount || 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Registered</TableCell>
              <TableCell align="right">{registeredCount || 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Waitlisted</TableCell>
              <TableCell align="right">{waitlistCount || 0}</TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>Denied</TableCell>
              <TableCell align="right">{deniedCount || 0}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default ProjectListQuickView;
