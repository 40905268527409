import { gql } from '@apollo/client';

export const updateSessionMutation_Name = 'UpdateSession';
export const updateSessionMutation_Gql = gql`
  mutation UpdateSession($session: UpdateSessionInput!) {
    updateSession(session: $session) {
      id
    }
  }
`;
