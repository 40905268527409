import {
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  OutlinedInput,
  Grid,
  TextField,
} from '@mui/material';
import {
  COLORADO_TENANT_ID,
  OREGON_TENANT_ID,
  VIRGINIA_TENANT_ID,
  DARTMOUTH_TENANT_ID,
  CS_TENANT_ID,
  DEMO_TENANT_ID,
} from 'utils/constants';
import { getTenantIdForDomain } from 'helpers/tenantHelpers';
import { useEffect, useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const firstTimes: string[] = ['yes', 'no'];

const getNamesFromList = (items: any, selectedValues: string[]) => {
  const labels: string[] = [];
  for (let selected of selectedValues) {
    labels.push(
      ...items.filter((c: any) => c.id === selected).map((x: any) => x.name)
    );
  }

  return labels.join(', ');
};

const getSexLabel = (value: any, sexs: any) => {
  if (!sexs) {
    return '';
  }
  const { name } = sexs.find((sex: any) => sex.id === value) || {};
  return name;
};

const getRoleDescriptionLabel = (value: any, roleDescriptions: any) => {
  const { name } = roleDescriptions.find((s: any) => s.id === value);
  return name;
};

const getSortOrder = (value: any, sexs: any) => {
  if (sexs.length > 0 && value) {
    const { sortOrder } = sexs.find((s: any) => s.id === value) || {};
    return sortOrder;
  }
  return '';
};

const SelectSex = ({
  classes,
  sexs,
  onChange,
  value,
  user,
  onFormElementChange,
}: any) => {
  const [showOtherIdentity, setShowOtherIdentity] = useState(false);

  useEffect(() => {
    setShowOtherIdentity(getSortOrder(value, sexs) === 1);
  }, [sexs, value]);

  return (
    <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
      {sexs.length > 0 && (
        <>
          <FormControl>
            <InputLabel required id="sex-select-label" color="warning">
              How do you identify?
            </InputLabel>
            <Select
              required
              className={classes.accountText}
              labelId="sex-select-label"
              id="sex-select"
              label="How do you identify?"
              color="warning"
              onChange={onChange}
              renderValue={() => getSexLabel(value, sexs)}
              value={value || ''}
              sx={{ textAlign: 'left' }}
            >
              {sexs.map((sex: any) => (
                <MenuItem key={sex.id} value={sex.id}>
                  {sex.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {showOtherIdentity && (
            <TextField
              sx={{ mt: 1 }}
              required
              className={classes.accountText}
              label="Other Identity"
              name="otherSex"
              value={user?.otherSex}
              onChange={onFormElementChange}
              color="warning"
            />
          )}
        </>
      )}
    </Grid>
  );
};

const SelectFirstTime = ({ classes, onChange, value }: any) => {
  return (
    <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
      {firstTimes.length > 0 && (
        <FormControl>
          <InputLabel required id="firsttime-select-label" color="warning">
            First time you are participating in ECHO?
          </InputLabel>
          <Select
            required
            className={classes.accountText}
            labelId="firsttime-select-label"
            id="firsttime-select"
            label="First time you are participating in ECHO?"
            color="warning"
            onChange={onChange}
            sx={{ textAlign: 'left' }}
            value={value === null ? '' : value === true ? 'yes' : 'no'}
          >
            <MenuItem value={''} disabled>
              Select One
            </MenuItem>
            {firstTimes.map((item: any, index: number) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
};

const SelectMarketingSource = ({
  classes,
  marketingSources,
  onChange,
  value,
  showOtherMarketingSource,
  user,
  onFormElementChange,
}: any) => {
  return (
    <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
      {marketingSources.length > 0 && (
        <>
          <FormControl sx={{ mb: 1 }}>
            <InputLabel required id="marketingSources-label" color="warning">
              How did you hear about ECHO? (select all that apply)
            </InputLabel>
            <Select
              required
              className={classes.accountSelect}
              labelId="marketingSources-label"
              multiple
              value={value.length ? value : []}
              onChange={onChange}
              input={
                <OutlinedInput label="How did you hear about ECHO? (select all that apply)" />
              }
              renderValue={(selected) =>
                getNamesFromList(marketingSources, selected)
              }
              MenuProps={MenuProps}
              color="warning"
            >
              {marketingSources.map((source: any, index: number) => (
                <MenuItem key={index} value={source.id}>
                  <Checkbox checked={value.indexOf(source.id) > -1} />
                  <ListItemText primary={source.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {showOtherMarketingSource && (
            <TextField
              required
              className={classes.accountText}
              label="Other"
              name="otherMarketingSource"
              value={user.otherMarketingSource}
              onChange={onFormElementChange}
              color="warning"
              sx={{ textAlign: 'center' }}
            />
          )}
        </>
      )}
    </Grid>
  );
};

const SelectRoleDescription = ({
  classes,
  roleDescriptions,
  onChange,
  value,
}: any) => {
  return (
    <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
      {roleDescriptions.length > 0 && (
        <FormControl>
          <InputLabel required id="roledesc-select-label" color="warning">
            Which of the following best describes your role?
          </InputLabel>
          <Select
            required
            className={classes.accountText}
            labelId="roledesc-select-label"
            id="roledesc-select"
            label="Which of the following best describes your role?"
            color="warning"
            renderValue={() => getRoleDescriptionLabel(value, roleDescriptions)}
            value={value || ''}
            onChange={onChange}
            sx={{ textAlign: 'left' }}
          >
            {roleDescriptions.map((desc: any, index: number) => (
              <MenuItem key={index} value={desc.id}>
                {desc.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
};

const SelectStartYear = ({ classes, startYears, onChange, value }: any) => {
  return (
    <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
      {startYears.length > 0 && (
        <FormControl>
          <InputLabel required id="startyear-select-label" color="warning">
            What year did you start in your current profession?
          </InputLabel>
          <Select
            required
            className={classes.accountText}
            labelId="startyear-select-label"
            id="startyear-select"
            label="What year did you start in your current profession?"
            color="warning"
            value={value || ''}
            onChange={onChange}
            sx={{ textAlign: 'left' }}
          >
            {startYears.map((year: number, index: number) => (
              <MenuItem key={index} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
};

const SelectBirthYear = ({ classes, birthYears, onChange, value }: any) => {
  return (
    <Grid item lg={6} xs={12} sx={{ mb: 1 }}>
      {birthYears.length > 0 && (
        <FormControl>
          <InputLabel required id="birthyear-select-label" color="warning">
            What is your birth year?
          </InputLabel>
          <Select
            required
            className={classes.accountText}
            labelId="birthyear-select-label"
            id="birthyear-select"
            label="What is your birth year?"
            color="warning"
            value={value || ''}
            onChange={onChange}
          >
            {birthYears.map((year: number, index: number) => (
              <MenuItem key={index} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
};

const TentantFields = ({
  classes,
  isHome,
  user,
  sexs,
  marketingSources,
  roleDescriptions,
  onSexChange,
  onMarketingSourceIdsChange,
  onFirstTimeChange,
  onStartYearChange,
  onRoleDescChange,
  onBirthYearChange,
  onFormElementChange,
  showOtherMarketingSource,
}: any) => {
  const tenantId = getTenantIdForDomain();

  if (!isHome) {
    return <></>;
  }

  if (tenantId === COLORADO_TENANT_ID || tenantId === DEMO_TENANT_ID) {
    return (
      <>
        <SelectSex
          classes={classes}
          sexs={sexs}
          user={user}
          value={user.sexId}
          onChange={onSexChange}
          onFormElementChange={onFormElementChange}
        />
        <SelectBirthYear
          classes={classes}
          birthYears={birthYears}
          value={user.birthYear}
          onChange={onBirthYearChange}
        />
        <SelectFirstTime
          classes={classes}
          onChange={onFirstTimeChange}
          value={user.firstSeries}
        />
        <SelectMarketingSource
          classes={classes}
          marketingSources={marketingSources}
          value={user.marketingSourceIds}
          onChange={onMarketingSourceIdsChange}
          showOtherMarketingSource={showOtherMarketingSource}
          user={user}
          onFormElementChange={onFormElementChange}
        />
        <SelectRoleDescription
          classes={classes}
          roleDescriptions={roleDescriptions}
          value={user.roleDescriptionId}
          onChange={onRoleDescChange}
        />
        <SelectStartYear
          classes={classes}
          startYears={startYears}
          value={user.professionStartYear}
          onChange={onStartYearChange}
        />
      </>
    );
  }

  if (tenantId === OREGON_TENANT_ID) {
    return (
      <>
        <SelectSex
          classes={classes}
          sexs={sexs}
          value={user.sexId}
          onChange={onSexChange}
          onFormElementChange={onFormElementChange}
          user={user}
        />
        <SelectBirthYear
          classes={classes}
          birthYears={birthYears}
          value={user.birthYear}
          onChange={onBirthYearChange}
        />
      </>
    );
  }

  if (tenantId === VIRGINIA_TENANT_ID) {
    return <></>;
  }

  if (tenantId === DARTMOUTH_TENANT_ID) {
    return (
      <SelectSex
        classes={classes}
        sexs={sexs}
        value={user.sexId}
        onChange={onSexChange}
        onFormElementChange={onFormElementChange}
        user={user}
      />
    );
  }

  if (tenantId === CS_TENANT_ID) {
    return <></>;
  }

  return <></>;
};

export default TentantFields;

const startYears = [
  2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011,
  2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000, 1999, 1998,
  1997, 1996, 1995, 1994, 1993, 1992, 1991, 1990, 1989, 1988, 1987, 1986, 1985,
  1984, 1983, 1982, 1981, 1980, 1979, 1978, 1977, 1976, 1975, 1974, 1973, 1972,
  1971, 1970, 1969, 1968, 1967, 1966, 1965, 1964, 1963, 1962, 1961, 1960, 1959,
  1958, 1957, 1956, 1955, 1954, 1953, 1952, 1951, 1950, 1949, 1948, 1947, 1946,
  1945, 1944, 1943, 1942, 1941, 1940,
];

const generateValidWorkingBirthYears = () => {
  const currentYear = new Date().getFullYear();
  const last80Years = [];
  
  for (let i = currentYear; i >= currentYear - 80; i--) {
    if (currentYear - i > 17) {
      last80Years.push(i);
    }
  }

  return last80Years;
}

const birthYears = generateValidWorkingBirthYears();
