import { AppBar, Toolbar, Typography, Stack, IconButton, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import HomeTitleAndBody from '../components/home/components/HomeTitleAndBody';
import supportedBrowsers from '../utils/browserVersions';

const UnsupportedBrowserPage = () => {
    const browserList = supportedBrowsers
    .map((browser) => `<li>${browser.name} (version ${browser.minVersion} or higher)</li>`)
    .join(' ');
    const unsupportedMessage = `<div style="margin-left: 20px;"><ul>${browserList}</ul></div>`;

    return (
    <>
      <AppBar position="static" sx={{ mb: 2 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'left' }}>
            ECHO Connect
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl" sx={{ p: 14, mb: 2 }}>
        <HomeTitleAndBody title="Unsupported Browser">
            <Stack spacing={2}>
                <Typography variant="subtitle1">
                    We're sorry, but your browser is not supported. Check your browser version by visiting <a href="https://www.whatsmybrowser.org/">What's my browser?</a>
                    
                    <br/><br/>Please upgrade to one of the following compatible browsers to be use this website:
                </Typography>
                <Typography variant="subtitle1">
                    <div dangerouslySetInnerHTML={{ __html: unsupportedMessage }} />
                </Typography>
                <Typography variant="subtitle1">
                    If you need further assistance, please contact the UIS Service Desk at 303-860-HELP(4357) or via email at help@cu.edu.
                </Typography>
            </Stack>
        </HomeTitleAndBody>
      </Container>
    </>
    );
};

export default UnsupportedBrowserPage;