import { gql } from '@apollo/client';

export const addSessionMutation_Name = 'AddSession';
export const addSessionMutation_Gql = gql`
  mutation AddSession($session: AddSessionInput!) {
    addSession(session: $session) {
      id
    }
  }
`;
