import {
  GetAccountLookups_getAccountLookups_professions,
  GetAccountLookups_getAccountLookups_professions_secondLevelProfessions,
  GetAccountLookups_getAccountLookups_professions_secondLevelProfessions_thirdLevelProfessions,
} from '../../../models/GeneratedModels';

const R = require('ramda');

export const getSecondaryProfessions = (
  professionId: string,
  professions: GetAccountLookups_getAccountLookups_professions[]
) => {
  if (professions && professions.length > 0) {
    const matchingProfessionIndex = R.findIndex(R.propEq('id', professionId))(professions);

    if (matchingProfessionIndex > -1) {
      const matchingProfessions = professions[matchingProfessionIndex];
      if (matchingProfessions) {
        const secondaryProfessions = matchingProfessions.secondLevelProfessions;
        if (secondaryProfessions && secondaryProfessions.length > 0) {
        return secondaryProfessions;
        }
      }
    }
  }

  return [];
};

export const getTertiaryProfessions = (
  professionId: string,
  secondaryProfessionId: string,
  professions: GetAccountLookups_getAccountLookups_professions[]
) => {
  if (professions) {
    const secondaryProfessions = getSecondaryProfessions(professionId, professions);

    if (secondaryProfessions && secondaryProfessions.length > 0) {
      const matchingSecondaryProfessionIndex = R.findIndex(R.propEq('id', secondaryProfessionId))(secondaryProfessions);
      
      if (matchingSecondaryProfessionIndex > -1) {
        const matchingSecondaryProfession = secondaryProfessions[matchingSecondaryProfessionIndex];
        if (matchingSecondaryProfession) {
          const tertiaryProfessions = matchingSecondaryProfession.thirdLevelProfessions;
          if (tertiaryProfessions && tertiaryProfessions.length > 0) {
            return tertiaryProfessions;
          }
        }
      }
    }
  }

  return [];
};

export const hasOtherProfession = (
  professionId: string,
  secondaryProfessionId: string,
  tertiaryProfessionId: string,
  professions: GetAccountLookups_getAccountLookups_professions[] | undefined
): boolean => {
  if (!professions) {
    return false;
  }

  let matchedProfession: GetAccountLookups_getAccountLookups_professions | null = null;
  let matchedSecondaryProfession: GetAccountLookups_getAccountLookups_professions_secondLevelProfessions | null = null;
  let matchedTertiaryProfession: GetAccountLookups_getAccountLookups_professions_secondLevelProfessions_thirdLevelProfessions | null =
    null;

  // Professions
  const matchingProfessionIndex = R.findIndex(R.propEq('id', professionId))(professions);
  if (matchingProfessionIndex > -1) {
    matchedProfession = professions[matchingProfessionIndex];

    if (matchedProfession.isOther) return true;
  }

  // Secondary Profession
  if (matchedProfession && matchedProfession.secondLevelProfessions && secondaryProfessionId.length > 0) {
    const matchingSecondaryProfessionIndex = R.findIndex(R.propEq('id', secondaryProfessionId))(
      matchedProfession.secondLevelProfessions
    );

    if (matchingSecondaryProfessionIndex > -1) {
      matchedSecondaryProfession = matchedProfession.secondLevelProfessions[matchingSecondaryProfessionIndex];

      if (matchedSecondaryProfession.isOther) return true;
    }
  }

  // Tertiary Profession
  if (
    matchedSecondaryProfession &&
    matchedSecondaryProfession.thirdLevelProfessions &&
    tertiaryProfessionId.length > 0
  ) {
    const matchingTertiaryProfessionIndex = R.findIndex(R.propEq('id', tertiaryProfessionId))(
      matchedSecondaryProfession.thirdLevelProfessions
    );

    if (matchingTertiaryProfessionIndex > -1) {
      matchedTertiaryProfession = matchedSecondaryProfession.thirdLevelProfessions[matchingTertiaryProfessionIndex];

      if (matchedTertiaryProfession.isOther) return true;
    }
  }

  return false;
};
