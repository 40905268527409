import { useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import PageHeader from '../components/global/PageHeader';
import UpdatePassword from 'components/account/UpdatePassword/UpdatePassword';
import ProgressIndicator from 'components/global/ProgressIndicator';
import { accountLinks } from 'utils/links';
import StyledRouterLink from 'components/global/StyledRouterLink';
import { validateForgotPassword_Gql } from 'gql/user/validateForgotPassword';
import {
  ValidateForgotPassword,
  ValidateForgotPasswordVariables,
  ValidateForgotPassword_validateForgotPassword,
  ValidateForgotPassword_validateForgotPassword_user,
} from 'models/GeneratedModels';
import useEchoQuery from 'hooks/useEchoQuery';
import { Container } from '@mui/material';

interface ResetPasswordParams {
  code: string;
}

const ResetPassword = () => {
  const { code } = useParams<ResetPasswordParams>();

  const { data, loading, error } = useEchoQuery<ValidateForgotPassword, ValidateForgotPasswordVariables>(
    validateForgotPassword_Gql,
    {
      variables: {
        code: decodeURIComponent(code),
      },
    }
  );

  const response: ValidateForgotPassword_validateForgotPassword | undefined = data?.validateForgotPassword;
  const user: ValidateForgotPassword_validateForgotPassword_user | null | undefined = response?.user;

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Loading..." />
      <Container maxWidth="xl" sx={{ p: 14, mb: 2 }}>
        <PageHeader title="Reset Password" />

        {error && <Alert severity="error">{error.message}</Alert>}
        {response && !response.isSuccess && <Alert severity="error">{response.message}</Alert>}
        {response && response.codeExpired && (
          <Alert severity="info">
            Your password reset link has expired. Please{' '}
            <StyledRouterLink to={accountLinks.forgotPassword.href} text="go here" /> to request another password reset.
          </Alert>
        )}

        {user && (
          <>
            <Typography variant="h6">
              Reset Password for {user.firstName} {user.lastName}
            </Typography>
            <UpdatePassword userId={user.id} redirectToLogin={true} />
          </>
        )}
      </Container>
    </>
  );
};

export default ResetPassword;
