import React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import LearnerResource from './LearnerResource';
import SectionHeader from 'components/learner/SectionHeader/SectionHeader';

import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';
import {
  GetCohortDetailForLearner_getCohortDetailForLearner_sessions,
  GetCohortDetailForLearner_getCohortDetailForLearner_sessionResources,
  GetCohortDetailForLearner_getCohortDetailForLearner_sessions_HubMemberCohortSession
} from 'models/GeneratedModels';

interface LearnerCohortSessionsProps {
  programId: string;
  sessions: GetCohortDetailForLearner_getCohortDetailForLearner_sessions[] | null;
  sessionResources: GetCohortDetailForLearner_getCohortDetailForLearner_sessionResources[] | null;
}

interface Roles {
  facilitator: string[];
  presenter: string[];
  panelist: string[];
}

const LearnerCohortSessions = ({ programId, sessions, sessionResources }: LearnerCohortSessionsProps) => {
  const getResourcesBySession = (sessionId: string) => {
    if (!sessionResources || sessionResources.length === 0) return [];

    return sessionResources.filter((sr) => sr.sessionId === sessionId);
  };

  const organizeRoles = (hubSessions: (GetCohortDetailForLearner_getCohortDetailForLearner_sessions_HubMemberCohortSession | null)[] | null) => {
    const roles: Roles = { facilitator: [], presenter: [], panelist: [] };
    hubSessions?.forEach(session => {
      const name = `${session?.hubMemberCohort?.user?.firstName} ${session?.hubMemberCohort?.user?.lastName}`;
      const credential = session?.hubMemberCohort?.credentials;
      const nameAndCreds = credential ? `${name}, ${credential}` : name;
      if (session?.hubRole.name === 'Facilitator' && !session.hubMemberCohort?.hide) {
        roles.facilitator.push(nameAndCreds);
      }
      if (session?.hubRole.name === 'Presenter' && !session.hubMemberCohort?.hide) {
        roles.presenter.push(nameAndCreds);
      }
      if (session?.hubRole.name === 'Panelist' && !session.hubMemberCohort?.hide) {
        roles.panelist.push(nameAndCreds);
      }
    });
    return roles;
  };

  const getMaxRoleEntries = (roles: any) => {
    return Math.max(roles.facilitator.length, roles.presenter.length, roles.panelist.length);
  };

  return (
    <>
      <SectionHeader>
        <Box sx={{ textAlign: 'left' }}>Sessions</Box>
      </SectionHeader>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Resources</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions && sessions.map((session) => {
              const resources = getResourcesBySession(session.id);
              const roles = organizeRoles(session.HubMemberCohortSession);
              const maxEntries = getMaxRoleEntries(roles);

              return (
                <>
                  <TableRow key={session.id}>
                    <TableCell>{`${session.number} - ${session.title}`}</TableCell>
                    <TableCell>{convertDateToTimeZoneFromUtc(session.startDate, true, false, false)}</TableCell>
                    <TableCell>{convertDateToTimeZoneFromUtc(session.endDate, true, false, true)}</TableCell>
                    <TableCell>
                      {resources.map((sr) => (
                        <LearnerResource
                          key={sr.resource.id}
                          displayName={sr.resource.displayName}
                          name={sr.resource.name}
                          resourceTypeId={sr.resource.resourceTypeId}
                          url={sr.resource.url}
                          programId={programId}
                        />
                      ))}
                    </TableCell>
                  </TableRow>
                  { maxEntries > 0 &&
                    <TableRow>
                      <TableCell colSpan={4}>
                        {roles.facilitator.length > 0 &&
                          <span style={{ marginRight: '25px' }}>
                            <strong>Facilitator(s):</strong> {roles.facilitator.join('; ')}
                          </span>
                        }
                        {roles.presenter.length > 0 &&
                          <span style={{ marginRight: '25px' }}>
                            <strong>Presenter(s):</strong> {roles.presenter.join('; ')}
                          </span>
                        }
                        {roles.panelist.length > 0 &&
                          <span>
                            <strong>Panelist(s):</strong> {roles.panelist.join('; ')}
                          </span>
                        }
                      </TableCell>
                    </TableRow>
                  }
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LearnerCohortSessions;
