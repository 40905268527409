import { gql } from '@apollo/client';

export const getRolesForUser_Name = 'GetRolesForUser';
export const getRolesForUser_Gql = gql`
  query GetRolesForUser($userId: ID!) {
    getRolesForUser(userId: $userId) {
      id
      name
    }
  }
`;
