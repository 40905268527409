import { gql } from '@apollo/client';

export const LogoutUserMutation_Name = 'LogoutUser';
export const LogoutUserMutation_Gql = gql`
  mutation LogoutUser {
    logout {
      success
    }
  }
`;
