import { gql } from '@apollo/client';

export const addExistingHubMemberCohort_Name = 'AddExistingHubMemberCohort';
export const addExistingHubMemberCohort_Gql = gql`
  mutation AddExistingHubMemberCohort($hubMemberCohort: AddExistingHubMemberCohortInput!) {
    addExistingHubMemberCohort(hubMemberCohort: $hubMemberCohort) {
      id
      userId
      cohortId
    }
  }
`;
