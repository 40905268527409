import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import SectionHeader from 'components/learner/SectionHeader/SectionHeader';
import TextSection from 'components/learner/TextSection/TextSection';
import HubMemberSessions from 'components/admin/CohortDetails/components/HubMemberSessions'
import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';
import { useAuth } from 'hooks/useAuth';

import LearnerCohortSessions from './components/LearnerCohortSessions';
import LearnerResource from './components/LearnerResource';

import { getMembersByCohort_Gql } from '../../../gql/hubTeam/getMembersByCohort';

import {
  GetMembersByCohort,
  GetMembersByCohortVariables,
  GetCohortDetailForLearner_getCohortDetailForLearner
} from 'models/GeneratedModels';

interface LearnerCohortDashboardProps {
  cohort: GetCohortDetailForLearner_getCohortDetailForLearner;
}

const LearnerCohortDetail = ({ cohort }: LearnerCohortDashboardProps) => {

  const { loading, data } = useQuery<GetMembersByCohort, GetMembersByCohortVariables>(
    getMembersByCohort_Gql,
    {
      variables: {
        cohortId: cohort.id,
      },
    }
  );

  const formatDate = (date: Date | null, isOngoing?: boolean | null): string => {
    if (isOngoing) return 'Cohort is Ongoing';

    if (!date) return '';

    return convertDateToTimeZoneFromUtc(date);
  };

  const checkLoggedInUser = () => {
    const hubMemberFound = data?.getMembersByCohort?.findIndex((member) => member.userId === auth.user?.id);

    return hubMemberFound ? hubMemberFound > -1 : false;
  }

  const auth = useAuth();
  const showCohortRoster = () => {
    return auth.user?.isAlbatross || auth.user?.isAdmin || auth.user?.isEchoStaff || auth.user?.isPartner || checkLoggedInUser();
  };

  return (
    <>
      <Grid container>
        <Grid xs={8}>
          <TextSection title="What This ECHO Will Focus On" text={cohort?.evaluationGoal ?? ''} />
          <TextSection title="Learning Objectives" text={cohort?.learningObjectives ?? ''} />
          <TextSection title="Project Partner(s)" text={cohort?.projectPartner ?? ''} />
        </Grid>
        <Grid xs={4}>
          {cohort && cohort.cohortResources && cohort.cohortResources?.length > 0 && (
            <Box sx={{ mb: 3 }}>
              <SectionHeader>Cohort Resources</SectionHeader>
              <Stack spacing={2}>
                {cohort.cohortResources.map((cr) => (
                  <div>
                    <LearnerResource
                      displayName={cr.displayName}
                      name={cr.name}
                      resourceTypeId={cr.resourceTypeId}
                      url={cr.url}
                      programId={cohort.programId}
                    />
                  </div>
                ))}
              </Stack>
            </Box>
          )}

          <SectionHeader>Quick Links</SectionHeader>
          <Stack spacing={1} alignItems='baseline'>
            <Link sx={{ textDecoration: 'none', color: 'primary' }} href={cohort?.meetingLink ?? ''} target="_blank">
              Meeting Link
            </Link>
            {showCohortRoster() && (
              <Link sx={{ textDecoration: 'none' }} component={RouterLink} to={`/cohort-roster/${cohort?.id ?? ''}`}>
                View Cohort Roster
              </Link>
            )}
          </Stack>

          {/* <HubMemberSessions sessions={cohort?.sessions} /> */}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item>
          <TextSection
            title="Program Dates"
            text={`${formatDate(cohort?.startDate)} - ${formatDate(cohort?.endDate, cohort?.isOngoing)}`}
          />
        </Grid>
      </Grid>
      <LearnerCohortSessions
        programId={cohort?.programId ?? ''}
        sessions={cohort?.sessions ?? []}
        sessionResources={cohort?.sessionResources ?? []}
      />
    </>
  );
};

export default LearnerCohortDetail;
