import React, { ReactNode, useState, useEffect } from 'react';
import { Box, Drawer, Button, Stack } from '@mui/material';
import PageHeader from './PageHeader';

interface DrawerWithTriggerProps {
  trigger: ReactNode;
  children: ReactNode;
  width: number;
  title: string;
  showSave: boolean;
  showCancel: boolean;
  isOpen: boolean;
  onSaveClick?: Function;
  textAlign?: 'left' | 'center';
  anchor?: 'right' | 'left' | 'top' | 'bottom';
  cancelText?: string;
  onCancelClick?: () => void;
  openDrawer?: () => void;
}

const DrawerWithTrigger = ({ textAlign = 'left', anchor = 'right', ...props }: DrawerWithTriggerProps) => {
  const {
    trigger,
    children,
    width,
    title,
    showSave,
    showCancel,
    cancelText,
    isOpen,
    onSaveClick,
    onCancelClick,
    openDrawer,
  } = props;

  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const cancelClick = () => {
    if (onCancelClick) {
      onCancelClick();
    }

    setOpen(false);
  };

  return (
    <>
      {openDrawer ? <span onClick={openDrawer}>{trigger}</span> : <span onClick={() => setOpen(true)}>{trigger}</span>}

      <Drawer anchor={anchor} open={open} onClose={() => cancelClick()}>
        <Box sx={{ width: `${width}px`, textAlign: 'center', mt: 2 }}>
          <PageHeader title={title} />

          <Stack spacing={2} sx={{ ml: 2, mr: 2 }}>
            {children}

            {showSave && onSaveClick && (
              <Button color="primary" variant="contained" onClick={() => onSaveClick()}>
                Save
              </Button>
            )}

            {showCancel && (
              <Button color="primary" variant="outlined" onClick={cancelClick}>
                {cancelText ?? 'Cancel'}
              </Button>
            )}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerWithTrigger;
