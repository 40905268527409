import Typography from '@mui/material/Typography';

const PasswordRequirements = () => {
  return (
    <Typography variant="caption" sx={{ textAlign: 'center' }}>
      Password must be at least 8 characters long, contain one uppercase, one lowercase, one number, and one special
      character.
    </Typography>
  );
};

export default PasswordRequirements;
