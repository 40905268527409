import { gql } from '@apollo/client';

export const getAllProgramsQuery_Name = 'GetAllPrograms';
export const getAllProgramsQuery_Gql = gql`
  query GetAllPrograms {
    getAllPrograms {
      id
      name
      partnerId
      partner {
        name
      }
      isArchived
      _count {
        cohorts
      }
    }
  }
`;
