import { gql } from '@apollo/client';

export const getCohortsOpenForRegistrationQuery_Name = 'GetCohortsOpenForRegistration';
export const getCohortsOpenForRegistrationQuery_Gql = gql`
  query GetCohortsOpenForRegistration {
    getCohortsOpenForRegistration {
      id
      internalLabel
      externalLabel
      startDate
      endDate
      isOngoing
      allowPreregistration
    }
  }
`;
