import { gql } from '@apollo/client';

export const getCohortDetailForLearner_Name = 'GetCohortDetailForLearner';
export const getCohortDetailForLearner_Gql = gql`
  query GetCohortDetailForLearner($cohortId: String!) {
    getCohortDetailForLearner(cohortId: $cohortId) {
      cohortResources {
        displayName
        id
        name
        resourceTypeId
        url
      }
      sessionResources {
        sessionId
        resource {
          displayName
          id
          name
          resourceTypeId
          url
        }
      }
      id
      endDate
      evaluationGoal
      externalLabel
      internalLabel
      isOngoing
      learningObjectives
      meetingLink
      programId
      projectPartner
      scheduleDescription
      startDate
      sessions {
        id
        number
        title
        startDate
        endDate
        HubMemberCohortSession {
          id
          hubRoleId
          hubMemberCohort {
            id
            facilitateAll
            presentAll
            panelistAll
            credentials
            hide
            user {
              id
              firstName
              lastName
            }
          }
          hubRole {
            id
            name
          }
        }
      }
    }
  }
`;
