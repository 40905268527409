import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import PreviewIcon from '@mui/icons-material/Preview';

import { displayFormattedTime, convertDateTimeToTimeZoneFromUtc, convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';
import { getEmailHistory_Gql } from '../../../gql/user/getEmailHistory';
import {
  GetEmailHistoryForUser,
  GetEmailHistoryForUserVariables,
  GetEmailHistoryForUser_getEmailHistoryForUser,
} from '../../../models/GeneratedModels';
import ProgressIndicator from '../../global/ProgressIndicator';
import SafeDisplayHtml from '../../global/SafeDisplayHtml';
import { getTenantIdForDomain } from 'helpers/tenantHelpers';

interface EmailHistoryProps {
  userId: string;
}

const EmailHistory = ({ userId }: EmailHistoryProps) => {
  const { loading, data } = useQuery<GetEmailHistoryForUser, GetEmailHistoryForUserVariables>(getEmailHistory_Gql, {
    variables: {
      userId,
    },
  });

  const emails: GetEmailHistoryForUser_getEmailHistoryForUser[] | undefined | null = data?.getEmailHistoryForUser;

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Loading..." />

      {emails && emails.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Date Sent</TableCell>
                <TableCell align="left">From Address</TableCell>
                <TableCell align="left">Subject</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emails.map((email) => (
                <TableRow key={email.sentAt} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {convertDateToTimeZoneFromUtc(email.sentAt, true)}
                  </TableCell>
                  <TableCell align="left">{email.fromAddress}</TableCell>
                  <TableCell align="left">{email.subject}</TableCell>
                  <TableCell align="right">
                    <EmailPreview
                      subject={email.subject}
                      text={email.text}
                      dateSent={convertDateToTimeZoneFromUtc(email.sentAt, true)}
                      from={email.fromAddress}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {(!emails || emails.length === 0) && <Alert severity="info">This user hasn't been sent any emails yet.</Alert>}
    </>
  );
};

interface EmailPreviewProps {
  subject: string | null;
  text: string | null;
  dateSent: string;
  from: string | null;
}

const EmailPreview = ({ subject, text, dateSent, from }: EmailPreviewProps) => {
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleClickOpen = () => {
    setPreviewOpen(true);
  };

  const handleClose = () => {
    setPreviewOpen(false);
  };

  return (
    <>
      <Tooltip title="View Email">
        <PreviewIcon onClick={handleClickOpen} sx={{ cursor: 'pointer', color: 'primary.main' }} />
      </Tooltip>
      <Dialog open={previewOpen} onClose={handleClose}>
        <DialogTitle>Email Preview</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack spacing={2}>
              <b>Sent: </b> {dateSent}
              <b>From: </b> {from}
              <b>Subject: </b> {subject}
              <Divider />
              <SafeDisplayHtml htmlText={text} />
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmailHistory;
