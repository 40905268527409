import { useMutation } from '@apollo/client';
import { toggleArchiveState_Gql } from 'gql/programResources/toggleArchiveState';
import { ToggleArchiveState, ToggleArchiveStateVariables } from 'models/GeneratedModels';
import { getResourceForProgram_Name } from 'gql/programResources/getResourceForProgram';

export function useToggleResourceArchive() {
  const [toggleArchived, { loading }] = useMutation<ToggleArchiveState, ToggleArchiveStateVariables>(
    toggleArchiveState_Gql,
    {
      refetchQueries: [getResourceForProgram_Name],
    }
  );

  return { toggleArchived, loading };
}
