import { gql } from '@apollo/client';

export const deleteCohortRegistrationMutation_Name = 'DeleteCohortRegistration';
export const deleteCohortRegistrationMutation_Gql = gql`
  mutation DeleteCohortRegistration($id: String!) {
    deleteCohortRegistration(id: $id) {
      message
      status
    }
  }
`;
