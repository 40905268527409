import { gql } from '@apollo/client';

export const ExtendSessionMutation_Name = 'ExtendSession';
export const ExtendSessionMutation_Gql = gql`
  mutation ExtendSession {
    extendToken {
      isValid
      errorMessage
    }
  }
`;
