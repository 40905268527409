import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Box, Drawer, Button, TextField, Typography, SelectChangeEvent, Alert } from '@mui/material';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { FaPlus } from 'react-icons/fa';

import ProgressIndicator from '../../global/ProgressIndicator';
import SuccessSnackbar from '../../global/SuccessSnackbar';
import { GetAllPartners, GetAllPartners_getPartners } from '../../../models/GeneratedModels';
import { AddProgramInput } from '../../../models/GeneratedModels';
import { getAllProgramsQuery_Name } from '../../../gql/program/getAllProgramsQuery';
import { addProgramMutation_Gql } from '../../../gql/program/addProgramMutation';
import { getPartnersQuery_Gql } from '../../../gql/partner/getPartnersQuery';

const AddProgramDrawer = () => {
  const [open, setOpen] = React.useState(false);
  const [program, setProgram] = useState<AddProgramInput>(setDefaultProgram());
  const [partners, setPartners] = useState<GetAllPartners_getPartners[] | null>([]);
  const [hasNameError, setHasNameError] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { loading: partnerLoading, data } = useQuery<GetAllPartners>(getPartnersQuery_Gql);

  useEffect(() => {
    if (partnerLoading || !data) return;

    setPartners(data.getPartners);
  }, [data, partnerLoading]);

  const [addNewProgram, { error, loading }] = useMutation(addProgramMutation_Gql, {
    variables: {
      program: program,
    },
    refetchQueries: [getAllProgramsQuery_Name],
  });

  useEffect(() => {
    setShowProgress(loading);
  }, [loading]);

  const onFormElementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setProgram({
      ...program,
      [name]: value,
    });
  };

  const onSelectElementChange = (e: SelectChangeEvent) => {
    const name = e.target.name;
    const value = e.target.value;

    setProgram({
      ...program,
      [name]: value,
    });
  };

  const isValid = (): boolean => {
    setHasNameError(false);
    let valid = true;

    if (program.name.length === 0) {
      setHasNameError(true);
      valid = false;
    }

    return valid;
  };

  const saveProgram = async () => {
    try {
      if (!isValid()) return;

      await addNewProgram();

      if (!error) {
        setProgram(setDefaultProgram());
        setShowSuccess(true);
        setOpen(false);
      }
    } catch {}
  };

  const cancelEntry = async () => {
    setProgram(setDefaultProgram());
    setOpen(false);
  };

  function setDefaultProgram(): AddProgramInput {
    return {
      name: '',
      partnerId: null,
    };
  }

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outlined" color="primary" size="small">
        <FaPlus />
        &nbsp; Add New Program
      </Button>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <ProgressIndicator isOpen={showProgress} title="Saving" />

        {error && <Alert severity="error">{error.graphQLErrors[0].message}</Alert>}

        <Typography sx={{ marginTop: 3, marginBottom: 3, textAlign: 'center' }} variant="h5" color="primary">
          Add New Program
        </Typography>

        <Box sx={{ width: 400 }}>
          <TextField
            sx={{ width: 350, marginLeft: 2 }}
            value={program.name}
            name="name"
            variant="outlined"
            color="warning"
            onChange={onFormElementChange}
            helperText="Required"
            error={hasNameError}
          />

          <FormControl sx={{ width: 350, ml: 2, mt: 2 }}>
            <InputLabel id="partner-label" color="warning">
              Implementation Partner
            </InputLabel>
            <Select
              value={program.partnerId ?? ''}
              name="partnerId"
              onChange={onSelectElementChange}
              labelId="partner-label"
              label="Implementation Partner"
              color="warning"
            >
              <MenuItem value=""></MenuItem>
              {partners &&
                partners.map((partner: GetAllPartners_getPartners) => (
                  <MenuItem key={partner.id} value={partner.id}>
                    {partner.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <Box sx={{ ml: 2, mt: 2, textAlign: 'center' }}>
            <Button onClick={saveProgram} color="primary" variant="contained">
              Add
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button onClick={cancelEntry} color="primary" variant="outlined">
              Cancel
            </Button>
          </Box>
        </Box>
      </Drawer>
      <SuccessSnackbar isOpen={showSuccess} text="Program has been created" />
    </>
  );
};

export default AddProgramDrawer;
