import { useState } from 'react';
import { useMutation } from '@apollo/client';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';

import ProgressIndicator from '../../global/ProgressIndicator';
import DrawerWithTrigger from '../../global/DrawerWithTrigger';
import AddEditRegistrationQuestion from './AddEditRegistrationQuestion';

import { updateRegistrationQuestions_Gql } from '../../../gql/cohort/updateRegistrationQuestion';
import { GetCohortByIdQuery_Name } from '../../../gql/cohort/getCohortByIdQuery';
import { RegistrationQuestionInput, UpdateRegistrationQuestionVariables } from '../../../models/GeneratedModels';
import { EditRegistrationQuestionProps } from './Cohort.interfaces';

const EditRegistrationQuestion = ({ cohortId, registrationQuestion }: EditRegistrationQuestionProps) => {
  const [errorText, setErrorText] = useState('');

  const question: RegistrationQuestionInput = {
    id: registrationQuestion.id,
    title: registrationQuestion.title,
    type: registrationQuestion.type,
    name: registrationQuestion.name,
    required: registrationQuestion.required,
    yesNo: registrationQuestion.yesNo,
    isMultiSelect: registrationQuestion.isMultiSelect,
    hasOtherOption: registrationQuestion.hasOtherOption,
    scale: registrationQuestion.scale?.map((scale) => { return { label: scale.label }}),
    statements: registrationQuestion.statements?.map((st) => { return { name: st.name, statement: st.statement }})
  };

  if (registrationQuestion.options) {
    question.options = [];

    registrationQuestion.options.forEach((o) => {
      question?.options?.push({
        label: o.label,
        value: o.value,
      });
    });
  }

  const [drawerOpen, setIsDrawerOpen] = useState(false);

  const [updateRegistrationQuestion, { loading }] = useMutation<void, UpdateRegistrationQuestionVariables>(
    updateRegistrationQuestions_Gql,
    {
      refetchQueries: [GetCohortByIdQuery_Name],
    }
  );

  const onSave = async (question: RegistrationQuestionInput) => {
    try {
      setErrorText('');

      await updateRegistrationQuestion({
        variables: {
          cohortId,
          registrationQuestion: question,
        },
      });

      setIsDrawerOpen(false);
    } catch (error: any) {
      console.info(`error: ${JSON.stringify(error)}`);
      setErrorText(error.graphQLErrors[0].message);
    }
  };

  return (
    <>
      <DrawerWithTrigger
        trigger={
          <Tooltip title={`Edit ${question.name}`}>
            <EditIcon sx={{ color: 'primary.main', cursor: 'pointer' }} onClick={() => setIsDrawerOpen(true)} />
          </Tooltip>
        }
        width={500}
        title="Edit Registration Question"
        showSave={false}
        showCancel={true}
        isOpen={drawerOpen}
      >
        <ProgressIndicator isOpen={loading} title="Saving..." />

        <AddEditRegistrationQuestion registrationQuestion={question} onSave={onSave} errorText={errorText} />
      </DrawerWithTrigger>
    </>
  );
};

export default EditRegistrationQuestion;
