import { reactapp_APIURL } from '../../../../../helpers/tenantHelpers';

import useAxios from 'hooks/useAxios';

export function useGetFileFromS3() {
  const { get } = useAxios();

  const getFile = async (programId: string, fileName: string) => {  
    try {
      const route = `${reactapp_APIURL}/file/download?programId=${programId}&fileName=${fileName}`;
      const response = await get(route);

      const binary = atob(response.data.file);
      const byteNumbers = new Array(binary.length);

      for (let i = 0; i < binary.length; i++) {
        byteNumbers[i] = binary.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: response.data.contentType });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      console.error('Error uploading file:', error);
    }
  };  

  return { getFile };
}
