import React from 'react';

const NotImplemented = () => {
  return (
    <>
      <img
        alt="New Feature"
        src="https://kodosurvey.com/sites/default/files/styles/resources_large/public/2018-10/new_feature_5795c1fa1aea7.jpg?itok=4wqJsv87"
        style={{ width: '500px', height: '500px' }}
      />

      <p>
        <b>This feature is in development</b>
      </p>
    </>
  );
};

export default NotImplemented;
