import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import DrawerWithTrigger from 'components/global/DrawerWithTrigger';
import LinkIcon from '@mui/icons-material/Link';
import ProgressIndicator from 'components/global/ProgressIndicator';

import { addResource_Gql } from 'gql/programResources/addResource';
import { getResourceForProgram_Name } from 'gql/programResources/getResourceForProgram';
import { AddResource, AddResourceVariables, AddResourceInput } from 'models/GeneratedModels';
import { RESOURCE_TYPE_URL } from 'utils/constants';

interface UrlResourceProps {
  programId: string;
}

const UrlResource = ({ programId }: UrlResourceProps) => {
  const [hasError, setHasError] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [urlResource, setUrlResource] = useState<AddResourceInput>(getDefaultResourceInput());

  const [saveUrlResource, { loading }] = useMutation<AddResource, AddResourceVariables>(addResource_Gql, {
    refetchQueries: [getResourceForProgram_Name],
  });

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setUrlResource({
      ...urlResource,
      [name]: value,
    });
  };

  const validateForm = (): boolean => {
    setHasError(false);

    if (!urlResource.name || urlResource.name.trim().length === 0) {
      setHasError(true);
      return false;
    }

    if (!urlResource.url || urlResource.url.trim().length === 0) {
      setHasError(true);
      return false;
    }

    return true;
  };

  const onSaveClick = async () => {
    const isFormValid = validateForm();

    if (isFormValid) {
      await saveUrlResource({
        variables: {
          resource: urlResource,
        },
      });

      setUrlResource(getDefaultResourceInput());
      setDrawerOpen(false);
    }
  };

  function getDefaultResourceInput(): AddResourceInput {
    return {
      programId,
      resourceTypeId: RESOURCE_TYPE_URL,
      name: '',
      displayName: '',
      url: '',
    };
  }

  return (
    <>
      <DrawerWithTrigger
        trigger={
          <Button sx={{ top: -12 }} variant="outlined" color="primary" size="small" startIcon={<LinkIcon />}>
            Add URL
          </Button>
        }
        width={500}
        title="Add a URL Resource"
        showSave={true}
        onSaveClick={onSaveClick}
        showCancel={true}
        isOpen={drawerOpen}
      >
        <ProgressIndicator isOpen={loading} title="Saving..." />

        <Stack spacing={2}>
          {hasError && <Alert severity="error">Resource name and URL are required</Alert>}

          <TextField
            onChange={onTextChange}
            value={urlResource.name}
            name="name"
            color="warning"
            label="Resource Name"
          />

          <TextField
            onChange={onTextChange}
            value={urlResource.displayName}
            name="displayName"
            color="warning"
            label="Display Name"
          />

          <TextField onChange={onTextChange} value={urlResource.url} name="url" color="warning" label="URL" />
        </Stack>
      </DrawerWithTrigger>
    </>
  );
};

export default UrlResource;
