import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import { loginLink } from 'utils/links';

import ProgressIndicator from '../../global/ProgressIndicator';

import { useAuth } from '../../../hooks/useAuth';

import { addPreRegistrant_Gql } from '../../../gql/cohortPreRegistration/addPreRegistrant';
import { getPreRegistrationsForUser_Name } from '../../../gql/cohortPreRegistration/getPreRegistrationsForUser';
import {
  AddPreRegistrantVariables,
  AddCohortPreRegistrationInput,
} from '../../../models/GeneratedModels';

interface PreRegistrationProps {
  cohortTitle: string;
  cohortId: string;
}

const PreRegistration = ({ cohortTitle, cohortId }: PreRegistrationProps) => {
  const history = useHistory();
  const auth = useAuth();

  const [open, setOpen] = useState(false);
  const [input, setInput] = useState<AddCohortPreRegistrationInput>(createPreRegistrationInput());
  const [hasValidationError, setHasValidationError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  //TODO: Show on the detail page if already pre-registered (if logged in)

  const [addPreRegistrant, { loading }] = useMutation<void, AddPreRegistrantVariables>(addPreRegistrant_Gql, {
    refetchQueries: [getPreRegistrationsForUser_Name],
  });

  const onFormElementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleClickOpen = () => {
    if (!auth.user?.isAuthenticated) {
      history.push(loginLink.href)
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setInput({
      cohortId,
      firstName: '',
      lastName: '',
      email: '',
    });

    setErrorText('');
    setHasValidationError(false);

    setOpen(false);
  };

  const validateForm = (): boolean => {
    setHasValidationError(false);
    setErrorText('');

    if (!input.firstName || input.firstName.length === 0) {
      setHasValidationError(true);
      return false;
    }

    if (!input.lastName || input.lastName.length === 0) {
      setHasValidationError(true);
      return false;
    }

    if (!input.email || input.email.length === 0) {
      setHasValidationError(true);
      return false;
    }

    return true;
  };

  const registerClick = async () => {
    try {
      if (!validateForm()) return;

      await addPreRegistrant({
        variables: {
          preRegistrant: input,
        },
      });

      setShowConfirmation(true);
    } catch (error: any) {
      setErrorText(error.graphQLErrors[0].message);
    }
  };

  function createPreRegistrationInput(): AddCohortPreRegistrationInput {
    if (!auth.user) {
      return {
        cohortId,
        firstName: '',
        lastName: '',
        email: '',
      };
    }

    return {
      userId: auth.user.id,
      cohortId,
      firstName: getNameParts('first'),
      lastName: getNameParts('last'),
      email: auth.user?.email ?? '',
    };
  }

  function getNameParts(part: 'first' | 'last') {
    try {
      if (!auth.user || !auth.user.fullName) return '';

      const nameParts = auth.user.fullName.split(' ');

      return part === 'first' ? nameParts[0] : nameParts[1];
    } catch {
      return '';
    }
  }

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Saving..." />

      <Button variant="contained" onClick={handleClickOpen}>
        Pre-Register For This Cohort
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Pre-Register for {cohortTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill out the form below to pre-register. You will receive an email when registration for this cohort
            has been opened.
          </DialogContentText>
          <Box sx={{ mt: 2 }}>
            {showConfirmation && <Alert severity="success">Your pre-registration was successful</Alert>}

            {!showConfirmation && (
              <>
                {auth.user?.isAuthenticated ? (
                  <>
                    <Grid container>
                      <Grid item md={6} xs={12}>
                        <Typography variant="h6">Name</Typography>
                        <Typography variant="body1">{`${input.firstName} ${input.lastName}`}</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography variant="h6">Email</Typography>
                        <Typography variant="body1">{input.email}</Typography>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Stack spacing={2}>
                      <TextField
                        value={input.firstName}
                        name="firstName"
                        label="First Name"
                        color="warning"
                        fullWidth
                        variant="standard"
                        onChange={onFormElementChange}
                      />
                      <TextField
                        value={input.lastName}
                        name="lastName"
                        label="Last Name"
                        color="warning"
                        fullWidth
                        variant="standard"
                        onChange={onFormElementChange}
                      />
                      <TextField
                        value={input.email}
                        name="email"
                        label="Email"
                        color="warning"
                        fullWidth
                        variant="standard"
                        onChange={onFormElementChange}
                      />
                    </Stack>
                  </>
                )}
              </>
            )}
          </Box>
          {hasValidationError && (
            <Alert sx={{ mt: 2 }} severity="error">
              All Fields are Required
            </Alert>
          )}

          {errorText.length > 0 && <Alert severity="error">{errorText}</Alert>}
        </DialogContent>
        <DialogActions>
          {showConfirmation && (
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          )}

          {!showConfirmation && (
            <>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" onClick={registerClick}>
                Pre-Register
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PreRegistration;
