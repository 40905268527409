import { useEffect } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HomeTitleAndBody from './HomeTitleAndBody';

import favicon from './images/CO-favicon.png';
import { Container } from '@mui/material';

const ColoradoHome = () => {
  useEffect(() => {
    const faviconElement  = document.getElementById('favicon') as HTMLLinkElement;
    if (faviconElement) {
      faviconElement.href = favicon;
    }
  }, []);

  return (
    <Container maxWidth="xl" sx={{ p: 14, mb: 2 }}>
      <HomeTitleAndBody title="Join In and Help Make Health Knowledge ECHO">
        <Stack spacing={2}>
          <Typography variant="subtitle1" textAlign="justify">
            ECHO Colorado (Extension for Community Health Outcomes) leverages learning, training and practice support to
            give voice to health care knowledge across Colorado and beyond.
          </Typography>

          <Typography variant="subtitle1" textAlign="justify">
            Working with specialists, generalists and a wide range of different organizations, ECHO Colorado builds
            virtual learning collaboratives where health care professionals can share knowledge, skills and experiences.
            The goal is to increase everyone's ability to manage complex health care problems.
          </Typography>
        </Stack>
      </HomeTitleAndBody>
    </Container>
  );
};

export default ColoradoHome;
