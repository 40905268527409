import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

interface StyledRouterLinkButtonProps {
  to: string;
  text: string;
  icon?: React.ReactNode;
  color?: 'secondary' | 'inherit' | 'primary' | 'success' | 'error' | 'info' | 'warning' | undefined;
}

const StyledRouterLinkButton = ({ to, text, icon, color = 'secondary' }: StyledRouterLinkButtonProps) => {
  return (
    <Link component={RouterLink} to={to}>
      <Button sx={{ mr: 1 }} color={color}>
        {icon && <Box sx={{ mr: 1 }}>{icon}</Box>}
        {text}
      </Button>
    </Link>
  );
};

export default StyledRouterLinkButton;
