import React, { useState } from 'react';
import { BrowserRouter as Router, RouteComponentProps, Switch, Route, withRouter } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ViewListIcon from '@mui/icons-material/ViewList';

import { routes } from '../../config/Routes';
import RouteItem from '../../models/RouteItem.model';
import NavDrawer from './NavDrawer';
import { useAuth } from '../../hooks/useAuth';
import ProtectedRoute from './ProtectedRoute';
import AccountDropdown from './AccountDropdown';
import StyledRouterLinkButton from './StyledRouterLinkButton';
import { accountLinks, loginLink, learnerLinks } from 'utils/links';

const MenuTemp = () => {
  const auth = useAuth();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };

  return (
    <>
      <Router>
        <AppBar position="static" sx={{ mb: 10, position: "fixed", top: 0, zIndex: 100 }}>
          <Toolbar>
            <IconButton onClick={() => setIsDrawerOpen(true)} edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <NavDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
            <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'left' }}>
              ECHO Connect
            </Typography>

            <StyledRouterLinkButton
              to={learnerLinks.progams.href}
              text={learnerLinks.progams.text}
              icon={<ViewListIcon />}
            />

            {(!auth.user || !auth.user.isAuthenticated) && (
              <>
                <StyledRouterLinkButton
                  to={accountLinks.createAccount.href}
                  text={accountLinks.createAccount.text}
                  icon={<PersonAddIcon />}
                />
                <StyledRouterLinkButton to={loginLink.href} text={loginLink.text} icon={<LoginIcon />} />
              </>
            )}

            {auth.user && auth.user.isAuthenticated && <AccountDropdown />}
          </Toolbar>
        </AppBar>
        <Switch>
          {routes.map((route: RouteItem) =>
            route.protected ? (
              <ProtectedRoute key={route.key} path={route.path} component={route.component} />
            ) : (
              // @ts-ignore
              <Route key={route.key} path={route.path} component={route.component as RouteComponentProps} exact />
            )
          )}
        </Switch>
      </Router>
    </>
  );
};

export default withRouter(MenuTemp);
