import React, { useState, useEffect } from 'react';
import {
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  TextField,
  Alert,
  Stack,
  Box
} from '@mui/material';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { FaTimes } from 'react-icons/fa';

import LikertScale from './Questions/LikertScale';

import { RegistrationQuestionInput } from '../../../models/GeneratedModels';
import { AddEditRegistrationQuestionProps } from './Cohort.interfaces';

import 'react-quill/dist/quill.snow.css';

const ReactQuill = require('react-quill');
const R = require('ramda');

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    helperText: {
      color: theme.palette.primary.main,
    },
    editor: {
      height: '200px',
      marginBottom: '45px'
    },
    delete: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    add: {
      color: 'green',
      cursor: 'pointer',
    },
  })
);

const AddEditRegistrationQuestion = ({ registrationQuestion, onSave, errorText }: AddEditRegistrationQuestionProps) => {
  const classes = useStyles();

  const [question, setQuestion] = useState<RegistrationQuestionInput>(registrationQuestion);
  const [newAnswerText, setNewAnswerText] = useState('');

  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [hasNameError, setHasNameError] = useState(false);
  const [hasYesTextError, setHasYesTextError] = useState(false);
  const [hasNoTextError, setHasNoTextError] = useState(false);
  const [hasAnswerError, setHasAnswerError] = useState(false);
  const [hasScaleError, setHasScaleError] = useState(false);
  const [hasStatementError, setHasStatementError] = useState(false);

  useEffect(() => {
    setQuestion(JSON.parse(JSON.stringify(registrationQuestion)));
  }, [registrationQuestion]);

  const onRequiredChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    setQuestion({
      ...question,
      required: checked,
    });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === 'yesText') {
      const tempNoText = question.yesNo?.noText;

      setQuestion({
        ...question,
        yesNo: {
          yesText: value,
          noText: tempNoText,
        },
      });

      return;
    }

    if (name === 'noText') {
      const tempYesText = question.yesNo?.yesText;

      setQuestion({
        ...question,
        yesNo: {
          yesText: tempYesText,
          noText: value,
        },
      });

      return;
    }

    setQuestion({
      ...question,
      [name]: value,
    });
  };

  const handleNewAnswerTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewAnswerText(value);
  };

  const addNewOption = () => {
    let optionList = question.options;
    if (!optionList) optionList = [];

    optionList.push({
      label: newAnswerText,
      value: newAnswerText,
    });

    setQuestion({
      ...question,
      options: optionList,
    });

    setNewAnswerText('');
  };

  const deleteOption = (index: number) => {
    let optionList = question.options;
    if (!optionList) optionList = [];

    if (index > -1) {
      optionList.splice(index, 1);

      setQuestion({
        ...question,
        options: optionList,
      });
    }
  };

  const validateForm = (): boolean => {
    let isValid = true;
    setShowErrorAlert(false);
    setHasNameError(false);
    setHasYesTextError(false);
    setHasNoTextError(false);
    setHasAnswerError(false);
    setHasScaleError(false);
    setHasStatementError(false);

    if (question.name.length === 0) {
      setHasNameError(true);
      isValid = false;
    }

    if (question.title.length === 0 || question.title === '<p><br></p>') {
      isValid = false;
    }

    if (question.type === 'yes/no') {
      if (question?.yesNo?.yesText?.length === 0) {
        setHasYesTextError(true);
        isValid = false;
      }

      if (question?.yesNo?.noText?.length === 0) {
        setHasNoTextError(true);
        isValid = false;
      }
    }

    if (question.type === 'select') {
      if (question.options && question.options?.length < 2) {
        setHasAnswerError(true);
        isValid = false;
      }
    }

    if (question.type === 'likert') {
      if (question.scale && question.scale.length < 2) {
        setHasScaleError(true);
        isValid = false;
      }
      if (question.statements && question.statements.length < 2) {
        setHasStatementError(true);
        isValid = false;
      }
    }

    setShowErrorAlert(!isValid);
    return isValid;
  };

  const saveClick = () => {
    if (!validateForm()) return;

    if (question.type === 'yes/no') {
      question.yesNo = {
        noText: question.yesNo?.noText,
        yesText: question.yesNo?.yesText
      }
    }

    onSave(question);
  };

  const onHandleMultiSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    setQuestion({
      ...question,
      isMultiSelect: checked,
    })
  }

  const includeOtherOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    setQuestion({
      ...question,
      hasOtherOption: checked,
    });
  }


  return (
    <>
      <Stack mt={1}>
        {errorText.length > 0 && <Alert severity="error">{errorText}</Alert>}

        {showErrorAlert && <Alert severity="error">Please update all required fields</Alert>}

        <FormControlLabel
          control={<Checkbox onChange={onRequiredChange} checked={question.required || undefined} size='small' />}
          label="Question is Required"
        />

        { question.type === 'select' &&
          <>
            <FormControlLabel
              control={<Checkbox onChange={includeOtherOption} checked={question.hasOtherOption || undefined} size='small' />}
              label='Add "Other" Option'
            />

            <FormControlLabel
              control={<Checkbox onChange={onHandleMultiSelect} checked={question.isMultiSelect || false} size='small' />}
              label="Multi Select"
            />
          </>
        }

        <Box mb={1} mt={2} textAlign='left'>
          <TextField
            size='small'
            fullWidth
            name="name"
            onChange={handleTextChange}
            variant="outlined"
            color="warning"
            label="Name/Export Label"
            value={question.name}
            error={hasNameError}
            FormHelperTextProps={{ classes: { root: classes.helperText } }}
          />
          <Typography variant="caption" color="primary.main" sx={{ textAlign: 'left' }}>
            Required (Do not add special characters to the Name/Export Label)
          </Typography>
        </Box>

        <Box my={1} textAlign='left'>
          <ReactQuill
            id="title"
            className={classes.editor}
            value={question.title}
            onChange={(value: string) => setQuestion({ ...question, title: value })}
          />
          <Typography variant="caption" color="primary.main" sx={{ textAlign: 'left' }}>
            Required
          </Typography>
        </Box>

        {question.type === 'yes/no' && (
          <Grid container spacing={2} alignItems="center" my={1}>
            <Grid item xs={6}>
              <Box textAlign='left'>
                <TextField
                  size='small'
                  fullWidth
                  name="yesText"
                  onChange={handleTextChange}
                  variant="outlined"
                  color="warning"
                  label="Yes Text"
                  value={question.yesNo?.yesText}
                  error={hasYesTextError}
                />
                <Typography variant="caption" color="primary.main">
                  Required
                </Typography>
              </Box>
            </Grid>
      
            <Grid item xs={6}>
              <Box textAlign='left'>
                <TextField
                  size='small'
                  fullWidth
                  name="noText"
                  onChange={handleTextChange}
                  variant="outlined"
                  color="warning"
                  label="No Text"
                  value={question.yesNo?.noText}
                  error={hasNoTextError}
                />
                <Typography variant="caption" color="primary.main">
                  Required
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}

        { question.type === 'likert' &&
          <>
            <LikertScale question={question} setQuestion={setQuestion} />
            {hasScaleError && <Alert severity="error" sx={{ mb: 2 }}>You must have at least 2 scales</Alert>}
            {hasStatementError && <Alert severity="error" sx={{ mb: 2 }}>You must have at least 2 statements</Alert>}
          </>
        }

        {question.type === 'select' && (
          <>
            <Typography variant="h6" textAlign='left' mb={1}>Add Answers</Typography>
            <Grid container mb={2}>
              <Grid item xs={10}>
                <TextField
                  size='small'
                  fullWidth
                  onChange={handleNewAnswerTextChange}
                  variant="outlined"
                  color="warning"
                  label="New Answer"
                  value={newAnswerText}
                  // error={hasTitleError}
                  FormHelperTextProps={{ classes: { root: classes.helperText } }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button onClick={addNewOption} variant='contained'>Add</Button>
              </Grid>
            </Grid>

            {hasAnswerError && <Alert severity="error" sx={{ mb: 2 }}>You must have at least 2 answer options</Alert>}
            {question.options && question.options.length > 0 && (
              <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                  <TableBody>
                    {question.options &&
                      question.options.map((option, i) => (
                        <TableRow key={`${option.label}-${i}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            {option.label}
                          </TableCell>
                          <TableCell align="right">
                            <FaTimes
                              onClick={() => deleteOption(i)}
                              title="Delete Question"
                              className={classes.delete}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}

        <Button variant="contained" onClick={saveClick}>
          Save
        </Button>
      </Stack>
    </>
  );
};

export default AddEditRegistrationQuestion;
