import { gql } from '@apollo/client';

export const updateEmailTemplate_Name = 'UpdateEmailTemplate';
export const updateEmailTemplate_Gql = gql`
  mutation UpdateEmailTemplate($template: UpdateEmailTemplateInput!) {
    updateEmailTemplate(template: $template) {
      id
    }
  }
`;
