import React, { useState, useEffect } from 'react';
import { Container, Link } from '@mui/material';

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PageHeader from 'components/global/PageHeader';
import ProgressIndicator from 'components/global/ProgressIndicator';
import FileExportsGlobal from 'components/admin/CohortDetails/components/FileExportsGlobal';
import PermissionDeniedSplash from 'pages/PermissionDeniedSplash';
import { useAuth } from 'hooks/useAuth';

const GlobalExports = () => {
  const [loading, setLoading] = useState(false);
  const [excelExporting, setExcelExporting] = useState(false);
  
  const auth = useAuth();
  const isAuthorized = () => {
    return auth.user?.isAlbatross || auth.user?.isAdmin || auth.user?.isEchoStaff || auth.user?.isDevelopmentStaff || auth.user?.isImplementationStaff || auth.user?.isEvaluationStaff;
  };
  
  if (!isAuthorized()) {
    return <PermissionDeniedSplash />;
  }

  return (
    (!isAuthorized()) ? (
      <PermissionDeniedSplash />
    ) : (
    <>
      {isAuthorized() && (
      <>
      <ProgressIndicator isOpen={excelExporting} title="Loading..." />
      <Container maxWidth="xl" sx={{ p: 10, mb: 2 }}>
        <PageHeader title="Exports : All Programs" />

        <Grid container sx={{mt: 5}}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
              <Stack spacing={5}>
                <FileExportsGlobal
                  onExportStart={setExcelExporting}
                />
              </Stack>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Container>
      </>
      )}
    </>
  ));
};

export default GlobalExports;
