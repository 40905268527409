import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import TrashIcon from '@mui/icons-material/Delete';
import YesNoDialog from '../YesNoDialog';

interface DeleteIconProps {
  iconTooltip: string;
  dialogTitle: string;
  confirmText: string;
  dialogText: string;
  reference?: any;
  onClose: (action: boolean, reference?: any) => void;
}

const DeleteIcon = (props: DeleteIconProps) => {
  const { iconTooltip, dialogTitle, confirmText, dialogText, reference, onClose } = props;

  const [openDialog, setDialogOpen] = useState(false);

  const handleDialogClose = (value: boolean) => {
    setDialogOpen(false);
    onClose(value, reference);
  };

  return (
    <>
      <Tooltip title={iconTooltip}>
        <TrashIcon sx={{ cursor: 'pointer' }} onClick={() => setDialogOpen(true)} />
      </Tooltip>

      <YesNoDialog
        dialogTitle={dialogTitle}
        confirmText={confirmText}
        dialogText={dialogText}
        open={openDialog}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default DeleteIcon;
