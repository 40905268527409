import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox'

import EditIcon from '@mui/icons-material/Edit';

import DeleteIcon from '../../global/Icons/DeleteIcon';
import EditHubMemberCohort from './components/EditHubMemberCohort';
import PageHeader from '../../global/PageHeader';
import ProgressIndicator from '../../global/ProgressIndicator';

import ViewAccountLink from '../Account/ViewAccountLink';

import SendHubTeamNextStepsEmail from './components/SendHubTeamNextStepsEmail';
import AddHubMember from './components/AddHubMember';

import { getMemberRoleList, combineSessionRoles } from './HubTeam.helpers';
import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';

import { getSessionsForCohortQuery_Gql } from '../../../gql/session/getSessionsForCohortQuery';
import { getMembersByCohort_Gql, getMembersByCohort_Name } from '../../../gql/hubTeam/getMembersByCohort';
import { updateHubMemberCohortAndSessions_Gql } from '../../../gql/hubTeam/updateHubMemberCohortAndSessions';
import { deleteHubMemberCohortById_Gql } from '../../../gql/hubTeam/deleteHubMemberCohortById';

import CohortParams from '../../../models/params/CohortParams';

import {
  GetMembersByCohort,
  GetMembersByCohortVariables,
  GetMembersByCohort_getMembersByCohort,
  UpdateHubMemberCohortAndSessionsVariables
} from '../../../models/GeneratedModels';

const HubTeamList = () => {
  const { cohortId } = useParams<CohortParams>();

  const { loading: sessionsLoading, data: sessionsData } = useQuery(getSessionsForCohortQuery_Gql, {
    variables: {
      cohortId,
    },
  });

  const { loading: teamLoading, data: teamData } = useQuery<GetMembersByCohort, GetMembersByCohortVariables>(
    getMembersByCohort_Gql,
    {
      variables: {
        cohortId,
      },
    }
  );

  const [updateHubTeamMember] = useMutation<void, UpdateHubMemberCohortAndSessionsVariables>(
    updateHubMemberCohortAndSessions_Gql,
    {
      refetchQueries: [getMembersByCohort_Name],
    }
  );

  const [removeTeamMember, { loading: removingMember }] = useMutation(deleteHubMemberCohortById_Gql, {
    refetchQueries: [getMembersByCohort_Name],
  });

  const deleteHubTeamMemberFromCohort = async (isConfirmed: boolean, hubMemberCohortId: string) => {
    if (isConfirmed) {
      await removeTeamMember({
        variables: {
          hubMemberCohortId,
        },
      });
    }
  };

  const hubTeamMembers: GetMembersByCohort_getMembersByCohort[] = teamData?.getMembersByCohort ?? [];

  const handleHide = (e: React.ChangeEvent<HTMLInputElement>, hubTeamMember: GetMembersByCohort_getMembersByCohort) => {
    const sessions = combineSessionRoles(
      hubTeamMember.id,
      hubTeamMember.sessionsAsFacilitator,
      hubTeamMember.sessionsAsPresenter,
      hubTeamMember.sessionsAsPanelist
    );

    updateHubTeamMember({ variables: {
      hubMemberCohort: {
        id: hubTeamMember.id,
        facilitateAll: hubTeamMember.facilitateAll,
        presentAll: hubTeamMember.presentAll,
        panelistAll: hubTeamMember.panelistAll,
        credentials: hubTeamMember.credentials,
        hubMemberCohortSessions: sessions,
        hide: e.target.checked
      }}}
    )
  }

  return (
    <>
      <ProgressIndicator isOpen={sessionsLoading || teamLoading} title="Loading..." />
      <ProgressIndicator isOpen={removingMember} title="Saving..." />

      <PageHeader title="Hub Team Management" />

      <Stack sx={{ mb: 2 }} spacing={2} direction="row">
        {!sessionsLoading && <AddHubMember cohortId={cohortId} />}

        {/* <Button variant="outlined" startIcon={<DownloadIcon />} size="small">
          Export Faculty Profiles
        </Button> */}
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Hide</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Role(s)</TableCell>
              <TableCell>Next Steps Sent</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hubTeamMembers.map((hubTeamMember) => (
              <TableRow key={hubTeamMember.id}>
                <TableCell>
                  <Tooltip title={`Edit ${hubTeamMember?.user?.firstName}`}>
                    <EditHubMemberCohort
                      trigger={<EditIcon sx={{ color: 'primary.main', cursor: 'pointer' }} />}
                      drawerTitle={`Edit ${hubTeamMember?.user?.firstName} ${hubTeamMember?.user?.lastName}`}
                      sessions={sessionsData?.getSessionsByCohort}
                      cohortMember={hubTeamMember}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={hubTeamMember.hide || false}
                    onChange={(e) => handleHide(e, hubTeamMember)}
                  />
                </TableCell>

                <TableCell>
                  <ViewAccountLink
                    userId={hubTeamMember.userId}
                    name={`${hubTeamMember?.user?.firstName} ${hubTeamMember?.user?.lastName}`}
                    email={hubTeamMember?.user?.email}
                  />
                </TableCell>
                <TableCell>{getMemberRoleList(hubTeamMember)}</TableCell>
                <TableCell>
                  {hubTeamMember.nextStepsSentDate
                    ? convertDateToTimeZoneFromUtc(hubTeamMember.nextStepsSentDate, true)
                    : ''}
                </TableCell>
                <TableCell align="right">
                  {getMemberRoleList(hubTeamMember).length > 0 && !hubTeamMember.nextStepsSentDate && (
                    <SendHubTeamNextStepsEmail hubMemberCohortId={hubTeamMember.id} />
                  )}

                  <DeleteIcon
                    iconTooltip={`Remove ${hubTeamMember?.user?.firstName} from hub team`}
                    dialogTitle="Remove Hub Team Member"
                    confirmText="Remove Team Member"
                    dialogText={`This will remove ${hubTeamMember?.user?.firstName} ${hubTeamMember?.user?.lastName} from the hub team.`}
                    onClose={deleteHubTeamMemberFromCohort}
                    reference={hubTeamMember.id}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default HubTeamList;
