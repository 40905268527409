import { gql } from '@apollo/client';

export const updateProgramMutation_Name = 'UpdateProgram';
export const updateProgramMutation_Gql = gql`
  mutation UpdateProgram($program: UpdateProgramInput!) {
    updateProgram(program: $program) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
