import { Box, Typography, Link } from '@mui/material';
import { LabelAndValueProps } from '../../cohort/Cohort.interfaces';

const LabelAndValueLink = ({ label, link }: LabelAndValueProps) => {
  return (
    <>
      {link && (
        <Box sx={{ mb: 1 }}>
          <Typography variant="body1" color="textPrimary">
            {label} <Link href={link}>{link}</Link>
          </Typography>
        </Box>
      )}
    </>
  );
};

export default LabelAndValueLink;
