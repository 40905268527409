import { useState } from 'react';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogActions, DialogContent, TextField, Button, Stack, Box, Alert } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import GenericEmailTemplate from '../../../../models/GenericEmailTemplate.model';
import PageHeader from '../../../global/PageHeader';
import TextEditor from 'components/global/TextEditor/TextEditor';

// import 'react-quill/dist/quill.snow.css';

// const ReactQuill = require('react-quill');

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    editIcon: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    editor: {
      height: '300px',
    },
  })
);

interface EditTemplateDialogProps {
  template: GenericEmailTemplate;
  onSaveClick: (template: GenericEmailTemplate) => void;
}

const EditTemplateDialog = ({ template, onSaveClick }: EditTemplateDialogProps) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [updatedTemplate, setUpdatedTemplate] = useState<GenericEmailTemplate>(template);

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setUpdatedTemplate({
      ...updatedTemplate,
      [name]: value,
    });
  };

  const onTextEditorChange = (name: string, value: string) => {
    setUpdatedTemplate({ ...updatedTemplate, [name]: value });
  };

  const isValid = (): boolean => {
    setErrMsg('');
    let isValidForm = true;

    if (updatedTemplate.subject.length === 0) {
      setErrMsg('Subject is required');
      isValidForm = false;
    }

    if (updatedTemplate.template === '<p><br></p>') {
      setErrMsg('Template text is required');
      isValidForm = false;
    }

    return isValidForm;
  };

  const onClick = () => {
    if (isValid()) {
      const template: GenericEmailTemplate = {
        id: updatedTemplate.id,
        name: updatedTemplate.name,
        template: updatedTemplate.template,
        subject: updatedTemplate.subject,
      };

      setOpenDialog(false);

      onSaveClick(template);
    }
  };

  return (
    <>
      <EditIcon className={classes.editIcon} onClick={() => setOpenDialog(true)} />
      <Dialog maxWidth="xl" open={openDialog}>
        <DialogContent sx={{ height: 600, width: 800 }}>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <PageHeader title={template.name} />
          </Box>
          <Stack spacing={2}>
            {errMsg.length > 0 && <Alert severity="error">{errMsg}</Alert>}

            <TextField
              onChange={onTextChange}
              name="subject"
              label="Subject"
              color="warning"
              value={updatedTemplate.subject}
            />

            <TextEditor
              name="template"
              onChange={onTextEditorChange}
              value={updatedTemplate.template}
              style={{ height: '300px' }}
            />

            {/* <ReactQuill
              id="scheduleDescription"
              className={classes.editor}
              value={updatedTemplate.template}
              onChange={(value: string) => setUpdatedTemplate({ ...updatedTemplate, template: value })}
            /> */}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClick}>
            Save
          </Button>
          <Button variant="outlined" color="primary" onClick={() => setOpenDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditTemplateDialog;
