import { useAuth } from '../hooks/useAuth';

import MyLearnerAccount from 'components/account/MyLearnerAccount/MyLearnerAccount';
import MyStaffAccount from 'components/account/MyStaffAccount/MyStaffAccount';
import PageHeader from '../components/global/PageHeader';
import { Container } from '@mui/material';

const MyAccount = () => {
  const auth = useAuth();

  const isStaffAccount = () => {
    if (auth.user) {
      return auth.user.isAlbatross || auth.user.isAdmin || auth.user.isEchoStaff || auth.user?.isDevelopmentStaff || auth.user?.isImplementationStaff || auth.user?.isEvaluationStaff;
    }

    return false;
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ p: 10, mb: 12, textAlign: 'center' }}>
        <PageHeader title="Edit Your Account"></PageHeader>

        {isStaffAccount() ? <MyStaffAccount /> : <MyLearnerAccount />}
      </Container>
    </>
  );
};

export default MyAccount;
