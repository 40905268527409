import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { FormControl, FormControlLabel, Checkbox, Card, CardContent } from '@mui/material';
import { Button, TextField, MenuItem, Select, InputLabel } from '@mui/material';
import { Grid, Alert, SelectChangeEvent } from '@mui/material';

import ProgressIndicator from '../../global/ProgressIndicator';
import SuccessSnackbar from '../../global/SuccessSnackbar';
import { GetProgramById_getProgramById } from '../../../models/GeneratedModels';
import { updateProgramMutation_Gql } from '../../../gql/program/updateProgramMutation';
import { getPartnersQuery_Gql } from '../../../gql/partner/getPartnersQuery';
import { UpdateProgramInput } from '../../../models/GeneratedModels';
import { GetAllPartners, GetAllPartners_getPartners } from '../../../models/GeneratedModels';
import { getAllProgramsQuery_Name } from '../../../gql/program/getAllProgramsQuery';
import { getProgramById_Name } from '../../../gql/program/getProgramByIdQuery';

interface EditProgramProps {
  program: GetProgramById_getProgramById;
}

const EditProgram = ({ program }: EditProgramProps) => {
  const [programDetail, setProgramDetail] = useState<GetProgramById_getProgramById>(program);
  const [partners, setPartners] = useState<GetAllPartners_getPartners[] | null>([]);
  const [hasNameError, setHasNameError] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setProgramDetail(program);
  }, [program]);

  const { loading, data } = useQuery<GetAllPartners>(getPartnersQuery_Gql);

  useEffect(() => {
    if (loading || !data) return;

    setPartners(data.getPartners);
  }, [data, loading]);

  const [updateProgram, { loading: saving, error }] = useMutation(updateProgramMutation_Gql, {
    refetchQueries: [getAllProgramsQuery_Name, getProgramById_Name],
  });

  useEffect(() => {
    setShowProgress(loading);
  }, [loading, saving]);

  const onFormElementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'isArchived') {
      setProgramDetail({
        ...programDetail,
        isArchived: e.target.checked,
      });

      return;
    }

    setProgramDetail({
      ...programDetail,
      [name]: value,
    });
  };

  const onSelectElementChange = (e: SelectChangeEvent) => {
    const name = e.target.name;
    const value = e.target.value;

    setProgramDetail({
      ...programDetail,
      [name]: value,
    });
  };

  const isValid = (): boolean => {
    setHasNameError(false);
    let valid = true;

    if (programDetail.name.length === 0) {
      setHasNameError(true);
      valid = false;
    }

    return valid;
  };

  const saveProgram = async () => {
    if (!isValid()) return;

    try {
      const programToUpdate: UpdateProgramInput = {
        id: programDetail.id,
        name: programDetail.name,
        partnerId: programDetail.partnerId,
        isArchived: programDetail.isArchived,
      };

      await updateProgram({
        variables: {
          program: programToUpdate,
        },
      });

      setShowSuccess(true);
    } catch {}
  };

  return (
    <>
      <ProgressIndicator isOpen={showProgress} title="Loading" />

      {error && <Alert severity="error">{error}</Alert>}

      <Card variant="outlined">
        <CardContent>
          <Grid container>
            <Grid item xs={4}>
              <FormControl sx={{ width: '90%' }}>
                <TextField
                  name="name"
                  label="Program Name"
                  value={programDetail.name}
                  variant="outlined"
                  onChange={onFormElementChange}
                  color="warning"
                  helperText="Required"
                  error={hasNameError}
              />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ width: '90%' }}>
                <InputLabel id="partner-label" color="warning">
                  Implementation Partner
                </InputLabel>
                <Select
                  sx={{ textAlign: 'left' }}
                  value={programDetail.partnerId ?? ''}
                  name="partnerId"
                  onChange={onSelectElementChange}
                  labelId="partner-label"
                  label="Implementation Partner"
                  color="warning"
                >
                  <MenuItem value=""></MenuItem>
                  {partners &&
                    partners.map((partner: GetAllPartners_getPartners) => (
                      <MenuItem key={partner.id} value={partner.id}>
                        {partner.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={programDetail.isArchived}
                  onChange={onFormElementChange}
                  color="primary"
                  name="isArchived"
                />
              }
              label="Archived"
            />
            </Grid>  
            <Grid item xs={2}>
              <Button onClick={saveProgram} color="primary" variant="contained">
                Save
              </Button>
            </Grid>         
          </Grid>
        </CardContent>
      </Card>

      <SuccessSnackbar isOpen={showSuccess} text="Program has been updated" />
    </>
  );
};

export default EditProgram;
