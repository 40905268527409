import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { Button, Stack } from '@mui/material';

import ProgressIndicator from '../../global/ProgressIndicator';
import DrawerWithTrigger from '../../global/DrawerWithTrigger';
import AddEditRegistrationQuestion from './AddEditRegistrationQuestion';

import { addRegistrationQuestion_Gql } from '../../../gql/cohort/addRegistrationQuestion';
import { GetCohortByIdQuery_Name } from '../../../gql/cohort/getCohortByIdQuery';
import { RegistrationQuestionInput, AddRegistrationQuestionVariables } from '../../../models/GeneratedModels';
import { AddRegistrationQuestionProps } from './Cohort.interfaces';

const defaultTextQuestion = {
  id: '',
  title: '',
  type: 'text',
  name: '',
  required: false,
  yesNo: null,
  options: null,
  scale: null,
  statements: null
};

const defaultYesNoQuestion = {
  id: '',
  title: '',
  type: 'yes/no',
  name: '',
  required: false,
  yesNo: {
    yesText: 'Yes',
    noText: 'No',
  },
  options: null,
  scale: null,
  statements: null
};

const defaultSelectQuestion = {
  id: '',
  title: '',
  type: 'select',
  name: '',
  required: false,
  yesNo: null,
  options: [],
  scale: null,
  statements: null
};

const defaultLikertScaleQuestion = {
  id: '',
  title: '',
  type: 'likert',
  name: '',
  required: false,
  yesNo: null,
  options: null,
  scale: [],
  statements: []
}

const AddRegistrationQuestion = ({ cohortId }: AddRegistrationQuestionProps) => {
  const [errorText, setErrorText] = useState('');
  const [drawer, handleDrawer] = useState('');

  const [addRegistrationQuestion, { loading }] = useMutation<void, AddRegistrationQuestionVariables>(
    addRegistrationQuestion_Gql,
    {
      refetchQueries: [GetCohortByIdQuery_Name],
    }
  );

  const onSave = async (question: RegistrationQuestionInput) => {
    try {
      setErrorText('');

      await addRegistrationQuestion({
        variables: {
          cohortId,
          registrationQuestion: { ...question, id: uuidv4() },
        },
      });

      handleDrawer('');
    } catch (error: any) {
      setErrorText(error.graphQLErrors[0].message);
    }
  };

  return (
    <>
      <Stack direction="row" spacing={3}>
        <DrawerWithTrigger
          trigger={
            <Button variant="outlined" onClick={() => handleDrawer('text')} size='small'>
              + Text Question
            </Button>
          }
          width={500}
          title="Add Registration Question"
          showSave={false}
          showCancel={true}
          isOpen={drawer === 'text'}
        >
          <ProgressIndicator isOpen={loading} title="Saving..." />

          <AddEditRegistrationQuestion
            registrationQuestion={defaultTextQuestion}
            onSave={onSave}
            errorText={errorText}
          />
        </DrawerWithTrigger>

        <DrawerWithTrigger
          trigger={
            <Button variant="outlined" onClick={() => handleDrawer('yesNo')} size='small'>
              + Yes/No Question
            </Button>
          }
          width={500}
          title="Add Registration Question"
          showSave={false}
          showCancel={true}
          isOpen={drawer === 'yesNo'}
        >
          <ProgressIndicator isOpen={loading} title="Saving..." />

          <AddEditRegistrationQuestion
            registrationQuestion={defaultYesNoQuestion}
            onSave={onSave}
            errorText={errorText}
          />
        </DrawerWithTrigger>

        <DrawerWithTrigger
          trigger={
            <Button variant="outlined" onClick={() => handleDrawer('select')} size='small'>
              + Dropdown Question
            </Button>
          }
          width={500}
          title="Add Registration Question"
          showSave={false}
          showCancel={true}
          isOpen={drawer === 'select'}
        >
          <ProgressIndicator isOpen={loading} title="Saving..." />

          <AddEditRegistrationQuestion
            registrationQuestion={defaultSelectQuestion}
            onSave={onSave}
            errorText={errorText}
          />
        </DrawerWithTrigger>

        <DrawerWithTrigger
          trigger={
            <Button variant="outlined" onClick={() => handleDrawer('likert')} size='small'>
              + Likert Scale Question
            </Button>
          }
          width={500}
          title="Add Registration Question"
          showSave={false}
          showCancel={true}
          isOpen={drawer === 'likert'}
        >
          <ProgressIndicator isOpen={loading} title="Saving..." />

          <AddEditRegistrationQuestion
            registrationQuestion={defaultLikertScaleQuestion}
            onSave={onSave}
            errorText={errorText}
          />
        </DrawerWithTrigger>
      </Stack>
    </>
  );
};

export default AddRegistrationQuestion;
