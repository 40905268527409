import 'react-quill/dist/quill.snow.css';
const ReactQuill = require('react-quill');

interface SafeDisplayHtmlProps {
  htmlText: string | null;
}

const SafeDisplayHtml = ({ htmlText }: SafeDisplayHtmlProps) => {
  return <ReactQuill value={htmlText} readonly={true} theme={'bubble'} />;
};

export default SafeDisplayHtml;
