import { gql } from '@apollo/client';

export const sendCohortEmail_Name = 'SendCohortEmail';
export const sendCohortEmail_Gql = gql`
  mutation SendCohortEmail($cohortEmail: SendCohortEmailInput!) {
    sendCohortEmail(cohortEmail: $cohortEmail) {
      success
      numberSent
      error
    }
  }
`;
