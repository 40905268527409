
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Breadcrumbs, { BreadcrumbItem } from 'components/global/Breadcrumbs';
import CohortList from './CohortList';
import EditProgram from './EditProgram';
import LatestResources from './LatestResources';
import PageHeader from 'components/global/PageHeader';
import ProgressIndicator from 'components/global/ProgressIndicator';
import QuickLinks from './QuickLinks';

import { adminLinks } from 'utils/links';
import { getProgramById_Gql } from 'gql/program/getProgramByIdQuery';
import ProgramParams from 'models/ProgramParams.model';
import {
  GetProgramById,
  GetProgramByIdVariables,
  GetProgramById_getProgramById,
} from 'models/GeneratedModels';
import { Container } from '@mui/material';

const ProgramDashboard = () => {
  let { id } = useParams<ProgramParams>();

  const { loading, data, error } = useQuery<
    GetProgramById,
    GetProgramByIdVariables
  >(getProgramById_Gql, {
    variables: {
      id: id,
    },
    fetchPolicy: 'no-cache',
  });

  const program: GetProgramById_getProgramById | null | undefined =
    data?.getProgramById;
  const breadcrumbItems: BreadcrumbItem[] = [
    {
      to: adminLinks.allPrograms.href,
      text: adminLinks.allPrograms.text,
      isActive: true,
    },
    {
      to: '',
      text: program?.name ?? '',
      isActive: false,
    },
  ];

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Loading..." />
      <Container maxWidth="xl" sx={{ pt: 10, mb: 12 }}>
        {program && (
          <>
            <Breadcrumbs breadcrumbs={breadcrumbItems} />
            {error && (
              <Alert severity="error">{error.graphQLErrors[0].message}</Alert>
            )}
            {program && <PageHeader title={program.name} />}

            <Box sx={{ mt: 4 }}>
              {program && <EditProgram program={program} />}
            </Box>
            <Grid sx={{ mt: 4, textAlign: 'left' }} container>
              <Grid item xs={9}>
                <CohortList
                  programId={id}
                  programName={program?.name ?? ''}
                  cohorts={program.cohorts}
                />
              </Grid>
              <Grid item xs={3} sx={{ textAlign: 'center' }}>
                <QuickLinks programId={id} />

                <LatestResources programId={id} resources={program.resources} />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
};

export default ProgramDashboard;
