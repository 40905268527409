import { ApolloError } from '@apollo/client';
import Alert from '@mui/material/Alert';

interface GqlErrorDisplayProps {
  error: ApolloError | undefined;
}

const GqlErrorDisplay = ({ error }: GqlErrorDisplayProps) => {
  if (!error) return <></>;

  return <Alert severity="error">{error?.graphQLErrors[0]?.message ?? ''}</Alert>;
};

export default GqlErrorDisplay;
