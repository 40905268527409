import { ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import BorderAllIcon from '@mui/icons-material/BorderAll';
import CameraFrontIcon from '@mui/icons-material/CameraFront';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import ViewListIcon from '@mui/icons-material/ViewList';

import { useAuth } from '../../hooks/useAuth';

interface NavDrawerProps {
  isDrawerOpen: boolean;
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const NavDrawer = ({ isDrawerOpen, toggleDrawer }: NavDrawerProps) => {
  const auth = useAuth();
  const isAdminAuthorized = () => {
    return auth.user?.isAlbatross || auth.user?.isAdmin || auth.user?.isEchoStaff || auth.user?.isDevelopmentStaff || auth.user?.isImplementationStaff || auth.user?.isEvaluationStaff;
  };
  const isPartnerRole = () => {
    return auth.user?.isPartner;
  };

  return (
    <>
      <Drawer
        sx={{ width: '300px', '& .MuiDrawer-paper': { 
          // Target the inner paper element
          width: '300px !important' },
         }}
        onClick={toggleDrawer(false)}
        variant="temporary"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
      >
        <NavDrawerHeader title="HOME" />
        <Stack spacing={1}>
          <NavDrawerItem path="/" menuText="Home" icon={<HomeIcon />} />
          <NavDrawerItem path="/programs" menuText="ECHO Programs" icon={<ListAltIcon />} />

          {auth.user?.isAuthenticated && <NavDrawerItem path="/my-echos" menuText="My ECHOs" icon={<PersonIcon />} />}
        </Stack>

        {(isAdminAuthorized() || isPartnerRole()) && (
          <>
            <Divider sx={{ mt: 2 }} />

            <NavDrawerHeader title="PROGRAM MANAGEMENT" />
            <Stack spacing={1}>
              <NavDrawerItem path="/admin/current-projects" menuText="All Current Projects" icon={<ViewListIcon />} />
              <NavDrawerItem
                path="/admin/my-current-projects"
                menuText="My Current Projects"
                icon={<CameraFrontIcon />}
              />
              <NavDrawerItem path="/admin/programs" menuText="All Programs" icon={<BorderAllIcon />} />
            </Stack>

            {isAdminAuthorized() && (
              <>
              <Divider sx={{ mt: 2 }} />

              <NavDrawerHeader title="ADMIN" />
              <Stack spacing={1}>
                <NavDrawerItem path="/admin/accounts" menuText="Accounts" icon={<PeopleIcon />} />
                <NavDrawerItem
                  path="/admin/partners"
                  menuText="Implementation Partners"
                  icon={<ConnectWithoutContactIcon />}
                />
                {/* <NavDrawerItem path="/NotImplemented" menuText="Manage Site Content" icon={<SourceIcon />} /> */}
                <NavDrawerItem path="/admin/emailTemplates" menuText="Manage Email Templates" icon={<EmailIcon />} />
              </Stack>

              <Divider sx={{ mt: 2 }} />
              <NavDrawerHeader title="REPORTS" />
              <Stack spacing={1}>
                <NavDrawerItem path="/admin/exports" menuText="Global Exports" icon={<PeopleIcon />} />
              </Stack>
              </>
            )}
          </>
        )}
      </Drawer>
    </>
  );
};

interface NavDrawerHeaderProps {
  title: string;
}

const NavDrawerHeader = ({ title }: NavDrawerHeaderProps) => {
  return <Box sx={{ fontWeight: 'bold', color: 'primary.main', ml: 1, mt: 2, mb: 1 }}>{title}</Box>;
};

interface NavDrawerItemProps {
  path: string;
  icon: ReactNode;
  menuText: string;
}

const NavDrawerItem = ({ path, icon, menuText }: NavDrawerItemProps) => {
  const location = useLocation();
  const isActiveRoute = path.toLowerCase() === location.pathname.toLowerCase();

  return (
    <NavLink to={path} style={{ textDecoration: 'none' }}>
      <MenuItem selected={isActiveRoute}>
        <ListItemIcon sx={{ color: 'warning.main' }}>{icon}</ListItemIcon>
        <ListItemText sx={{ color: 'warning.main' }} primary={menuText} />
      </MenuItem>
    </NavLink>
  );
};

export default NavDrawer;
