import React, { useState, ChangeEvent } from 'react';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import PasswordIcon from '@mui/icons-material/Password';

import ProgressIndicator from '../../global/ProgressIndicator';
import PasswordRequirements from '../../account/Password/PasswordRequirements';
import { validatePassword } from '../../account/Password/Password.helpers';

import { UpdatePassword_Gql } from '../../../gql/user/updatePassword';
import { UpdatePassword, UpdatePasswordVariables } from '../../../models/GeneratedModels';

interface PasswordFields {
  password: string;
  confirmPassword: string;
}

interface ChangePasswordProps {
  userId: string;
  firstName: string;
  lastName: string;
  labelText?: string;
}

const ChangePassword = ({ userId, firstName, lastName, labelText = '' }: ChangePasswordProps) => {
  const [open, setOpen] = React.useState(false);
  const [errorText, setErrorText] = useState('');

  const [password, setPassword] = useState<PasswordFields>({
    password: '',
    confirmPassword: '',
  });

  const [savePassword, { loading }] = useMutation<UpdatePassword, UpdatePasswordVariables>(UpdatePassword_Gql);

  const onFormElementChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setPassword({
      ...password,
      [name]: value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const savePasswordUpdate = async () => {
    setErrorText('');

    const passwordValidation = validatePassword(password.password, password.confirmPassword);
    if (passwordValidation.isValid) {
      try {
        await savePassword({
          variables: {
            userId,
            password: password.password,
          },
        });

        handleClose();
      } catch (error: any) {
        setErrorText(error.graphQLErrors[0].message);
      }
    } else {
      setErrorText(passwordValidation.message);
    }
  };

  const handleClose = () => {
    setPassword({
      password: '',
      confirmPassword: '',
    });

    setOpen(false);
  };

  return (
    <>
      <Tooltip title={`Change password for ${firstName}`}>
        <PasswordIcon color="primary" sx={{ cursor: 'pointer' }} onClick={handleClickOpen} />
      </Tooltip>{' '}
      {labelText}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{`Change Password for ${firstName} ${lastName}`}</DialogTitle>
        <DialogContent sx={{ width: 400 }}>
          <ProgressIndicator isOpen={loading} title="Saving..." />

          <TextField
            autoFocus
            margin="dense"
            name="password"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            color="warning"
            value={password.password}
            onChange={onFormElementChange}
          />
          <PasswordRequirements />
          <TextField
            sx={{ mt: 2 }}
            margin="dense"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            fullWidth
            variant="outlined"
            color="warning"
            value={password.confirmPassword}
            onChange={onFormElementChange}
          />

          {errorText.length > 0 && (
            <Alert sx={{ mt: 2 }} severity="error">
              {errorText}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={savePasswordUpdate} variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangePassword;
