import React, { useState, useMemo } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  Box,
  TablePagination,
  TableFooter,
  IconButton,
  useTheme,
  Paper,
  Tooltip,
  Button
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HelpIcon from '@mui/icons-material/Help';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import ViewAccountLink from '../../../Account/ViewAccountLink';

import ApproveIcon from './ApproveIcon';
import WaitlistIcon from './WaitlistIcon';
import DenyIcon from './DenyIcon';
import ActiveInactiveIcons from './ActiveInactiveIcons';
import ResendConfirmationIcon from './ResendConfirmationIcon';
import DeleteIcon from './DeleteIcon';
import Mailer from './Mailer';

import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';
import SortableTableColumn, {
  SortProperty,
  complexSort,
} from 'components/global/SortableTableColumn';

import { useAuth } from '../../../../../hooks/useAuth';
import { DEFAULT_PAGE_SIZE } from '../../../../../utils/constants';

import { GetRegistrantsForCohort_getRegistrantsForCohort } from '../../../../../models/GeneratedModels';

interface ExtendedGetRegistrantsForCohort_getRegistrantsForCohort extends GetRegistrantsForCohort_getRegistrantsForCohort {
  fullName?: string
  organization?: string
  credentials?: string
  isActiveText?: string
}

interface RegistrantListProps {
  registrants: ExtendedGetRegistrantsForCohort_getRegistrantsForCohort[] | null;
  showActive: boolean;
  listType: 'new' | 'registered' | 'waitlist' | 'denied';
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

const sortPropDefault: SortProperty = {
  prop: 'fullName',
  order: 'asc'
};

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>

      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>

      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

const RegistrantList = ({
  registrants,
  showActive,
  listType,
}: RegistrantListProps) => {
  const auth = useAuth();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [sortProp, setSortProp] = useState<SortProperty>(sortPropDefault);

  const loggedInUserId = auth?.user?.id ?? '';
  
  const sortedRegistrants = useMemo(() => {
    if (!registrants) return [];
    const data = registrants.map(reg => ({
      ...reg,
      fullName: reg.user ? `${reg.user.lastName}, ${reg.user.firstName}` : '',
      organization: reg.user.organization || '',
      credentials: reg.user.userCredentialsForDisplay || '',
      isActiveText: reg.isActive ? 'Y' : 'N' 
    }));
    return complexSort(data, sortProp);
  }, [registrants, sortProp]);

  const paginatedRegistrants = useMemo(() => {
    const startIndex = page * rowsPerPage;
    return sortedRegistrants.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedRegistrants, page, rowsPerPage]);

  const [selectedRegistrants, setSelectedRegistrants] = useState<ExtendedGetRegistrantsForCohort_getRegistrantsForCohort[]>([]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset page to 0 when rows per page change
  };

  const handleSort = (property: string) => {
    const isAsc = sortProp.prop === property && sortProp.order === 'asc';
    setSortProp({ prop: property, order: isAsc ? 'desc' : 'asc' });
  };

  const showRegistrationAnswers = (): boolean => listType === 'new';
  const showApproveIcon = (): boolean => listType !== 'registered';
  const showWaitlistIcon = (): boolean => listType !== 'waitlist';
  const showDenyIcon = (): boolean => listType !== 'denied';
  const showActiveIcons = (): boolean => listType === 'registered';
  const showResendConfirmationIcon = (): boolean => listType === 'registered';
  const showDeleteIcon = (): boolean => listType === 'denied';

  const handleChangeCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    registrant: ExtendedGetRegistrantsForCohort_getRegistrantsForCohort
  ) => {
    event.target.checked
      ? setSelectedRegistrants([...selectedRegistrants, registrant])
      : setSelectedRegistrants(selectedRegistrants.filter((r) => r.userId !== registrant.userId));
  };

  return (
    <Box textAlign="left">
      <Mailer
        renderTrigger={(onClick) => (
          <Button
            variant="outlined"
            sx={{ mb: 2 }}
            onClick={onClick}
            disabled={selectedRegistrants.length === 0}
          >
            Email Selected Learners
          </Button>
        )}
        popupTitle="Send Email"
        recipients={selectedRegistrants}
        senderId={loggedInUserId}
      />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {showRegistrationAnswers() && <TableCell></TableCell>}
              <TableCell>
                {!!paginatedRegistrants?.length && (
                  <Checkbox
                    checked={selectedRegistrants.length === paginatedRegistrants?.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedRegistrants(paginatedRegistrants);
                      } else {
                        setSelectedRegistrants([]);
                      }
                    }}
                  />
                )}
              </TableCell>
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={() => handleSort('fullName')}
                sortProperty="fullName"
                headerText="Name"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={() => handleSort('organization')}
                sortProperty="organization"
                headerText="Organization"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={() => handleSort('credentials')}
                sortProperty="credentials"
                headerText="Credentials"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={() => handleSort('createdAt')}
                sortProperty="createdAt"
                headerText="Registration Date"
                order={sortProp?.order}
              />
              {showActive &&
                <SortableTableColumn
                  currentSort={sortProp?.prop}
                  onSort={() => handleSort('isActiveText')}
                  sortProperty="isActiveText"
                  headerText="Active"
                  order={sortProp?.order}
                />
              }
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRegistrants &&
              paginatedRegistrants.map(
                (
                  registration: ExtendedGetRegistrantsForCohort_getRegistrantsForCohort
                ) => {
                  return (
                    <TableRow key={registration.id}>
                      {showRegistrationAnswers() && (
                        <TableCell>
                          {registration.registrationAnswers && (
                            <RegistrationAnswerDialog
                              registrationAnswers={
                                registration.registrationAnswers
                              }
                              firstName={registration.user.firstName ?? ''}
                            />
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        <Checkbox
                          checked={
                            !!selectedRegistrants.find(
                              (r) => r.userId === registration.userId
                            )
                          }
                          onChange={(e) =>
                            handleChangeCheckBox(e, registration)
                          }
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <ViewAccountLink
                          userId={registration.userId}
                          name={registration.fullName || ''}
                          email={registration.user.email}
                        />
                      </TableCell>
                      <TableCell>{registration.organization}</TableCell>
                      <TableCell>
                        {registration.credentials}
                      </TableCell>
                      <TableCell>
                        {convertDateToTimeZoneFromUtc(registration.createdAt)}
                      </TableCell>
                      {showActive && (
                        <TableCell>{registration.isActiveText}</TableCell>
                      )}
                      <TableCell>
                        {showApproveIcon() && (
                          <ApproveIcon
                            approveRegistrationInput={{
                              id: registration.id,
                              decisionUserId: loggedInUserId,
                            }}
                          />
                        )}

                        {showWaitlistIcon() && (
                          <WaitlistIcon
                            waitlistRegistrationInput={{
                              id: registration.id,
                              decisionUserId: loggedInUserId,
                            }}
                          />
                        )}

                        {showDenyIcon() && (
                          <DenyIcon
                            registration={registration}
                            denyRegistrationInput={{
                              id: registration.id,
                              decisionUserId: loggedInUserId,
                            }}
                          />
                        )}

                        {showDeleteIcon() && (
                          <DeleteIcon
                            id={registration.id}
                          />
                        )}

                        {showActiveIcons() && (
                          <ActiveInactiveIcons
                            registrationInput={{
                              id: registration.id,
                              decisionUserId: loggedInUserId,
                              isActive: registration.isActive,
                            }}
                          />
                        )}

                        {showResendConfirmationIcon() && (
                          <ResendConfirmationIcon id={registration.id} />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
          </TableBody>

          {paginatedRegistrants && paginatedRegistrants.length > 0 && (
            <TableFooter>
              <TablePagination
                count={sortedRegistrants.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                sx={{
                  '.MuiTablePagination-spacer': {
                    display:"none"
                 },
                 '.MuiTablePagination-toolbar':{
                  display:'flex', justifyContent:'center'
                 }
                }}
              />
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

interface RegistrationAnswerDialogProps {
  registrationAnswers: string;
  firstName: string;
}

const RegistrationAnswerDialog = ({
  registrationAnswers,
  firstName,
}: RegistrationAnswerDialogProps) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const answersAsJson = JSON.parse(registrationAnswers);

  return (
    <>
      <Tooltip title="View registration answers">
        <HelpIcon
          onClick={handleClickOpen}
          sx={{ cursor: 'pointer', color: 'primary.main' }}
        />
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Registration Answers for {firstName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Object.keys(answersAsJson).map((key, i) => (
              <div style={{ marginBottom: '15px' }}>
                <p key={i}>
                  <strong>{i+1}. {key}</strong>
                </p>
                <label>
                  Answer: {answersAsJson[key]}
                </label>
              </div>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RegistrantList;
