import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Box, Alert, Container } from '@mui/material';

import PageHeader from '../components/global/PageHeader';
import LearnerAccountDetail from '../components/account/LearnerAccountDetail/LearnerAccountDetail';
import ProgressIndicator from '../components/global/ProgressIndicator';

import {
  AddOrUpdateUserInput,
  AddUserVariables,
} from '../models/GeneratedModels';
import { addUserMutation_Gql } from '../gql/user/addUserMutation';
import { getDefaultUser } from '../components/account/LearnerAccountDetail/LearnerAccountDetails.helpers';

const CreateAccount = () => {
  const history = useHistory();

  const [addUser, { error, loading }] = useMutation<void, AddUserVariables>(
    addUserMutation_Gql
  );

  const addNewUser = async (user: AddOrUpdateUserInput) => {
    try {
      await addUser({
        variables: {
          user,
        },
      });

      if (!error) {
        history.push('/login');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ p: 14, mb: 12 }}>
        <PageHeader title="Create Your Account"></PageHeader>

        {error && (
          <Alert severity="error">{error.graphQLErrors[0].message}</Alert>
        )}

        <ProgressIndicator isOpen={loading} title="Saving..." />

        <Box sx={{ mt: 3 }}>
          <LearnerAccountDetail
            userAccount={getDefaultUser()}
            onSave={addNewUser}
            buttonText="Create Account"
            submissionError={error?.graphQLErrors[0].message ?? ''}
            mode="create"
          />
        </Box>
      </Container>
    </>
  );
};

export default CreateAccount;
