import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

interface StyledRouterLinkIconProps {
  to: string;
  icon: React.ReactNode;
}

const StyledRouterLinkIcon = ({ to, icon }: StyledRouterLinkIconProps) => {
  return (
    <Link component={RouterLink} to={to}>
      {icon}
    </Link>
  );
};

export default StyledRouterLinkIcon;
