import { gql } from '@apollo/client';

export const addProgramMutation_Name = 'AddProgram';
export const addProgramMutation_Gql = gql`
  mutation AddProgram($program: AddProgramInput!) {
    addProgram(program: $program) {
      id
      name
    }
  }
`;
