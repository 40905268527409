import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EmailIcon from '@mui/icons-material/Email';

import { programLinks } from '../../../utils/links';
import StyledRouterLinkButton from 'components/global/StyledRouterLinkButton';

interface QuickLinksProps {
  programId: string;
}

const QuickLinks = ({ programId }: QuickLinksProps) => {
  return (
    <>
      <Typography variant="h6">Quick Links</Typography>

      <Box sx={{ textAlign: 'left', marginLeft: 3, marginBottom: 2 }}>
        <StyledRouterLinkButton
          to={`${programLinks.manageEmailTemplates.href}/${programId}`}
          text={`${programLinks.manageEmailTemplates.text}`}
          icon={<EmailIcon sx={{ color: 'warning.main', fontSize: 18, marginRight: 1, verticalAlign: 'middle' }} />}
          color="primary"
        />

        <StyledRouterLinkButton
          to={`${programLinks.manageResources.href}/${programId}`}
          text={`${programLinks.manageResources.text}`}
          icon={
            <AttachFileIcon sx={{ color: 'warning.main', fontSize: 18, marginRight: 1, verticalAlign: 'middle' }} />
          }
          color="primary"
        />
      </Box>
    </>
  );
};

export default QuickLinks;
