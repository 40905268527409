import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import EditTemplateDialog from "./components/EditTemplateDialog";
import GenericEmailTemplate from "../../../models/GenericEmailTemplate.model";
import DeleteIcon from '../../global/Icons/DeleteIcon';

interface EmailTemplateListProps {
  accountTemplates: GenericEmailTemplate[];
  cohortTemplates: GenericEmailTemplate[];
  hubTeamTemplates: GenericEmailTemplate[];
  registrationTemplates: GenericEmailTemplate[];
  templatesNoCategory: GenericEmailTemplate[];
  onSaveClick: (template: any) => void;
  onDeleteClick: (template: any) => any;
}

const EmailTemplateList = (props: EmailTemplateListProps) => {
  const {
    accountTemplates,
    cohortTemplates,
    hubTeamTemplates,
    onSaveClick,
    onDeleteClick,
    registrationTemplates,
    templatesNoCategory,
  } = props;

  return (
    <>
      <CategoryAndTemplateList
        categoryTitle="Account Templates"
        templates={accountTemplates}
        onSaveClick={onSaveClick}
        onDeleteClick={onDeleteClick}
      />

      <CategoryAndTemplateList
        categoryTitle="Cohort Templates"
        templates={cohortTemplates}
        onSaveClick={onSaveClick}
        onDeleteClick={onDeleteClick}
      />

      <CategoryAndTemplateList
        categoryTitle="Hub Team Templates"
        templates={hubTeamTemplates}
        onSaveClick={onSaveClick}
        onDeleteClick={onDeleteClick}
      />

      <CategoryAndTemplateList
        categoryTitle="Registration Templates"
        templates={registrationTemplates}
        onSaveClick={onSaveClick}
        onDeleteClick={onDeleteClick}
      />

      {templatesNoCategory.length > 0 && (
        <CategoryAndTemplateList
          categoryTitle="Other Templates"
          templates={templatesNoCategory}
          onSaveClick={onSaveClick}
          onDeleteClick={onDeleteClick}
        />
      )}
    </>
  );
};

interface CategoryAndTemplateListProps {
  categoryTitle: string;
  templates: GenericEmailTemplate[];
  onSaveClick: (template: any) => void;
  onDeleteClick: (template: any) => any;
}

const CategoryAndTemplateList = ({
  categoryTitle,
  onSaveClick,
  onDeleteClick,
  templates,
}: CategoryAndTemplateListProps) => {
  return (
    <>
      <Typography variant="h6" sx={{ textAlign: "left" }}>
        {categoryTitle}
      </Typography>
      <Divider />

      {templates.length > 0 && (
        <TableContainer sx={{ mt: 2, mb: 4 }} component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="email templates for program"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "40%" }}>Template Name</TableCell>
                <TableCell sx={{ width: "40%" }}>Template Subject</TableCell>
                <TableCell sx={{ width: "20%" }} align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {templates.map((template) => (
                <TableRow
                  key={template.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {template.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {template.subject}
                  </TableCell>
                  <TableCell align="right">
                    <EditTemplateDialog
                      template={template}
                      onSaveClick={onSaveClick}
                    />
                    <DeleteIcon
                      iconTooltip={`Remove ${template.name} from email templates?`}
                      dialogTitle="Remove Registration Question"
                      dialogText={`Are you sure you want to remove the ${template.name} email template?`}
                      confirmText="Remove"
                      onClose={() => onDeleteClick(template)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {templates.length === 0 && (
        <Alert sx={{ mb: 4, mt: 2 }} severity="info">
          No templates found
        </Alert>
      )}
    </>
  );
};

export default EmailTemplateList;
