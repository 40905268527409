import axios from 'axios';
import { getTenantIdForDomain } from 'helpers/tenantHelpers';
import { useAuth } from './useAuth';

export default function useAxios() {
  const auth = useAuth();

  const get = async (url: string, headers: any = null) => {
    const options = getAxiosOptions(headers);
    const result = await axios.get(url, options);
    return result.data;
  };

  const post = async (url: string, data: any, headers: any = null) => {
    const options = getAxiosOptions(headers);
    const result = await axios.post(url, data, options);
    return result.data;
  };

  const put = async (url: string, data: any, headers: any = null, p0: boolean) => {
    try {
      const options = getAxiosOptions({
        ...headers,
        'Content-Type': data.type
      });
      try {
        const result = await axios.put(url, data, options);
        return result.data;
      } catch (error) {
        console.error('Error uploading file1:', error);
        throw error;
      }
    } catch (error) {
      console.error('Error uploading file2:', error);
      throw error;
    }
  };

  return { get, post, put };
}

function getAxiosOptions(additionalHeaders: any = null) {
  return {
    withCredentials: true,
    headers: {
      ...additionalHeaders,
      tenant: getTenantIdForDomain(),
      'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  };
}
