import { useAuth } from '../hooks/useAuth';
import PageHeader from '../components/global/PageHeader';
import { Container } from '@mui/material';

import UpdateCurrentPassword from 'components/account/updateCurrentPassword/UpdateCurrentPassword';

const ChangePassword = () => {
  const auth = useAuth();

  return (
    <>
      <Container maxWidth="xl" sx={{ p: 14, mb: 2 }}>
        <PageHeader title="Change Password" />

        <UpdateCurrentPassword userId={auth.user?.id ?? ''} redirectToLogin={false} />
      </Container>
    </>
  );
};

export default ChangePassword;
