import { gql } from '@apollo/client';

export const UpdatePassword_Name = 'UpdatePassword';
export const UpdatePassword_Gql = gql`
  mutation UpdatePassword($userId: ID!, $password: String!) {
    updatePassword(userId: $userId, password: $password) {
      id
      firstName
      lastName
      email
    }
  }
`;
