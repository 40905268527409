import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';


import {
  GetMembersByCohort_getMembersByCohort
} from 'models/GeneratedModels';

interface HubMembers {
  hubTeamMembers: GetMembersByCohort_getMembersByCohort[]
}

interface HubMemberRoles {
  Facilitator: string[];
  Presenter: string[];
  Panelist: string[];
}

const HubMemberSessions = ({ hubTeamMembers }: HubMembers) => {

  const collectHubMembers = (hubMembers: GetMembersByCohort_getMembersByCohort[]) => {
    const members: {
      Facilitator: Set<string>;
      Presenter: Set<string>;
      Panelist: Set<string>;
    } = {
      Facilitator: new Set<string>(),
      Presenter: new Set<string>(),
      Panelist: new Set<string>()
    };

    hubMembers?.forEach(mem => {
      const fullName = `${mem?.user?.firstName} ${mem?.user?.lastName}`;
      const credential = mem?.credentials;
      const nameAndCreds = credential ? `${fullName}, ${credential}` : fullName;

      if (mem.sessionsAsFacilitator.length > 0) {
        members.Facilitator.add(nameAndCreds);
      }
      if (mem.sessionsAsPresenter.length > 0) {
        members.Presenter.add(nameAndCreds);
      }
      if (mem.sessionsAsPanelist.length > 0) {
        members.Panelist.add(nameAndCreds);
      }
    });

    return {
      Facilitator: Array.from(members.Facilitator),
      Presenter: Array.from(members.Presenter),
      Panelist: Array.from(members.Panelist)
    };
  };

  const hubMembers: HubMemberRoles = collectHubMembers(hubTeamMembers || []);

  const updateLabel = (role: string) => {
    if (role === 'Facilitator') {
      return 'Facilitator(s)'
    }

    if (role === 'Presenter') {
      return 'Presenter(s)'
    }

    return 'Panelist(s)';
  }

  return (
    <Box sx={{ my: 5 }}>
      <Table size="small" sx={{ marginBottom: '10px' }}>
        <TableBody>
          {(Object.keys(hubMembers) as (keyof HubMemberRoles)[]).map((role) => (
            <TableRow key={role}>
              <TableCell component="th" scope="row">{updateLabel(role)}:</TableCell>
              <TableCell align="right">{hubMembers[role].join('; ')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default HubMemberSessions;
