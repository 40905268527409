import { gql } from '@apollo/client';

export const getSessionsForCohortQuery_Name = 'GetSessionsByCohort';
export const getSessionsForCohortQuery_Gql = gql`
  query GetSessionsByCohort($cohortId: String!) {
    getSessionsByCohort(cohortId: $cohortId) {
      id
      cohortId
      number
      title
      startDate
      endDate
      active
      learningOutcomes
      presenterNotes
      facultyNotes
      isFuture
    }
  }
`;
