import { useCallback, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Button } from '@mui/material';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';

import PageHeader from '../../global/PageHeader';
import DeleteIcon from '../../global/Icons/DeleteIcon';
import ProgressIndicator from '../../global/ProgressIndicator';

import AddRegistrationQuestion from './AddRegistrationQuestion';
import EditRegistrationQuestion from './EditRegistrationQuestions';

import { deleteRegistrationQuestion_Gql } from '../../../gql/cohort/deleteRegistrationQuestion';
import { updateAllRegistrationQuestions_Gql } from '../../../gql/cohort/updateAllRegistrationQuestions';
import { GetCohortByIdQuery_Name } from '../../../gql/cohort/getCohortByIdQuery';

import {
  DeleteRegistrationQuestionVariables,
  updateAllRegistrationQuestionsVariables,
  GetCohortById_getCohortById_registrationQuestions
} from '../../../models/GeneratedModels';

import { RegistrationQuestionsProps } from './Cohort.interfaces';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    buttonRow: {
      display: 'inline-block',
    },
    delete: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  })
);

const RegistrationQuestions = ({ registrationQuestions, cohortId }: RegistrationQuestionsProps) => {
  const classes = useStyles();

  const [pendingQuestionToDelete, setPendingQuestionToDelete] = useState('');
  const [displayQuestions, setDisplayQuestions] = useState(registrationQuestions || []);

  const [deleteQuestion, { loading }] = useMutation<void, DeleteRegistrationQuestionVariables>(
    deleteRegistrationQuestion_Gql,
    {
      refetchQueries: [GetCohortByIdQuery_Name],
    }
  );

  const [updateAllRegistrationQuestions, { loading: updateLoading }] = useMutation<void, updateAllRegistrationQuestionsVariables>(
    updateAllRegistrationQuestions_Gql,
    {
      refetchQueries: [GetCohortByIdQuery_Name],
    }
  );

  useEffect(() => {
    if (registrationQuestions !== null) {
      setDisplayQuestions(registrationQuestions);
    }
  }, [registrationQuestions, setDisplayQuestions])

  const deleteConfirmationClose = async (isConfirmed: boolean) => {
    if (isConfirmed && pendingQuestionToDelete.length > 0) {
      await deleteQuestion({
        variables: {
          cohortId: cohortId,
          registrationQuestionId: pendingQuestionToDelete,
        },
      });
    }
    setPendingQuestionToDelete('');
  };

  const handleChangeSortingOrder = (index: number, direction: 'up' | 'down') => {
    setDisplayQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      const swapWithIndex = direction === 'up' ? index - 1 : index + 1;

      [newQuestions[index], newQuestions[swapWithIndex]] = [newQuestions[swapWithIndex], newQuestions[index]];
      return newQuestions;
    });
  };

  const isOrderChanged = useCallback(() => {
    if (!registrationQuestions || !displayQuestions || registrationQuestions.length !== displayQuestions.length) {
      return true;
    }
    for (let i = 0; i < registrationQuestions.length; i++) {
      if (registrationQuestions[i].id !== displayQuestions[i].id) {
        return true;
      }
    }
    return false;
  }, [registrationQuestions, displayQuestions]);

  const handleDiscardSort = useCallback(() => {
    setDisplayQuestions(registrationQuestions || []);
  }, [registrationQuestions, setDisplayQuestions]);

  const handleUpdateSorting = useCallback(() => {
    const updatedQuestions = displayQuestions.map(({ __typename, options, yesNo, statements, scale, ...rest }) => {
      const cleanedOptions = options?.map(({ __typename, ...optionWithoutTypename }) => optionWithoutTypename) || options;
      const cleanedYesNo = yesNo ? (({ __typename, ...restYesNo }) => restYesNo)(yesNo) : yesNo;
      const cleanedStatements = statements?.map(({ __typename, ...statementWithoutTypename }) => statementWithoutTypename) || statements;
      const cleanScale = scale?.map(({ __typename, ...scaleWithoutTypename }) => scaleWithoutTypename) || scale;

      return {
        ...rest,
        options: cleanedOptions,
        yesNo: cleanedYesNo,
        statements: cleanedStatements,
        scale: cleanScale
      };
    });

    updateAllRegistrationQuestions({
      variables: {
        cohortId,
        registrationQuestions: updatedQuestions
      }
    })
  }, [displayQuestions, cohortId, updateAllRegistrationQuestions]);

  const getQuestionType = (question: GetCohortById_getCohortById_registrationQuestions) => {
    if (question.type === 'radio') {
      return 'yes/no'
    }

    if (question.isMultiSelect) {
      return `${question.type}-multi`;
    }

    return question.type;
  }

  return (
    <>
      <ProgressIndicator isOpen={loading || updateLoading} title="Saving..." />

      <Prompt
        when={isOrderChanged()}
        message='You have unsaved changes. Are you sure you want to leave?'
      />

      <PageHeader title="Cohort Registration Questions" />

      <Box display='flex' justifyContent='space-between' marginBottom='15px'>
        <AddRegistrationQuestion cohortId={cohortId} />
        { isOrderChanged() &&
          <Box>
            <Button variant='contained' onClick={handleUpdateSorting}>Update Sorting</Button>
            <Button style={{ marginLeft: 10 }} onClick={handleDiscardSort}>Discard</Button>
          </Box>
        }
      </Box>

      <TableContainer component={Paper}>
        <Table size='small' aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Sort</TableCell>
              <TableCell>Question</TableCell>
              <TableCell align="center">Name/Export Label</TableCell>
              <TableCell align="center">Question Type</TableCell>
              <TableCell align="center">Required</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayQuestions &&
              displayQuestions.map((rq, i) => (
                <TableRow key={`${rq.name}-${i}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <EditRegistrationQuestion cohortId={cohortId} registrationQuestion={rq} />
                  </TableCell>
                  <TableCell>
                    <Box display='flex'>
                      { i !== 0 &&
                        <ArrowUpward
                          fontSize='small'
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleChangeSortingOrder(i, 'up')}
                        />
                      }
                      { i !== displayQuestions.length - 1 &&
                        <ArrowDownward
                          fontSize='small'
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleChangeSortingOrder(i, 'down')}
                        />
                      }
                    </Box>
                  </TableCell>
                  <TableCell>
                    <div dangerouslySetInnerHTML={{ __html: rq.title }} />
                  </TableCell>
                  <TableCell align="center">{rq.name}</TableCell>
                  <TableCell align="center">{getQuestionType(rq)}</TableCell>
                  <TableCell align="center">{rq.required ? 'Yes' : 'No'}</TableCell>
                  <TableCell align="right">
                    <div className={classes.buttonRow} onClick={() => setPendingQuestionToDelete(rq.id)}>
                      <DeleteIcon
                        iconTooltip={`Remove ${rq.name} from registration questions?`}
                        dialogTitle="Remove Registration Question"
                        dialogText={`Are you sure you want to remove the ${rq.name} registration question?`}
                        confirmText="Remove"
                        onClose={deleteConfirmationClose}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <LearnerRegistrationQuestions
        onSubmit={onSubmit}
        questions={registrationQuestions}
        saveButtonText="Register for Cohort"
      /> */}
    </>
  );
};

export default RegistrationQuestions;
