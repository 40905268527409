import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Box, Drawer, Typography, TextField } from '@mui/material';

import { getPartnersQuery_Name } from '../../../gql/partner/getPartnersQuery';
import { updatePartnerMutation_Gql } from '../../../gql/partner/updatePartnerMutation';
import { addPartnerMutation_Gql } from '../../../gql/partner/addPartnerMutation';
import { GetAllPartners_getPartners } from '../../../models/GeneratedModels';

interface PartnerDrawerProps {
  open: boolean;
  partner?: GetAllPartners_getPartners;
  onDrawerClose: Function;
}

const PartnerDrawer = ({
  open,
  partner = { __typename: 'Partner', id: '', name: '' },
  onDrawerClose,
}: PartnerDrawerProps) => {
  const [drawerOpen, setDrawerOpen] = useState(open);
  const [partnerToEdit, setPartnerToEdit] = useState<GetAllPartners_getPartners>(partner);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const isNewPartner = partner.id.length === 0;

  useEffect(() => {
    setDrawerOpen(open);
  }, [open]);

  const [addPartner] = useMutation(addPartnerMutation_Gql, {
    refetchQueries: [getPartnersQuery_Name],
  });

  const [updatePartner] = useMutation(updatePartnerMutation_Gql, {
    refetchQueries: [getPartnersQuery_Name],
  });

  const partnerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (partnerToEdit) {
      resetForm();
      setPartnerToEdit({
        ...partnerToEdit,
        name: event.target.value,
      });
    }
  };

  const resetForm = () => {
    setError(false);
    setErrorMsg('');
  };

  const validateForm = (): boolean => {
    resetForm();
    let isValid = true;

    if (partnerToEdit.name.length === 0) {
      isValid = false;
      setError(true);
      setErrorMsg('Partner name is required');
    }

    return isValid;
  };

  const savePartner = () => {
    if (!validateForm()) return;

    try {
      if (!partnerToEdit) return;

      if (isNewPartner) {
        addPartner({
          variables: {
            partner: {
              name: partnerToEdit.name,
            },
          },
        });
      } else {
        updatePartner({
          variables: {
            partner: {
              id: partnerToEdit.id,
              name: partnerToEdit.name,
            },
          },
        });
      }

      onDrawerClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Drawer variant="temporary" open={drawerOpen} anchor="right" onClose={() => setDrawerOpen(false)}>
        <Box sx={{ width: 500, textAlign: 'center' }}>
          <Typography variant="h6" color="primary">
            {!isNewPartner ? `Edit ${partnerToEdit?.name}` : 'Add New Partner'}
          </Typography>

          <TextField
            sx={{ mt: 3, mb: 2, width: 400 }}
            label="Partner Name"
            value={partnerToEdit?.name}
            onChange={partnerNameChange}
            error={error}
            helperText={errorMsg}
          />
          <br />

          <Button onClick={savePartner} sx={{ width: 125, mr: 2 }} variant="contained" color="primary">
            Save
          </Button>
          <Button onClick={() => onDrawerClose()} sx={{ width: 125 }} variant="outlined" color="primary">
            Cancel
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default PartnerDrawer;
