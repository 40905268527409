import { useState } from 'react';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import PageHeader from '../components/global/PageHeader';
import ProgressIndicator from '../components/global/ProgressIndicator';
import StyledRouterLink from 'components/global/StyledRouterLink';
import SortableTableColumn, {
  SortProperty,
  sortBy,
} from '../components/global/SortableTableColumn';

import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';
import { getCohortsOpenForRegistrationQuery_Gql } from '../gql/cohort/getCohortsOpenForRegistrationQuery';
import {
  GetCohortsOpenForRegistration,
  GetCohortsOpenForRegistration_getCohortsOpenForRegistration,
} from '../models/GeneratedModels';
import { learnerLinks } from 'utils/links';
import { Container } from '@mui/material';

const R = require('ramda');

const LearnerPrograms = () => {
  const { loading, data } = useQuery<GetCohortsOpenForRegistration>(
    getCohortsOpenForRegistrationQuery_Gql
  );
  const [sortProp, setSortProp] = useState<SortProperty>();

  const pageTitle = 'ECHOs You Can Explore';

  const getSortableCohortList =
    (): GetCohortsOpenForRegistration_getCohortsOpenForRegistration[] => {
      const cohorts = data?.getCohortsOpenForRegistration ?? [];

      // Create a Set to keep track of unique cohort IDs
      const uniqueIds = new Set<string>();
      const uniqueCohorts = cohorts
        .filter((cohort) => {
          if (!uniqueIds.has(cohort.id)) {
            uniqueIds.add(cohort.id);
            return true; // Include the cohort if it's not a duplicate
          }
          return false; // Exclude duplicates
        })
        .map((cohort) => ({
          ...cohort,
          label: cohort.externalLabel || '',
          endDate: cohort.isOngoing ? '' : cohort.endDate || '',
          startDate: cohort.startDate || '',
        }));

      return sortBy(uniqueCohorts, sortProp);
    };

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Loading" />
      <Container maxWidth="xl" sx={{ p: 10, mb: 12 }}>
        <PageHeader title={pageTitle} />

        <Box sx={{ textAlign: 'left', fontSize: '18px', mb: 2 }}>
          <StyledRouterLink
            to={learnerLinks.myEchos.href}
            text="Take Me To My ECHOs"
          />
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <SortableTableColumn
                  currentSort={sortProp?.prop}
                  onSort={setSortProp}
                  sortProperty="label"
                  headerText="ECHO Title"
                />
                <SortableTableColumn
                  currentSort={sortProp?.prop}
                  onSort={setSortProp}
                  sortProperty="startDate"
                  headerText="When It Starts"
                  align="center"
                />
                <SortableTableColumn
                  currentSort={sortProp?.prop}
                  onSort={setSortProp}
                  sortProperty="endDate"
                  headerText="When It Ends"
                  align="center"
                />
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getSortableCohortList().map((cohort) => (
                <TableRow
                  key={cohort.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">
                    {cohort.allowPreregistration && (
                      <Tooltip title="This cohort is open for pre-registration. Those who pre-register will receive an email when registration opens">
                        <CalendarTodayIcon
                          sx={{ fontSize: '16px', color: 'primary.main', mr: 1 }}
                        />
                      </Tooltip>
                    )}
                    {cohort.externalLabel || '(No Name)'}
                  </TableCell>
                  <TableCell align="center">
                    {convertDateToTimeZoneFromUtc(cohort.startDate)}
                  </TableCell>
                  <TableCell align="center">
                    {cohort.isOngoing
                      ? 'Ongoing'
                      : convertDateToTimeZoneFromUtc(cohort.endDate)}
                  </TableCell>
                  <TableCell align="right">
                    <StyledRouterLink
                      to={`${learnerLinks.cohortDetail.href}/${cohort.id}`}
                      text={learnerLinks.cohortDetail.text}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default LearnerPrograms;
