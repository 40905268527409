import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Link, Container } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

import Breadcrumbs, { BreadcrumbItem } from '../components/global/Breadcrumbs';
import PageHeader from '../components/global/PageHeader';
import ProgressIndicator from '../components/global/ProgressIndicator';

import CohortParams from '../models/params/CohortParams';
import { getCohortRoster_Gql } from '../gql/cohort/getCohortRoster';
import { GetCohortRoster, GetCohortRosterVariables, GetCohortRoster_getCohortRoster } from '../models/GeneratedModels';

const CohortRoster = () => {
  const { cohortId } = useParams<CohortParams>();

  const { data, loading } = useQuery<GetCohortRoster, GetCohortRosterVariables>(getCohortRoster_Gql, {
    variables: {
      cohortId,
    },
  });

  const cohort: GetCohortRoster_getCohortRoster | null | undefined = data?.getCohortRoster;

  const breadcrumbs: BreadcrumbItem[] = [
    {
      to: '/my-echos',
      text: 'My ECHOs',
      isActive: true,
    },
    {
      to: `/program-dashboard/${cohortId}`,
      text: `${cohort?.cohortTitle ?? ''}`,
      isActive: true,
    },
    {
      text: 'Cohort Roster',
      isActive: false,
    },
  ];

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Loading..." />
      <Container maxWidth="xl" sx={{ p: 14, mb: 12 }}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <PageHeader title={cohort?.cohortTitle ?? ''} />

        {false && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h6" color="primary">
                Faculty for this ECHO
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Credentials</TableCell>
                      <TableCell>Organization</TableCell>
                      <TableCell>Location</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>
                        Coan, Elizabeth
                        <Tooltip title="Email Elizabeth">
                          <Link href="mailto:elizabeth.coan@childrenscolorado.org">
                            <EmailIcon sx={{ fontSize: 16, verticalAlign: 'middle', ml: 1 }} />
                          </Link>
                        </Tooltip>
                      </TableCell>
                      <TableCell>Panelist</TableCell>
                      <TableCell>PsyD</TableCell>
                      <TableCell>Childrens Hospital Colorado</TableCell>
                      <TableCell>Aurora, CO</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        )}

        <Typography sx={{ mt: 4, textAlign: 'left' }} variant="h6" color="primary">
          Cohort Roster
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Credentials</TableCell>
                <TableCell>Organization</TableCell>
                <TableCell>Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cohort &&
                cohort.cohortRosters &&
                cohort.cohortRosters.map((cr) => (
                  <TableRow key={cr.email} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      {cr.name}
                      {cr.email && cr.email.length > 0 && (
                        <Tooltip title={`Email ${cr.name}`}>
                        <Link href={`mailto:${cr.email}`}>
                          <EmailIcon sx={{ fontSize: 16, verticalAlign: 'middle', ml: 1 }} />
                        </Link>
                      </Tooltip>
                    )}
                    </TableCell>
                    <TableCell>{cr.credentials}</TableCell>
                    <TableCell>{cr.organization}</TableCell>
                    <TableCell>{cr.location}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default CohortRoster;
