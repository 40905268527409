
import { useState } from 'react';

import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ProgressIndicator from 'components/global/ProgressIndicator';

import { FileExportsGlobalProps } from '../../cohort/Cohort.interfaces';
import {
  exportAllRegistrants,
  exportAccountsNoRegistration,
  exportAllEvaluationData,
} from './FileExports.helpers';

interface ExportDownloadIconProps {
  onClick: () => void;
}

const ExportDownloadIcon = ({ onClick }: ExportDownloadIconProps) => {
  return (
    <>
      <DownloadIcon onClick={onClick} sx={{ cursor: 'pointer', color: 'primary.main' }} />
    </>
  );
};

const FileExportsGlobal = ({ onExportStart }: FileExportsGlobalProps) => {
  const [excelExporting, setExcelExporting] = useState(false);

  return (
    <>
      <ProgressIndicator isOpen={excelExporting} title="Processing Global Excel export, takes a few minutes..." />
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>User Accounts with No Registrations</TableCell>
            <TableCell align="right">
              <ExportDownloadIcon onClick={async () => await exportAccountsNoRegistration(setExcelExporting)} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Export All Registrants</TableCell>
            <TableCell align="right">
              <ExportDownloadIcon onClick={async () => await exportAllRegistrants(setExcelExporting)} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Data Export with Variable Labels</TableCell>
            <TableCell align="right">
              <ExportDownloadIcon
                onClick={async () => await exportAllEvaluationData('label', setExcelExporting)}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              Data Export with Variable Values
            </TableCell>
            <TableCell align="right">
              <ExportDownloadIcon
                onClick={async () => await exportAllEvaluationData('value', setExcelExporting)}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default FileExportsGlobal;
