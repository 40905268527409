import { gql } from '@apollo/client';

export const denyCohortRegistrationMutation_Name = 'DenyCohortRegistration';
export const denyCohortRegistrationMutation_Gql = gql`
  mutation DenyCohortRegistration($cohortRegistration: DenyCohortRegistrationInput!) {
    denyCohortRegistration(cohortRegistration: $cohortRegistration) {
      id
      updatedAt
    }
  }
`;
