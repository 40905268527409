import { gql } from '@apollo/client';

export const addPreRegistrant_Name = 'AddPreRegistrant';
export const addPreRegistrant_Gql = gql`
  mutation AddPreRegistrant($preRegistrant: AddCohortPreRegistrationInput!) {
    addPreRegistrant(preRegistrant: $preRegistrant) {
      id
    }
  }
`;
