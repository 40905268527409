import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HomeTitleAndBody from './HomeTitleAndBody';
import { Container } from '@mui/material';

const VirginiaHome = () => {
  return (
    <>
      <Container maxWidth="xl" sx={{ p: 14, mb: 2 }}>
        <HomeTitleAndBody title="Join In and Help Make Health Knowledge ECHO">
          <Stack spacing={2}>
            <Typography variant="subtitle1">
              Virginia Project ECHO (Extension for Community Health Outcomes) is a unique platform that leverages
              learning, training and practice support to give voice to knowledge across Virginia and beyond.
            </Typography>

            <Typography variant="subtitle1">
              Working with specialists, generalists, and a wide range of different organizations, Virginia Project ECHO
              helps build learning collaboratives where healthcare professionals, community advocates, law enforcement,
              and university staff can share knowledge, skills, and experiences. The goal is to increase everyone's
              ability to manage complex health care problems, share best practices, and collaborate to best utilize
              resources to prevent disease and keep people safe in Virginia.
            </Typography>
          </Stack>
        </HomeTitleAndBody>
      </Container>
    </>
  );
};

export default VirginiaHome;
