import { gql } from '@apollo/client';

export const getPreRegistrantsForCohortQuery_Name = 'GetPreRegistrantsForCohort';
export const getPreRegistrantsForCohortQuery_Gql = gql`
  query GetPreRegistrantsForCohort($cohortId: String!) {
    getPreRegistrantsForCohort(cohortId: $cohortId) {
      id
      cohortId
      userId
      email
      emailSent
      firstName
      lastName
      organization
      profession
      createdAt
      user {
        firstName
        lastName
        email
        organization
        profession {
          name
        }
      }
    }
  }
`;
