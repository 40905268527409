import { useMutation } from '@apollo/client';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';

import ProgressIndicator from '../../../../global/ProgressIndicator';
import { getRegistrantsForCohortQuery_Name } from '../../../../../gql/cohortRegistration/getRegistrantsForCohortQuery';
import { deleteCohortRegistrationMutation_Gql } from '../../../../../gql/cohortRegistration/deleteCohortRegistrationMutation';

interface DeleteIconProps {
  id: String;
}

const DeleteIconButton = ({ id }: DeleteIconProps) => {
  const [deleteUser, { loading, error }] = useMutation(deleteCohortRegistrationMutation_Gql, {
    variables: {
      id,
    },
    refetchQueries: [getRegistrantsForCohortQuery_Name],
  });

  const handleDelete = async () => {
    try {
      await deleteUser();
    } catch {}
  };

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Saving..." />

      {error && <Alert severity="error">{error.graphQLErrors[0].message}</Alert>}

      <Tooltip title="Remove Registrant">
        <DeleteIcon onClick={handleDelete} sx={{ color: 'primary.main', cursor: 'pointer', mr: 1 }} />
      </Tooltip>
    </>
  );
};

export default DeleteIconButton;
