import { gql } from '@apollo/client';

export const updateHubMemberCohortAndSessions_Name = 'UpdateHubMemberCohortAndSessions';
export const updateHubMemberCohortAndSessions_Gql = gql`
  mutation UpdateHubMemberCohortAndSessions($hubMemberCohort: UpdateHubMemberCohortInput!) {
    updateHubMemberCohortAndSessions(hubMemberCohort: $hubMemberCohort) {
      id
      userId
      cohortId
    }
  }
`;
