import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';

import { Box, Drawer, Button } from '@mui/material';
import { FaPlus } from 'react-icons/fa';
import {
  TextField,
  Alert,
  Typography,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { addSessionMutation_Gql } from '../../../gql/session/addSessionMutation';
import { getSessionsForCohortQuery_Name } from '../../../gql/session/getSessionsForCohortQuery';
import {
  AddSessionVariables,
  AddSessionInput,
} from '../../../models/GeneratedModels';
import ProgressIndicator from '../../global/ProgressIndicator';

import { getTenantIdForDomain } from 'helpers/tenantHelpers';
import { getTimeZoneForTenant } from 'helpers/dateHelpers';
import moment from 'moment-timezone';
interface AddSessionDrawerProps {
  cohortId: string;
}

const AddSessionDrawer = ({ cohortId }: AddSessionDrawerProps) => {
  
  const startDateRef = useRef<HTMLInputElement | null>(null);
  const startTimeRef = useRef<HTMLInputElement | null>(null);
  const endDateRef = useRef<HTMLInputElement | null>(null);
  const endTimeRef = useRef<HTMLInputElement | null>(null);
  
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasTitleError, setHasTitleError] = useState(false);

  const [session, setSession] = useState<AddSessionInput>({
    cohortId,
    number: null,
    title: '',
    startDate: new Date(),
    endDate: new Date(),
    active: true,
    learningOutcomes: '',
    presenterNotes: '',
    facultyNotes: '',
    sendCalendarInvites: false,
  });
  const tenantId = getTenantIdForDomain();
  const timeZone = getTimeZoneForTenant(tenantId);
  const currentDate = new Date();
  const janOffset = new Date(currentDate.getFullYear(), 0, 1).getTimezoneOffset();
  const julOffset = new Date(currentDate.getFullYear(), 6, 1).getTimezoneOffset();
  const isDST = Math.min(janOffset, julOffset) !== currentDate.getTimezoneOffset();

  const tenantTimeZone = isDST ? moment.tz(timeZone).utcOffset() - 60  : moment.tz(timeZone).utcOffset();
  const userTimeZone = isDST ? moment.tz(moment.tz.guess()).utcOffset() - 60  : moment.tz(moment.tz.guess()).utcOffset();
  const userTimeZonePST = tenantTimeZone > userTimeZone ? '-' : '';
  const userTenantTimeZoneDifference = Math.abs(tenantTimeZone - userTimeZone);
  const timeZoneOffset = tenantTimeZone + parseInt(`${userTimeZonePST}${userTenantTimeZoneDifference}`);

  const [sessionStartDate, setSessionStartDate] = useState(moment().format('YYYY-MM-DD')); // State to hold the session start time
  const [sessionStartTime, setSessionStartTime] = useState(moment().format('HH:mm:ss')); // State to hold the session start time
  const [sessionEndDate, setSessionEndDate] = useState(moment().add(1, 'hours').format('YYYY-MM-DD')); // State to hold the session start time
  const [sessionEndTime, setSessionEndTime] = useState(moment().add(1, 'hours').format('HH:mm:ss')); // State to hold the session start time

  const [addNewSession, { loading, error }] = useMutation<AddSessionVariables>(
    addSessionMutation_Gql,
    {
      variables: {
        session,
      },
      refetchQueries: [getSessionsForCohortQuery_Name],
    }
  );

  useEffect(() => {
    setIsSaving(loading);
  }, [loading]);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    let name = e.target.name;

    if (name === 'number') {
      setSession({ ...session, [name]: Number(value) });
    } else {
      setSession({ ...session, [name]: value });
    }
  };

  const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== "") {
      const updatedStartTime = event.target.value
      setSessionStartTime(updatedStartTime);
    } else {
      console.log('startTime');
    }
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== "") {
      const updatedEndTime = event.target.value
      setSessionEndTime(updatedEndTime);
    } else {
      console.log('endTime');
    }
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== "") {
      const updatedStartDate = event.target.value
      setSessionStartDate(updatedStartDate);
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== "") {
      const updatedEndDate = event.target.value
      setSessionEndDate(updatedEndDate);
    }
  };

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSession({ ...session, sendCalendarInvites: event.target.checked });
  };

  const formValidation = (): boolean => {
    let isValid = true;
    setHasTitleError(false);

    if (session.title === null || session.title?.length === 0) {
      setHasTitleError(true);
      isValid = false;
    }

    return isValid;
  };

  const addSession = async () => {
    try {
      
      if (!formValidation()) return;

      const sDate = startDateRef?.current?.value ||'';
      const sTime = startTimeRef?.current?.value ||'';
      const eDate = endDateRef?.current?.value ||'';
      const eTime = endTimeRef?.current?.value ||'';

      const submitStartDateTime = moment(`${sDate}${sTime}`, 'YYYY-MM-DD HH:mm', timeZone);
      const submitEndDateTime = moment(`${eDate}${eTime}`, 'YYYY-MM-DD HH:mm', timeZone);

      session.startDate = new Date(`${submitStartDateTime.format('YYYY-MM-DD')}T${submitStartDateTime.format('HH:mm:ss')}.000Z`);
      session.endDate = new Date(`${submitEndDateTime.format('YYYY-MM-DD')}T${submitEndDateTime.format('HH:mm:ss')}.000Z`);
    
      await addNewSession();
      setOpen(false);
    } catch (err1: any) {
      console.error(err1);
    }
  };

  const cancelEntry = async () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Button
          onClick={() => setOpen(true)}
          variant="outlined"
          color="primary"
        >
          <FaPlus />
          &nbsp; Add New Session
        </Button>
      </div>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Typography
          sx={{ marginTop: 3, marginBottom: 3, textAlign: 'center' }}
          variant="h5"
          color="primary"
        >
          Add New Session
        </Typography>

        {error && (
          <Alert sx={{ mb: 2 }} severity="error">
            {error}
          </Alert>
        )}

        <Box sx={{ width: 400, ml: 2 }}>
          <TextField
            sx={{ width: 350, mb: 3 }}
            label="Session Number"
            name="number"
            type="number"
            variant="outlined"
            onChange={handleTextChange}
            color="warning"
          />

          <TextField
            sx={{ width: 350, mb: 3 }}
            label="Title"
            name="title"
            variant="outlined"
            error={hasTitleError}
            helperText="Required"
            onChange={handleTextChange}
            color="warning"
          />

          <Box sx={{ width: 350, mb: 3 }}>
            <div style={{ display: 'flex' }}>
                <TextField
                  sx={{ mb: 3 }}
                  type="date"
                  id="startDate"
                  name="startDate"
                  inputRef={startDateRef}
                  onChange={handleStartDateChange}
                  value={sessionStartDate}
                  style={{ width: '100%', marginRight: '8px' }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Session Start Date"
                  color="warning"
                />

                <TextField
                  sx={{ mb: 3 }}
                  type="time"
                  id="startTime"
                  name="startstartTimeDate"
                  inputRef={startTimeRef}
                  onChange={handleStartTimeChange}
                  value={sessionStartTime.substring(0, 5)}
                  style={{ width: '100%', marginRight: '8px' }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Time"
                  color="warning" 
                />
              </div>

              <div style={{ display: 'flex' }}>
                <TextField
                  sx={{ mb: 3 }}
                  type="date"
                  id="endDate"
                  name="endDate"
                  inputRef={endDateRef}
                  onChange={handleEndDateChange}
                  value={sessionEndDate}
                  style={{ width: '100%', marginRight: '8px' }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Session End Date"
                  color="warning"
                />

                <TextField
                  sx={{ mb: 3 }}
                  type="time"
                  id="endTime"
                  name="endTime"
                  inputRef={endTimeRef}
                  onChange={handleEndTimeChange}
                  value={sessionEndTime.substring(0, 5)}
                  style={{ width: '100%', marginRight: '8px' }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Time"
                  color="warning" 
                />
              </div>

            <Alert sx={{ mt: 2 }} severity="info">
              Note: These times are in the organization's local timezone.
            </Alert>
          </Box>

          <div>
            <Tooltip title="This will send calendar invites for the session to all registered learners">
              <InfoIcon
                sx={{ fontSize: '16px', mr: 2, color: 'primary.main' }}
              />
            </Tooltip>

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={session.sendCalendarInvites}
                  onChange={handleCheckedChange}
                />
              }
              label="Send Calendar Invites for this Session"
            />
          </div>

          <Box sx={{ mt: 3, marginLeft: 2, textAlign: 'center' }}>
            <Button onClick={addSession} color="primary" variant="contained">
              Add
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button onClick={cancelEntry} color="primary" variant="outlined">
              Cancel
            </Button>
          </Box>
        </Box>
      </Drawer>

      <ProgressIndicator isOpen={isSaving} title="Saving Session" />
    </>
  );
};

export default AddSessionDrawer;
