import { gql } from '@apollo/client';

export const updateUser_Name = 'UpdateUser';
export const updateUser_Gql = gql`
  mutation UpdateUser($user: AddOrUpdateUserInput) {
    updateUser(user: $user) {
      id
      firstName
      lastName
      email
    }
  }
`;
