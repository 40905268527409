import { gql } from '@apollo/client';

export const getMembersByCohort_Name = 'GetMembersByCohort';
export const getMembersByCohort_Gql = gql`
  query GetMembersByCohort($cohortId: String!) {
    getMembersByCohort(cohortId: $cohortId) {
      id
      userId
      cohortId
      nextStepsSentDate
      user {
        firstName
        lastName
        email
      }
      facilitateAll
      presentAll
      panelistAll
      sessionsAsFacilitator
      sessionsAsPresenter
      sessionsAsPanelist
      credentials
      hide
    }
  }
`;
