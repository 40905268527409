import { SelectChangeEvent } from '@mui/material';
import HubTeamSessionSelector from './HubTeamSessionSelector';
import { GetSessionsByCohort_getSessionsByCohort } from '../../../../models/GeneratedModels';

interface HubTeamSessionListProps {
  sessions: GetSessionsByCohort_getSessionsByCohort[];
  facilitateAll: boolean;
  selectedFacilitatorSessions: string[];
  presentAll: boolean;
  selectedPresenterSessions: string[];
  panelistAll: boolean;
  selectedPanelistSessions: string[];
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, selectName: string) => void;
  onMultiSelectChange: (e: SelectChangeEvent<string[]>) => void;
}

const HubTeamSessionList = ({
  sessions,
  facilitateAll,
  selectedFacilitatorSessions,
  presentAll,
  selectedPresenterSessions,
  panelistAll,
  selectedPanelistSessions,
  onCheckboxChange,
  onMultiSelectChange,
}: HubTeamSessionListProps) => {
  return (
    <>
      <HubTeamSessionSelector
        title="Facilitator"
        id="facilitator"
        selectLabel="Select Session(s)"
        checkLabel="Select All Sessions"
        checkValue={facilitateAll}
        selectValue={selectedFacilitatorSessions}
        checkName="facilitateAll"
        selectName="sessionsAsFacilitator"
        sessions={sessions}
        onCheckboxChange={onCheckboxChange}
        onMultiSelectChange={onMultiSelectChange}
      />

      <HubTeamSessionSelector
        title="Presenter"
        id="presenter"
        selectLabel="Select Session(s)"
        checkLabel="Select All Sessions"
        checkValue={presentAll}
        selectValue={selectedPresenterSessions}
        checkName="presentAll"
        selectName="sessionsAsPresenter"
        sessions={sessions}
        onCheckboxChange={onCheckboxChange}
        onMultiSelectChange={onMultiSelectChange}
      />

      <HubTeamSessionSelector
        title="Panelist"
        id="panelist"
        selectLabel="Select Session(s)"
        checkLabel="Select All Sessions"
        checkValue={panelistAll}
        selectValue={selectedPanelistSessions}
        checkName="panelistAll"
        selectName="sessionsAsPanelist"
        sessions={sessions}
        onCheckboxChange={onCheckboxChange}
        onMultiSelectChange={onMultiSelectChange}
      />
    </>
  );
};

export default HubTeamSessionList;
