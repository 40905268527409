import { getTenantIdForDomain } from 'helpers/tenantHelpers';
import {
  COLORADO_TENANT_ID,
  OREGON_TENANT_ID,
  VIRGINIA_TENANT_ID,
  DARTMOUTH_TENANT_ID,
  CS_TENANT_ID,
  DEMO_TENANT_ID,
} from 'utils/constants';
import ColoradoLoginHeader from './components/ColoradoLoginHeader';
import OregonLoginHeader from './components/OregonLoginHeader';
import VirginiaLoginHeader from './components/VirginiaLoginHeader';
import DartmouthLoginHeader from './components/DartmouthLoginHeader';
import CSLoginHeader from './components/CSLoginHeader';
import DemoLoginHeader from './components/DemoLoginHeader';

const LoginHeader = () => {
  const currentTenantId = getTenantIdForDomain();

  if (currentTenantId === COLORADO_TENANT_ID) {
    return <ColoradoLoginHeader />;
  }

  if (currentTenantId === OREGON_TENANT_ID) {
    return <OregonLoginHeader />;
  }

  if (currentTenantId === VIRGINIA_TENANT_ID) {
    return <VirginiaLoginHeader />;
  }

  if (currentTenantId === DARTMOUTH_TENANT_ID) {
    return <DartmouthLoginHeader />;
  }

  if (currentTenantId === DEMO_TENANT_ID) {
    return <DemoLoginHeader />;
  }

  if (currentTenantId === CS_TENANT_ID) {
    return <CSLoginHeader />;
  }

  return <></>;
};

export default LoginHeader;
