import { gql } from '@apollo/client';

export const approveCohortRegistrationMutation_Name = '';
export const approveCohortRegistrationMutation_Gql = gql`
  mutation ApproveCohortRegistration($cohortRegistration: ApproveCohortRegistrationInput!) {
    approveCohortRegistration(cohortRegistration: $cohortRegistration) {
      id
      updatedAt
    }
  }
`;
