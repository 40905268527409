import React, { useState, useEffect, useCallback } from 'react';

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PageHeader from 'components/global/PageHeader';
import ProgressIndicator from 'components/global/ProgressIndicator';

import { useAuth } from '../hooks/useAuth';
import { Container } from '@mui/material';

const LogoutContainer = () => {
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const handleLogout = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    try {
      await auth.signOut();
      window.location.href = `/login`; // Full page reload
    } catch (error) {
      console.error('Logout failed:', error);
      setLoading(false); // Reset loading state if logout fails
    }
  }, [auth, loading]);

  useEffect(() => {
    const timeoutId = setTimeout(handleLogout, 3000); // 3 seconds in milliseconds

    // Clear the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, [handleLogout]);

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Logout..." />
      <Container maxWidth="xl" sx={{ p: 14, mb: 2 }}>
        <Grid container sx={{mt: 5}}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
              <Stack spacing={5}>
                  <Alert severity="info">Appears your ECHO Connect session has expired or timed out. You will need to login again and are being redirected to the Login screen.</Alert>
              </Stack>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Container>
      <PageHeader title="Your session has timed out or expired." />
    </>
  );
};

export default LogoutContainer;
