import Typography from '@mui/material/Typography';

const DartmouthLoginHeader = () => {
  return (
    <Typography variant="body1">
      Welcome to Project ECHO at Dartmouth Health. Please enter your login info or create an account.
    </Typography>
  );
};

export default DartmouthLoginHeader;
