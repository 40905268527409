import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Button,
  TextField,
  Box
} from '@mui/material';
import { FaTimes, FaEdit } from 'react-icons/fa';

import { RegistrationQuestionInput } from '../../../../models/GeneratedModels';

interface RegistrationQuestionInputInterface {
  question: RegistrationQuestionInput
  setQuestion: (e: RegistrationQuestionInput) => void
}

const LikertScale = ({ question, setQuestion }: RegistrationQuestionInputInterface) => {
  const [scale, setScale] = useState({ label: '' });
  const [scaleError, setScaleError] = useState({ label: '', value: '', limit: '' });
  const [editingScaleIndex, setEditingScaleIndex] = useState<number | null>(null);

  const [statement, setStatement] = useState({ name: '', statement: '' });
  const [statementError, setStatementError] = useState({ name: '', statement: '', limit: '' });
  const [editingStatementIndex, setEditingStatementIndex] = useState<number | null>(null);

  const handleScaleValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScaleError({ ...scaleError, [e.target.name]: '' });
    setScale({ ...scale, [e.target.name]: e.target.value });
  };

  const handleStatementValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatementError({ ...statementError, [e.target.name]: '' });
    setStatement({ ...statement, [e.target.name]: e.target.value });
  };

  const addScale = () => {
    if (question.scale && question.scale.length >= 10) {
      setScaleError({ ...scaleError, limit: 'You can only add up to 10 scales' });
      return;
    }

    if (scale.label === '') {
      setScaleError({ ...scaleError, label: 'Label is required' });
      return;
    }

    if (question.scale && question.scale.some((s, index) => s.label === scale.label && index !== editingScaleIndex)) {
      setScaleError({ ...scaleError, label: 'Label must be unique' });
      return;
    }

    if (editingScaleIndex !== null) {
      const updatedScales = question.scale?.map((s, index) => index === editingScaleIndex ? scale : s);
      setQuestion({ ...question, scale: updatedScales });
      setEditingScaleIndex(null);
    } else {
      if (question.scale && Array.isArray(question.scale)) {
        const scales = [...question.scale];
        setQuestion({ ...question, scale: [...scales, scale] });
      } else {
        setQuestion({ ...question, scale: [scale] });
      }
    }

    setScale({ label: '' });
  };

  const addStatement = () => {
    if (question.statements && question.statements.length >= 10) {
      setStatementError({ ...statementError, limit: 'You can only add up to 10 statements' });
      return;
    }

    if (statement.name === '') {
      setStatementError({ ...statementError, name: 'Name is required' });
      return;
    }

    if (statement.statement === '') {
      setStatementError({ ...statementError, statement: 'Statement is required' });
      return;
    }

    if (question.statements && question.statements.some((s, index) => s.name === statement.name && index !== editingStatementIndex)) {
      setStatementError({ ...statementError, name: 'Name must be unique' });
      return;
    }

    if (editingStatementIndex !== null) {
      const updatedStatements = question.statements?.map((s, index) => index === editingStatementIndex ? statement : s);
      setQuestion({ ...question, statements: updatedStatements });
      setEditingStatementIndex(null);
    } else {
      if (question.statements && Array.isArray(question.statements)) {
        const statements = [...question.statements];
        setQuestion({ ...question, statements: [...statements, statement] });
      } else {
        setQuestion({ ...question, statements: [statement] });
      }
    }

    setStatement({ name: '', statement: '' });
  };

  const deleteScale = (index: number) => {
    const updatedScales = question.scale?.filter((_, i) => i !== index);
    setQuestion({ ...question, scale: updatedScales });
  };

  const deleteStatement = (index: number) => {
    const updatedStatements = question.statements?.filter((_, i) => i !== index);
    setQuestion({ ...question, statements: updatedStatements });
  };

  const editScale = (index: number) => {
    if (question.scale) {
      setScale({ label: question.scale[index].label || '' });
      setEditingScaleIndex(index);
    }
  };

  const editStatement = (index: number) => {
    if (question.statements) {
      setStatement({ name: question.statements[index].name || '', statement: question.statements[index].statement || '' });
      setEditingStatementIndex(index);
    }
  };

  return (
    <>
      <Typography variant="h6" textAlign="left" mt={4} mb={1}>Add Scale Response Options</Typography>

      <Grid container mb={2}>
        <Grid item xs={8} pr={1}>
          <Box textAlign="left">
            <TextField
              size="small"
              fullWidth
              name="label"
              onChange={handleScaleValues}
              variant="outlined"
              color="warning"
              label="Scale Label"
              value={scale.label}
              error={!!scaleError.label}
              helperText={scaleError.label}
            />
          </Box>
        </Grid>

        <Grid item xs={4} pl={1}>
          <Button variant="outlined" fullWidth onClick={addScale} sx={{ lineHeight: 2 }}>
            {editingScaleIndex !== null ? 'Update Scale' : 'Add Scale'}
          </Button>
          {scaleError.limit && (
            <Typography color="error" variant="body2" mt={1}>
              {scaleError.limit}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} pt={0} textAlign='left'>
          <Typography variant='caption' textAlign='left' color="primary.main">Add options in order as they should appear left to right</Typography>
        </Grid>
      </Grid>

      {question.scale && question.scale.length > 0 && (
        <Table aria-label="bordered table" size="small" sx={{ mb: 5 }}>
          <TableBody>
            {question.scale.map((item, index) => (
              <TableRow key={index} sx={{ display: 'flex', position: 'relative' }}>
                <TableCell sx={{ border: 1, borderColor: 'grey.300', flex: 1, position: 'relative', paddingRight: '75px' }}>
                  {item.label}
                  <IconButton
                    aria-label="edit"
                    size="small"
                    sx={{
                      position: 'absolute',
                      right: 40,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: 'primary.main',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      padding: '4px',
                    }}
                    onClick={() => editScale(index)}
                  >
                    <FaEdit />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: 'primary.main',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      padding: '4px',
                    }}
                    onClick={() => deleteScale(index)}
                  >
                    <FaTimes />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <Typography variant="h6" textAlign="left" my={1}>Statements</Typography>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Box textAlign="left">
            <TextField
              size="small"
              fullWidth
              name="name"
              onChange={handleStatementValues}
              variant="outlined"
              color="warning"
              label="Name/Export Label"
              value={statement.name}
              error={!!statementError.name}
              helperText={statementError.name}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box textAlign="left">
            <TextField
              size="small"
              fullWidth
              name="statement"
              onChange={handleStatementValues}
              variant="outlined"
              color="warning"
              label="Statement"
              value={statement.statement}
              error={!!statementError.statement}
              helperText={statementError.statement}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" fullWidth onClick={addStatement}>
            {editingStatementIndex !== null ? 'Update Statement' : 'Add Statement'}
          </Button>
          {statementError.limit && (
            <Typography color="error" variant="body2" mt={1}>
              {statementError.limit}
            </Typography>
          )}
        </Grid>
      </Grid>

      {question.statements && question.statements.length > 0 && (
        <Table aria-label="bordered table" size="small" sx={{ mb: 4 }}>
          <TableBody>
            {question.statements.map((item, index) => (
              <TableRow key={index} sx={{ display: 'flex', position: 'relative' }}>
                <TableCell sx={{ border: 1, borderColor: 'grey.300', flex: 1, position: 'relative', paddingRight: '75px' }}>
                  {item.statement} - <Typography variant='caption'>{item.name}</Typography>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    sx={{
                      position: 'absolute',
                      right: 40,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: 'primary.main',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      padding: '4px',
                    }}
                    onClick={() => editStatement(index)}
                  >
                    <FaEdit />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: 'primary.main',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      padding: '4px',
                    }}
                    onClick={() => deleteStatement(index)}
                  >
                    <FaTimes />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default LikertScale;
