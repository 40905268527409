import { LabelProps } from '../../cohort/Cohort.interfaces';
import { Typography } from '@mui/material';

const Label = ({ text }: LabelProps) => {
  return (
    <Typography align='left' variant="body1" sx={{ fontWeight: 'bold' }} color="textPrimary">
      {text}
    </Typography>
  );
};

export default Label;
