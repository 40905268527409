export const COLORADO_TENANT_ID = 'd7aa5e26-9893-44b1-b740-6b0026c4bc8e';
export const OREGON_TENANT_ID = '7385e85a-1fac-11ec-9ca8-7f152a1ef718';
export const VIRGINIA_TENANT_ID = 'a7b5caf6-1906-11ed-8a7d-6ba44a079035';
export const DARTMOUTH_TENANT_ID = 'af8b6092-1906-11ed-9657-0f0a11c66d5a';
export const RUTGERS_TENANT_ID = 'd53d6d2b-8d4f-4dce-bc65-5a5be31b8e2a';

export const DEMO_TENANT_ID = '40fe0dc8-3062-4677-bf41-882c9a62e995';
export const TRIAL_TENANT_ID = '2b154ce8-4eb4-4e0c-8d54-0771c74fc7c3';

export const CS_TENANT_ID = 'c43b6754-948b-4b13-9769-9f07cc2bf2f5';

export const USER_ID = 'user_id';
export const USER_EMAIL = 'user_email';
export const USER_FULLNAME = 'user_fullname';
export const USER_ROLES = 'user_roles';
export const USER_TOKEN = 'user_token';

export const DEFAULT_PAGE_SIZE = 50;

export const FACILITATOR_ROLE_ID = 'f9643c34-ae41-4515-89d8-532ea166c9a9';
export const PRESENTER_ROLE_ID = '8542c25f-e67d-432c-8e1f-59d6fe04b7a2';
export const PANELIST_ROLE_ID = '217630cb-815b-456c-87e8-be3858015f41';

export const RESOURCE_TYPE_AGENDA = '75ac2502-46e1-11ec-998e-131cf48f79b5';
export const RESOURCE_TYPE_DRAFT_PRESENTATION_SLIDE = '7e4e4082-46e1-11ec-b7bb-93f20f46a08e';
export const RESOURCE_TYPE_FINAL_PRESENTATION_SLIDE = '839f1f02-46e1-11ec-80e3-4fdf70283f99';
export const RESOURCE_TYPE_GENERAL_RESOURCE = '87c046f6-46e1-11ec-9635-93338388b626';
export const RESOURCE_TYPE_URL = '8c3fe862-46e1-11ec-82a5-13a60ed940a6';
export const RESOURCE_TYPE_CASEFORM = 'af947882-46e1-11ec-91d7-e7f8bc8a9039';

export const TIME_ZONE_CENTRAL = 'America/Chicago';
export const TIME_ZONE_MOUNTAIN = 'America/Denver';
export const TIME_ZONE_EASTERN = 'America/New_York';
export const TIME_ZONE_PACIFIC = 'America/Los_Angeles';
