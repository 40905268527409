import { Box, TableCell } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from 'react';
import * as R from 'ramda';

export interface SortProperty {
  prop: string;
  order: 'asc' | 'desc' | undefined;
}

function customComparator<T>(a: T, b: T, prop: keyof T, order: 'asc' | 'desc' | undefined) {
  const getSortValue = (obj: T, key: keyof T): [number, number | string] => {
    const value = obj[key];

    if (value === null) return [1, 0];

    if (value === "Ongoing") return [2, 0];

    if (typeof value === 'number') return [3, value];

    if (typeof value === 'string') {
      const trimmedValue = value.trim().toLowerCase();
      const dateMatch = trimmedValue.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
      if (dateMatch) {
        const [, month, day, year] = dateMatch;
        return [4, parseInt(`${year}${month}${day}`, 10)];
      }
      return [5, trimmedValue];
    }

    return [6, ''];
  };

  const aValue = getSortValue(a, prop);
  const bValue = getSortValue(b, prop);

  if (aValue[0] !== bValue[0]) {
    return order === 'asc' ? aValue[0] - bValue[0] : bValue[0] - aValue[0];
  }

  if (aValue[1] !== bValue[1]) {
    if (typeof aValue[1] === 'number' && typeof bValue[1] === 'number') {
      return order === 'asc' ? aValue[1] - bValue[1] : bValue[1] - aValue[1];
    }

    if (typeof aValue[1] === 'string' && typeof bValue[1] === 'string') {
      return order === 'asc' ? aValue[1].localeCompare(bValue[1]) : bValue[1].localeCompare(aValue[1]);
    }
  }

  return 0;
}

export function complexSort<T>(data: T[], sortProp?: SortProperty): T[] {
  if (!sortProp || !sortProp.order) {
    return data;
  }
  return [...data].sort((a, b) => customComparator(a, b, sortProp.prop as keyof T, sortProp.order));
}

export function sortBy<T>(data: T[], sortProp?: SortProperty) {
  if (sortProp && sortProp.order) {
    // console.log(sortProp.prop, R.prop(sortProp.prop));
    const sortBy =
      sortProp.order === 'asc'
        ? R.sortBy(R.prop(sortProp.prop))
        : R.sort(R.descend<any>(R.prop(sortProp.prop)));
    return sortBy(data);
  }
  return data;
} 


interface SortableTableColumnProps {
  currentSort?: string;
  sortProperty: string;
  headerText: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  onSort?: (sortProp: SortProperty) => void;
  order?: 'asc' | 'desc' | undefined
}

const SortableTableColumn = ({
  currentSort,
  sortProperty,
  headerText,
  align,
  onSort,
  order,
}: SortableTableColumnProps) => {
  const [sort, setSort] = useState<'asc' | 'desc' | undefined>(order);

  useEffect(() => {
    if (currentSort !== sortProperty) {
      setSort(undefined);
    }
  }, [currentSort, sortProperty, setSort]);

  return (
    <>
      <TableCell
        align={align}
        sx={{
          color: 'primary.main',
          cursor: 'pointer',
          userSelect: 'none',
          msUserSelect: 'none',
          MozUserSelect: 'none',
          WebkitUserSelect: 'none',
          whiteSpace: 'nowrap'
        }}
        onClick={() => {
          const newOrder = !sort
            ? 'asc'
            : sort === 'asc'
            ? 'desc'
            : undefined;
          setSort(newOrder);
          onSort?.({ prop: sortProperty, order: newOrder });
        }}
      >
        {headerText}
        {currentSort === sortProperty && sort === 'asc' ? (
          <KeyboardArrowDownIcon
            sx={{ fontSize: '16px', verticalAlign: 'middle' }}
          />
        ) : currentSort === sortProperty && sort === 'desc' ? (
          <KeyboardArrowUpIcon
            sx={{ fontSize: '16px', verticalAlign: 'middle' }}
          />
        ) : (
          <Box
            sx={{
              display: 'inline-block',
              width: '16px',
              height: '16px',
            }}
          />
        )}
      </TableCell>
    </>
  );
};

export default SortableTableColumn;
