import { gql } from '@apollo/client';

export const initiateForgotPassword_Name = 'InitiateForgotPassword';
export const initiateForgotPassword_Gql = gql`
  mutation InitiateForgotPassword($forgotPassword: InitiateForgotPasswordInput!) {
    initiateForgotPassword(forgotPassword: $forgotPassword) {
      isSuccess
      message
    }
  }
`;
