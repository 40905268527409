import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { getPartnersQuery_Gql } from '../../../gql/partner/getPartnersQuery';
import {
  GetAllPartners_getPartners,
  GetAllPartners,
  UpdateStaffUser,
  UpdateStaffUserVariables,
  AddOrUpdateStaffUserInput,
} from '../../../models/GeneratedModels';
import { updateStaffUser_Gql } from '../../../gql/user/updateStaffUser';
import { SearchUsersByName_searchUsersByName } from '../../../models/GeneratedModels';

import ProgressIndicator from '../../global/ProgressIndicator';
import AddEditStaffAccount from './AddEditStaffAccount';
import EditIcon from '@mui/icons-material/Edit';

interface EditStaffAccountProps {
  user: SearchUsersByName_searchUsersByName;
}

const EditStaffAccount = ({ user }: EditStaffAccountProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [staffUser, setStaffUser] = useState<AddOrUpdateStaffUserInput>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    partnerId: null,
  });

  useEffect(() => {
    setStaffUser({
      id: user.id,
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      email: user.email ?? '',
      partnerId: user.partnerId ?? '',
    });
  }, [user]);

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const { data, loading: partnersLoading } = useQuery<GetAllPartners>(getPartnersQuery_Gql);

  const partners: GetAllPartners_getPartners[] = data?.getPartners ?? [];

  const hasValue = (value: string) => value.trim().length > 0;

  const [updateStaffAccount, { loading: updateStaffLoading }] = useMutation<UpdateStaffUser, UpdateStaffUserVariables>(
    updateStaffUser_Gql
  );

  const resetForm = () => {
    setErrorText('');
    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);

    setStaffUser({
      id: user.id,
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      email: user.email ?? '',
      partnerId: user.partnerId ?? '',
    });
  };

  const formIsValid = (staffAccount: AddOrUpdateStaffUserInput): boolean => {
    let isValid = true;
    setErrorText('');

    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);

    if (!hasValue(staffAccount.firstName)) {
      setFirstNameError(true);
      isValid = false;
    }

    if (!hasValue(staffAccount.lastName)) {
      setLastNameError(true);
      isValid = false;
    }

    if (!hasValue(staffAccount.email)) {
      setEmailError(true);
      isValid = false;
    }

    if (!isValid) {
      setErrorText('Please enter the required fields');
    }

    return isValid;
  };

  const addNewStaffUser = async (staffAccount: AddOrUpdateStaffUserInput) => {
    setStaffUser(staffAccount);

    if (formIsValid(staffAccount) === true) {
      try {
        await updateStaffAccount({
          variables: {
            user: staffAccount,
          },
        });

        resetForm();
        setDrawerOpen(false);
      } catch (error: any) {
        setErrorText(error.graphQLErrors[0].message);
      }
    }
  };

  const onCancelClick = () => {
    resetForm();
    setDrawerOpen(false);
  };

  return (
    <>
      <ProgressIndicator isOpen={partnersLoading} title="Loading..." />

      <ProgressIndicator isOpen={updateStaffLoading} title="Saving..." />

      <AddEditStaffAccount
        drawerTrigger={
          <EditIcon onClick={() => setDrawerOpen(true)} color="primary" sx={{ fontSize: '16px', cursor: 'pointer' }} />
        }
        user={staffUser}
        saveUserClick={addNewStaffUser}
        onCancelClick={onCancelClick}
        partners={partners}
        isOpen={drawerOpen}
        errors={{
          errorText,
          firstNameError,
          lastNameError,
          emailError,
        }}
      />
    </>
  );
};

export default EditStaffAccount;
