import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import DrawerWithTrigger from '../../global/DrawerWithTrigger';
import LockIcon from '@mui/icons-material/Lock';
import { Grid, FormGroup, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import { useAuth } from '../../../hooks/useAuth';
import {
  GetRolesForUser,
  GetRolesForUserVariables,
  UpdateUserRoles,
  UpdateUserRolesVariables,
} from '../../../models/GeneratedModels';
import { getRolesForUser_Gql } from '../../../gql/user/getRolesForUser';
import { updateRolesForUser_Gql } from '../../../gql/user/updateRolesForUser';

const R = require('ramda');

const AdministratorRoleId = '39df8f29-88d7-4afd-be7c-c743b853574b';
const DevelopmentRoleId = '3eeb3971-69b1-4411-87b7-1936f84a1ef3';
const EchoStaffRoleId = '939906d5-f90d-43a5-84ba-4f6ac084d69f';
const EvaluationRoleId = 'b20e0a41-d07e-4b71-9936-5a865a83423c';
const ImplementationRoleId = '123173c8-caed-4688-9cab-fd1dd8d4f865';
const PartnerRoleId = 'f5829ef9-83df-4f13-bda1-c966e36f1385';
const RegistrantRoleId = 'cf068bd1-9694-4c2a-8cb2-5f224be229ad';

interface UserPermissionList {
  isAdmin: boolean;
  isEchoStaff: boolean;
  isDevelopment: boolean;
  isEvaluation: boolean;
  isImplementation: boolean;
  isPartner: boolean;
  isRegistrant: boolean;
}

interface ManageUserPermissionsProps {
  userId: string;
  firstName: string;
  lastName: string;
}

const ManageUserPermissions = ({ userId, firstName, lastName }: ManageUserPermissionsProps) => {
  const { user } = useAuth();

  const [drawerOpen, setIsDrawerOpen] = useState(false);
  const [roleIds, setRoleIds] = useState<string>('');
  const [permissions, setPermissions] = useState<UserPermissionList>({
    isAdmin: false,
    isEchoStaff: false,
    isDevelopment: false,
    isEvaluation: false,
    isImplementation: false,
    isPartner: false,
    isRegistrant: false,
  });

  const { loading, data } = useQuery<GetRolesForUser, GetRolesForUserVariables>(getRolesForUser_Gql, {
    variables: {
      userId,
    },
  });

  useEffect(() => {
    if (loading || !data) return;

    const userRoles = data?.getRolesForUser ?? [];

    if (userRoles.length > 0) {
      setRoleIds(userRoles.map((r) => r.id).join(','));
    }

    setPermissions({
      isAdmin: userRoles.some((x) => x.id === AdministratorRoleId),
      isDevelopment: userRoles.some((x) => x.id === DevelopmentRoleId),
      isEchoStaff: userRoles.some((x) => x.id === EchoStaffRoleId),
      isEvaluation: userRoles.some((x) => x.id === EvaluationRoleId),
      isImplementation: userRoles.some((x) => x.id === ImplementationRoleId),
      isPartner: userRoles.some((x) => x.id === PartnerRoleId),
      isRegistrant: userRoles.some((x) => x.id === RegistrantRoleId),
    });
  }, [loading, data]);

  const [saveRoles] = useMutation<UpdateUserRoles, UpdateUserRolesVariables>(updateRolesForUser_Gql);

  const onSaveClick = () => {
    if (roleIds.length === 0) return;

    const roleList = roleIds.split(',');
    if (roleList[0] === '') roleList.splice(0, 1);

    saveRoles({
      variables: {
        userId,
        roleIds: roleList,
      },
    });

    setIsDrawerOpen(false);
  };

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, value } = e.target;

    const selectedRoles = roleIds.split(',');

    if (checked) {
      selectedRoles.push(value);
    } else {
      const index = R.indexOf(value, selectedRoles);
      if (index >= 0) {
        selectedRoles.splice(index, 1);
      }
    }

    setRoleIds(selectedRoles.join(','));

    setPermissions({
      ...permissions,
      [name]: checked,
    });
  };

  const isSubRoleDisabled = !(permissions.isEchoStaff || permissions.isPartner);

  return (
    <>
      <DrawerWithTrigger
        trigger={
          <Tooltip title="Manage Permissions">
            <LockIcon
              color="primary"
              sx={{ ml: 1, fontSize: '16px', cursor: 'pointer' }}
              onClick={() => setIsDrawerOpen(true)}
            />
          </Tooltip>
        }
        width={500}
        title={`Manage Permissions - ${firstName} ${lastName}`}
        showSave={true}
        onSaveClick={onSaveClick}
        showCancel={true}
        isOpen={drawerOpen}
      >
        <Grid container>
          <Grid item xs={6}>
            <FormGroup>
              {(user?.isAlbatross || user?.isAdmin) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      value={AdministratorRoleId}
                      checked={permissions.isAdmin}
                      onChange={onCheckboxChange}
                      name="isAdmin"
                    />
                  }
                  label="Administrator"
                />
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    value={EchoStaffRoleId}
                    checked={permissions.isEchoStaff}
                    onChange={onCheckboxChange}
                    name="isEchoStaff"
                  />
                }
                label="ECHOStaff"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={PartnerRoleId}
                    checked={permissions.isPartner}
                    onChange={onCheckboxChange}
                    name="isPartner"
                  />
                }
                label="Partner"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={RegistrantRoleId}
                    checked={permissions.isRegistrant}
                    onChange={onCheckboxChange}
                    name="isRegistrant"
                  />
                }
                label="Learner"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={DevelopmentRoleId}
                    checked={permissions.isDevelopment}
                    onChange={onCheckboxChange}
                    name="isDevelopment"
                    disabled={isSubRoleDisabled}
                  />
                }
                label="Development"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={ImplementationRoleId}
                    checked={permissions.isImplementation}
                    onChange={onCheckboxChange}
                    name="isImplementation"
                    disabled={isSubRoleDisabled}
                  />
                }
                label="Implementation"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={EvaluationRoleId}
                    checked={permissions.isEvaluation}
                    onChange={onCheckboxChange}
                    name="isEvaluation"
                    disabled={isSubRoleDisabled}
                  />
                }
                label="Evaluation"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </DrawerWithTrigger>
    </>
  );
};

export default ManageUserPermissions;
