import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import EmailIcon from '@mui/icons-material/Email';

interface ViewAccountLinkProps {
  userId: string;
  name: string;
  email?: string;
}

const ViewAccountLink = ({ userId, name, email }: ViewAccountLinkProps) => {
  return (
    <div>
      {/* <Link component={RouterLink} to={`/admin/account/detail/${userId}`} target="_blank"> */}
      <Link component={RouterLink} to={`/admin/account/detail/${userId}`}>
        {name}
      </Link>

      {email && email.length > 0 && (
        <Link href={`mailto:${email}`}>
          <EmailIcon color="primary" sx={{ ml: 1, fontSize: '16px', verticalAlign: 'middle' }} />
        </Link>
      )}
    </div>
  );
};

export default ViewAccountLink;
