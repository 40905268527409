import { gql } from '@apollo/client';

export const getNextStepsEmailContent_Name = 'GetNextStepsEmailContent';
export const getNextStepsEmailContent_Gql = gql`
  query GetNextStepsEmailContent($hubMemberCohortId: String!) {
    getNextStepsEmailContent(hubMemberCohortId: $hubMemberCohortId) {
      subject
      text
    }
  }
`;
