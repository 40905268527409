import { gql } from '@apollo/client';

export const getAccountLookupsQuery_Name = 'GetAccountLookups';
export const getAccountLookupsQuery_Gql = gql`
  query GetAccountLookups {
    getAccountLookups {
      credentials {
        id
        name
        isOther
      }
      professions {
        id
        name
        isOther
        secondLevelProfessions {
          id
          name
          isOther
          thirdLevelProfessions {
            id
            name
            isOther
          }
        }
      }
      ethnicities {
        id
        name
        isOther
      }
      counties {
        id
        name
      }
      organizationDescriptions {
        id
        name
      }
      sexs {
        id
        name
        sortOrder
      }
      marketingSources {
        id
        name
        sortOrder
      }
      roleDescriptions {
        id
        name
      }
      workSettings {
        id
        name
        isOther
      }
    }
  }
`;
