import React, { ReactNode, useState } from 'react';
import { useMutation } from '@apollo/client';
import { SelectChangeEvent, Stack, Alert, Checkbox, FormControlLabel, TextField } from '@mui/material';

import ProgressIndicator from '../../../global/ProgressIndicator';
import DrawerWithTrigger from '../../../global/DrawerWithTrigger';
import HubTeamSessionList from './HubTeamSessionList';

import {
  GetSessionsByCohort_getSessionsByCohort,
  GetMembersByCohort_getMembersByCohort,
  UpdateHubMemberCohortInput,
  UpdateHubMemberCohortAndSessionsVariables,
} from '../../../../models/GeneratedModels';
import { updateHubMemberCohortAndSessions_Gql } from '../../../../gql/hubTeam/updateHubMemberCohortAndSessions';
import { getMembersByCohort_Name } from '../../../../gql/hubTeam/getMembersByCohort';
import { combineSessionRoles } from '../HubTeam.helpers';

interface EditHubAccountProps {
  trigger: ReactNode;
  drawerTitle: string;
  cohortMember: GetMembersByCohort_getMembersByCohort;
  sessions: GetSessionsByCohort_getSessionsByCohort[];
}

const EditHubMemberCohort = ({ trigger, drawerTitle, cohortMember, sessions }: EditHubAccountProps) => {
  const [hubTeamMember, setHubTeamMember] = useState<GetMembersByCohort_getMembersByCohort>(cohortMember);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  const [updateHubTeamMember, { loading, error }] = useMutation<void, UpdateHubMemberCohortAndSessionsVariables>(
    updateHubMemberCohortAndSessions_Gql,
    {
      refetchQueries: [getMembersByCohort_Name],
    }
  );

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, selectName: string) => {
    const name = e.target.name;
    const checked = e.target.checked;
    setHubTeamMember({
      ...hubTeamMember,
      [name]: checked,
      [selectName]: sessions.map(s => s.id)
    });
  };

  const onSendEmailCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setSendEmail(checked);
  };

  const onMultiSelectChange = (e: SelectChangeEvent<string[]>) => {
    const name = e.target.name;
    const value = e.target.value;
    setHubTeamMember({
      ...hubTeamMember,
      [name]: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const updateHubMember = async () => {
    const sessions = combineSessionRoles(
      hubTeamMember.id,
      hubTeamMember.sessionsAsFacilitator,
      hubTeamMember.sessionsAsPresenter,
      hubTeamMember.sessionsAsPanelist
    );

    const hubMemberCohortInput: UpdateHubMemberCohortInput = {
      id: hubTeamMember.id,
      facilitateAll: hubTeamMember.facilitateAll,
      presentAll: hubTeamMember.presentAll,
      panelistAll: hubTeamMember.panelistAll,
      credentials: hubTeamMember.credentials,
      hubMemberCohortSessions: sessions,
      sendEmails: sendEmail,
    };

    await updateHubTeamMember({
      variables: {
        hubMemberCohort: hubMemberCohortInput,
      },
    });

    setDrawerOpen(false);
  };

  return (
    <>
      {error && <Alert severity="error">{error.graphQLErrors[0].message}</Alert>}

      <ProgressIndicator isOpen={loading} title="Saving" />

      <DrawerWithTrigger
        trigger={trigger}
        width={550}
        title={drawerTitle}
        showSave={true}
        showCancel={true}
        isOpen={drawerOpen}
        onSaveClick={updateHubMember}
      >
        <Stack spacing={2} textAlign='left' marginBottom={4}>
          <FormControlLabel
            sx={{ mb: 1 }}
            control={
              <Checkbox color="primary" checked={sendEmail} onChange={onSendEmailCheckboxChange} name="sendEmails" />
            }
            label={`Send Calendar Invites to ${hubTeamMember?.user?.firstName}`}
          />

          <TextField
            fullWidth
            size='small'
            label="HUB Team Credentials/Title"
            name="credentials"
            value={hubTeamMember.credentials}
            onChange={(e) => setHubTeamMember({
              ...hubTeamMember,
              credentials: e.target.value
            })}
            color="warning"
          />

          <HubTeamSessionList
            sessions={sessions}
            facilitateAll={hubTeamMember.facilitateAll ?? false}
            selectedFacilitatorSessions={hubTeamMember.sessionsAsFacilitator ?? []}
            presentAll={hubTeamMember.presentAll ?? false}
            selectedPresenterSessions={hubTeamMember.sessionsAsPresenter ?? []}
            panelistAll={hubTeamMember.panelistAll ?? false}
            selectedPanelistSessions={hubTeamMember.sessionsAsPanelist ?? []}
            onCheckboxChange={onCheckboxChange}
            onMultiSelectChange={onMultiSelectChange}
          />
        </Stack>
      </DrawerWithTrigger>
    </>
  );
};

export default EditHubMemberCohort;
