import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import PageHeader from '../../global/PageHeader';
import TabPanel from '../program/cohort/TabPanel';
import EditCohortDetails from './EditCohortDetails';
import CohortNotes from './CohortNotes';
import { GetCohortById_getCohortById } from '../../../models/GeneratedModels';

interface EditCohortProps {
  cohort: GetCohortById_getCohortById;
}

const EditCohort = ({ cohort }: EditCohortProps) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [cohortDetail, setCohortDetail] = useState<GetCohortById_getCohortById>(cohort);

  useEffect(() => {
    setCohortDetail(cohort);
  }, [cohort]);

  const a11yProps = (index: any) => {
    return {
      id: `edit-cohort-tab-${index}`,
      'aria-controls': `edit-cohort-tabpanel-${index}`,
    };
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <PageHeader title="Edit Cohort" />

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="edit cohort tabs"
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Cohort Details" {...a11yProps(0)} />
        <Tab label="Notes" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <EditCohortDetails cohort={cohortDetail} />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <CohortNotes cohort={cohortDetail} />
      </TabPanel>
    </>
  );
};

export default EditCohort;
