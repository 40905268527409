import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

interface AdminSessionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
}

const AdminSession = ({ isOpen, onClose, onContinue }: AdminSessionModalProps) => {
  return (
    <Dialog 
      open={isOpen} 
      onClose={() => {}} // This prevents closing on backdrop click or escape key
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          width: '75%',
          maxHeight: '75vh',
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)', // 85% opacity black overlay
          },
        },
      }}
      sx={{
        zIndex: 9999, // High z-index to ensure it's on top
      }}
    >
      <DialogTitle>Echo Connect - Admins</DialogTitle>
      <DialogContent>
        <Typography>You have the ability to maintain your Admin session for a length of 6 hrs at a time</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Extend my Admin Session</Button>
        <Button onClick={onContinue} color="primary">Continue</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminSession;
