import { gql } from '@apollo/client';

export const searchUserByName_Name = 'SearchUsersByName';
export const searchUserByName_Gql = gql`
  query SearchUsersByName($firstName: String, $lastName: String) {
    searchUsersByName(firstName: $firstName, lastName: $lastName) {
      id
      firstName
      lastName
      email
      userCredentialsForDisplay
      organization
      userRolesForDisplay
      partnerId
    }
  }
`;
