import { gql } from '@apollo/client';

export const getEmailTemplatesForProgram_Name = 'GetEmailTemplatesForProgram';
export const getEmailTemplatesForProgram_Gql = gql`
  query GetEmailTemplatesForProgram($programId: String!) {
    getEmailTemplatesForProgram(programId: $programId) {
      emailCategory
      id
      name
      subject
      template
    }
  }
`;
