import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import moment from 'moment-timezone';

import { getTenantIdForDomain } from 'helpers/tenantHelpers';
import { TIME_ZONE_MOUNTAIN, TIME_ZONE_EASTERN, TIME_ZONE_PACIFIC, TIME_ZONE_CENTRAL } from 'utils/constants';
import {
  COLORADO_TENANT_ID,
  OREGON_TENANT_ID,
  DARTMOUTH_TENANT_ID,
  VIRGINIA_TENANT_ID,
  CS_TENANT_ID,
  DEMO_TENANT_ID,
} from 'utils/constants';

dayjs.extend(utc);
dayjs.extend(tz);

export function convertDateToTimeZoneFromUtc(
  date: Date | null,
  includeTime: boolean = false,
  showTimeZone: boolean = false,
  isSessionEnd: boolean = false,
  dateFormat: boolean = false,
): string {
  if (!date) return '';

  const tenantId = getTenantIdForDomain();
  const timeZone = getTimeZoneForTenant(tenantId);
  const abbreviation = getTimeZoneAbbreviation(timeZone);

//  console.log(`Timezone: ${timeZone}`);
//  if (includeTime) { console.log(`date: ${date} ---- ${dayjs(date)}`); console.log(convertUtcToTimeZoneWithTime(dayjs(date), timeZone)); }
//  if (!includeTime) { console.log(`date: ${date} ---- ${dayjs(date)}`); convertUtcToTimeZone(dayjs(date), timeZone); }
  
  const formattedTimezoneDate = includeTime
    ? convertUtcToTimeZoneWithTime(dayjs(date), timeZone, isSessionEnd, dateFormat)
    : convertUtcToTimeZone(dayjs(date), timeZone, isSessionEnd, dateFormat);

  return showTimeZone ? `${formattedTimezoneDate} ${abbreviation}` : formattedTimezoneDate;
}

export function convertDateToTimeFromUtc(
  date: Date | null,
  includeDate: boolean = false,
  showTimeZone: boolean = false
): string {
  if (!date) return '';

  const tenantId = getTenantIdForDomain();
  const timeZone = getTimeZoneForTenant(tenantId);
  const abbreviation = getTimeZoneAbbreviation(timeZone);

  const formattedDate = includeDate
    ? convertUtcToTimeZoneWithTime(dayjs(date), timeZone, false)
    : convertUtcToTimeZoneWithOnlyTime(dayjs(date), timeZone);

  return showTimeZone ? `${formattedDate} ${abbreviation}` : formattedDate;
}

export function convertDateTimeToTimeZoneFromUtc(
  date: Date | null,
  showTimeZone: boolean = false
): string {
  if (!date) return '';

  const tenantId = getTenantIdForDomain();
  const timeZone = getTimeZoneForTenant(tenantId);
  const abbreviation = getTimeZoneAbbreviation(timeZone);

  const formattedDate = dayjs(date, timeZone).format('MMMM D, YYYY h:mm A');

  return showTimeZone ? `${formattedDate} ${abbreviation}` : formattedDate;
}

export function getTimeZoneForTenant(tenantId: string) {
  if (tenantId === COLORADO_TENANT_ID) return TIME_ZONE_MOUNTAIN;
  if (tenantId === CS_TENANT_ID) return TIME_ZONE_MOUNTAIN;
  if (tenantId === OREGON_TENANT_ID) return TIME_ZONE_PACIFIC;
  if (tenantId === DARTMOUTH_TENANT_ID) return TIME_ZONE_EASTERN;
  if (tenantId === VIRGINIA_TENANT_ID) return TIME_ZONE_EASTERN;
  
  if (tenantId === DEMO_TENANT_ID) return TIME_ZONE_CENTRAL;

  return '';
}

export function getTimeZoneAbbreviation(timeZone: string): string {
  if (timeZone === TIME_ZONE_MOUNTAIN) return 'MT';
  if (timeZone === TIME_ZONE_PACIFIC) return 'PT';
  if (timeZone === TIME_ZONE_EASTERN) return 'ET';
  if (timeZone === TIME_ZONE_CENTRAL) return 'CT';

  return '';
}

export function convertMinutesToHours(minutes: number): string {
  const isNegative = minutes < 0;
  const absoluteMinutes = Math.abs(minutes);
  const hours = absoluteMinutes / 60;
  let hoursToReturn = isNegative ? "-0" : "0";
  return `${hoursToReturn}${hours}00`;
}

export function splitDateTime(dateString: string): [string, string] {
  const dateObject = new Date(dateString);

  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();
  const hours = dateObject.getUTCHours();
  const minutes = dateObject.getUTCMinutes();
  const seconds = dateObject.getUTCSeconds();

  const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;

  return [formattedDate, formattedTime];
}

function convertUtcToTimeZoneWithOnlyTime(utcDate: dayjs.Dayjs, timeZone: string) {
  return utcDate.utc().format('h:mm A');
  // return utcDate.tz(timeZone).format('h:mm A');
}

function convertUtcToTimeZoneWithTime(utcDate: dayjs.Dayjs, timeZone: string, isSessionEnd: boolean, dateFormat: boolean = false,) {
  /*
  // Convert to JavaScript Date type
  const jsDate = utcDate.toDate();
  // Convert to Mountain Time and format
  const mtTime = jsDate.toLocaleString('en-US', { timeZone: timeZone }).replace(/,/g, "");
  // Format in 'MMMM D, YYYY h:mm A' format
  return dayjs(mtTime).format('MMMM D, YYYY h:mm A');
  */

  const convertedUTCDate = utcDate.utc();
  const convertedUTCEndDate = utcDate.utc();

  if (isSessionEnd && dateFormat) {
    return convertedUTCEndDate.format('MM/DD/YYYY h:mm A');
  } else if (isSessionEnd) {
    return convertedUTCEndDate.format('MMMM D, YYYY h:mm A');
  } else if (!isSessionEnd && dateFormat) {
    return convertedUTCDate.format('MM/DD/YYYY h:mm A');
  }
  return utcDate.utc().format('MMMM D, YYYY h:mm A');
  // return utcDate.tz(timeZone).format('MMMM D, YYYY h:mm A');
}

function convertUtcToTimeZone(utcDate: dayjs.Dayjs, timeZone: string, isSessionEnd: boolean, dateFormat: boolean = false,) {
  /*
  // Convert to JavaScript Date type
  const jsDate = utcDate.toDate();
  // Convert to Mountain Time and format
  const mtTime = jsDate.toLocaleString('en-US', { timeZone: timeZone }).replace(/,/g, "");
  // Format in 'MMMM D, YYYY h:mm A' format
  return dayjs(mtTime).format('MMMM D, YYYY');
  */
  const convertedUTCDate = utcDate.utc();
  const convertedUTCEndDate = utcDate.utc();

  if (isSessionEnd && dateFormat) {
    return convertedUTCEndDate.format('MM/DD/YYYY');
  } else if (isSessionEnd) {
    return convertedUTCEndDate.format('MMMM D, YYYY');
  } else if (!isSessionEnd && dateFormat) {
    return convertedUTCDate.format('MM/DD/YYYY');
  }

  return convertedUTCDate.format('MMMM D, YYYY');
  //return utcDate.tz(timeZone).format('MMMM D, YYYY');
}

export function displayFormattedTime(dateTimestamp: string, tenant: string) {
  /*
  // Convert to JavaScript Date type
  const jsDate = utcDate.toDate();
  // Convert to Mountain Time and format
  const mtTime = jsDate.toLocaleString('en-US', { timeZone: timeZone }).replace(/,/g, "");
  // Format in 'MMMM D, YYYY h:mm A' format
  return dayjs(mtTime).format('MMMM D, YYYY h:mm A');
  */

  const dtTimestamp = moment(dateTimestamp);
  const tenantTimezone = getTimeZoneForTenant(tenant);
  const formattedDate = dtTimestamp.tz(tenantTimezone).format('YYYY-MM-DD HH:mm A');

  return formattedDate;
}

export const getTodayStartDateStamp = (): string => {
  const tenantId = getTenantIdForDomain();
  const tenantTimezone = getTimeZoneForTenant(tenantId);
  const tenantTime = `${moment.tz(tenantTimezone).format('YYYY-MM-DD')} 00:00:00`;
  return `${moment.tz(tenantTimezone).format('YYYY-MM-DD')} 00:00:00`;
};

export const getTodayEndDateStamp = (): string => {
  const tenantId = getTenantIdForDomain();
  const tenantTimezone = getTimeZoneForTenant(tenantId);
  const tenantTime = `${moment.tz(tenantTimezone).format('YYYY-MM-DD')} 23:59:59`;
  return `${moment.tz(tenantTimezone).format('YYYY-MM-DD')} 23:59:59`;
};

export const getDateStamp = (): Date => {
  
  const tenantId = getTenantIdForDomain();
  const tenantTimezone = getTimeZoneForTenant(tenantId);

  const dateWithoutTime = moment.tz(((moment.tz(tenantTimezone)).toISOString().slice(0, 10)), tenantTimezone);
  const dateString = `${dateWithoutTime.format('YYYY-MM-DD')}T00:00:00.000Z`;
  
  return new Date(dateString);
}
