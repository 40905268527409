import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HomeTitleAndBody from './HomeTitleAndBody';
import { Container } from '@mui/material';

const CSHome = () => {
  return (
    <Container maxWidth="xl" sx={{ p: 14, mb: 2 }}>
      <HomeTitleAndBody title="Join In and Help Make Health Knowledge ECHO">
        <Stack spacing={2}>
          <Typography variant="subtitle1">Production Testing Tenant for Campbell Software, LLC</Typography>
        </Stack>
      </HomeTitleAndBody>
    </Container>    
  );
};

export default CSHome;
