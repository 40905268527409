import { useMutation } from '@apollo/client';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import Person from '@mui/icons-material/Person';
import PersonOff from '@mui/icons-material/PersonOff';

import ProgressIndicator from 'components/global/ProgressIndicator';
import { getRegistrantsForCohortQuery_Name } from 'gql/cohortRegistration/getRegistrantsForCohortQuery';
import { toggleCohortRegistrationActiveMutation_Gql } from 'gql/cohortRegistration/toggleCohortRegistrationActiveMutation';
import { ToggleCohortRegistrationActiveInput } from 'models/GeneratedModels';

interface ActiveInactiveIconsProps {
  registrationInput: ToggleCohortRegistrationActiveInput;
}

const ActiveInactiveIcons = ({ registrationInput }: ActiveInactiveIconsProps) => {
  const [approveUser, { loading, error }] = useMutation(toggleCohortRegistrationActiveMutation_Gql, {
    refetchQueries: [getRegistrantsForCohortQuery_Name],
  });

  const updateActiveStatus = async (isActive: boolean) => {
    try {
      await approveUser({
        variables: {
          cohortRegistration: {
            id: registrationInput.id,
            decisionUserId: registrationInput.decisionUserId,
            isActive,
          },
        },
      });
    } catch {}
  };

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Saving..." />

      {error && <Alert severity="error">{error.graphQLErrors[0].message}</Alert>}

      {registrationInput.isActive && (
        <Tooltip title="Mark Registrant Inactive">
          <Person
            onClick={() => updateActiveStatus(false)}
            sx={{ color: 'green', mr: 1, cursor: 'pointer' }}
          />
        </Tooltip>
      )}

      {!registrationInput.isActive && (
        <Tooltip title="Mark Registrant Active">
          <PersonOff
            onClick={() => updateActiveStatus(true)}
            sx={{ color: 'red', mr: 1, cursor: 'pointer' }}
          />
        </Tooltip>
      )}
    </>
  );
};

export default ActiveInactiveIcons;
