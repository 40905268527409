import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { HubTeamListProps } from '../../cohort/Cohort.interfaces';
import Label from './Label';
import ViewAccountLink from '../../Account/ViewAccountLink';

const HubTeamList = ({
  development,
  implementation,
  evaluation,
  developmentSupportUser,
  implementationSupportUser,
  evaluationSupportUser
}: HubTeamListProps) => {
  return (
    <>
      <Label text="ECHO Team Leads" />
      <Table size="small" sx={{ marginBottom: '10px' }}>
        <TableBody>
          <TableRow>
            <TableCell>Development</TableCell>
            <TableCell align="right">
              {development && (
                <ViewAccountLink
                  userId={development.id}
                  name={`${development.firstName} ${development.lastName}`}
                  email={development.email}
                />
              )}
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>Implementation</TableCell>
            <TableCell align="right">
              {implementation && (
                <ViewAccountLink
                  userId={implementation.id}
                  name={`${implementation.firstName} ${implementation.lastName}`}
                  email={implementation.email}
                />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Evaluation</TableCell>
            <TableCell align="right">
              {evaluation && (
                <ViewAccountLink
                  userId={evaluation.id}
                  name={`${evaluation.firstName} ${evaluation.lastName}`}
                  email={evaluation.email}
                />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Label text='ECHO Support Team' />
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>Development</TableCell>
            <TableCell align="right">
              {developmentSupportUser?.map((user, i) => {
                if (i === 0) {
                  return `${user?.firstName} ${user?.lastName}`
                }

                return `, ${user?.firstName} ${user?.lastName}`
              })}
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>Implementation</TableCell>
            <TableCell align="right">
              {implementationSupportUser?.map((user, i) => {
                if (i === 0) {
                  return `${user?.firstName} ${user?.lastName}`
                }

                return `, ${user?.firstName} ${user?.lastName}`
              })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Evaluation</TableCell>
            <TableCell align="right">
              {evaluationSupportUser?.map((user, i) => {
                if (i === 0) {
                  return `${user?.firstName} ${user?.lastName}`
                }

                return `, ${user?.firstName} ${user?.lastName}`
              })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default HubTeamList;
