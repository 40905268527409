import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import SectionHeader from 'components/learner/SectionHeader/SectionHeader';
import StyledRouterLinkIcon from 'components/global/StyledRouterLinkIcon';
import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';

import {
  GetLearnerCohorts_getLearnerCohorts_currentCohorts,
  GetLearnerCohorts_getLearnerCohorts_pendingCohorts,
  GetLearnerCohorts_getLearnerCohorts_waitlistCohorts,
  GetLearnerCohorts_getLearnerCohorts_completedCohorts,
  GetCohortByHubMember_getCohortByHubMember,
} from 'models/GeneratedModels';

interface CohortListProps {
  title?: string;
  showNextSession?: boolean;
  showDetailLink: boolean;
  cohorts:
    | null
    | undefined
    | GetLearnerCohorts_getLearnerCohorts_currentCohorts[]
    | GetLearnerCohorts_getLearnerCohorts_pendingCohorts[]
    | GetLearnerCohorts_getLearnerCohorts_waitlistCohorts[]
    | GetLearnerCohorts_getLearnerCohorts_completedCohorts[]
    | GetCohortByHubMember_getCohortByHubMember[]
}

const MyEchoCohortList = ({ title, cohorts, showDetailLink, showNextSession = false }: CohortListProps) => {

  //TODO: For some reason, video link shows regardless
  // const showMeetingLink = (nextSession: string | null, meetingLink: string | null): boolean => {
  //   if (!nextSession || !meetingLink) return false;

  //   const today = dayjs().format('MM/DD/YYYY');
  //   const nextSessionDate = dayjs(nextSession).format('MM/DD/YYYY');

  //   const result = today === nextSessionDate;
  //   console.info(`result: ${JSON.stringify(result)}`);

  //   return result;
  // };

  return (
    <>
      {cohorts && cohorts.length > 0 && (
        <>
          {title && <SectionHeader>{title}</SectionHeader>}

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Program</TableCell>
                  <TableCell>Start</TableCell>
                  <TableCell>End</TableCell>
                  {showNextSession && <TableCell>Next Session</TableCell>}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cohorts &&
                  cohorts.map((c) => (
                    <TableRow key={c.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{c.externalLabel || ''}</TableCell>
                      <TableCell>{convertDateToTimeZoneFromUtc(c.startDate, false)}</TableCell>
                      <TableCell>{c.isOngoing ? 'Ongoing' : convertDateToTimeZoneFromUtc(c.endDate, false)}</TableCell>
                      {showNextSession && c.nextSessionDate !== null && <TableCell>{c.nextSessionDate}</TableCell>}
                      <TableCell align="left">
                        {/* {showMeetingLink(c.nextSessionDate, c.meetingLink) && (
                          <Tooltip title="Join Meeting">
                            <Link href={c?.meetingLink ?? ''} target="_blank">
                              <VideocamIcon />
                            </Link>
                          </Tooltip>
                        )} */}
                      </TableCell>
                      <TableCell>
                        {showDetailLink && (
                          <Tooltip title="Go to program details">
                            <StyledRouterLinkIcon to={`/program-dashboard/${c.id}`} icon={<DoubleArrowIcon />} />
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default MyEchoCohortList;
