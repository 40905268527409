import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';
import LinkIcon from '@mui/icons-material/Link';

import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';
import generateS3Link from 'helpers/s3Helpers';
import { RESOURCE_TYPE_URL } from 'utils/constants';
import { GetProgramById_getProgramById_resources } from 'models/GeneratedModels';

interface LatestResourcesProps {
  programId: string;
  resources: GetProgramById_getProgramById_resources[] | null | undefined;
}

const LatestResources = ({ programId, resources }: LatestResourcesProps) => {
  return (
    <>
      <Typography variant="h6">Latest Resources</Typography>

      <List>
        {resources &&
          resources.map((r) => (
            <ListItem key={r.createdAt}>
              {r.resourceTypeId === RESOURCE_TYPE_URL ? (
                <UrlResourceItem
                  displayName={r.displayName}
                  name={r.name}
                  dateAdded={convertDateToTimeZoneFromUtc(r.createdAt)}
                  url={r.url ?? ''}
                />
              ) : (
                <FileResourceItem
                  displayName={r.displayName}
                  name={r.name}
                  dateAdded={convertDateToTimeZoneFromUtc(r.createdAt)}
                  programId={programId}
                />
              )}
            </ListItem>
          ))}
      </List>
    </>
  );
};

interface ListItemProps {
  displayName: string | null;
  name: string;
  dateAdded: string;
  url?: string;
  programId?: string;
}

const UrlResourceItem = ({ dateAdded, displayName, name, url }: ListItemProps) => {
  return (
    <>
      <ListItemAvatar>
        <Avatar>
          <Link href={url} target="_blank">
            <LinkIcon sx={{ color: 'warning.main' }} />
          </Link>
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={
          <Link href={url} target="_blank">
            {displayName ?? name}
          </Link>
        }
        secondary={dateAdded}
      />
    </>
  );
};

const FileResourceItem = ({ dateAdded, displayName, name, programId }: ListItemProps) => {
  const url = generateS3Link(programId ?? '', name);

  return (
    <>
      <ListItemAvatar>
        <Avatar>
          <Link href={url}>
            <DownloadIcon sx={{ color: 'warning.main' }} />
          </Link>
        </Avatar>
      </ListItemAvatar>

      <ListItemText primary={<Link href={url}>{displayName ?? name}</Link>} secondary={dateAdded} />
    </>
  );
};

export default LatestResources;
