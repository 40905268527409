import { gql } from '@apollo/client';

export const updateAllRegistrationQuestions_Name = 'updateAllRegistrationQuestions';
export const updateAllRegistrationQuestions_Gql = gql`
  mutation updateAllRegistrationQuestions($cohortId: String!, $registrationQuestions: [RegistrationQuestionInput!]!) {
    updateAllRegistrationQuestions(cohortId: $cohortId, registrationQuestions: $registrationQuestions) {
      id
    }
  }
`;
