import { gql } from '@apollo/client';

export const archiveCohort_Name = 'ArchiveCohort';
export const archiveCohort_Gql = gql`
  mutation ArchiveCohort($cohort: ArchiveCohortInput!) {
    archiveCohort(cohort: $cohort) {
      id
    }
  }
`;
