import { useState } from 'react';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';

import { GetAllPartners_getPartners } from '../../../models/GeneratedModels';
import PartnerDrawer from './PartnerDrawer';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    iconLink: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      marginRight: 6,
    },
  })
);

interface EditPartnerIconWithDrawerProps {
  partner: GetAllPartners_getPartners;
}

const EditPartnerIconWithDrawer = ({ partner }: EditPartnerIconWithDrawerProps) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Tooltip title={`Edit ${partner.name}`}>
        <EditIcon className={classes.iconLink} onClick={() => setDrawerOpen(true)} />
      </Tooltip>

      <PartnerDrawer open={drawerOpen} partner={partner} onDrawerClose={closeDrawer} />
    </>
  );
};

export default EditPartnerIconWithDrawer;
