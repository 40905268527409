import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Link, Drawer, Box, Typography, Paper, Button, Tooltip } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    iconLink: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      marginRight: 6,
    },
    accountLink: {
      color: theme.palette.text.secondary,
    },
  })
);

interface PartnerAccountsIconWithDrawerProps {
  partnerName: string;
}

const PartnerAccountsIconWithDrawer = ({ partnerName }: PartnerAccountsIconWithDrawerProps) => {
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <Tooltip title={`View accounts for ${partnerName}`}>
        <PeopleIcon className={classes.iconLink} onClick={() => setDrawerOpen(true)} />
      </Tooltip>

      <Drawer variant="temporary" open={drawerOpen} anchor="right" onClose={() => setDrawerOpen(false)}>
        <Box sx={{ width: 700, textAlign: 'center' }}>
          <Typography variant="h6" color="primary">
            Partner Accounts
          </Typography>

          <TableContainer sx={{ mb: 3 }} component={Paper}>
            <Table sx={{ width: '100%' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <RouterLink className={classes.accountLink} to="/NotImplemented">
                      Doe, Jane
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <Link href="mailto:jane.doe@email.com">jane.doe@email.com</Link>
                  </TableCell>
                  <TableCell>303-555-1212</TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <RouterLink className={classes.accountLink} to="/NotImplemented">
                      Doe, Jane
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <Link href="mailto:jane.doe@email.com">jane.doe@email.com</Link>
                  </TableCell>
                  <TableCell>303-555-1212</TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <RouterLink className={classes.accountLink} to="/NotImplemented">
                      Doe, Jane
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <Link href="mailto:jane.doe@email.com">jane.doe@email.com</Link>
                  </TableCell>
                  <TableCell>303-555-1212</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Button onClick={() => setDrawerOpen(false)} sx={{ width: 125 }} variant="outlined" color="primary">
            Close
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default PartnerAccountsIconWithDrawer;
