import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import DashboardIcon from '@mui/icons-material/Dashboard';

import PageHeader from '../../components/global/PageHeader';
import ProgressIndicator from 'components/global/ProgressIndicator';

import AddProgramDrawer from '../../components/admin/program/AddProgramDrawer';
import { GetAllPrograms, GetAllPrograms_getAllPrograms } from '../../models/GeneratedModels';
import { getAllProgramsQuery_Gql } from '../../gql/program/getAllProgramsQuery';
import PermissionDeniedSplash from 'pages/PermissionDeniedSplash';
import { useAuth } from 'hooks/useAuth';

const Programs = () => {
  const { loading, data, error } = useQuery<GetAllPrograms>(getAllProgramsQuery_Gql, {
    fetchPolicy: 'no-cache',
  });

  const programs: GetAllPrograms_getAllPrograms[] = data?.getAllPrograms ?? [];
  
  const auth = useAuth();
  const isAuthorized = () => {
    return auth.user?.isAlbatross 
      || auth.user?.isAdmin 
      || auth.user?.isEchoStaff 
      || auth.user?.isDevelopmentStaff 
      || auth.user?.isImplementationStaff 
      || auth.user?.isEvaluationStaff 
      || auth.user?.isPartner;
  };
 
  if (error) {
    return <h1>{error.graphQLErrors[0].message}</h1>;
  }

  if (!isAuthorized()) {
    return <PermissionDeniedSplash />;
  }

  return (
    (!isAuthorized()) ? (
      <PermissionDeniedSplash />
    ) : (
    <>
      {isAuthorized() && (
      <>
      <ProgressIndicator isOpen={loading} title="Loading..." />
      <Container maxWidth="xl" sx={{ p: 10, mb: 12 }}>

      <PageHeader title="ECHO Programs" />

      <Box sx={{ textAlign: 'left', mb: 2 }}>
        <AddProgramDrawer />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Program</TableCell>
              <TableCell>Partner</TableCell>
              <TableCell># of Cohorts</TableCell>
              <TableCell>Archived</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {programs.map((program) => (
              <TableRow key={program.id}>
                <TableCell component="th" scope="row">
                  {program.name}
                </TableCell>
                <TableCell>{program.partner?.name}</TableCell>
                <TableCell align="left">{program._count?.cohorts}</TableCell>
                <TableCell>{program.isArchived ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <Tooltip title="Go to program dashboard">
                    <IconButton component={Link} to={`/admin/program/dashboard/${program.id}`}>
                      <DashboardIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Container>
      </>
      )}
    </>
  ));
};

export default Programs;
