import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Button from '@mui/material/Button';

import {
  GetAllPartners_getPartners,
  GetAllPartners,
  AddStaffUser,
  AddStaffUserVariables,
  AddOrUpdateStaffUserInput,
} from '../../../models/GeneratedModels';
import { getPartnersQuery_Gql } from '../../../gql/partner/getPartnersQuery';
import { addStaffUser_Gql } from '../../../gql/user/addStaffUser';
import { validatePassword } from '../../account/Password/Password.helpers';

import ProgressIndicator from '../../global/ProgressIndicator';
import AddEditStaffAccount from './AddEditStaffAccount';

const AddStaffAccount = () => {
  const [errorText, setErrorText] = useState('');
  const [newStaffUser, setNewStaffUser] = useState<AddOrUpdateStaffUserInput>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    partnerId: null,
  });

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { data, loading: partnersLoading } = useQuery<GetAllPartners>(getPartnersQuery_Gql);

  const partners: GetAllPartners_getPartners[] = data?.getPartners ?? [];

  const hasValue = (value: string) => value.trim().length > 0;

  const [addStaffAccount, { loading: addStaffLoading }] = useMutation<AddStaffUser, AddStaffUserVariables>(
    addStaffUser_Gql
  );

  const resetForm = () => {
    setErrorText('');
    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);
    setPasswordError(false);

    setNewStaffUser({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      partnerId: null,
    });
  };

  const formIsValid = (staffAccount: AddOrUpdateStaffUserInput, confirmPassword: string): boolean => {
    let isValid = true;
    setErrorText('');

    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);
    setPasswordError(false);

    if (!hasValue(staffAccount.firstName)) {
      setFirstNameError(true);
      isValid = false;
    }

    if (!hasValue(staffAccount.lastName)) {
      setLastNameError(true);
      isValid = false;
    }

    if (!hasValue(staffAccount.email)) {
      setEmailError(true);
      isValid = false;
    }

    if (!isValid) {
      setErrorText('Please enter the required fields');
    }

    const passwordValidation = validatePassword(staffAccount.password ?? '', confirmPassword);
    if (!passwordValidation.isValid) {
      setPasswordError(true);
      setErrorText(passwordValidation.message);
      isValid = false;
    }

    return isValid;
  };

  const addNewStaffUser = async (staffAccount: AddOrUpdateStaffUserInput, confirmPassword: string) => {
    if (formIsValid(staffAccount, confirmPassword) === true) {
      try {
        await addStaffAccount({
          variables: {
            user: staffAccount,
          },
        });

        resetForm();
        setDrawerOpen(false);
      } catch (error: any) {
        setErrorText(error.graphQLErrors[0].message);
      }
    }
  };

  const onCancelClick = () => {
    resetForm();
    setDrawerOpen(false);
  };

  return (
    <>
      <ProgressIndicator isOpen={partnersLoading} title="Loading..." />

      <ProgressIndicator isOpen={addStaffLoading} title="Saving..." />

      <AddEditStaffAccount
        drawerTrigger={
          <Button variant="outlined" color="primary" size="small" onClick={() => setDrawerOpen(true)}>
            + Add Staff Account
          </Button>
        }
        user={newStaffUser}
        saveUserClick={addNewStaffUser}
        onCancelClick={onCancelClick}
        partners={partners}
        isOpen={drawerOpen}
        errors={{
          errorText,
          firstNameError,
          lastNameError,
          emailError,
          passwordError,
        }}
      />
    </>
  );
};

export default AddStaffAccount;
