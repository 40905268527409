import { gql } from '@apollo/client';

export const DenialTextForRegistration_Name = 'GetRegistrationDenialEmail';
export const DenialTextForRegistration_Gql = gql`
  query GetRegistrationDenialEmail($cohortRegistrationId: ID!) {
    getRegistrationDenialEmail(cohortRegistrationId: $cohortRegistrationId) {
      subject
      text
    }
  }
`;
