export const loginLink = {
  href: '/login',
  text: 'Log In',
};

export const accountLinks = {
  changePassword: {
    href: '/change-password',
    text: 'Change Password',
  },
  createAccount: {
    href: '/createAccount',
    text: 'Create an Account',
  },
  forgotPassword: {
    href: '/forgotPassword',
    text: 'Forgot My Password',
  },
  resetPassword: {
    href: '/resetPassword',
    title: 'Reset Password',
  },
  editAccount: {    
    href: '/my-account',
    text: 'Edit My Account',
  }
};

export const learnerLinks = {
  cohortDetail: {
    href: '/cohortDetails',
    text: 'Details',
  },
  myEchos: {
    href: '/my-echos',
    text: 'My ECHOs',
  },
  progams: {
    href: '/programs',
    text: 'Browse Programs',
  },
};

export const adminLinks = {
  allPrograms: {
    href: '/admin/programs',
    text: 'All Programs',
  },
  currentProjects: {
    href: '/admin/current-projects',
    text: 'Current Projects',
  },
};

export const programLinks = {
  manageEmailTemplates: {
    href: '/admin/program/manageEmailTemplates',
    text: 'Manage Email Templates',
  },
  manageResources: {
    href: '/admin/program/manageResources',
    text: 'Manage Resources',
  },
  programDashboard: {
    href: '/admin/program/dashboard',
    text: 'Manage Program',
  },
};
