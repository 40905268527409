import React, { useState, useEffect } from 'react';
import { Container, Link } from '@mui/material';

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PageHeader from 'components/global/PageHeader';
import ProgressIndicator from 'components/global/ProgressIndicator';

const RegistrationUnavailableSplash = () => {
  const [loading, setLoading] = useState(false);
  
  // Use useEffect to trigger redirect after 15 seconds
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Redirect to /programs after 30 seconds
      setLoading(true);
      window.location.href = '/programs';
    }, 9000); // 9 seconds in milliseconds

    // Clear the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array ensures the effect runs only once on component mount


  return (
    <>
      <ProgressIndicator isOpen={loading} title="Loading..." />
      <Container maxWidth="xl" sx={{ p: 14, mb: 2 }}>
        <PageHeader title="Registration is currently unavailable for this ECHO" />

        <Grid container sx={{mt: 5}}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
              <Stack spacing={5}>
                  <Alert severity="info">You will be redirected to ECHOs You Can Explore shortly.
                    (Or <Link color="inherit" href="/programs">Click Here</Link> to navigate to the available ECHOs now)</Alert>
              </Stack>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Container>
    </>
  );
};

export default RegistrationUnavailableSplash;
