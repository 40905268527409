import { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, CircularProgress } from '@mui/material';

interface ProgressIndicatorProps {
  isOpen: boolean;
  title: string;
}

const ProgressIndicator = ({ isOpen, title }: ProgressIndicatorProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(isOpen);

  useEffect(() => {
    setOpenDialog(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80vw', maxHeight: 435 }, textAlign: 'center' }}
      open={openDialog}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <CircularProgress color="primary" />
      </DialogContent>
    </Dialog>
  );
};

export default ProgressIndicator;
