import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

import ProgressIndicator from 'components/global/ProgressIndicator';

import {
  SearchUsersByName,
  SearchUsersByNameVariables,
  SearchUsersByName_searchUsersByName,
} from 'models/GeneratedModels';
import { searchUserByName_Gql } from 'gql/user/searchUserByName';
import { isEmpty } from 'utils/validators';

import { AddExistingHubMemberCohort, AddExistingHubMemberCohortVariables } from 'models/GeneratedModels';
import { getMembersByCohort_Name } from 'gql/hubTeam/getMembersByCohort';
import { addExistingHubMemberCohort_Gql } from 'gql/hubTeam/addExistingHubMemberCohort';

interface SearchExistingUserProps {
  cohortId: string;
  onCloseDrawer: () => void;
}

const SearchExistingUser = ({ cohortId, onCloseDrawer }: SearchExistingUserProps) => {
  const [firstNameText, setFirstNameText] = useState<string>('');
  const [lastNameText, setLastNameText] = useState<string>('');
  const [hasError, setHasError] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchUsersByName_searchUsersByName[]>([]);
  const [searchWasRun, setSearchWasRun] = useState(false);

  const [searchAccounts, { loading, data }] = useLazyQuery<SearchUsersByName, SearchUsersByNameVariables>(
    searchUserByName_Gql,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [addHubMember, { loading: saving, error }] = useMutation<
    AddExistingHubMemberCohort,
    AddExistingHubMemberCohortVariables
  >(addExistingHubMemberCohort_Gql, {
    refetchQueries: [getMembersByCohort_Name],
  });

  useEffect(() => {
    setSearchResults(data?.searchUsersByName ?? []);
  }, [data]);

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'firstName') {
      setFirstNameText(value);
    }

    if (name === 'lastName') {
      setLastNameText(value);
    }
  };

  const onSearchClick = () => {
    setHasError(false);

    if (isEmpty(firstNameText) && isEmpty(lastNameText)) {
      setHasError(true);
      return;
    }

    setSearchWasRun(true);

    searchAccounts({
      variables: {
        firstName: firstNameText,
        lastName: lastNameText,
      },
    });
  };

  const resetForm = () => {
    setSearchResults([]);
    setSearchWasRun(false);
    setHasError(false);
    setFirstNameText('');
    setLastNameText('');
  };

  const onCancelClick = () => {
    resetForm();
    onCloseDrawer();
  };

  const selectUser = async (selectedUserId: string) => {
    try {
      await addHubMember({
        variables: {
          hubMemberCohort: {
            cohortId,
            userId: selectedUserId,
          },
        },
      });

      resetForm();
      onCloseDrawer();
    } catch {}
  };

  const onSearchTextKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearchClick();
    }
  };

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Loading..." />
      <ProgressIndicator isOpen={saving} title="Saving..." />

      <Stack spacing={2}>
        <Typography variant="h6">Search for Existing User</Typography>

        {hasError && <Alert severity="error">You must enter something to search by</Alert>}

        <Grid container>
          <Grid item xs={6} paddingRight={1}>
            <TextField
              fullWidth
              size='small'
              label="First Name"
              name="firstName"
              onChange={onTextChange}
              onKeyPress={onSearchTextKeyPress}
              value={firstNameText}
              color="warning"
            />
          </Grid>
          <Grid item xs={6} paddingLeft={1}>
            <TextField
              fullWidth
              size='small'
              label="Last Name"
              name="lastName"
              onChange={onTextChange}
              onKeyPress={onSearchTextKeyPress}
              value={lastNameText}
              color="warning"
            />
          </Grid>
        </Grid>

        <Typography variant="caption">Search by first and/or last name</Typography>

        <Button variant="contained" color="primary" onClick={onSearchClick}>
          Search
        </Button>

        {error && <Alert severity="error">{error.graphQLErrors[0].message}</Alert>}

        {searchWasRun && searchResults.length === 0 && <Alert severity="info">No matches were found</Alert>}

        {searchResults.length > 0 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchResults.map((result) => (
                  <TableRow key={result.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      <Tooltip title={`Add ${result.firstName} to hub team`}>
                        <AddIcon
                          sx={{ color: 'primary.main', cursor: 'pointer' }}
                          onClick={() => selectUser(result.id)}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {`${result.lastName}, ${result.firstName}`}
                    </TableCell>
                    <TableCell>{result.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Button variant="outlined" color="primary" onClick={onCancelClick}>
          Cancel
        </Button>
      </Stack>
    </>
  );
};

export default SearchExistingUser;
