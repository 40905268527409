import React, { useState, ChangeEvent, useEffect } from 'react';
import { Stack, TextField, Typography, Alert } from '@mui/material';
import { FormControl, Select, InputLabel, MenuItem, SelectChangeEvent } from '@mui/material';

import { GetAllPartners_getPartners, AddOrUpdateStaffUserInput } from '../../../models/GeneratedModels';
import PasswordRequirements from '../../account/Password/PasswordRequirements';
import DrawerWithTrigger from '../../global/DrawerWithTrigger';

interface AddEditStaffErrors {
  errorText: string;
  firstNameError: boolean;
  lastNameError: boolean;
  emailError: boolean;
  passwordError?: boolean;
}

interface AddEditStaffAccountProps {
  drawerTrigger: React.ReactNode;
  user: AddOrUpdateStaffUserInput;
  saveUserClick: (user: AddOrUpdateStaffUserInput, confirmPassword: string) => void;
  onCancelClick: () => void;
  partners: GetAllPartners_getPartners[];
  errors: AddEditStaffErrors;
  isOpen: boolean;
}

const AddEditStaffAccount = ({
  drawerTrigger,
  user,
  saveUserClick,
  onCancelClick,
  partners,
  errors,
  isOpen,
}: AddEditStaffAccountProps) => {
  const { errorText, firstNameError, lastNameError, emailError, passwordError = false } = errors;

  const [staffUser, setStaffUser] = useState(user);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(isOpen);

  useEffect(() => {
    setDrawerOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setStaffUser(user);

    if (user?.password?.length === 0) {
      setConfirmPassword('');
    }
  }, [user]);

  const onFormElementChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setStaffUser({
      ...staffUser,
      [name]: value,
    });
  };

  const onConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    setConfirmPassword(value);
  };

  const onSelectElementChange = (e: SelectChangeEvent) => {
    const value = e.target.value;

    setStaffUser({
      ...staffUser,
      partnerId: value.length > 0 ? value : null,
    });
  };

  const onSaveClick = () => {
    saveUserClick(staffUser, confirmPassword);
  };

  return (
    <>
      <DrawerWithTrigger
        width={500}
        title="Add Staff Account"
        showSave={true}
        showCancel={true}
        onSaveClick={onSaveClick}
        isOpen={drawerOpen}
        trigger={drawerTrigger}
        onCancelClick={onCancelClick}
      >
        <Typography variant="caption">
          By default, the new staff account will get the ECHO staff security role
        </Typography>

        <Stack spacing={2} sx={{ ml: 2, mr: 2 }}>
          <TextField
            label="First Name"
            value={staffUser.firstName}
            color="warning"
            onChange={onFormElementChange}
            name="firstName"
            error={firstNameError}
          />

          <TextField
            label="Last Name"
            value={staffUser.lastName}
            color="warning"
            name="lastName"
            onChange={onFormElementChange}
            error={lastNameError}
          />

          <TextField
            label="Email"
            value={staffUser.email}
            color="warning"
            name="email"
            onChange={onFormElementChange}
            error={emailError}
          />

          {!user.id && (
            <>
              <TextField
                label="Password"
                type="password"
                value={staffUser.password}
                color="warning"
                onChange={onFormElementChange}
                name="password"
                error={passwordError}
              />

              <PasswordRequirements />

              <TextField
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                color="warning"
                onChange={onConfirmPasswordChange}
              />
            </>
          )}

          <FormControl sx={{ width: '100%' }}>
            <InputLabel color="warning" id="partner-label">
              Implementation Partner
            </InputLabel>

            <Select
              labelId="partner-label"
              id="partner-select"
              value={staffUser.partnerId ?? ''}
              label="Partner"
              color="warning"
              onChange={onSelectElementChange}
              sx={{ textAlign: 'left' }}
            >
              <MenuItem value="">No Partner</MenuItem>
              {partners.map((partner) => (
                <MenuItem key={partner.id} value={partner.id}>
                  {partner.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {errorText.length > 0 && <Alert severity="error">{errorText}</Alert>}
        </Stack>
      </DrawerWithTrigger>
    </>
  );
};

export default AddEditStaffAccount;
