import { gql } from '@apollo/client';

export const toggleCohortRegistrationActiveMutation_Name = '';
export const toggleCohortRegistrationActiveMutation_Gql = gql`
  mutation ToggleCohortRegistrationActive($cohortRegistration: ToggleCohortRegistrationActiveInput!) {
    toggleCohortRegistrationActive(cohortRegistration: $cohortRegistration) {
      id
      updatedAt
    }
  }
`;
