import { gql } from '@apollo/client';

export const updateProgramEmailTemplate_Name = 'UpdateProgramEmailTemplate';
export const updateProgramEmailTemplate_Gql = gql`
  mutation UpdateProgramEmailTemplate($template: UpdateProgramEmailTemplateInput!) {
    updateProgramEmailTemplate(template: $template) {
      id
    }
  }
`;
