import { useMutation } from '@apollo/client';
import { getResourceForProgram_Name } from 'gql/programResources/getResourceForProgram';
import { deleteResource_Gql } from 'gql/programResources/deleteResource';
import { DeleteResource, DeleteResourceVariables } from 'models/GeneratedModels';

export function useDeleteResource() {
  const [deleteResource, { loading }] = useMutation<DeleteResource, DeleteResourceVariables>(deleteResource_Gql, {
    refetchQueries: [getResourceForProgram_Name],
  });

  const removeResourceFromS3AndDB = async (resourceId: string) => {
    await deleteResource({
      variables: {
        resourceId,
      },
    });
  };

  return { removeResourceFromS3AndDB, loading };
}
