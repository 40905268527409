import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HomeTitleAndBody from './HomeTitleAndBody';
import { Container } from '@mui/material';

const OregonHome = () => {
  return (
    <>
      <Container maxWidth="xl" sx={{ p: 14, mb: 2 }}>
        <HomeTitleAndBody title="Join In and Help Make Health Knowledge ECHO">
          <Stack spacing={2} sx={{ textAlign: 'left' }}>
            <Typography variant="subtitle1">
              <strong>
                Oregon ECHO Network (Extension for Community Health Outcomes)
              </strong>{' '}
              delivers and supports telementoring programs for clinicians and
              clinical teams to learn, share, and connect.
            </Typography>

            <Typography variant="subtitle1">
              Using a simple webcam, specialists and healthcare experts connect
              with a group of remote participants in interactive video sessions to
              discuss their toughest cases and learn evidence-based approaches to
              manage complex conditions and improve quality of care in their
              communities.
            </Typography>

            <Typography variant="subtitle1">
              As a project housed within the Oregon Rural Practice Based Research
              Network at OHSU, the Oregon ECHO Network is committed to building
              and sustaining a diverse, equitable, inclusive and anti-racist
              organization. We do so by evaluating how we develop and support our
              workforce, the partnerships we uphold and how we engage in
              community-partnered dialogue, research, coaching and education
              throughout.
            </Typography>
          </Stack>
        </HomeTitleAndBody>
      </Container>
    </>
  );
};

export default OregonHome;
