import RouteItem from '../models/RouteItem.model';

import AccountDetail from '../pages/admin/AccountDetail';
import Accounts from '../pages/admin/Accounts';
// import Attendance from '../components/admin/Session/Attendance';
import CohortDashbaord from '../pages/admin/CohortDashboard';
import CohortDetails from '../pages/CohortDetail';
import CohortRoster from '../pages/CohortRoster';
import CreateAccount from '../pages/CreateAccount';
import CurrentProjects from '../pages/admin/CurrentProjects';
import EditAccount from '../components/admin/Account/EditAccount';
import EmailTemplates from '../pages/admin/EmailTemplates';
import LearnerCohortDashboard from '../pages/LearnerCohortDashboard';
import LearnerPrograms from '../pages/LearnerPrograms';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import ManageEmailTemplates from '../components/admin/program/ManageEmailTemplates';
import ManageResources from '../components/admin/program/resources/ManageResources';
import MyAccount from '../pages/MyAccount';
import MyCurrentProjects from '../pages/admin/MyCurrentProjects';
import MyEchos from '../pages/MyEchos';
import NotImplemented from '../components/global/NotImplemented';
import Partners from '../pages/admin/Partners';
import ProgramDashboard from '../components/admin/program/ProgramDashboard';
import Programs from '../pages/admin/Programs';
import ChangePassword from '../pages/ChangePassword';
import CohortRegistration from '../pages/CohortRegistration';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import RegistrationUnavailable from '../pages/RegistrationUnavailableSplash';
import PermissionDeniedSplash from '../pages/PermissionDeniedSplash';
import GlobalExports from '../pages/admin/GlobalExports';

import Index from 'pages/Index';

import { accountLinks, programLinks } from '../utils/links';

const accountRoutes: Array<RouteItem> = [
  {
    key: 'route-change-password',
    path: accountLinks.changePassword.href,
    component: ChangePassword,
    protected: true,
  },
];

const unprotectedRoutes: Array<RouteItem> = [
  {
    key: 'route-learner-program',
    path: '/programs',
    component: LearnerPrograms,
    protected: false,
  },
  {
    key: 'route-registration-unavailable',
    path: '/registrationUnavailable',
    component: RegistrationUnavailable,
    protected: false,
  },
  {
    key: 'route-access-denied',
    path: '/PermissionDeniedSplash',
    component: PermissionDeniedSplash,
    protected: false,
  },
  {
    key: 'route-logout',
    path: '/logout',
    component: Logout,
    protected: false,
  },
];

const programRoutes: Array<RouteItem> = [
  {
    key: 'route-manage-resources',
    path: `${programLinks.manageResources.href}/:id`,
    component: ManageResources,
    protected: true,
  },
  {
    key: 'route-program-dashboard',
    path: `${programLinks.programDashboard.href}/:id`,
    component: ProgramDashboard,
    protected: true,
  },
  {
    key: 'route-program-email-templates',
    path: `${programLinks.manageEmailTemplates.href}/:id`,
    component: ManageEmailTemplates,
    protected: true,
  },
];

export const routes: Array<RouteItem> = [
  ...accountRoutes,
  ...unprotectedRoutes,
  ...programRoutes,
  {
    key: 'route-reset-password',
    path: `${accountLinks.resetPassword.href}/:code`,
    component: ResetPassword,
    protected: false,
  },
  {
    key: 'route-cohort-registration',
    path: '/registration/:cohortId',
    component: CohortRegistration,
    protected: true,
  },
  {
    key: 'route-forgot-password',
    path: accountLinks.forgotPassword.href,
    component: ForgotPassword,
    protected: false,
  },
  {
    key: 'route-edit-account',
    path: '/admin/account/edit/:userId',
    component: EditAccount,
    protected: true,
  },
  {
    key: 'route-my-account',
    path: '/my-account',
    component: MyAccount,
    protected: true,
  },
  {
    key: 'route-accounts',
    path: '/admin/account/detail/:id',
    component: AccountDetail,
    protected: true,
  },
  {
    key: 'route-my-echos',
    path: '/cohort-roster/:cohortId',
    component: CohortRoster,
    protected: true,
  },
  {
    key: 'route-email-templates',
    path: '/admin/emailTemplates',
    component: EmailTemplates,
    protected: true,
  },
  {
    key: 'route-my-echos',
    path: '/my-echos',
    component: MyEchos,
    protected: true,
  },
  {
    key: 'route-learner-cohort-dashboard',
    path: '/program-dashboard/:cohortId',
    component: LearnerCohortDashboard,
    protected: true,
  },
  {
    key: 'route-accounts',
    path: '/admin/accounts',
    component: Accounts,
    protected: true,
  },
  {
    key: 'route-current-projects',
    path: '/admin/current-projects',
    component: CurrentProjects,
    protected: true,
  },
  {
    key: 'route-my-current-projects',
    path: '/admin/my-current-projects',
    component: MyCurrentProjects,
    protected: true,
  },
  // {
  //   key: 'route-session-attendance',
  //   path: '/admin/session/attendance/:sessionId',
  //   component: Attendance,
  //   protected: true,
  // },
  {
    key: 'route-cohort',
    path: '/admin/cohort/:cohortId',
    component: CohortDashbaord,
    protected: true,
  },
  {
    key: 'route-index',
    path: '/',
    component: Index,
    protected: false,
  },
  {
    key: 'route-create-account',
    path: '/createAccount',
    component: CreateAccount,
    protected: false,
  },
  {
    key: 'route-cohort-details',
    path: '/cohortDetails/:cohortId',
    component: CohortDetails,
    protected: false,
  },
  {
    key: 'route-login',
    path: '/login',
    component: Login,
    protected: false,
  },
  {
    key: 'route-logout',
    path: '/logout',
    component: Logout,
    protected: false,
  },  
  {
    key: 'route-notimplemented',
    path: '/NotImplemented',
    component: NotImplemented,
    protected: false,
  },
  {
    key: 'route-partner',
    path: '/admin/partners',
    component: Partners,
    protected: true,
  },
  {
    key: 'route-program',
    path: '/admin/programs',
    component: Programs,
    protected: true,
  },
  {
    key: 'route-global-export',
    path: '/admin/exports',
    component: GlobalExports,
    protected: true,
  },
  
];
