import { gql } from '@apollo/client';

export const validateForgotPassword_Name = 'ValidateForgotPassword';
export const validateForgotPassword_Gql = gql`
  query ValidateForgotPassword($code: String!) {
    validateForgotPassword(code: $code) {
      isSuccess
      codeExpired
      message
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
