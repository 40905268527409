import { Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import SectionHeader from '../SectionHeader/SectionHeader';

interface SectionTextProps {
  title: string;
  text: string | null;
}

const TextSection = ({ title, text }: SectionTextProps) => {
  return (
    <>
      {text ? (
        <>
          <SectionHeader>{title}</SectionHeader>
          <Typography variant="body1" sx={{ mb: 2 }} textAlign="justify">
            <ReactQuill value={text} readOnly={true} theme={'bubble'} />
          </Typography>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default TextSection;
