import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { SelectChangeEvent } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { FaTimes } from 'react-icons/fa';
import { sortBy, SortProperty } from 'components/global/SortableTableColumn';

import DrawerWithTrigger from 'components/global/DrawerWithTrigger';
import ProgressIndicator from 'components/global/ProgressIndicator';

import {
  CohortResourceInput,
  GetDataForResourceEdit,
  GetDataForResourceEditVariables,
  GetDataForResourceEdit_getDataForResourceEdit,
  GetDataForResourceEdit_getDataForResourceEdit_cohorts,
  GetDataForResourceEdit_getDataForResourceEdit_resourceTypes,
  UpdateResource,
  UpdateResourceInput,
  UpdateResourceVariables,
} from 'models/GeneratedModels';
import { getDataForResourceEdit_Gql, getDataForResourceEdit_Name } from 'gql/programResources/getDataForResourceEdit';
import { getResourceForProgram_Name } from 'gql/programResources/getResourceForProgram';
import { updateResource_Gql } from 'gql/programResources/updateResource';
import { RESOURCE_TYPE_URL } from 'utils/constants';

const R = require('ramda');

interface EditResourceProps {
  resourceId: string;
  resourceName: string;
}

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    edit: {
      color: 'goldenrod',
      cursor: 'pointer',
    },
    delete: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    attendanceLink: {
      color: theme.palette.primary.main,
    },
  })
);

const EditResource = ({ resourceId, resourceName }: EditResourceProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [cohortResources, setCohortResources] = useState<CohortResourceInput[]>([]);
  const [resourceInput, setResourceInput] = useState<UpdateResourceInput>({
    id: resourceId,
    displayName: null,
    resourceTypeId: null,
    url: null,
  });
 
  const sortPropDefault: SortProperty = {
    prop: 'number',
    order: 'asc'
  };
  const classes = useStyles();

  const { data, loading } = useQuery<GetDataForResourceEdit, GetDataForResourceEditVariables>(
    getDataForResourceEdit_Gql,
    {
      variables: {
        resourceId,
      },
      skip: !drawerOpen,
    }
  );

  const [saveResource, { loading: resourceSaving }] = useMutation<UpdateResource, UpdateResourceVariables>(
    updateResource_Gql,
    {
      refetchQueries: [getDataForResourceEdit_Name, getResourceForProgram_Name],
    }
  );

  useEffect(() => {
    if (!loading && data) {
      const resource = data.getDataForResourceEdit.resource;
      const savedCohortResources = data.getDataForResourceEdit.cohortResources;

      setResourceInput({
        ...resourceInput,
        displayName: resource.displayName,
        resourceTypeId: resource.resourceTypeId,
        url: resource.url,
      });

      if (savedCohortResources && savedCohortResources.length > 0) {
        // Doing this to remove the __typename property from the query result; there's probably a better way
        const cohortResourcesToAdd: CohortResourceInput[] = [];
        savedCohortResources.forEach((cr) => {
          cohortResourcesToAdd.push({
            id: cr.id,
            cohortId: cr.cohortId,
            resourceId: cr.resourceId,
            sessionId: cr.sessionId,
          });
        });

        setCohortResources([...cohortResourcesToAdd]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  const resourceData: GetDataForResourceEdit_getDataForResourceEdit | null = data?.getDataForResourceEdit ?? null;
  const cohorts: GetDataForResourceEdit_getDataForResourceEdit_cohorts[] = resourceData?.cohorts ?? [];
  const resourceTypes: GetDataForResourceEdit_getDataForResourceEdit_resourceTypes[] =
    resourceData?.resourceTypes ?? [];
  const isUrlType = resourceData?.resource.resourceTypeId === RESOURCE_TYPE_URL ?? false;

  const addCohortResource = (cohortId: string, sessionId: string) => {
    const newCohortResource: CohortResourceInput = {
      id: uuidv4(),
      cohortId,
      resourceId,
      sessionId: sessionId === 'all' ? null : sessionId,
    };

    setCohortResources([...cohortResources, newCohortResource]);
  };

  const getSelectedSessionByCohort = (cohortId: string) => {
    const cohortResource = R.find(R.propEq('cohortId', cohortId))(cohortResources) as
      | CohortResourceInput
      | null
      | undefined;

    if (!cohortResource) return '';

    return cohortResource.sessionId ? cohortResource.sessionId : 'all';
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setResourceInput({
      ...resourceInput,
      [name]: value,
    });
  };

  const onSelectElementChange = (e: SelectChangeEvent) => {
    const name = e.target.name;
    const value = e.target.value;

    setResourceInput({
      ...resourceInput,
      [name]: value,
    });
  };

  const onSessionSelectChange = (e: SelectChangeEvent) => {
    const cohortId = e.target.name;
    const sessionId = e.target.value;

    const existingIndex = R.findIndex(R.propEq('cohortId', cohortId))(cohortResources);

    if (existingIndex === -1) {
      addCohortResource(cohortId, sessionId);
    } else {
      // Remove the existing and add the updated
      setCohortResources([...cohortResources.splice(existingIndex, 1)]);
      addCohortResource(cohortId, sessionId);
    }
  };

  const clearResourceAssignment = async (resourceId: string, cohortId: string, sessionId: string) => {
    if (cohortId.length && sessionId.length && sessionId !== "all") {
      const existingSessionIndex = R.findIndex(R.propEq('cohortId', cohortId))(cohortResources);
      if (existingSessionIndex === -1) {
        console.log('Theres no assignment');
      } else {
        const updatedCohortSessionResources = [...cohortResources];
        updatedCohortSessionResources.splice(existingSessionIndex, 1);
        setCohortResources(updatedCohortSessionResources);
      }
    } else {
      const existingCohortIndex = R.findIndex(R.propEq('cohortId', cohortId))(cohortResources);

      if (existingCohortIndex === -1) {
        console.log('Theres no assignment');
      } else {
        // Remove the existing
        const updatedCohortResources = [...cohortResources];
        updatedCohortResources.splice(existingCohortIndex, 1);
        setCohortResources(updatedCohortResources);
      }
    }
  }
  
  const onSaveClick = async () => {
    const { displayName, resourceTypeId, url } = resourceInput;

    await saveResource({
      variables: {
        resource: {
          id: resourceId,
          displayName,
          resourceTypeId,
          url,
          cohortResources: cohortResources.length > 0 ? cohortResources : null,
        },
      },
    });

    setDrawerOpen(false);
  };

  return (
    <>
      <DrawerWithTrigger
        trigger={
          <Tooltip title={`Edit ${resourceName}`}>
            <EditIcon onClick={() => setDrawerOpen(true)} sx={{ color: 'primary.main', cursor: 'pointer' }} />
          </Tooltip>
        }
        width={700}
        title="Edit Resource"
        showSave={true}
        onSaveClick={onSaveClick}
        showCancel={true}
        isOpen={drawerOpen}
        cancelText="Cancel"
      >
        <ProgressIndicator isOpen={loading} title="Loading..." />
        <ProgressIndicator isOpen={resourceSaving} title="Saving..." />

        {!loading && (
          <Stack spacing={3}>
            <Typography variant="h6">
              <strong>Resource Name: </strong> {resourceData?.resource.name ?? ''}
            </Typography>

            <TextField
              value={resourceInput.displayName}
              name="displayName"
              onChange={onTextChange}
              label="Resource Display Name"
              color="warning"
            />

            {!isUrlType && (
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel id="resource-type-label" color="warning">
                  Resource Type
                </InputLabel>
                <Select
                  sx={{ textAlign: 'left' }}
                  labelId="resource-type-label"
                  id="select-resource-type"
                  label="Resource Type"
                  color="warning"
                  name="resourceTypeId"
                  onChange={onSelectElementChange}
                  value={resourceInput.resourceTypeId ?? ''}
                >
                  {resourceTypes.map((rt) => (
                    <MenuItem key={rt.id} value={rt.id}>
                      {rt.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {isUrlType && (
              <TextField value={resourceInput.url} name="url" onChange={onTextChange} label="URL" color="warning" />
            )}

            <Alert sx={{ textAlign: 'left' }} severity="info">
              Assign resources for each cohort to be available at the overall cohort level or assigned to a specific
              session. Clearing out your assignments will only take effect after clicking Save.
            </Alert>

            <TableContainer component={Paper}>
              <Table sx={{ width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell width="390px">Cohort</TableCell>
                    <TableCell align="center" width="290px">
                      Resource Assignment
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cohorts.map((cohort) => (
                    <TableRow key={cohort.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {cohort.internalLabel}
                      </TableCell>
                      <TableCell align="center">
                        <FormControl fullWidth>
                          <InputLabel id="resource-session-label" color="warning">
                            Assign to a Session
                          </InputLabel>
                          <Select
                            sx={{ textAlign: 'left' }}
                            labelId="resource-session-label"
                            id="select-session-type"
                            label="Assign Resource"
                            color="warning"
                            name={cohort.id}
                            onChange={onSessionSelectChange}
                            value={getSelectedSessionByCohort(cohort.id)}
                          >
                            <MenuItem value={'all'}>Overall Cohort</MenuItem>
                            {sortBy((cohort.sessions && cohort.sessions.length ? cohort.sessions : []), sortPropDefault).map((s) => (
                              <MenuItem key={s.id} value={s.id}>
                                [{s.number}]: {s.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">    
                        <FormControl fullWidth>
                          {getSelectedSessionByCohort(cohort.id) !== '' ? 
                          (<FaTimes
                              title="Clear Resource Assignment"
                              className={classes.delete}
                              onClick={() => clearResourceAssignment(resourceInput.id, cohort.id, getSelectedSessionByCohort(cohort.id))}
                            />) :
                            (<FaTimes
                                title="Clear Resource Assignment"
                                className={classes.delete}
                                style={{ color: 'grey' }}
                              />
                            )
                          }
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        )}
      </DrawerWithTrigger>
    </>
  );
};

export default EditResource;
