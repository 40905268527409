import { gql } from '@apollo/client';

export const addStaffUser_Name = 'AddStaffUser';
export const addStaffUser_Gql = gql`
  mutation AddStaffUser($user: AddOrUpdateStaffUserInput!) {
    addStaffUser(user: $user) {
      id
      firstName
      lastName
      email
    }
  }
`;
