import { gql } from '@apollo/client';

export const updateResource_Name = 'UpdateResource';
export const updateResource_Gql = gql`
  mutation UpdateResource($resource: UpdateResourceInput!) {
    updateResource(resource: $resource) {
      id
    }
  }
`;
