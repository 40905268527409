import { gql } from '@apollo/client';

export const resendRegistrationConfirmationEmail_Name = 'ResendRegistrationConfirmationEmail';
export const resendRegistrationConfirmationEmail_Gql = gql`
  mutation ResendRegistrationConfirmationEmail($cohortRegistrationId: String!) {
    resendRegistrationConfirmationEmail(cohortRegistrationId: $cohortRegistrationId) {
      id
      updatedAt
    }
  }
`;
