import { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FiDownload } from 'react-icons/fi';
import { FaEnvelope } from 'react-icons/fa';

import ProgressIndicator from 'components/global/ProgressIndicator';
import SortableTableColumn, {
  SortProperty,
  complexSort,
} from 'components/global/SortableTableColumn';

import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';

import { exportCohortPreRegistrants } from '../../../CohortDetails/components/FileExports.helpers';

import { sendInvitationToPreRegistrant_Gql } from '../../../../../gql/cohortPreRegistration/sendInvitationToPreRegistrant';
import { sendInvitationToPreRegistrantCohort_Gql } from '../../../../../gql/cohortPreRegistration/sendInvitationToPreRegistrantCohort';
import { getPreRegistrantsForCohortQuery_Name } from '../../../../../gql/cohortPreRegistration/getPreRegistrantsForCohortQuery';

import {
  SendInvitationToPreRegistrant,
  SendInvitationToPreRegistrantVariables,
  SendInvitationToAllPreRegistrants,
  SendInvitationToAllPreRegistrantsVariables,
  GetPreRegistrantsForCohort_getPreRegistrantsForCohort
} from '../../../../../models/GeneratedModels';

interface PreRegistrantsProps {
  cohortId: string;
  internalLabel: string | null;
  preRegistrants: GetPreRegistrantsForCohort_getPreRegistrantsForCohort[];
}

const sortPropDefault: SortProperty = {
  prop: 'email',
  order: 'asc'
};

const PreRegistrants = ({ cohortId, internalLabel, preRegistrants }: PreRegistrantsProps) => {
  const [excelExporting, setExcelExporting] = useState(false);
  const [sortProp, setSortProp] = useState<SortProperty>(sortPropDefault);

  const [sendPreRegistrantEmail, { loading: sendOneLoading }] = useMutation<
    SendInvitationToPreRegistrant,
    SendInvitationToPreRegistrantVariables
  >(sendInvitationToPreRegistrant_Gql);

  const [sendPreRegistrantEmailToAll, { loading: sendAllLoading }] = useMutation<
    SendInvitationToAllPreRegistrants,
    SendInvitationToAllPreRegistrantsVariables
  >(sendInvitationToPreRegistrantCohort_Gql);

  const modifiedPreRegistrants = useMemo(() => {
    const data = preRegistrants.map((reg) => {
      if (reg.user) {
        return {
          ...reg,
          fullName: `${reg.user.lastName}, ${reg.user.firstName}`,
          email: reg.user.email,
          organization: reg.user.organization,
          profession: reg.user.profession?.name
        };
      }

      return {
        ...reg,
        fullName: `${reg.lastName}, ${reg.firstName}`,
        email: reg.email,
        organization: reg.organization,
        profession: reg.profession
      };
    });

    return data;
  }, [preRegistrants]);

  const sendEmailToAll = async () => {
    await sendPreRegistrantEmailToAll({
      variables: {
        cohortId,
      },
      refetchQueries: [getPreRegistrantsForCohortQuery_Name],
    });
  };

  const sendIndividualEmail = async (preRegistrantId: string) => {
    await sendPreRegistrantEmail({
      variables: {
        preRegistrantId,
      },
      refetchQueries: [getPreRegistrantsForCohortQuery_Name],
    });
  };

  return (
    <>
      <ProgressIndicator isOpen={sendOneLoading || sendAllLoading} title="Sending..." />
      <ProgressIndicator isOpen={excelExporting} title="Processing Excel export..." />

      <Stack direction={'row'} spacing={3} sx={{ mb: 3 }}>
        <Button
          onClick={async () => exportCohortPreRegistrants(cohortId, internalLabel ?? '', setExcelExporting)}
          variant="outlined"
          startIcon={<FiDownload />}
        >
          Download Pre-Registrants
        </Button>

        <Button onClick={sendEmailToAll} variant="outlined" startIcon={<FaEnvelope />}>
          Send Registration Email to All
        </Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="fullName"
                headerText="Name"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="email"
                headerText="Email"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="organization"
                headerText="Organization"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="profession"
                headerText="Profession"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="createdAt"
                headerText="Registration Date"
                order={sortProp?.order}
              />
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {modifiedPreRegistrants &&
              complexSort(modifiedPreRegistrants, sortProp.order ? sortProp : sortPropDefault).map((registrant) => (
                <TableRow key={registrant.id}>
                  <TableCell component="th" scope="row">
                    {registrant.fullName}
                  </TableCell>
                  <TableCell>
                    {registrant.email}
                  </TableCell>
                  <TableCell>
                    {registrant.organization}
                  </TableCell>
                  <TableCell>
                    {registrant.profession}
                  </TableCell>
                  <TableCell>{convertDateToTimeZoneFromUtc(registrant.createdAt)}</TableCell>
                  <TableCell>
                    {registrant.emailSent && <div>Registration Email Sent</div>}

                    {!registrant.emailSent && (
                      <Button
                        onClick={() => sendIndividualEmail(registrant.id)}
                        variant="outlined"
                        color="primary"
                        size="small"
                      >
                        Send Registration Email
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PreRegistrants;
