import { useEffect } from 'react';

import './App.css';
import theme from './utils/theme';

import { ThemeProvider } from '@mui/material/styles';
import { clarity } from 'react-microsoft-clarity';

import UnsupportedBrowserPage from './pages/UnsupportedBrowser';
import Menu from './components/global/Menu';
import Footer from './components/global/Footer';
import CookieConsent from './components/global/Consent';
import supportedBrowsers from './utils/browserVersions';


import { 
  OREGON_TENANT_ID,
  COLORADO_TENANT_ID,
  DARTMOUTH_TENANT_ID,
  VIRGINIA_TENANT_ID,
 } from 'utils/constants';
import { getTenantIdForDomain,
  COLORADO_CLARITY_ID,
  OREGON_CLARITY_ID, 
  VIRGINIA_CLARITY_ID,
  DARTMOUTH_CLARITY_ID,
} from './helpers/tenantHelpers';

import { ProvideAuth } from './hooks/useAuth';

import favicon from './components/home/components/images/OEN-favicon.png';

function App() {
  const tenantId = getTenantIdForDomain();
  const oregonTenantId = OREGON_TENANT_ID;
  const coloradoClarityId = COLORADO_CLARITY_ID;
  const oregonClarityId = OREGON_CLARITY_ID;
  const virginiaClarityId = VIRGINIA_CLARITY_ID;
  const dartmouthClarityId = DARTMOUTH_CLARITY_ID;

  useEffect(() => {
    const faviconElement = document.getElementById(
      'favicon'
    ) as HTMLLinkElement;
    if (faviconElement && tenantId === oregonTenantId) {
      faviconElement.href = favicon;
    }

    let clarityID = '';
    switch (tenantId) {
      case COLORADO_TENANT_ID:
        // clarityID = coloradoClarityId;
        clarityID = 'lobh7ixx33';
        break;
      case OREGON_TENANT_ID:
        // clarityID = oregonClarityId;
        clarityID = 'lobqcoirjw';
        break;
      case VIRGINIA_TENANT_ID:
        clarityID = '';
        break;
      case DARTMOUTH_TENANT_ID:
        clarityID = '';
        break;
      default:
        // Handle the case where tenantId doesn't match any of the predefined values
        break;
    }
    if (clarityID.trim().length) {
      clarity.init(clarityID);
    }
  }, [tenantId, oregonTenantId, coloradoClarityId, oregonClarityId, virginiaClarityId, dartmouthClarityId]);

  const userAgent = window.navigator.userAgent.toLowerCase();
  let isSupported = true;

  const browserData = supportedBrowsers.find((b) =>
    userAgent.includes(b.name)
  );

  if (browserData) {
    const match = userAgent.match(`${browserData.name}/([0-9]+\\.[0-9]+)`);
    if (match) {
      const version = parseInt(match[1]);
      isSupported = version >= browserData.minVersion;
    } else {
      isSupported = false;
    }
  }

  if (!isSupported) {
    return <UnsupportedBrowserPage />;
  }

  return (
    <>
      <ProvideAuth>
        <ThemeProvider theme={theme}>
          <Menu />
            &nbsp;
            <CookieConsent />
          <Footer />
        </ThemeProvider>
      </ProvideAuth>
    </>
  );
}

export default App;
