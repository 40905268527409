import { gql } from '@apollo/client';

export const getFullUserById_Name = 'GetFullUserById';
export const getFullUserById_Gql = gql`
  query GetFullUserById($id: String!) {
    getFullUserById(id: $id) {
      id
      fullName
      firstName
      lastName
      email
      otherCredential
      professionId
      secondLevelProfessionId
      thirdLevelProfessionId
      otherProfession
      otherEthnicity
      organization
      organizationAddress1
      organizationAddress2
      city
      state
      countyId
      zip
      provideDirectCare
      provideMedicaid
      primaryCare
      createdAt
      updatedAt
      credentials
      receiveEmails
      userCredentialIds
      userEthnicityIds
      partnerId
      userCredentialsForDisplay
      userProfessionsForDisplay
      userEthnicitiesForDisplay
      cohortRegistrationsAsUsers {
        createdAt
        cohort {
          startDate
          program {
            name
          }
        }
      }
      emails {
        id
        createdAt
      }
    }
  }
`;
