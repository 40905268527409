import { Button, Box, Stack, Typography, Dialog, DialogContent, DialogTitle } from '@mui/material';

interface YesNoDialogProps {
  open: boolean;
  dialogTitle: string;
  confirmText: string;
  dialogText: string;
  onClose: (value: boolean) => void;
  cancelText?: string;
}

const YesNoDialog = ({
  open,
  dialogTitle,
  confirmText,
  dialogText,
  onClose,
  cancelText = 'Cancel',
}: YesNoDialogProps) => {
  const handleClose = (value: boolean) => {
    onClose(value);
  };

  return (
    <Dialog onClose={() => handleClose(false)} open={open}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body1">{dialogText}</Typography>

          <Box sx={{ textAlign: 'right' }}>
            <Button sx={{ mr: 3 }} variant="outlined" color="warning" onClick={() => handleClose(false)}>
              {cancelText}
            </Button>
            <Button variant="contained" color="warning" onClick={() => handleClose(true)}>
              {confirmText}
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default YesNoDialog;
