import { getTenantIdForDomain } from 'helpers/tenantHelpers';
import {
  COLORADO_TENANT_ID,
  OREGON_TENANT_ID,
  VIRGINIA_TENANT_ID,
  DARTMOUTH_TENANT_ID,
  CS_TENANT_ID,
  DEMO_TENANT_ID,
} from 'utils/constants';
import ColoradoLoginTitle from './components/ColoradoLoginTitle';
import OregonLoginTitle from './components/OregonLoginTitle';
import VirginiaLoginTitle from './components/VirginiaLoginTitle';
import DartmouthLoginTitle from './components/DartmouthLoginTitle';
import CSLoginTitle from './components/CSLoginTitle';
import DemoLoginTitle from './components/DemoLoginTitle';

const LoginTitle = () => {
  const currentTenantId = getTenantIdForDomain();

  if (currentTenantId === COLORADO_TENANT_ID) {
    return <ColoradoLoginTitle />;
  }

  if (currentTenantId === OREGON_TENANT_ID) {
    return <OregonLoginTitle />;
  }

  if (currentTenantId === VIRGINIA_TENANT_ID) {
    return <VirginiaLoginTitle />;
  }

  if (currentTenantId === DARTMOUTH_TENANT_ID) {
    return <DartmouthLoginTitle />;
  }

  if (currentTenantId === DEMO_TENANT_ID) {
    return <DemoLoginTitle />;
  }

  if (currentTenantId === CS_TENANT_ID) {
    return <CSLoginTitle />;
  }

  return <></>;
};

export default LoginTitle;
