import { gql } from '@apollo/client';

export const toggleArchiveState_Name = 'ToggleArchiveState';
export const toggleArchiveState_Gql = gql`
  mutation ToggleArchiveState($resourceId: String!, $isArchived: Boolean!) {
    toggleArchiveState(resourceId: $resourceId, isArchived: $isArchived) {
      id
      archived
    }
  }
`;
