import { gql } from '@apollo/client';

export const getAllOpenCohorts_Name = 'GetAllOpenCohorts';
export const getAllOpenCohorts_Gql = gql`
  query GetAllOpenCohorts($status: String!) {
    getAllOpenCohorts(status: $status) {
      id
      internalLabel
      externalLabel
      startDate
      endDate
      isOngoing
      meetingLink
      archived
      registrationCloseDate
      registrationOpenDate
      preRegistrantsCount
      newRegistrantsCount
      registeredCount
      waitlistCount
      deniedCount
      nextSessionDate
      program {
        name
      }
      developmentUser {
        id
        fullName
        email
      }
      implementationUser {
        id
        fullName
        email
      }
      evaluationUser {
        id
        fullName
        email
      }
      developmentSupportUser {
        id
        firstName
        lastName
      }
      evaluationSupportUser {
        id
        firstName
        lastName
      }
      implementationSupportUser {
        id
        firstName
        lastName
      }
    }
  }
`;
