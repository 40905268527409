import { gql } from '@apollo/client';

export const addResource_Name = 'AddResource';
export const addResource_Gql = gql`
  mutation AddResource($resource: AddResourceInput!) {
    addResource(resource: $resource) {
      id
    }
  }
`;
