import { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { makeStyles } from '@mui/styles';
import {
  TextField,
  Select,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
  FormControl,
  Grid,
  MenuItem,
  Alert,
  Button,
  Tooltip,
  Typography,
  Autocomplete
} from '@mui/material';
import { Theme, createStyles } from '@mui/material/styles';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import ProgressIndicator from '../../global/ProgressIndicator';
import SuccessSnackbar from '../../global/SuccessSnackbar';

import { GetCohortByIdQuery_Name } from '../../../gql/cohort/getCohortByIdQuery';
import { getCohortDashboard_Name } from '../../../gql/cohort/getCohortDashboard';
import { UpdateCohort_Gql } from '../../../gql/cohort/updateCohort';
import { getHubTeamUsers_Gql } from '../../../gql/user/getHubTeamUsers';
import {
  EditCohortInput,
  GetCohortById_getCohortById,
} from '../../../models/GeneratedModels';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
const timeZones = require('../../../utils/timeZones.json');

dayjs.extend(utc);
dayjs.extend(tz);

interface EditCohortDetailsProps {
  cohort: GetCohortById_getCohortById;
}

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    dateField: {
      position: 'relative'
    },
    edit: {
      color: 'goldenrod',
      cursor: 'pointer',
    },
    delete: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      position: 'absolute',
      top: 8,
      right: 40
    },
    attendanceLink: {
      color: theme.palette.primary.main,
    },
  })
);

const EditCohortDetails = ({ cohort }: EditCohortDetailsProps) => {
  const [cohortDetail, setCohortDetail] =
    useState<GetCohortById_getCohortById>(cohort);
  const [showProgress, setShowProgress] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const startDateRef = useRef<HTMLInputElement | null>(null);
  const endDateRef = useRef<HTMLInputElement | null>(null);
  const registrationOpenDateRef = useRef<HTMLInputElement | null>(null);
  const registrationCloseDateRef = useRef<HTMLInputElement | null>(null);

  const classes = useStyles();

  useEffect(() => {
    setCohortDetail(cohort);
  }, [cohort]);

  const { loading: hubTeamLoading, data } = useQuery(getHubTeamUsers_Gql);

  const [updateCohort, { loading, error }] = useMutation(UpdateCohort_Gql, {
    refetchQueries: [GetCohortByIdQuery_Name, getCohortDashboard_Name],
  });

  const [isDevelopmentUserIdValid, setIsDevelopmentUserIdValid] = useState(
    cohortDetail.developmentUserId ?? false
  );
  const [isImplementationUserIdValid, setIsImplementationUserIdValid] =
    useState(cohortDetail.implementationUserId ?? false);
  const [isEvaluationUserIdValid, setIsEvaluationUserIdValid] = useState(
    cohortDetail.evaluationUserId ?? false
  );

  const parsedStartDate = cohortDetail.startDate ? cohortDetail.startDate.split('T')[0] : '';
  const parsedEndDate = cohortDetail.endDate ? cohortDetail.endDate.split('T')[0]   : '';
  const parsedRegistrationOpenDate = cohortDetail.registrationOpenDate ? cohortDetail.registrationOpenDate.split('T')[0]  : '';
  const parsedRegistrationCloseDate = cohortDetail.registrationCloseDate ? cohortDetail.registrationCloseDate.split('T')[0] : '';

  const [startDate, setStartDate] = useState(parsedStartDate);
  const [endDate, setEndDate] = useState(parsedEndDate);
  const [registrationOpenDate, setRegistrationOpenDate] = useState(parsedRegistrationOpenDate);
  const [registrationCloseDate, setRegistrationCloseDate] = useState(parsedRegistrationCloseDate);

  useEffect(() => {
    if (hubTeamLoading || loading) {
      setShowProgress(true);
    } else {
      setShowProgress(false);
    }
  }, [hubTeamLoading, loading]);

  const saveCohortDetail = async () => {
    try {

      const sDate = startDateRef?.current?.value ||'';
      const eDate = endDateRef?.current?.value ||'';
      const rOpenDate = registrationOpenDateRef?.current?.value ||'';
      const rCloseDate = registrationCloseDateRef?.current?.value ||'';

      const submitStartDate = sDate ? `${sDate}T00:00:00.000Z` : null;
      const submitEndDate = eDate ? `${eDate}T00:00:00.000Z` : null;
      const submitRegistrationOpenDate = rOpenDate ? `${rOpenDate}T00:00:00.000Z` : null;
      const submitRegistrationCloseDate = rCloseDate ? `${rCloseDate}T00:00:00.000Z` : null;

      const cohortToUpdate: EditCohortInput = {
        id: cohortDetail.id,
        developmentUserId: cohortDetail.developmentUserId,
        implementationUserId: cohortDetail.implementationUserId,
        evaluationUserId: cohortDetail.evaluationUserId,
        meetingLink: cohortDetail.meetingLink,
        evaluationGoal: cohortDetail.evaluationGoal,
        audience: cohortDetail.audience,
        startDate: submitStartDate,
        endDate: cohortDetail.isOngoing ? null : submitEndDate,
        scheduleDescription: cohortDetail.scheduleDescription,
        statistics: cohortDetail.statistics,
        registrationOpenDate: submitRegistrationOpenDate,
        registrationCloseDate: cohortDetail.isOngoing
          ? null
          : submitRegistrationCloseDate,
        archived: cohortDetail.archived,
        internalLabel: cohortDetail.internalLabel,
        externalLabel: cohortDetail.externalLabel,
        prelaunchDateTime: cohortDetail.prelaunchDateTime,
        projectPartner: cohortDetail.projectPartner,
        learningObjectives: cohortDetail.learningObjectives,
        caseFormLink: cohortDetail.caseFormLink,
        surveyLink: cohortDetail.surveyLink,
        timeZone: cohortDetail.timeZone,
        allowPreregistration: cohortDetail.allowPreregistration,
        isOngoing: cohortDetail.isOngoing,
        sessionDurationInMinutes: cohortDetail.sessionDurationInMinutes,
        implementationSupportUserIds: cohortDetail.implementationSupportUserIds,
        developmentSupportUserIds: cohortDetail.developmentSupportUserIds,
        evaluationSupportUserIds: cohortDetail.evaluationSupportUserIds,
      };

      await updateCohort({
        variables: {
          cohort: cohortToUpdate,
        },
      });

      if (!error) setShowSuccess(true);
    } catch {}
  };

  // Change event handlers
  const onFormElementChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'sessionDurationInMinutes') {
      setCohortDetail({
        ...cohortDetail,
        sessionDurationInMinutes: value.length > 0 ? Number(value) : 0,
      });
    } else {
      setCohortDetail({
        ...cohortDetail,
        [name]: value,
      });
    }
  };

  const handleClearStartDate = () => {
    setStartDate('');
  };

  const handleClearEndDate = () => {
    setEndDate('');
  };

  const handleClearRegistrationOpenDate = () => {
    setRegistrationOpenDate('');
  };

  const handleClearRegistrationCloseDate = () => {
    setRegistrationCloseDate('');
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== "") {
      const updatedStartDate = event.target.value;
      setStartDate(updatedStartDate);
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== "") {
      const updatedEndDate = event.target.value;
      setEndDate(updatedEndDate);
    }
  };

  const handleRegistrationOpenDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== "") {
      const updatedRegistrationOpenDate = event.target.value;
      setRegistrationOpenDate(updatedRegistrationOpenDate);
    }
  };

  const handleRegistrationCloseDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== "") {
      const updatedRegistrationCloseDate = event.target.value;
      setRegistrationCloseDate(updatedRegistrationCloseDate);
    }
  };

  const handleCheckedChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.checked;
    setCohortDetail({
      ...cohortDetail,
      [name]: value,
    });
  };

  const formatTimeZone = (timeZone: string) => {
    return timeZone.replace(new RegExp('&amp;', 'g'), '&');
  };

  return (
    <>
      <ProgressIndicator isOpen={showProgress} title="Saving" />

      {error && (
        <Alert sx={{ mb: 2 }} severity="error">
          {error}
        </Alert>
      )}

      <Grid container spacing={4} sx={{ pb: 2, mt: 1 }}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Stack direction="row" spacing={2} flex={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    color="primary"
                    checked={cohortDetail.allowPreregistration || false}
                    name="allowPreregistration"
                    onChange={handleCheckedChange}
                  />
                }
                label="Allow Pre-Registration"
              />
              
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    color="primary"
                    checked={cohortDetail.isOngoing}
                    name="isOngoing"
                    onChange={handleCheckedChange}
                  />
                }
                label="Ongoing Cohort"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    color="primary"
                    checked={cohortDetail.archived}
                    name="archived"
                    onChange={handleCheckedChange}
                  />
                }
                label="Archive Cohort"
              />
            </Stack>

            <Select
              size='small'
              labelId="timezone-select-label"
              label="Time Zone"
              name="timeZone"
              color="warning"
              value={cohortDetail.timeZone ?? undefined}
              disabled={true}
            >
              <MenuItem value="">
                <em>Select a Timezone</em>
              </MenuItem>
              {timeZones.map((tz: any, i: any) => (
                <MenuItem key={i} value={tz.value}>
                  {formatTimeZone(tz.text)}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            size='small'
            label="Internal Title"
            variant="outlined"
            name="internalLabel"
            value={cohortDetail.internalLabel}
            onChange={onFormElementChange}
            color="warning"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            size='small'
            label="External Title"
            variant="outlined"
            name="externalLabel"
            value={cohortDetail.externalLabel}
            color="warning"
            onChange={onFormElementChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.dateField}>
            <TextField
              size='small'
              type="date"
              id="startDate"
              name="startDate"
              inputRef={startDateRef}
              onChange={handleStartDateChange}
              value={startDate}
              style={{ width: '100%', marginRight: '8px' }}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label="Start Date"
              color="warning"
            />
            {startDate && (
              <Tooltip title="Clear" placement='top'>
                <CancelOutlinedIcon
                  className={classes.delete}
                  onClick={handleClearStartDate}
                />
              </Tooltip>
            )}
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.dateField}>
            <TextField
              size='small'
              type="date"
              id="registrationOpenDate"
              name="registrationOpenDate"
              inputRef={registrationOpenDateRef}
              onChange={handleRegistrationOpenDateChange}
              value={registrationOpenDate}
              style={{ width: '100%', marginRight: '8px' }}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label="Date to Open Registration"
              color="warning"
            />
            {registrationOpenDate && (
              <Tooltip title="Clear" placement='top'>
                <CancelOutlinedIcon
                  className={classes.delete}
                  onClick={handleClearRegistrationOpenDate}
                />
              </Tooltip>
            )}
          </div>
        </Grid>

        {!cohortDetail.isOngoing && (
          <Grid item xs={12} sm={6}>
            <div className={classes.dateField}>
              <TextField
                size='small'
                type="date"
                id="endDate"
                name="endDate"
                inputRef={endDateRef}
                onChange={handleEndDateChange}
                value={endDate}
                style={{ width: '100%', marginRight: '8px' }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label="End Date"
                color="warning"                 
              />
              {endDate && (
                <Tooltip title="Clear" placement='top'>
                  <CancelOutlinedIcon
                    className={classes.delete}
                    onClick={handleClearEndDate}
                  />
                </Tooltip>
              )}
            </div>
          </Grid>
        )}

        {!cohortDetail.isOngoing && (
          <Grid item xs={12} sm={6}>
            <div className={classes.dateField}>
              <TextField
                size='small'
                type="date"
                id="registrationCloseDate"
                name="registrationCloseDate"
                inputRef={registrationCloseDateRef}
                onChange={handleRegistrationCloseDateChange}
                value={registrationCloseDate}
                style={{ width: '100%', marginRight: '8px' }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label="Date to Close Registration"
                color="warning"
              />
              {registrationCloseDate && (
                <Tooltip title="Clear" placement='top'>
                  <CancelOutlinedIcon
                    className={classes.delete}
                    onClick={handleClearRegistrationCloseDate}
                  />
                </Tooltip>
              )}
            </div>
          </Grid>
        )}

        <Grid item xs={12} marginTop={'10px'}>
          <Typography variant="h6" gutterBottom>Cohort Links</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            size='small'
            label="Video Conference Link"
            variant="outlined"
            name="meetingLink"
            value={cohortDetail.meetingLink}
            color="warning"
            onChange={onFormElementChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            size='small'
            label="Case Form Link"
            variant="outlined"
            name="caseFormLink"
            value={cohortDetail.caseFormLink}
            color="warning"
            onChange={onFormElementChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            size='small'
            label="Survey Link"
            variant="outlined"
            name="surveyLink"
            value={cohortDetail.surveyLink}
            color="warning"
            onChange={onFormElementChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} marginTop={'10px'}>
          <Typography variant="h6" gutterBottom>Assign Users</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl error={!isDevelopmentUserIdValid} fullWidth size='small'>
            <Autocomplete
              size='small'
              id="development-lead-autocomplete"
              options={data?.getHubTeamUsers.developmentUsers ?? []}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
              value={data?.getHubTeamUsers.developmentUsers.find((user: any) => user.id === cohortDetail.developmentUserId) || null}
              onChange={(e, newValue) => {
                setIsDevelopmentUserIdValid(!!newValue);
                setCohortDetail({
                  ...cohortDetail,
                  developmentUserId: newValue ? newValue.id : null
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Development Lead"
                  placeholder="Select Development Lead"
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            {!isDevelopmentUserIdValid && (
              <FormHelperText>This field is required.</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl error={!isImplementationUserIdValid} fullWidth size='small'>
            <Autocomplete
              size='small'
              id="implementation-lead-autocomplete"
              options={data?.getHubTeamUsers.implementationUsers ?? []}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
              value={data?.getHubTeamUsers.implementationUsers.find((user: any) => user.id === cohortDetail.implementationUserId) || null}
              onChange={(e, newValue) => {
                setIsImplementationUserIdValid(!!newValue);
                setCohortDetail({
                  ...cohortDetail,
                  implementationUserId: newValue ? newValue.id : null
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Implementation Lead"
                  placeholder="Select Implementation Lead"
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            {!isImplementationUserIdValid && (
              <FormHelperText>This field is required.</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl error={!isEvaluationUserIdValid} fullWidth size='small'>
            <Autocomplete
              size='small'
              id="evaluation-lead-autocomplete"
              options={data?.getHubTeamUsers.evaluationUsers ?? []}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
              value={data?.getHubTeamUsers.evaluationUsers.find((user: any) => user.id === cohortDetail.evaluationUserId) || null}
              onChange={(e, newValue) => {
                setIsEvaluationUserIdValid(!!newValue);
                setCohortDetail({
                  ...cohortDetail,
                  evaluationUserId: newValue ? newValue.id : null
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Evaluation Lead"
                  placeholder="Select Evaluation Lead"
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            {!isEvaluationUserIdValid && (
              <FormHelperText>This field is required.</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size='small'>
            <Autocomplete
              size='small'
              multiple
              id="development-support-autocomplete"
              options={data?.getHubTeamUsers.developmentUsers ?? []}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
              value={data?.getHubTeamUsers.developmentUsers.filter((user: any) => cohortDetail?.developmentSupportUserIds?.includes(user.id)) || []}
              onChange={(e, newValue) => {
                setCohortDetail({
                  ...cohortDetail,
                  developmentSupportUserIds: newValue.map((user) => user.id)
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Development Support"
                  placeholder="Select Development Support"
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size='small'>
            <Autocomplete
              size='small'
              multiple
              id="implementation-support-autocomplete"
              options={data?.getHubTeamUsers.implementationUsers ?? []}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
              value={data?.getHubTeamUsers.implementationUsers.filter((user: any) => cohortDetail?.implementationSupportUserIds?.includes(user.id)) || []}
              onChange={(e, newValue) => {
                setCohortDetail({
                  ...cohortDetail,
                  implementationSupportUserIds: newValue.map((user) => user.id)
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Implementation Support"
                  placeholder="Select Implementation Support"
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size='small'>
            <Autocomplete
              size='small'
              multiple
              id="evaluation-support-autocomplete"
              options={data?.getHubTeamUsers.evaluationUsers ?? []}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
              value={data?.getHubTeamUsers.evaluationUsers.filter((user: any) => cohortDetail?.evaluationSupportUserIds?.includes(user.id)) || []}
              onChange={(e, newValue) => {
                setCohortDetail({
                  ...cohortDetail,
                  evaluationSupportUserIds: newValue.map((user) => user.id)
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Evaluation Support"
                  placeholder="Select Evaluation Support"
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          {error && (
            <Alert sx={{ mt: 2 }} severity="error">
              {error}
            </Alert>
          )}

          <Button
            sx={{ mt: 2 }}
            onClick={saveCohortDetail}
            variant="contained"
            color="primary"
            disabled={
              !(
                isDevelopmentUserIdValid &&
                isImplementationUserIdValid &&
                isEvaluationUserIdValid
              )
            }
          >
            Save Details
          </Button>

          <SuccessSnackbar
            isOpen={showSuccess}
            text="Cohort details have been saved"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EditCohortDetails;
