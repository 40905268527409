import { gql } from '@apollo/client';

export const GetCohortByIdQuery_Name = 'GetCohortById';
export const GetCohortByIdQuery_Gql = gql`
  query GetCohortById($cohortId: String!) {
    getCohortById(cohortId: $cohortId) {
      id
      programId
      developmentUserId
      implementationUserId
      evaluationUserId
      meetingLink
      caseFormLink
      evaluationGoal
      audience
      agreement
      startDate
      endDate
      scheduleDescription
      statistics
      registrationOpenDate
      registrationCloseDate
      registrationQuestions {
        id
        title
        type
        name
        required
        isMultiSelect
        yesNo {
          noText
          yesText
        }
        options {
          label
          value
        }
        hasOtherOption
        scale {
          label
        }
        statements {
          name
          statement
        }
      }
      archived
      internalLabel
      externalLabel
      prelaunchDateTime
      projectPartner
      tenantId
      learningObjectives
      surveyLink
      timeZone
      allowPreregistration
      isOngoing
      sessionDurationInMinutes
      agreement
      developmentSupportUserIds
      evaluationSupportUserIds
      implementationSupportUserIds
    }
  }
`;
