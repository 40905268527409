import { gql } from '@apollo/client';

export const addPartnerMutation_Name = 'AddPartner';
export const addPartnerMutation_Gql = gql`
  mutation AddPartner($partner: AddPartnerInput!) {
    addPartner(partner: $partner) {
      id
      name
    }
  }
`;
