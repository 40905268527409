import { gql } from '@apollo/client';

export const addRegistrationQuestion_Name = 'AddRegistrationQuestion';
export const addRegistrationQuestion_Gql = gql`
  mutation AddRegistrationQuestion($cohortId: String!, $registrationQuestion: RegistrationQuestionInput!) {
    addRegistrationQuestion(cohortId: $cohortId, registrationQuestion: $registrationQuestion) {
      id
    }
  }
`;
