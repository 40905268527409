import EditorToolbar, { formats } from './components/QuillToolbar';
import 'react-quill/dist/quill.snow.css';

const ReactQuill = require('react-quill');

interface TextEditorProps {
  title?: string;
  name: string;
  value: string | null;
  onChange: (name: string, value: string) => void;
  style: any;
}

const TextEditor = ({ name, onChange, value, title, style }: TextEditorProps) => {
  return (
    <>
      {title && <strong>{title}</strong>}
      <EditorToolbar name={'toolbar-' + name} />
      <ReactQuill
        theme="snow"
        id={name}
        value={value}
        style={style}
        onChange={(value: string) => onChange(name, value)}
        modules={{
          toolbar: {
            container: '#toolbar-' + name,
          },
        }}
        formats={formats}
      />
    </>
  );
};

export default TextEditor;
