import { useMutation } from '@apollo/client';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';

import ProgressIndicator from '../../../../global/ProgressIndicator';
import { getRegistrantsForCohortQuery_Name } from '../../../../../gql/cohortRegistration/getRegistrantsForCohortQuery';
import { approveCohortRegistrationMutation_Gql } from '../../../../../gql/cohortRegistration/approveCohortRegistrationMutation';
import { ApproveCohortRegistrationInput } from '../../../../../models/GeneratedModels';

interface ApproveIconProps {
  approveRegistrationInput: ApproveCohortRegistrationInput;
}

const ApproveIcon = ({ approveRegistrationInput }: ApproveIconProps) => {
  const [approveUser, { loading, error }] = useMutation(approveCohortRegistrationMutation_Gql, {
    variables: {
      cohortRegistration: approveRegistrationInput,
    },
    refetchQueries: [getRegistrantsForCohortQuery_Name],
  });

  const approveClick = async () => {
    try {
      await approveUser();
    } catch {}
  };

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Saving..." />

      {error && <Alert severity="error">{error.graphQLErrors[0].message}</Alert>}

      <Tooltip title="Approve Registrant">
        <CheckIcon onClick={approveClick} sx={{ color: 'green', cursor: 'pointer', mr: 1 }} />
      </Tooltip>
    </>
  );
};

export default ApproveIcon;
