import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import StyledRouterLink from 'components/global/StyledRouterLink';
import { programLinks } from 'utils/links';

interface ProgramBreadcrumbProps {
  programId: string;
  currentPageText: string;
}

const ProgramBreadcrumb = ({ programId, currentPageText }: ProgramBreadcrumbProps) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 25 }}>
      <StyledRouterLink to={`${programLinks.programDashboard.href}/${programId}`} text="Program Dashboard" />

      <Typography color="textPrimary">{currentPageText}</Typography>
    </Breadcrumbs>
  );
};

export default ProgramBreadcrumb;
