import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment-timezone';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';

import PageHeader from 'components/global/PageHeader';
import ProgressIndicator from '../../global/ProgressIndicator';
import TextEditor from '../../global/TextEditor/TextEditor';

import {
  GetSessionsByCohort_getSessionsByCohort,
  UpdateSessionInput,
} from '../../../models/GeneratedModels';
import { updateSessionMutation_Gql } from '../../../gql/session/updateSessionMutation';
import { getSessionsForCohortQuery_Name } from '../../../gql/session/getSessionsForCohortQuery';

import { getTenantIdForDomain } from 'helpers/tenantHelpers';
import { getTimeZoneForTenant, convertMinutesToHours, splitDateTime } from 'helpers/dateHelpers';

dayjs.extend(utc);
dayjs.extend(tz);

interface EditSessionProps {
  sessionId: string,
  sessionCohortId: string,
  sessionNumber: number | null,
  sessionTitle: string | null,
  sessionSartDate: string,
  sEndDate: string,
  sessionActive: boolean,
  sessionLearningOutcomes: string | null,
  sessionPresenterNotes: string | null,
  sessionFacultyNotes: string | null,
}

const EditSession = ({   
  sessionId,
  sessionCohortId,
  sessionNumber,
  sessionTitle,
  sessionSartDate,
  sEndDate,
  sessionActive,
  sessionLearningOutcomes,
  sessionPresenterNotes,
  sessionFacultyNotes }: EditSessionProps) => {

  const startDateRef = useRef<HTMLInputElement | null>(null);
  const startTimeRef = useRef<HTMLInputElement | null>(null);
  const endDateRef = useRef<HTMLInputElement | null>(null);
  const endTimeRef = useRef<HTMLInputElement | null>(null);
  
  const [open, setOpen] = useState(false);
  const [sessionToEdit, setSessionToEdit] =
    useState<GetSessionsByCohort_getSessionsByCohort>({
      __typename: "Session",
      id: sessionId,
      cohortId: sessionCohortId,
      number: sessionNumber,
      title: `${sessionTitle}`,
      startDate: sessionSartDate,
      endDate: sEndDate,
      active: !!sessionActive,
      learningOutcomes: `${sessionLearningOutcomes}`,
      presenterNotes: `${sessionPresenterNotes}`,
      facultyNotes: `${sessionFacultyNotes}`,
      isFuture: false, 
    });
  const [hasTitleError, setHasTitleError] = useState(false);
  const [sendCalendarInvites, setSendCalendarInvites] = useState(false);

  const [updateSession, { loading, error }] = useMutation(
    updateSessionMutation_Gql,
    {
      refetchQueries: [getSessionsForCohortQuery_Name],
    }
  );
  const tenantId = getTenantIdForDomain();
  const timeZone = getTimeZoneForTenant(tenantId);
  const currentDate = new Date();
  const janOffset = new Date(currentDate.getFullYear(), 0, 1).getTimezoneOffset();
  const julOffset = new Date(currentDate.getFullYear(), 6, 1).getTimezoneOffset();
  const isDST = Math.min(janOffset, julOffset) !== currentDate.getTimezoneOffset();
  
  const tenantTimeZone = isDST ? moment.tz(timeZone).utcOffset() - 60  : moment.tz(timeZone).utcOffset();
  const userTimeZone = isDST ? moment.tz(moment.tz.guess()).utcOffset() - 60  : moment.tz(moment.tz.guess()).utcOffset();
  const userTimeZonePST = tenantTimeZone > userTimeZone ? '-' : '';
  const userTenantTimeZoneDifference = Math.abs(tenantTimeZone - userTimeZone);
  const timeZoneOffset = tenantTimeZone + parseInt(`${userTimeZonePST}${userTenantTimeZoneDifference}`);

  const parsedStartDateTime = dayjs(sessionToEdit.startDate).utc();
  const parsedEndDateTime = dayjs(sessionToEdit.endDate).utc();

  const [sessionStartDate, setSessionStartDate] = useState(parsedStartDateTime.format('YYYY-MM-DD')); // State to hold the session start time
  const [sessionStartTime, setSessionStartTime] = useState(parsedStartDateTime.format('HH:mm:ss')); // State to hold the session start time
  const [sessionEndDate, setSessionEndDate] = useState(parsedEndDateTime.format('YYYY-MM-DD')); // State to hold the session start time
  const [sessionEndTime, setSessionEndTime] = useState(parsedEndDateTime.format('HH:mm:ss')); // State to hold the session start time

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendCalendarInvites(event.target.checked);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === 'number') {
      setSessionToEdit({ ...sessionToEdit, [name]: Number(value) });
    } else {
      setSessionToEdit({ ...sessionToEdit, [name]: value });
    }
  };

  const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== "") {
      const updatedStartTime = event.target.value
      setSessionStartTime(updatedStartTime);
    } else {
      console.log('startTime');
    }
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== "") {
      const updatedEndTime = event.target.value
      setSessionEndTime(updatedEndTime);
    } else {
      console.log('endTime');
    }
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== "") {
      const updatedStartDate = event.target.value
      setSessionStartDate(updatedStartDate);
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== "") {
      const updatedEndDate = event.target.value
      setSessionEndDate(updatedEndDate);
    }
  };

  const onTextEditorChange = (name: string, value: string) => {
    setSessionToEdit({ ...sessionToEdit, [name]: value });
  };

  const formValidation = (): boolean => {
    let isValid = true;
    setHasTitleError(false);

    if (sessionToEdit.title === null || sessionToEdit.title?.length === 0) {
      setHasTitleError(true);
      isValid = false;
    }

    return isValid;
  };

  const saveSession = async () => {
    if (!formValidation()) return;

    const sessionInput: UpdateSessionInput = {
      id: sessionToEdit.id,
      cohortId: sessionToEdit.cohortId,
      number: sessionToEdit.number,
      title: sessionToEdit.title,
      startDate: sessionToEdit.startDate,
      endDate: sessionToEdit.endDate,
      active: sessionToEdit.active,
      learningOutcomes: sessionToEdit.learningOutcomes,
      presenterNotes: sessionToEdit.presenterNotes,
      facultyNotes: sessionToEdit.facultyNotes,
      sendCalendarInvites: sendCalendarInvites,
    };

    const sDate = startDateRef?.current?.value ||'';
    const sTime = startTimeRef?.current?.value ||'';
    const eDate = endDateRef?.current?.value ||'';
    const eTime = endTimeRef?.current?.value ||'';

    const submitStartDateTime = moment(`${sDate}${sTime}`, 'YYYY-MM-DD HH:mm', timeZone);
    const submitEndDateTime = moment(`${eDate}${eTime}`, 'YYYY-MM-DD HH:mm', timeZone);

    sessionInput.startDate = new Date(`${submitStartDateTime.format('YYYY-MM-DD')}T${submitStartDateTime.format('HH:mm:ss')}.000Z`);
    sessionInput.endDate = new Date(`${submitEndDateTime.format('YYYY-MM-DD')}T${submitEndDateTime.format('HH:mm:ss')}.000Z`);
  
    try {
      await updateSession({
        variables: { session: sessionInput },
      });
    } catch {}

    if (!error) {
      setOpen(false);
    }
  };

  const cancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Edit Session">
        <EditIcon
          onClick={() => setOpen(true)}
          sx={{ color: 'primary.main', cursor: 'pointer' }}
        />
      </Tooltip>

      <Dialog open={open} onClose={cancel}>
        <DialogContent>
          <ProgressIndicator isOpen={loading} title="Saving Session" />

          <Box sx={{ textAlign: 'center' }}>
            <PageHeader title="Edit Session" />
          </Box>

          <Box sx={{ width: 550 }}>
            {error && (
              <Alert sx={{ mb: 2 }} severity="error">
                {error}
              </Alert>
            )}

            <Stack sx={{ mt: 3 }} spacing={2}>
              <div>
                <Tooltip title="This will send calendar invites for the session to all registered learners and hub team members">
                  <InfoIcon
                    sx={{ fontSize: '16px', mr: 2, color: 'primary.main' }}
                  />
                </Tooltip>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={sendCalendarInvites}
                      onChange={handleCheckedChange}
                    />
                  }
                  label="Send Calendar Invites for this Session"
                />
              </div>

              <TextField
                sx={{ width: '100%' }}
                name="title"
                onChange={handleTextChange}
                variant="outlined"
                label="Title"
                value={sessionToEdit.title}
                error={hasTitleError}
                helperText="Required"
                color="warning"
              />

              <TextField
                sx={{ width: '100%' }}
                name="number"
                onChange={handleTextChange}
                variant="outlined"
                label="Session Number"
                type="number"
                color="warning"
                value={sessionToEdit.number}
              />

              <div style={{ display: 'flex' }}>
                <TextField
                  sx={{ mb: 3 }}
                  type="date"
                  id="startDate"
                  name="startDate"
                  inputRef={startDateRef}
                  onChange={handleStartDateChange}
                  value={sessionStartDate}
                  style={{ width: '100%', marginRight: '8px' }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Session Start Date"
                  color="warning"
                />

                <TextField
                  sx={{ mb: 3 }}
                  type="time"
                  id="startTime"
                  name="startstartTimeDate"
                  inputRef={startTimeRef}
                  onChange={handleStartTimeChange}
                  value={sessionStartTime.substring(0, 5)}
                  style={{ width: '100%', marginRight: '8px' }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Time"
                  color="warning" 
                />
              </div>

              <div style={{ display: 'flex' }}>
                <TextField
                  sx={{ mb: 3 }}
                  type="date"
                  id="endDate"
                  name="endDate"
                  inputRef={endDateRef}
                  onChange={handleEndDateChange}
                  value={sessionEndDate}
                  style={{ width: '100%', marginRight: '8px' }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Session End Date"
                  color="warning"
                />

                <TextField
                  sx={{ mb: 3 }}
                  type="time"
                  id="endTime"
                  name="endTime"
                  inputRef={endTimeRef}
                  onChange={handleEndTimeChange}
                  value={sessionEndTime.substring(0, 5)}
                  style={{ width: '100%', marginRight: '8px' }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Time"
                  color="warning" 
                />
              </div>

              <Alert sx={{ mt: 2 }} severity="info">
                Note: These times are in the organization's local timezone.
              </Alert>
            </Stack>

            <Box sx={{ mt: 2 }}>
              <TextEditor
                name="learningOutcomes"
                onChange={onTextEditorChange}
                value={sessionToEdit.learningOutcomes}
                title="Learning Outcomes"
                style={{ height: '200px' }}
              />
            </Box>

            <Box sx={{ mt: 8 }}>
              <TextEditor
                name="presenterNotes"
                onChange={onTextEditorChange}
                value={sessionToEdit.presenterNotes}
                title="Presenter Notes"
                style={{ height: '200px' }}
              />
            </Box>

            <Box sx={{ mt: 8 }}>
              <TextEditor
                name="facultyNotes"
                onChange={onTextEditorChange}
                value={sessionToEdit.facultyNotes}
                title="Faculty Notes"
                style={{ height: '200px' }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={saveSession} variant="contained">
            Save
          </Button>
          <Button onClick={cancel} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditSession;
