import axios from 'axios';
import County from '../models/County.model';
import { reactapp_APIURL } from './tenantHelpers';

export const getCountiesByState = async (state: string): Promise<County[]> => {
  if (state.length === 0) return [];

  const url = `${reactapp_APIURL}/county?state=${state}`;
  const result = (await axios.get(url)).data;

  return result.data as County[];
};
