import { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';

interface SuccessSnackbarProps {
  isOpen: boolean;
  text: string;
}

const SuccessSnackbar = ({ isOpen, text }: SuccessSnackbarProps) => {
  const [open, setOpen] = useState(isOpen);
  const [successText, setSuccessText] = useState(text);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setSuccessText(successText);
  }, [successText]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={5000}
      >
        <Alert sx={{ mt: 2 }} severity="success">
          {successText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SuccessSnackbar;
