import { gql } from '@apollo/client';

export const updatePartnerMutation_Name = 'UpdatePartner';
export const updatePartnerMutation_Gql = gql`
  mutation UpdatePartner($partner: UpdatePartnerInput!) {
    updatePartner(partner: $partner) {
      id
      name
    }
  }
`;
