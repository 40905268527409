import { gql } from '@apollo/client';

export const UpdateCurrentPassword_Name = 'UpdateCurrentPassword';
export const UpdateCurrentPassword_Gql = gql`
  mutation UpdateCurrentPassword($updateCredentials: ValidateUpdateCurrentPasswordInput!) {
    updateCurrentPassword(updateCredentials: $updateCredentials) {
      isValid
      errorMessage
    }
  }
`;