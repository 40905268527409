import { gql } from '@apollo/client';

export const getAttendanceForSession_Name = 'GetAttendanceForSession';
export const getAttendanceForSession_Gql = gql`
  query GetAttendanceForSession($cohortId: String!, $sessionId: String!) {
    getAttendanceForSession(cohortId: $cohortId, sessionId: $sessionId) {
      cohortRegistrationId
      sessionId
      userId
      sessionAttendanceId
      firstName
      lastName
      email
      credentials
      organization
      isPresent
      notes
    }
  }
`;
