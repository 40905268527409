import { gql } from '@apollo/client';

export const UpdateCohort_Name = 'EditCohort';
export const UpdateCohort_Gql = gql`
  mutation EditCohort($cohort: EditCohortInput!) {
    editCohort(cohort: $cohort) {
      id
      internalLabel
    }
  }
`;
