import { useQuery } from '@apollo/client';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MyEchoCohortList from 'components/learner/MyEchos/components/MyEchoCohortList';
import PageHeader from 'components/global/PageHeader';
import ProgressIndicator from 'components/global/ProgressIndicator';
import StyledRouterLink from 'components/global/StyledRouterLink';

import { useAuth } from '../hooks/useAuth';
import { getLearnerCohorts_Gql } from 'gql/cohort/getLearnerCohorts';
import { getCohortByHubMember_Gql } from 'gql/cohort/getCohortByHubMember';
import {
  GetLearnerCohorts,
  GetLearnerCohortsVariables,
  GetLearnerCohorts_getLearnerCohorts,
  GetCohortByHubMember,
  GetCohortByHubMemberVariables,
  GetCohortByHubMember_getCohortByHubMember
} from 'models/GeneratedModels';
import { Container } from '@mui/material';

const MyEchos = () => {
  const auth = useAuth();
  const { data, loading } = useQuery<GetLearnerCohorts, GetLearnerCohortsVariables>(getLearnerCohorts_Gql, {
    variables: {
      userId: auth?.user?.id ?? '',
    },
  });

  const { data: hubMember, loading: hubMemberLoading } = useQuery<GetCohortByHubMember, GetCohortByHubMemberVariables>(getCohortByHubMember_Gql, {
    variables: {
      userid: auth.user?.id ?? ''
    }
  })

  const cohorts: GetLearnerCohorts_getLearnerCohorts | null | undefined = data?.getLearnerCohorts;
  const hubMemberCohorts: GetCohortByHubMember_getCohortByHubMember[] | null = hubMember?.getCohortByHubMember || [];

  const showNoEchos = (): boolean => {
    if (!cohorts) return true;

    return (
      cohorts.pendingCohorts?.length === 0 &&
      cohorts.currentCohorts?.length === 0 &&
      cohorts.waitlistCohorts?.length === 0 &&
      cohorts.completedCohorts?.length === 0 &&
      hubMemberCohorts.length === 0
    );
  };

  if ((!loading || !hubMemberLoading) && showNoEchos()) {
    return (
      <Container maxWidth="xl" sx={{ p: 10, mb: 2 }}>
        <Alert severity="info">
          You have not registered for any ECHO programs yet.{' '}
          <StyledRouterLink to="/programs" text="Find your next ECHO" />
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <ProgressIndicator isOpen={loading || hubMemberLoading} title="Loading..." />
      <Container maxWidth="xl" sx={{ p: 10, mb: 2 }}>
        <PageHeader title="My ECHOs" />

        <Box sx={{ textAlign: 'left', mb: 3 }}>
          <MyEchoCohortList
            title="Your Current ECHO Programs"
            cohorts={cohorts?.currentCohorts}
            showNextSession={true}
            showDetailLink={true}
          />
        </Box>

        <Box sx={{ textAlign: 'left', mb: 3 }}>
          <MyEchoCohortList
            title="Program Requests Pending Review"
            cohorts={cohorts?.pendingCohorts}
            showDetailLink={false}
          />
        </Box>

        <Box sx={{ textAlign: 'left', mb: 3 }}>
          <MyEchoCohortList
            title="Your Waitlist"
            cohorts={cohorts?.waitlistCohorts}
            showDetailLink={false}
          />
        </Box>

        <Box sx={{ textAlign: 'left', mb: 3 }}>
          <MyEchoCohortList
            title="Partnered ECHO Programs"
            cohorts={hubMemberCohorts}
            showDetailLink={true}
          />
        </Box>

        {cohorts?.completedCohorts && cohorts.completedCohorts.length > 0 && (
          <>
            <Divider sx={{ mb: 3 }} />

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h6" color="primary">
                  Completed ECHO Programs
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MyEchoCohortList cohorts={cohorts?.completedCohorts} showDetailLink={true} />
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Container>
    </>
  );
};

export default MyEchos;
