import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Paper, Tooltip } from '@mui/material';

import { getPartnersQuery_Gql } from '../../gql/partner/getPartnersQuery';
import { GetAllPartners, GetAllPartners_getPartners, DeletePartner, DeletePartnerVariables } from '../../models/GeneratedModels';
import AddPartnerButtonWithDrawer from '../../components/admin/partner/AddPartnerButtonWithDrawer';
import EditPartnerIconWithDrawer from '../../components/admin/partner/EditPartnerIconWithDrawer';
import PartnerAccountsIconWithDrawer from '../../components/admin/partner/PartnerAccountsIconWithDrawer';
import DeleteIcon from '../../components/global/Icons/DeleteIcon';

import { deletePartner_Gql } from '../../gql/partner/deletePartner';
import PermissionDeniedSplash from 'pages/PermissionDeniedSplash';
import { useAuth } from 'hooks/useAuth';

const Partners = () => {
  const [partners, setPartners] = useState<GetAllPartners_getPartners[] | null>([]);

  const { loading, data } = useQuery<GetAllPartners>(getPartnersQuery_Gql);

  useEffect(() => {
    if (loading || !data) return;

    setPartners(data.getPartners);
  }, [data, loading]);

  const [deletePartner] = useMutation<DeletePartner, DeletePartnerVariables>(
    deletePartner_Gql,
    { refetchQueries: [getPartnersQuery_Gql] }
  );

  const onDeleteClick = async (template: any) => {
    await deletePartner({
      variables: {
        partnerId: template.id,
      },
    });
  };

  const auth = useAuth();
  const isAuthorized = () => {
    return auth.user?.isAlbatross || auth.user?.isAdmin || auth.user?.isEchoStaff || auth.user?.isDevelopmentStaff || auth.user?.isImplementationStaff || auth.user?.isEvaluationStaff;
  };
  
  if (!isAuthorized()) {
    return <PermissionDeniedSplash />;
  }

  return (
    (!isAuthorized()) ? (
      <PermissionDeniedSplash />
    ) : (
    <>
      {isAuthorized() && (
      <Container maxWidth="xl" sx={{ p: 16, mb: 2 }}>
        <AddPartnerButtonWithDrawer />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Partner</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partners &&
                partners.map((partner) => (
                  <TableRow key={partner.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {partner.name}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title={`Edit ${partner.name}`}>
                        <EditPartnerIconWithDrawer partner={partner} />
                      </Tooltip>
                      <PartnerAccountsIconWithDrawer partnerName={partner.name} />
                      <DeleteIcon
                        iconTooltip={`Remove ${partner.name} from email partners?`}
                        dialogTitle="Remove Registration Question"
                        dialogText={`Are you sure you want to remove the ${partner.name} email partner?`}
                        confirmText="Remove"
                        onClose={() => onDeleteClick(partner)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      )}
    </>
  ));
};

export default Partners;
