import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { IconButton, Divider, Menu, MenuItem, Avatar, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LockIcon from '@mui/icons-material/Lock';
// import ChangePassword from '../admin/Account/ChangePassword';

import { useAuth } from '../../hooks/useAuth';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    dropdownLink: {
      textDecoration: 'none',
      color: theme.palette.warning.main,
    },
  })
);

const AccountDropdown = () => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutUser = () => {
    history.push('/login'); //TODO: This won't even work and I don't know why
    auth.signOut();
  };

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={{ color: 'secondary.main' }}>
            <AccountCircleIcon />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem disabled>{auth.user?.fullName}</MenuItem>
        <Divider />
        <MenuItem sx={{ color: 'warning.main', textDecoration: 'none' }}>
          <Link className={classes.dropdownLink} to="/my-echos">
            <ListItemIcon>
              <ListAltIcon sx={{ color: 'primary.main' }} />
            </ListItemIcon>{' '}
            My ECHOs
          </Link>
        </MenuItem>
        <MenuItem sx={{ color: 'warning.main', textDecoration: 'none' }}>
          <Link className={classes.dropdownLink} to="/my-account">
            {/* <ListItemIcon> */}
            <PersonIcon sx={{ color: 'primary.main', fontSize: '20px', mr: 1 }} />
            {/* </ListItemIcon>{' '} */}
            My Account
          </Link>
        </MenuItem>
        <MenuItem sx={{ color: 'warning.main', textDecoration: 'none' }}>
          <Link className={classes.dropdownLink} to="/change-password">
            <ListItemIcon>
              <LockIcon sx={{ color: 'primary.main' }} />
            </ListItemIcon>{' '}
            Change Password
          </Link>
        </MenuItem>
        <MenuItem onClick={() => logoutUser()} sx={{ color: 'warning.main', textDecoration: 'none' }}>
          <ListItemIcon sx={{ mr: 1 }}>
            <LogoutIcon sx={{ color: 'primary.main' }} />
          </ListItemIcon>{' '}
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountDropdown;
