import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ProgressIndicator from '../../global/ProgressIndicator';
import {
  GetCohortsForProgram_getCohortsForProgram,
  DuplicateCohort,
  DuplicateCohortVariables,
} from 'models/GeneratedModels';
import { getCohortsForProgramQuery_Name } from 'gql/cohort/getCohortsForProgramQuery';
import { duplicateCohort_Gql } from 'gql/cohort/duplicateCohort';

interface DuplicateCohortIconWithDialogProps {
  programName: string;
  cohort: GetCohortsForProgram_getCohortsForProgram;
  onDuplicateSuccess?: () => void;
}

const DuplicateCohortIconWithDialog = ({
  cohort,
  programName,
  onDuplicateSuccess,
}: DuplicateCohortIconWithDialogProps) => {
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [internalTitle, setInternalTitle] = useState(cohort.internalLabel);
  const [copyWaitlist, setCopyWaitlist] = useState(false);
  const [copySession, setCopySession] = useState(false);
  const [copyHubMembers, setCopyHubMembers] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasSaveError, setHasSaveError] = useState(false);
  const [saveErrorText, setSaveErrorText] = useState(false);

  const [duplicateCohort, { loading }] = useMutation<
    DuplicateCohort,
    DuplicateCohortVariables
  >(duplicateCohort_Gql);

  const handleClickOpen = () => {
    setDuplicateDialogOpen(true);
  };

  const handleClose = () => {
    setDuplicateDialogOpen(false);
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInternalTitle(value);
  };

  const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const checked = e.target.checked;

    if (name === 'waitlist') {
      setCopyWaitlist(checked);
    }

    if (name === 'sessions') {
      setCopySession(checked);
    }

    if (name === 'hubMembers') {
      setCopyHubMembers(checked);
    }
  };

  const onSaveClick = async () => {
    setHasError(false);
    setHasSaveError(false);
    setSaveErrorText(false);

    if (internalTitle && internalTitle.trim().length === 0) {
      setHasError(true);
      return;
    }

    try {
      await duplicateCohort({
        variables: {
          cohort: {
            cohortIdToDuplicate: cohort.id,
            newCohortInternalLabel: internalTitle || '',
            transferWaitlist: copyWaitlist,
            copySessions: copySession,
            copyHubMembers: copyHubMembers,
          },
        },
        refetchQueries: [getCohortsForProgramQuery_Name],
      });

      onDuplicateSuccess?.();
      handleClose();
    } catch (error: any) {
      setHasSaveError(true);
      setSaveErrorText(error.graphQLErrors[0].message);
    }
  };

  return (
    <>
      <Tooltip title={`Duplicate ${cohort.internalLabel}`}>
        <FileCopyIcon
          onClick={handleClickOpen}
          sx={{ color: 'primary.main', cursor: 'pointer' }}
        />
      </Tooltip>

      <Dialog open={duplicateDialogOpen} onClose={handleClose}>
        <DialogTitle>Duplicate {cohort.internalLabel}</DialogTitle>
        <DialogContent>
          <ProgressIndicator isOpen={loading} title="Saving..." />
          <DialogContentText>Program: {programName}</DialogContentText>

          <Divider sx={{ mb: 1, mt: 1 }} />

          <TextField
            sx={{ mt: 2 }}
            autoFocus
            margin="dense"
            id="internalTitle"
            label="Internal Title"
            fullWidth
            variant="outlined"
            color="warning"
            value={internalTitle}
            onChange={onTextChange}
          />

          <Grid container>
            <Grid xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={copyWaitlist}
                    name="waitlist"
                    onChange={onCheckChange}
                  />
                }
                label="Transfer Waitlist"
              />
              <br />
              <Typography sx={{ mb: 1 }} variant="caption">
                Copies over as new registrants
              </Typography>
            </Grid>
            <Grid xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={copySession}
                    name="sessions"
                    onChange={onCheckChange}
                  />
                }
                label="Copy Sessions"
              />
              <br />
              <Typography sx={{ mb: 1 }} variant="caption">
                Copies all sessions without the session date
              </Typography>
            </Grid>
            <Grid xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={copyHubMembers}
                    name="hubMembers"
                    onChange={onCheckChange}
                  />
                }
                label="Copy HubMember"
              />
              <br />
              <Typography sx={{ mb: 1 }} variant="caption">
                Copies all HubMembers as unassigned roles
              </Typography>
            </Grid>
          </Grid>

          {hasError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              An internal title is required
            </Alert>
          )}

          {hasSaveError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {saveErrorText}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="warning" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="warning" onClick={onSaveClick}>
            Duplicate Cohort
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DuplicateCohortIconWithDialog;
