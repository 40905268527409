import { Typography, Divider } from '@mui/material';
import { SectionHeaderProps } from '../LearnerAccountDetail.interfaces';

const SectionHeader = ({ title }: SectionHeaderProps) => {
  return (
    <>
      <Typography sx={{ textAlign: 'left' }} variant="h5">
        {title}
      </Typography>
      <Divider sx={{ mb: 2 }} />
    </>
  );
};

export default SectionHeader;
