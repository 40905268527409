import React, { useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import dayjs from 'dayjs';

import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import TextField from '@mui/material/TextField'; // Import TextField
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import InfoIcon from '@mui/icons-material/Info';

import { CurrentProjectListProps } from './CurrentProjectList.interfaces';
import PageHeader from '../../global/PageHeader';
import DrawerWithTrigger from '../../global/DrawerWithTrigger';
import ProjectListQuickView from './components/ProjectListQuickView';
import SortableTableColumn, {
  SortProperty,
  complexSort,
} from 'components/global/SortableTableColumn';
import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';

const formatDateForDisplay = (date?: Date, includeTime: boolean = false): string => {
  if (!date) return '';
  return convertDateToTimeZoneFromUtc(date, includeTime, false, false, true);
};

const CurrentProjectList = ({ title, cohorts, statusQuery, setStatusQuery }: CurrentProjectListProps) => {
  const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
      select: {
        textAlign: 'left',
      },
    })
  );
  const classes = useStyles();

  const [startDateQuery, setStartDateQuery] = useState<Date | null>(null);
  const [endDateQuery, setEndDateQuery] = useState<Date | null>(null);
  const sortPropDefault: SortProperty = {
    prop: 'internalLabel',
    order: 'asc'
  };
  const [sortProp, setSortProp] = useState<SortProperty>(sortPropDefault);

  const filteredData = cohorts.filter((item) => {
    const startDateComparison =
      item.startDate &&
      startDateQuery != null &&
      new Date(item.startDate).getTime() >= new Date(startDateQuery).getTime();
    const endDateComparison =
      item.endDate &&
      endDateQuery != null &&
      (item.isOngoing === true ||
        new Date(item.endDate).getTime() <= new Date(endDateQuery).getTime());

    let cumulativeMatch =
      (startDateQuery === null ||
        (startDateQuery != null && startDateComparison)) &&
      (endDateQuery === null || (endDateQuery != null && endDateComparison));

    return cumulativeMatch;
  }).map((item) => ({
    ...item,
    programName: item.program?.name?.trim().toLowerCase(),
  }));

  return (
    <>
      <PageHeader title={title} />

      <Card sx={{ width: '100%', mb: 3, textAlign: 'left' }}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item lg={4} xs={12}>
              <InputLabel id="credentials-label">Status</InputLabel>
              <Select
                label="Status"
                value={statusQuery}
                className={classes.select}
                onChange={(e) => setStatusQuery(e.target.value)}
                sx={{ width: '70%' }}
              >
                <MenuItem value="All">View All</MenuItem>
                <MenuItem value="Development">Development</MenuItem>
                <MenuItem value="Live">Live</MenuItem>
                <MenuItem value="Post Series">Post Series</MenuItem>
                <MenuItem value="Archived">Archived</MenuItem>
                <MenuItem value="Active">
                  Active (everything except for archived)
                </MenuItem>
              </Select>
            </Grid>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item lg={3} xs={12}>
                <InputLabel id="credentials-label">Start Date</InputLabel>
                <DatePicker
                  slotProps={{ textField: {} }}
                  value={startDateQuery}
                  className={classes.select}
                  onChange={(date: Date | null) => setStartDateQuery(date)}
                  sx={{ width: '90%' }}
                />
              </Grid>
              <Grid item lg={3} xs={12}>
                <InputLabel id="credentials-label">End Date</InputLabel>
                <DatePicker
                  value={endDateQuery}
                  className={classes.select}
                  onChange={(date: Date | null) => setEndDateQuery(date)}
                  slotProps={{ textField: {} }}
                  sx={{ width: '90%' }}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </CardContent>
      </Card>
      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="internalLabel"
                headerText="Cohort Title"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="preRegistrantsCount"
                headerText="Pre Registrants"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="newRegistrantsCount"
                headerText="New Registrants"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="registeredCount"
                headerText="Registered"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="startDate"
                headerText="Start Date"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="endDate"
                headerText="End Date"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="nextSessionDate"
                headerText="Next Session"
                order={sortProp?.order}
              />
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {complexSort(filteredData, sortProp.order ? sortProp : sortPropDefault).map((c) => (
              <TableRow>
                <TableCell>
                  <Tooltip title={`Dashboard for ${c.internalLabel}`}>
                    <Link component={RouterLink} to={`/admin/cohort/${c.id}`}>
                      {c.internalLabel}
                    </Link>
                  </Tooltip>
                </TableCell>
                <TableCell>{c.preRegistrantsCount !== 0 ? c.preRegistrantsCount : ''}</TableCell>
                <TableCell>{c.newRegistrantsCount !== 0 ? c.newRegistrantsCount: ''}</TableCell>
                <TableCell>{c.registeredCount !== 0 ? c.registeredCount : ''}</TableCell>
                <TableCell>{formatDateForDisplay(c.startDate)}</TableCell>
                <TableCell>
                  {c.isOngoing ? 'Ongoing' : formatDateForDisplay(c.endDate)}
                </TableCell>
                <TableCell>
                  {c.nextSessionDate !== 'No session currently scheduled' ? c.nextSessionDate : ''}
                </TableCell>
                <TableCell>
                  <Tooltip title={`More Info for ${c.internalLabel}`}>
                    <DrawerWithTrigger
                      trigger={
                        <InfoIcon
                          sx={{ color: 'primary.main', cursor: 'pointer' }}
                        />
                      }
                      width={450}
                      title={c.internalLabel ?? ''}
                      showSave={false}
                      showCancel={true}
                      isOpen={false}
                      textAlign="center"
                      anchor="right"
                      cancelText="Close"
                    >
                      <ProjectListQuickView
                        preRegistrantsCount={c.preRegistrantsCount}
                        newRegistrantsCount={c.newRegistrantsCount}
                        registeredCount={c.registeredCount}
                        waitlistCount={c.waitlistCount}
                        deniedCount={c.deniedCount}
                        cohortId={c.id}
                        meetingLink={c.meetingLink}
                        developmentSupportUser={c?.developmentSupportUser?.map(user => `${user?.firstName} ${user?.lastName}`) || []}
                        evaluationSupportUser={c?.evaluationSupportUser?.map(user => `${user?.firstName} ${user?.lastName}`) || []}
                        implementationSupportUser={c?.implementationSupportUser?.map(user => `${user?.firstName} ${user?.lastName}`) || []}
                        registrationOpenDate={c.registrationOpenDate}
                        registrationCloseDate={c.registrationCloseDate}
                        developmentUser={c.developmentUser?.fullName ?? ''}
                        evaluationUser={c.evaluationUser?.fullName ?? ''}
                        implementationUser={
                          c.implementationUser?.fullName ?? ''
                        }
                      />
                    </DrawerWithTrigger>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CurrentProjectList;
