import { gql } from '@apollo/client';

export const addCohortRegistrationMutation_Name = 'AddCohortRegistration';
export const addCohortRegistrationMutation_Gql = gql`
  mutation AddCohortRegistration($cohortRegistration: AddCohortRegistrationInput!) {
    addCohortRegistration(cohortRegistration: $cohortRegistration) {
      id
    }
  }
`;
