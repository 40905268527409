import Typography from '@mui/material/Typography';

const OregonLoginHeader = () => {
  return (
    <Typography variant="body1">
      Welcome to the Oregon ECHO Network. Please enter your login info or create an account. We look forward to learning
      with you.
    </Typography>
  );
};

export default OregonLoginHeader;
