import { gql } from '@apollo/client';

export const getLearnerCohorts_Name = 'GetLearnerCohorts';
export const getLearnerCohorts_Gql = gql`
  query GetLearnerCohorts($userId: ID!) {
    getLearnerCohorts(userId: $userId) {
      currentCohorts {
        id
        externalLabel
        startDate
        endDate
        isOngoing
        meetingLink
        nextSessionDate
      }
      pendingCohorts {
        id
        externalLabel
        startDate
        endDate
        isOngoing
        meetingLink
        nextSessionDate
      }
      waitlistCohorts {
        id
        externalLabel
        startDate
        endDate
        isOngoing
        meetingLink
        nextSessionDate
      }
      completedCohorts {
        id
        externalLabel
        startDate
        endDate
        isOngoing
        meetingLink
        nextSessionDate
      }
    }
  }
`;
