import { gql } from '@apollo/client';

export const SendEmail_Name = 'SendEmail';
export const SendEmail_Gql = gql`
  mutation SendEmail($email: SendEmailInput!) {
    sendEmail(email: $email) {
      success
    }
  }
`;
