import { Typography, Box } from '@mui/material';
import { LabelAndValueProps } from '../../cohort/Cohort.interfaces';
import Label from './Label';

const LabelAndValue = ({ label, value }: LabelAndValueProps) => {
  return (
    <>
      {value && (
        <Box sx={{ mb: 2 }}>
          <Label text={label} />

          <Typography variant="body1" color="textPrimary">
            <div dangerouslySetInnerHTML={{ __html: value.replaceAll('&amp;', '&') }} />
          </Typography>
        </Box>
      )}
    </>
  );
};

export default LabelAndValue;
