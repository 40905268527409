import { gql } from '@apollo/client';

export const updateStaffUser_Name = 'UpdateStaffUser';
export const updateStaffUser_Gql = gql`
  mutation UpdateStaffUser($user: AddOrUpdateStaffUserInput!) {
    updateStaffUser(user: $user) {
      id
      firstName
      lastName
      email
    }
  }
`;
