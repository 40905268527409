import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

interface StyledRouterLinkProps {
  to: string;
  text: string;
}

const StyledRouterLink = ({ to, text }: StyledRouterLinkProps) => {
  return (
    <Link sx={{ textDecoration: 'none', color: 'primary.main' }} component={RouterLink} to={to}>
      {text}
    </Link>
  );
};

export default StyledRouterLink;
