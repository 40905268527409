import { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';


import { getRegistrantsForCohortQuery_Name } from 'gql/cohortRegistration/getRegistrantsForCohortQuery';
import { denyCohortRegistrationMutation_Gql } from 'gql/cohortRegistration/denyCohortRegistrationMutation';
import { DenyCohortRegistrationInput } from 'models/GeneratedModels';
import { DenialTextForRegistration_Gql } from 'gql/cohortRegistration/denialTextForRegistration';
import {
  GetRegistrationDenialEmail,
  GetRegistrationDenialEmailVariables,
} from 'models/GeneratedModels';

import 'react-quill/dist/quill.snow.css';
import Mailer from './Mailer';
import { GetRegistrantsForCohort_getRegistrantsForCohort } from '../../../../../models/GeneratedModels';
interface DenyIconProps {
  registration: GetRegistrantsForCohort_getRegistrantsForCohort;
  denyRegistrationInput: DenyCohortRegistrationInput;
}

const DenyIcon = ({ registration, denyRegistrationInput }: DenyIconProps) => {
  const { id, decisionUserId } = denyRegistrationInput;

  const [denialSubject, setDenialSubject] = useState('');
  const [denialText, setDenialText] = useState('');

  const { loading: templateLoading, data } = useQuery<GetRegistrationDenialEmail, GetRegistrationDenialEmailVariables>(
    DenialTextForRegistration_Gql,
    {
      variables: {
        cohortRegistrationId: id,
      },
    }
  );

  const [denyUser, { loading: denySaving, error: savingError }] = useMutation(denyCohortRegistrationMutation_Gql, {
    variables: {
      cohortRegistration: denyRegistrationInput,
    },
    refetchQueries: [getRegistrantsForCohortQuery_Name],
  });

  useEffect(() => {
    if (data?.getRegistrationDenialEmail) {
      setDenialSubject(data.getRegistrationDenialEmail.subject);
      setDenialText(data.getRegistrationDenialEmail.text);
    }
  }, [data]);

  return (
    <Mailer
      renderTrigger={(onClick) => (
        <Tooltip title="Deny Registrant">
          <CloseIcon onClick={onClick} sx={{ color: 'primary.main', cursor: 'pointer', mr: 1 }} />
        </Tooltip>
      )}
      popupTitle="Deny Registration"
      subject={denialSubject}
      body={denialText}
      recipients={[registration]}
      senderId={decisionUserId}
      progressText={templateLoading ? 'Loading...' : denySaving ? 'Saving...' : ''}
      errorText={savingError ? savingError?.graphQLErrors[0].message : ''}
      submitButtonText="Send Denial"
      submitButtonTextAlt="Deny"
      checkSendEmail
      onSubmit={denyUser}
    />
  );
};

export default DenyIcon;
