import { gql } from '@apollo/client';

export const getCohortByHubMember_Name = 'GetCohortByHubMember';
export const getCohortByHubMember_Gql = gql`
  query GetCohortByHubMember($userid: ID!) {
    getCohortByHubMember(userid: $userid) {
      id
      externalLabel
      startDate
      endDate
      isOngoing
      meetingLink
      nextSessionDate
    }
  }
`;
