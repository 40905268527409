import GenericEmailTemplate from '../../../models/GenericEmailTemplate.model';
import {
  GetEmailTemplatesForTenant_getEmailTemplatesForTenant,
  GetEmailTemplatesForProgram_getEmailTemplatesForProgram,
  EmailCategory,
} from '../../../models/GeneratedModels';

export interface EmailTemplatesByCategory {
  accountTemplates: GenericEmailTemplate[];
  cohortTemplates: GenericEmailTemplate[];
  hubTeamTemplates: GenericEmailTemplate[];
  registrationTemplates: GenericEmailTemplate[];
  templatesNoCategory: GenericEmailTemplate[];
}

export const getEmailTemplatesByCategory = (
  templates: GetEmailTemplatesForTenant_getEmailTemplatesForTenant[]
): EmailTemplatesByCategory => {
  const genericTemplates: GenericEmailTemplate[] = [];

  if (templates.length > 0) {
    templates.forEach((t) => {
      genericTemplates.push({
        emailCategory: t.emailCategory,
        id: t.id,
        name: t.name,
        template: t.template,
        subject: t.subject,
      });
    });

    return getTemplatesByCategory(genericTemplates);
  }

  return getEmptyTemplateSet();
};

export const getProgramEmailTemplatesByCategory = (
  templates: GetEmailTemplatesForProgram_getEmailTemplatesForProgram[]
): EmailTemplatesByCategory => {
  const genericTemplates: GenericEmailTemplate[] = [];

  if (templates.length > 0) {
    templates.forEach((t) => {
      genericTemplates.push({
        emailCategory: t.emailCategory,
        id: t.id,
        name: t.name,
        template: t.template,
        subject: t.subject,
      });
    });

    return getTemplatesByCategory(genericTemplates);
  }

  return getEmptyTemplateSet();
};

function getTemplatesByCategory(templates: GenericEmailTemplate[]): EmailTemplatesByCategory {
  return {
    accountTemplates: templates.filter((t) => t.emailCategory === EmailCategory.account),
    cohortTemplates: templates.filter((t) => t.emailCategory === EmailCategory.cohort),
    hubTeamTemplates: templates.filter((t) => t.emailCategory === EmailCategory.hubTeam),
    registrationTemplates: templates.filter((t) => t.emailCategory === EmailCategory.registration),
    templatesNoCategory: templates.filter((t) => t.emailCategory === null),
  };
}

function getEmptyTemplateSet(): EmailTemplatesByCategory {
  return {
    accountTemplates: [],
    cohortTemplates: [],
    hubTeamTemplates: [],
    registrationTemplates: [],
    templatesNoCategory: [],
  };
}
