import { gql } from '@apollo/client';

export const getRegistrantsForCohortQuery_Name = 'GetRegistrantsForCohort';
export const getRegistrantsForCohortQuery_Gql = gql`
  query GetRegistrantsForCohort($cohortId: String!) {
    getRegistrantsForCohort(cohortId: $cohortId) {
      id
      userId
      isActive
      isApproved
      isDenied
      isWaitlisted
      copiedFromWaitlist
      createdAt
      registrationAnswers
      user {
        id
        firstName
        lastName
        email
        organization
        userCredentialsForDisplay
        profession {
          name
        }
      }
    }
  }
`;
