import { gql } from '@apollo/client';

export const getDataForResourceEdit_Name = 'GetDataForResourceEdit';
export const getDataForResourceEdit_Gql = gql`
  query GetDataForResourceEdit($resourceId: String!) {
    getDataForResourceEdit(resourceId: $resourceId) {
      resource {
        id
        name
        displayName
        resourceTypeId
        url
      }
      resourceTypes {
        id
        name
      }
      cohorts {
        id
        internalLabel
        sessions {
          id
          title
          number
        }
      }
      cohortResources {
        id
        resourceId
        cohortId
        sessionId
      }
    }
  }
`;
