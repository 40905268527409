import { gql } from '@apollo/client';

export const getEmailTemplatesForTenant_Name = 'GetEmailTemplatesForTenant';
export const getEmailTemplatesForTenant_Gql = gql`
  query GetEmailTemplatesForTenant {
    getEmailTemplatesForTenant {
      emailCategory
      id
      name
      subject
      template
    }
  }
`;
