import { gql } from '@apollo/client';

export const getProgramById_Name = 'GetProgramById';
export const getProgramById_Gql = gql`
  query GetProgramById($id: ID!) {
    getProgramById(id: $id) {
      id
      name
      partnerId
      createdAt
      updatedAt
      isArchived
      resources {
        displayName
        name
        resourceTypeId
        url
        createdAt
      }
      cohorts {
        id
        startDate
        endDate
        archived
        internalLabel
        registrationCloseDate
        isOngoing
      }
    }
  }
`;
