// import React, { ReactNode, useState } from 'react';
// import { Theme, createStyles, useTheme } from '@mui/material/styles';
import React from 'react';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Chip
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { GetSessionsByCohort_getSessionsByCohort } from '../../../../models/GeneratedModels';

const R = require('ramda');

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, selectValue: readonly string[], theme: Theme) {
  return {
    fontWeight:
      selectValue.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

interface HubTeamSessionSelectorProps {
  title: string;
  id: string;
  checkName: string;
  checkLabel: string;
  checkValue: boolean;
  selectLabel: string;
  selectName: string;
  selectValue: string[];
  sessions: GetSessionsByCohort_getSessionsByCohort[];
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, selectName: string) => void;
  onMultiSelectChange: (e: SelectChangeEvent<string[]>) => void;
}

const HubTeamSessionSelector = (props: HubTeamSessionSelectorProps) => {
  const {
    title,
    id,
    checkName,
    checkLabel,
    checkValue,
    selectLabel,
    selectName,
    selectValue,
    sessions,
    onCheckboxChange,
    onMultiSelectChange,
  } = props;

  const theme = useTheme();

  const getSessionNameById = (sessionId: string) => {
    const matchingSessionIndex = R.findIndex(R.propEq('id', sessionId))(sessions);

    return matchingSessionIndex >= 0 ? sessions[matchingSessionIndex].title : sessionId;
  };

  return (
    <>
      <Grid container display='flex' justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Typography variant="h6">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            sx={{ mr: 0 }}
            control={
              <Checkbox
                size='small'
                color="primary"
                checked={checkValue} onChange={(e) => onCheckboxChange(e, selectName)}
                name={checkName}
              />
            }
            label={checkLabel}
          />
        </Grid>
      </Grid>
      <FormControl fullWidth size='small' disabled={checkValue}>
        <InputLabel id={`${id}-select-label`} color="warning">
          {selectLabel}
        </InputLabel>
        <Select
          multiple
          labelId={`${id}-select-label`}
          id={`${id}-select`}
          value={selectValue}
          name={selectName}
          label={selectLabel}
          color="warning"
          onChange={onMultiSelectChange}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip size='small' color="warning" key={value} label={getSessionNameById(value)} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {sessions &&
            sessions.map((s) => (
              <MenuItem key={s.id} value={s.id} style={getStyles(s.id, selectValue, theme)}>
                {s.title}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default HubTeamSessionSelector;
