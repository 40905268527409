import { gql } from '@apollo/client';

export const deleteEmailTemplate_Name = 'DeleteEmailTemplate';
export const deleteEmailTemplate_Gql = gql`
  mutation DeleteEmailTemplate($emailTemplateId: String!) {
    deleteEmailTemplate(emailTemplateId: $emailTemplateId) {
      id
    }
  }
`;
