import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Tab from '@mui/material/Tab';
// import Tabs from '@mui/material/Tabs';
// import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

import DrawerWithTrigger from 'components/global/DrawerWithTrigger';
import SearchExistingUser from './SearchExistingUser';
// import AddNewHubTeamAccount from './AddNewHubTeamAccount';

interface AddHubMemberProps {
  cohortId: string;
}

const AddHubMember = ({ cohortId }: AddHubMemberProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  // Tab Management
  // const [activeTab, setActiveTab] = useState(0);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setActiveTab(newValue);
  // };

  return (
    <>
      <DrawerWithTrigger
        trigger={
          <Button
            variant="outlined"
            size="small"
            startIcon={<AddIcon />}
            sx={{ color: 'primary.main', cursor: 'pointer' }}
          >
            Add Hub Team Member
          </Button>
        }
        width={550}
        title="Add Hub Member"
        showSave={false}
        showCancel={false}
        isOpen={isDrawerOpen}
        openDrawer={openDrawer}
      >
        <Box sx={{ width: '100%' }}>
          {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={activeTab} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Search for Existing User" {...a11yProps(0)} />
              <Tab label="Add New User Account" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={activeTab} index={0}> */}
            <SearchExistingUser cohortId={cohortId} onCloseDrawer={onCloseDrawer} />
          {/* </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <AddNewHubTeamAccount cohortId={cohortId} />
          </TabPanel> */}
        </Box>
      </DrawerWithTrigger>
    </>
  );
};

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index: number) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

export default AddHubMember;
