import Typography from '@mui/material/Typography';

const ColoradoLoginHeader = () => {
  return (
    <Typography variant="body1">
      Welcome to ECHO Colorado. Please enter your login info or create an account, so you can be a part of making health
      knowledge ECHO across the state and beyond.
    </Typography>
  );
};

export default ColoradoLoginHeader;
