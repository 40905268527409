import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Tabs, Tab } from '@mui/material';

import TabPanel from './TabPanel';
import PageHeader from '../../../global/PageHeader';
import ProgressIndicator from '../../../global/ProgressIndicator';
import PreRegistrants from './Registrants/PreRegistrants';
import RegistrantList from './Registrants/RegistrantList';

import { GetRegistrantsForCohort_getRegistrantsForCohort } from '../../../../models/GeneratedModels';
import { getRegistrantsForCohortQuery_Gql } from '../../../../gql/cohortRegistration/getRegistrantsForCohortQuery';
import { getPreRegistrantsForCohortQuery_Gql } from '../../../../gql/cohortPreRegistration/getPreRegistrantsForCohortQuery';
import { GetPreRegistrantsForCohort_getPreRegistrantsForCohort } from '../../../../models/GeneratedModels';

const R = require('ramda');

interface CohortRegistrantsProps {
  cohortId: string;
  internalLabel: string | null;
}

const CohortRegistrants = ({ cohortId, internalLabel }: CohortRegistrantsProps) => {
  // const { cohortId } = useParams<CohortParams>();
  const [tabValue, setTabValue] = React.useState(0);
  const [showProgress, setShowProgress] = useState(false);

  const [preRegistrants, setPreRegistrants] = useState<GetPreRegistrantsForCohort_getPreRegistrantsForCohort[]>([]);
  const [newRegistrants, setNewRegistrants] = useState<GetRegistrantsForCohort_getRegistrantsForCohort[]>([]);
  const [approvedRegistrants, setApprovedRegistrants] = useState<GetRegistrantsForCohort_getRegistrantsForCohort[]>([]);
  const [deniedRegistrants, setDeniedRegistrants] = useState<GetRegistrantsForCohort_getRegistrantsForCohort[]>([]);
  const [waitlistedRegistrants, setWaitlistedRegistrants] = useState<GetRegistrantsForCohort_getRegistrantsForCohort[]>(
    []
  );

  const { loading: preRegLoading, data: preRegData } = useQuery(getPreRegistrantsForCohortQuery_Gql, {
    variables: {
      cohortId,
    },
  });

  useEffect(() => {
    setShowProgress(preRegLoading);
    if (preRegLoading || !preRegData) return;

    setPreRegistrants(preRegData.getPreRegistrantsForCohort);
  }, [preRegData, preRegLoading]);

  const { loading, data } = useQuery(getRegistrantsForCohortQuery_Gql, {
    variables: {
      cohortId,
    },
  });

  useEffect(() => {
    setShowProgress(loading);
    if (loading || !data) return;

    const allRegistrants = data.getRegistrantsForCohort;
    if (allRegistrants) {
      // New registrants
      const isNew = (x: GetRegistrantsForCohort_getRegistrantsForCohort) =>
        !x.isApproved && !x.isWaitlisted && !x.isDenied;

      setNewRegistrants(R.filter(isNew, allRegistrants));

      // Approved registrants
      const isApproved = (x: GetRegistrantsForCohort_getRegistrantsForCohort) => x.isApproved;
      setApprovedRegistrants(R.filter(isApproved, allRegistrants));

      // Denied registrants
      const isDenied = (x: GetRegistrantsForCohort_getRegistrantsForCohort) => x.isDenied;
      setDeniedRegistrants(R.filter(isDenied, allRegistrants));

      // Watlisted registrants
      const isWaitlisted = (x: GetRegistrantsForCohort_getRegistrantsForCohort) => x.isWaitlisted;
      setWaitlistedRegistrants(R.filter(isWaitlisted, allRegistrants));
    }
  }, [data, loading]);

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `registration-by-status-tab-${index}`,
      'aria-controls': `registration-by-status-${index}`,
    };
  };

  return (
    <>
      <PageHeader title="Registrants" />

      <ProgressIndicator isOpen={showProgress} title="Loading" />

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="registration by status tabs"
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label={`Pre-Registrants (${preRegistrants.length})`} {...a11yProps(0)} />
        <Tab label={`New (${newRegistrants.length})`} {...a11yProps(1)} />
        <Tab label={`Registered (${approvedRegistrants.length})`} {...a11yProps(2)} />
        <Tab label={`Waitlist (${waitlistedRegistrants.length})`} {...a11yProps(3)} />
        <Tab label={`Denied (${deniedRegistrants.length})`} {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <PreRegistrants cohortId={cohortId} internalLabel={internalLabel} preRegistrants={preRegistrants} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <RegistrantList registrants={newRegistrants} showActive={false} listType="new" />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <RegistrantList registrants={approvedRegistrants} showActive={true} listType="registered" />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <RegistrantList registrants={waitlistedRegistrants} showActive={false} listType="waitlist" />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <RegistrantList registrants={deniedRegistrants} showActive={false} listType="denied" />
      </TabPanel>
    </>
  );
};

export default CohortRegistrants;
