import React from 'react';
import Grid from '@mui/material/Grid';
import PageHeader from 'components/global/PageHeader';

interface HomeTitleAndBodyProps {
  title: string;
  children: React.ReactNode;
}

const HomeTitleAndBody = ({ title, children }: HomeTitleAndBodyProps) => {
  return (
    <Grid container sx={{ mt: 2 }}>
      <Grid item xs={2}></Grid>
      <Grid item xs={8}>
        <PageHeader title={title} />

        {children}
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
};

export default HomeTitleAndBody;
