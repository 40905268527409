import { gql } from '@apollo/client';

export const getHubTeamUsers_Name = 'GetHubTeamUsers';
export const getHubTeamUsers_Gql = gql`
  query GetHubTeamUsers {
    getHubTeamUsers {
      developmentUsers {
        id
        firstName
        lastName
      }
      implementationUsers {
        id
        firstName
        lastName
      }
      evaluationUsers {
        id
        firstName
        lastName
      }
    }
  }
`;
