import { useQuery, useMutation } from '@apollo/client';

import PageHeader from '../../components/global/PageHeader';
import ProgressIndicator from '../../components/global/ProgressIndicator';
import EmailTemplateList from '../../components/admin/EmailTemplate/EmailTemplateList';

import GenericEmailTemplate from '../../models/GenericEmailTemplate.model';
import {
  GetEmailTemplatesForTenant,
  GetEmailTemplatesForTenant_getEmailTemplatesForTenant,
  UpdateEmailTemplate,
  UpdateEmailTemplateVariables,
  DeleteEmailTemplate,
  DeleteEmailTemplateVariables,
} from '../../models/GeneratedModels';
import {
  getEmailTemplatesForTenant_Gql,
  getEmailTemplatesForTenant_Name,
} from '../../gql/emailTemplate/getEmailTemplatesForTenant';
import { updateEmailTemplate_Gql } from '../../gql/emailTemplate/updateEmailTemplate';
import { deleteEmailTemplate_Gql } from '../../gql/emailTemplate/deleteEmailTemplate';
import { getEmailTemplatesByCategory } from '../../components/admin/EmailTemplate/EmailTemplate.helpers';
import { Container } from '@mui/material';
import PermissionDeniedSplash from 'pages/PermissionDeniedSplash';
import { useAuth } from 'hooks/useAuth';

const EmailTemplates = () => {
  const { data, loading } = useQuery<GetEmailTemplatesForTenant>(getEmailTemplatesForTenant_Gql);

  const emailTemplates: GetEmailTemplatesForTenant_getEmailTemplatesForTenant[] =
    data?.getEmailTemplatesForTenant ?? [];
  const templatesByCategory = getEmailTemplatesByCategory(emailTemplates);

  const [updateEmailTemplate] = useMutation<UpdateEmailTemplate, UpdateEmailTemplateVariables>(
    updateEmailTemplate_Gql,
    { refetchQueries: [getEmailTemplatesForTenant_Name] }
  );

  const [deleteEmailTemplate] = useMutation<DeleteEmailTemplate, DeleteEmailTemplateVariables>(
    deleteEmailTemplate_Gql,
    { refetchQueries: [getEmailTemplatesForTenant_Name] }
  );

  const onSaveClick = async (template: GenericEmailTemplate) => {
    await updateEmailTemplate({
      variables: {
        template: {
          id: template.id,
          name: template.name,
          subject: template.subject,
          template: template.template,
        },
      },
    });
  };

  const onDeleteClick = async (template: GenericEmailTemplate) => {
    await deleteEmailTemplate({
      variables: {
        emailTemplateId: template.id,
      },
    });
  };

  const auth = useAuth();
  const isAuthorized = () => {
    return auth.user?.isAlbatross || auth.user?.isAdmin || auth.user?.isEchoStaff || auth.user?.isDevelopmentStaff || auth.user?.isImplementationStaff || auth.user?.isEvaluationStaff;
  };
  
  if (!isAuthorized()) {
    return <PermissionDeniedSplash />;
  }

  return (
    (!isAuthorized()) ? (
      <PermissionDeniedSplash />
    ) : (
    <>
      {isAuthorized() && (
      <>
      <ProgressIndicator isOpen={loading} title="Loading..." />
      <Container maxWidth="xl" sx={{ p: 10, mb: 2 }}>
        <PageHeader title="Manage Email Templates" />

        <EmailTemplateList
          accountTemplates={templatesByCategory.accountTemplates}
          cohortTemplates={templatesByCategory.cohortTemplates}
          hubTeamTemplates={templatesByCategory.hubTeamTemplates}
          registrationTemplates={templatesByCategory.registrationTemplates}
          templatesNoCategory={templatesByCategory.templatesNoCategory}
          onSaveClick={onSaveClick}
          onDeleteClick={onDeleteClick}
        />
      </Container>
      </>
      )}
    </>
  ));
};

export default EmailTemplates;
