import { gql } from '@apollo/client';

export const getEmailHistory_Name = 'GetEmailHistoryForUser';
export const getEmailHistory_Gql = gql`
  query GetEmailHistoryForUser($userId: ID!) {
    getEmailHistoryForUser(userId: $userId) {
      fromAddress
      subject
      text
      sentAt
    }
  }
`;
