import { createTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#be051b',
    },
    secondary: {
      main: '#FFF',
    },
    // type: 'light',
    text: {
      primary: '#000',
      secondary: '#000',
      disabled: '#666666',
    },
    warning: {
      main: '#000',
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#be051b",
        },
      }
    },
  },
});

export default theme;
