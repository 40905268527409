import { gql } from '@apollo/client';

export const deletePartner_Name = 'DeletePartner';
export const deletePartner_Gql = gql`
  mutation DeletePartner($partnerId: String!) {
    deletePartner(partnerId: $partnerId) {
      id
    }
  }
`;
