import { gql } from '@apollo/client';

export const getPartnersQuery_Name = 'GetAllPartners';
export const getPartnersQuery_Gql = gql`
  query GetAllPartners {
    getPartners {
      id
      name
    }
  }
`;
