import { gql } from '@apollo/client';

export const getResourceForProgram_Name = 'GetResourceByProgram';
export const getResourceForProgram_Gql = gql`
  query GetResourceByProgram($programId: String!) {
    getResourceByProgram(programId: $programId) {
      id
      programId
      resourceTypeId
      name
      displayName
      url
      archived
      createdAt
      resourceType {
        name
      }
    }
  }
`;
