import { gql } from '@apollo/client';

export const copyEmailTemplatesFromTenant_Name = 'CopyEmailTemplatesFromTenant';
export const copyEmailTemplatesFromTenant_Gql = gql`
  mutation CopyEmailTemplatesFromTenant($programId: String!) {
    copyEmailTemplatesFromTenant(programId: $programId) {
      id
      name
    }
  }
`;
