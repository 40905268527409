import { gql } from '@apollo/client';

export const duplicateCohort_Name = 'DuplicateCohort';
export const duplicateCohort_Gql = gql`
  mutation DuplicateCohort($cohort: DuplicateCohortInput!) {
    duplicateCohort(cohort: $cohort) {
      id
    }
  }
`;
