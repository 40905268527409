import { gql } from '@apollo/client';

export const getPreRegistrationsForUser_Name = 'GetPreRegistrationsForUser';
export const getPreRegistrationsForUser_Gql = gql`
  query GetPreRegistrationsForUser($userId: ID!) {
    getPreRegistrationsForUser(userId: $userId) {
      id
      cohortId
      userId
      email
      emailSent
      firstName
      lastName
      organization
      profession
      createdAt
    }
  }
`;
