import { Typography, Divider } from '@mui/material';

interface SectionHeaderProps {
  children: React.ReactNode;
}

const SectionHeader = ({ children }: SectionHeaderProps) => {
  return (
    <>
      <Typography sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: '16px', mt: 3 }}>
        {children}
      </Typography>
      <Divider sx={{ mb: 2, maxWidth: 600 }} />
    </>
  );
};

export default SectionHeader;
