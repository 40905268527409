import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Breadcrumbs, { BreadcrumbItem } from 'components/global/Breadcrumbs';
import LearnerCohortDetail from 'components/learner/LearnerCohortDetail/LearnerCohortDetail';
import PageHeader from 'components/global/PageHeader';
import ProgressIndicator from 'components/global/ProgressIndicator';

import { getCohortDetailForLearner_Gql } from 'gql/cohort/getCohortDetailForLearner';
import CohortParams from 'models/params/CohortParams';
import {
  GetCohortDetailForLearner,
  GetCohortDetailForLearnerVariables,
  GetCohortDetailForLearner_getCohortDetailForLearner,
} from 'models/GeneratedModels';
import { Container } from '@mui/material';

const LearnerCohortDashboard = () => {
  const { cohortId } = useParams<CohortParams>();

  const { data, loading } = useQuery<GetCohortDetailForLearner, GetCohortDetailForLearnerVariables>(
    getCohortDetailForLearner_Gql,
    {
      variables: {
        cohortId,
      },
    }
  );

  const cohort: GetCohortDetailForLearner_getCohortDetailForLearner | undefined = data?.getCohortDetailForLearner;

  const breadcrumbs: BreadcrumbItem[] = [
    {
      to: '/my-echos',
      text: 'My ECHOs',
      isActive: true,
    },
    {
      text: cohort?.externalLabel ?? '',
      isActive: false,
    },
  ];

  return (
    <>
      <Container maxWidth="xl" sx={{ p: 10, mb: 2 }}>
        {cohort && cohort.id && (
          <>
            <ProgressIndicator isOpen={loading} title="Loading..." />
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <PageHeader title={cohort?.externalLabel ?? ''} />

            <LearnerCohortDetail cohort={cohort} />
          </>
        )}
      </Container>
    </>
  );
};

export default LearnerCohortDashboard;
