import { useQuery } from '@apollo/client';

import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import ProgressIndicator from 'components/global/ProgressIndicator';
import LearnerCohortDetail from 'components/learner/LearnerCohortDetail/LearnerCohortDetail';
import PageHeader from '../../../components/global/PageHeader';

import { getCohortDetailForLearner_Gql } from '../../../gql/cohort/getCohortDetailForLearner';

import {
  GetCohortDetailForLearner,
  GetCohortDetailForLearnerVariables,
  GetCohortDetailForLearner_getCohortDetailForLearner,
} from '../../../models/GeneratedModels';


import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    editor: {
      height: '300px',
    },
    accountSelect: {
      width: '100%',
      marginBottom: 2,
      textAlign: 'left',
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ViewAsLearnerRegisteredProps {
    cohortId: string;
    isOpen: boolean;
    onClose: () => void;
}

const ViewAsLearnerRegistered = (props: ViewAsLearnerRegisteredProps) => {
  const {
    isOpen,
    onClose,
    cohortId,
  } = props;

  const getDialogTitle = () => {
     return 'View as Registered Learner';
  };
  
  const { data, loading } = useQuery<GetCohortDetailForLearner, GetCohortDetailForLearnerVariables>(
    getCohortDetailForLearner_Gql,
    {
      variables: {
        cohortId,
      },
    }
  );

  const cohort: GetCohortDetailForLearner_getCohortDetailForLearner | undefined = data?.getCohortDetailForLearner;

  const handleClose = () => {
    onClose();
  };

  const closeDialog = () => {
    handleClose();
  };

  
  return (
    <>
      <Dialog
        open={isOpen}
        PaperProps={{ 
          sx: {
            width: '80vw', // 80% of the viewport width
            height: '80vh', // 60% of the viewport height
            maxWidth: 'none', // To ensure dialog width is always responsive
            maxHeight: 'none', // To ensure dialog height is always responsive
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">{getDialogTitle()}</DialogTitle>
        <DialogContent>
        <>
          <ProgressIndicator isOpen={loading} title="Loading..." />
          
          {cohort && cohort.id && (
            <>
              <PageHeader title={cohort?.externalLabel ?? ''} />

              <LearnerCohortDetail cohort={cohort} />
            </>
          )}
        </>
        </DialogContent>
        <DialogActions>
            <>
              <Button
                sx={{ width: '200px' }}
                onClick={closeDialog}
                variant="contained"
                color="primary"
              >
                Close
              </Button>
            </>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewAsLearnerRegistered;