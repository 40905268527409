import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { useAuth } from '../../hooks/useAuth';

import CurrentProjectList from '../../components/admin/CurrentProjects/CurrentProjectList';
import { getAllOpenCohortsForUser_Gql } from '../../gql/cohort/getAllOpenCohortsForUser';
import {
  GetAllOpenCohortsForUser,
  GetAllOpenCohortsForUserVariables,
  GetAllOpenCohortsForUser_getAllOpenCohortsForUser,
} from '../../models/GeneratedModels';
import { Container } from '@mui/material';
import PermissionDeniedSplash from 'pages/PermissionDeniedSplash';

const MyCurrentProjects = () => {
  const [statusQuery, setStatusQuery] = useState<string>('Live');

  const auth = useAuth();

  const { data } = useQuery<GetAllOpenCohortsForUser, GetAllOpenCohortsForUserVariables>(getAllOpenCohortsForUser_Gql, {
    variables: {
      userId: auth.user?.id ?? '',
      status: statusQuery
    },
  });

  const cohorts: GetAllOpenCohortsForUser_getAllOpenCohortsForUser[] = data?.getAllOpenCohortsForUser ?? [];

  const isAuthorized = () => {
    return auth.user?.isAlbatross 
      || auth.user?.isAdmin 
      || auth.user?.isEchoStaff 
      || auth.user?.isDevelopmentStaff 
      || auth.user?.isImplementationStaff 
      || auth.user?.isEvaluationStaff
      || auth.user?.isPartner;
  };
  
  if (!isAuthorized()) {
    return <PermissionDeniedSplash />;
  }
  
  return (
    (!isAuthorized()) ? (
      <PermissionDeniedSplash />
    ) : (
    <>
      {isAuthorized() && (
      <Container maxWidth="xl" sx={{ p: 10, mb: 12 }}>
        <CurrentProjectList title="My Current Projects" cohorts={cohorts} statusQuery={statusQuery} setStatusQuery={setStatusQuery} />
      </Container>
      )}
    </>
  ));
};

export default MyCurrentProjects;
