import { HubMemberCohortSessionInput } from './../../../models/GeneratedModels';
import { FACILITATOR_ROLE_ID, PANELIST_ROLE_ID, PRESENTER_ROLE_ID } from '../../../utils/constants';
import { GetMembersByCohort_getMembersByCohort } from '../../../models/GeneratedModels';

export const combineSessionRoles = (
  hubTeamMemberId: string,
  sessionsAsFacilitator: string[],
  sessionsAsPresenter: string[],
  sessionsAsPanelist: string[]
) => {
  const sessions: HubMemberCohortSessionInput[] = [];

  sessionsAsFacilitator.forEach((s) => {
    sessions.push({
      hubMemberCohortId: hubTeamMemberId,
      sessionId: s,
      hubRoleId: FACILITATOR_ROLE_ID,
    });
  });

  sessionsAsPresenter.forEach((s) => {
    sessions.push({
      hubMemberCohortId: hubTeamMemberId,
      sessionId: s,
      hubRoleId: PRESENTER_ROLE_ID,
    });
  });

  sessionsAsPanelist.forEach((s) => {
    sessions.push({
      hubMemberCohortId: hubTeamMemberId,
      sessionId: s,
      hubRoleId: PANELIST_ROLE_ID,
    });
  });

  return sessions;
};

export const getMemberRoleList = (member: GetMembersByCohort_getMembersByCohort): string => {
  const roles: string[] = [];

  if (member.facilitateAll || member.sessionsAsFacilitator.length > 0) roles.push('Facilitator');
  if (member.presentAll || member.sessionsAsPresenter.length > 0) roles.push('Presenter');
  if (member.panelistAll || member.sessionsAsPanelist.length > 0) roles.push('Panelist');

  return roles.length > 0 ? roles.join(', ') : '';
};
